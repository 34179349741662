import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import moment from 'moment-timezone';

import {
  BasicDialog,
  Button,
  DateTimeInput,
  Form,
  SelectInput,
  TabularInput,
  TextInput,
  ToggleInput,
} from 'stti-react-common';

const { useFormController, Control } = Form;

const validFormat = 'YYYY-MM-DDTHH:mm';

const setLabelsByTemplate = (template) => {
  switch (template) {
    case 'dataDiagnosticRequest':
      return '';
    case 'forceResyncRequest':
      return 'Note: Force Resync does not clear data on the device nor push data to the Web Client. It forces the sync system to pull records from the Web Client and override the existing records on the Vehicle Client. This will ensure that the Vehicle Client has all records the server has.  There is a caveat; Records are not removed only override. If the Vehicle Client has a record the server does not, that record will still exist on the Vehicle Client.';
    case 'activateDevice':
      return '';
    case 'reActivateDevice':
      return '';
    default:
      return '';
  }
};

const shouldDisplayByTemplate = (template) => {
  switch (template) {
    case 'dataDiagnosticRequest':
      return '';
    case 'forceResyncRequest':
      return 'none';
    case 'activateDevice':
      return '';
    case 'reActivateDevice':
      return '';
    default:
      return '';
  }
};

const setParamsByTemplate = (template) => {
  switch (template) {
    case 'dataDiagnosticRequest':
      return [
        { key: 'startAt', value: moment().format(validFormat) },
        { key: 'endAt', value: moment().format(validFormat) },
      ];
    case 'forceResyncRequest':
      return [
        { key: 'operational', value: false, notes: 'Vehicles, Users and Organizational Units' },
        {
          key: 'documentation',
          value: false,
          notes: 'Eld Instructions, User Manual and Malfunction Instructions',
        },
        {
          key: 'eld',
          value: false,
          notes: 'All Eld/Driver log related data for the drive profile and unidentified profiles',
        },
        { key: 'geolocation', value: false, notes: 'Geozone and Geolocation database' },
        { key: 'messaging', value: false, notes: 'Just Messaging' },
        {
          key: 'configuration',
          value: false,
          notes:
            'Off Duty Reasons, Inspection Templates, Abort Reasons, Tire Pressure Limits, Speed Limits, Performance Rules',
        },
      ];
    case 'activateDevice':
      return [
        { key: 'activationCode', value: null },
        { key: 'assetTag', value: null },
      ];
    case 'reActivateDevice':
      return [
        { key: 'activationCode', value: null },
        { key: 'assetTag', value: null },
      ];
    default:
      return [];
  }
};

const setTypeByTemplate = (template) => {
  switch (template) {
    case 'noTemplate':
      return '';
    case 'dataDiagnosticRequest':
      return 'logAppender';
    default:
      return template;
  }
};

const setComponentByTemplate = (template) => {
  switch (template) {
    case 'dataDiagnosticRequest':
      return DateTimeInput;
    case 'forceResyncRequest':
      return ToggleInput;
    default:
      return TextInput;
  }
};

export const TabletDebugDialog = ({ isOpen, onClose, onConfirm, rowData }) => {
  const [currentTemplate, setCurrentTemplate] = useState('noTemplate');
  const [readOnly, setReadOnly] = useState(false);

  const form = useFormController({
    controls: {
      key: rowData.key,
      params: [],
    },
  });

  const { resetControls, controls, hasErrors, isDirty, setControl } = form;

  useEffect(() => {
    const readOnlyType = [
      'logAppender',
      'deactivateDevice',
      'activateDevice',
      'reActivateDevice',
      'forceResyncRequest',
    ].includes(controls.type);

    setReadOnly(readOnlyType);
  }, [controls]);

  useEffect(() => {
    resetControls();
  }, [rowData]);

  useEffect(() => {
    setCurrentTemplate('noTemplate');
  }, [isOpen]);

  useEffect(() => {
    setControl('params', setParamsByTemplate(currentTemplate));
    setControl('type', setTypeByTemplate(currentTemplate));
  }, [currentTemplate]);

  const setErrorByTemplate = (template, value) => {
    switch (template) {
      case 'dataDiagnosticRequest': {
        if (isEmpty(value)) {
          return true;
        }

        if (!moment(value).isValid() || value.length !== validFormat.length) {
          return `Invalid date/time entered, time must be formatted as ${validFormat}`;
        }
        return false;
      }
      case 'forceResyncRequest':
        return false;
      default:
        return !value;
    }
  };

  return (
    <BasicDialog
      className="TabletSummary__TabletDebugDialog"
      isOpen={isOpen}
      onClose={onClose}
      title={`Set Remote Action for: ${rowData.assetTag}`}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Add New Config',
          disabled: hasErrors || !isDirty,
          onClick: () => {
            const { params, ...rest } = controls;
            const payloadParams = map(params, ({ key, value }) => {
              if (key === 'startAt' || key === 'endAt') {
                return { key, value: moment(value).toISOString() };
              }
              return { key, value };
            });
            onConfirm({ ...rest, params: payloadParams });
            onClose();
          },
        },
      ]}
    >
      <SelectInput
        className="AdminSearchPanel__SelectInput--aligned"
        value={currentTemplate}
        options={[
          { label: 'No Template', value: 'noTemplate' },
          { label: 'Data Diagnostic Request', value: 'dataDiagnosticRequest' },
          { label: 'Force Resync', value: 'forceResyncRequest' },
          // { label: 'Deactivate Device', value: 'deactivateDevice' },
          // { label: 'Activate Device', value: 'activateDevice' },
          // { label: 'Re-activate Device', value: 'reActivateDevice' },
        ]}
        onChange={(value) => setCurrentTemplate(value)}
      />

      <Form form={form} className={currentTemplate}>
        <div className="TabletDebugDialog__controls">
          <Control
            name="type"
            Component={TextInput}
            label="Type"
            error={({ value }) => !value}
            readOnly={readOnly}
          />
        </div>
        <div className="TabletDebugDialog__diagnosticLabels">
          <span>
            <br />
            {setLabelsByTemplate(currentTemplate)}
          </span>
        </div>
        <div className="TabletDebugDialog__diagnosticKeyPairs">
          <Control
            Component={TabularInput}
            renderHeader={({ addRow }) => (
              <div className="TabularInput__title">
                <h4>Remote Action Params</h4>
                <Button
                  variant="outlined"
                  label="Add Key/Value"
                  onClick={addRow}
                  disabled={currentTemplate !== 'noTemplate'}
                  style={{
                    display: shouldDisplayByTemplate(currentTemplate),
                  }}
                />
              </div>
            )}
            renderRow={({ deleteRow }) => (
              <>
                <Control
                  Component={TextInput}
                  name="key"
                  {...(currentTemplate !== 'forceResyncRequest' && {
                    label: 'Key',
                  })}
                  error={({ value }) => !value}
                  disabled={currentTemplate !== 'noTemplate'}
                />
                <Control
                  Component={setComponentByTemplate(currentTemplate)}
                  name="value"
                  {...(currentTemplate !== 'forceResyncRequest' && {
                    label: 'Value',
                  })}
                  error={({ value }) => setErrorByTemplate(currentTemplate, value)}
                />
                {currentTemplate === 'forceResyncRequest' && (
                  <Control Component={TextInput} name="notes" type="text" multiline disabled />
                )}
                <Button
                  variant="outlined"
                  label="Delete Row"
                  icon="clear"
                  onClick={deleteRow}
                  disabled={currentTemplate !== 'noTemplate'}
                  style={{
                    display: shouldDisplayByTemplate(currentTemplate),
                  }}
                />
              </>
            )}
            name="params"
            ownErrorName="paramsTableError"
          />
        </div>
      </Form>
    </BasicDialog>
  );
};

TabletDebugDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};
