import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, TextInput } from 'stti-react-common';

export function TriggerInformation({ form, mode, triggerTypes, validateName, Control }) {
  const { name, triggerType } = form.controls;
  return (
    <div className="notification-detail__section">
      <Control
        className="full-width"
        Component={TextInput}
        name="name"
        label="Notification Name"
        maxLength={60}
        placeholder="Enter a unique name for the notification"
        error={({ value }) => validateName(value)}
        readOnly={mode === 'view'}
        value={name}
        onChange={(val) => form.setControl('name', val)}
      />
      <br />
      <br />
      <Control
        className="full-width"
        Component={SelectInput}
        name="triggerType"
        label="Notification Type"
        options={triggerTypes}
        placeholder="Select a notification type"
        readOnly={mode === 'view'}
        value={triggerType}
        onChange={(val) => form.setControl('triggerType', val.toUpperCase())}
        error={({ value }) => (!value ? 'This field is required.' : null)}
        isSearchable
      />
      <br />
      <br />
    </div>
  );
}

TriggerInformation.propTypes = {
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  triggerTypes: PropTypes.array.isRequired,
  validateName: PropTypes.func.isRequired,
  Control: PropTypes.elementType.isRequired,
};
