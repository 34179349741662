import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Activity',
    children: [
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Start Time',
      }),
      columnDef.dateColumn({
        field: 'endedAt',
        headerName: 'End Time',
        hide: true,
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Duration',
      }),
      columnDef.textColumn({
        field: 'vehicle.name',
        headerName: 'Vehicle',
        cellRenderer: 'Vehicle',
        rowGroupIndex: 0,
      }),
      columnDef.distanceColumn({
        field: 'vehicle.odometer',
        headerName: 'Odometer',
        aggFunc: null,
      }),
      columnDef.textColumn({
        field: 'vehicle.type',
        headerName: 'Vehicle Type',
        cellRenderer: 'VehicleType',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'ou.name',
        headerName: 'Organization',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'driver.name',
        headerName: 'Driver',
        cellRenderer: 'Driver',
      }),
      columnDef.textColumn({
        field: 'jurisdiction',
        headerName: 'Jurisdiction',
        cellRenderer: 'Jurisdiction',
      }),
      columnDef.textColumn({
        field: 'vendor',
        headerName: 'Vendor',
        cellRenderer: 'Vendor',
      }),
      columnDef.textColumn({
        field: 'location.name',
        headerName: 'Location',
        cellRenderer: 'Location',
      }),
    ],
  },
  {
    headerName: 'Details',
    children: [
      columnDef.distanceColumn({
        field: 'distance',
        headerName: 'Distance',
        aggFunc: 'sum',
      }),
      columnDef.textColumn({
        field: 'fuelType',
        headerName: 'Fuel Type',
        cellRenderer: 'FuelType',
      }),
      columnDef.fuelColumn({
        field: 'addedFuel',
        headerName: 'Fuel Quantity',
        width: 150,
        aggFunc: 'sum',
      }),
      columnDef.fuelColumn({
        field: 'addedDef',
        headerName: 'DEF Quantity',
        aggFunc: 'sum',
        hide: true,
      }),
    ],
  },
  {
    headerName: 'Est. Performance',
    children: [
      columnDef.mileageColumn({
        colId: 'mileage',
        headerName: 'Mileage',
        distanceField: 'distance',
        fuelField: 'addedFuel',
        fuelTypeField: 'fuelType',
        width: 200,
      }),
    ],
  },
];
