import React from 'react';
import FleetTrack from '@streamline-fleet-track';

import './FleetTrack.scss';

const FleetTrackWrapper = (props) => (
  <div className="FleetTrack">
    <FleetTrack {...props} />
  </div>
);

export { FleetTrackWrapper };
