import { isEmpty, isNil } from 'lodash';

import { dateObject } from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

const dateProperties = (logDate, timeZone) => {
  // TODO: use common util for driverLogs and unidentifiedLogs
  const startOfDayMoment = dateObject(logDate, timeZone).startOf('day');
  const endOfDayMoment = dateObject(logDate, timeZone).endOf('day');
  const isPartial = dateObject().isBefore(endOfDayMoment);

  return {
    logDate,
    timeZone,
    startedAt: startOfDayMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    endedAt: isPartial ? undefined : endOfDayMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
  };
};

const driverObject = ({ driver }) => {
  if (!driver) return { name: '', firstName: '', lastName: '' };

  if (!driver.name || isEmpty(driver.name)) {
    return { name: '', firstName: '', lastName: '', ...driver };
  }

  return {
    firstName: driver.name.split(' ')[0],
    lastName: driver.name.split(' ')[1],
    ...driver,
  };
};

const transformUnidentifiedLog = (unidentifiedLog) => {
  const {
    logDate,
    timeZone,
    ous,
    onDutyDrivingTotal,
    onDutyNotDrivingTotal,
    dutyStatusChanges = [],
  } = unidentifiedLog;

  const onDutyTotal = (onDutyDrivingTotal || 0) + (onDutyNotDrivingTotal || 0) || null;

  return {
    onDutyTotal,
    ...unidentifiedLog,
    ...dateProperties(logDate, timeZone),
    dutyStatusChanges: dutyStatusChanges.map((dutyStatusChange) => ({
      timeZone,
      ...dutyStatusChange,
    })),
    ouKey: ous.map(({ key }) => key), // for ouKeys filter (also is reason for singular "ouKey")
    hasActivity: !!onDutyTotal,
    driver: driverObject(unidentifiedLog),
  };
};

// fetchUnidentifiedLogs({ startDate, endDate, ouKey })
export const fetchUnidentifiedLogs = makeRestApiAction({
  service: 'unidentifiedLogs',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    start: dateObject(startDate).format('YYYY-MM-DD'),
    end: dateObject(endDate).format('YYYY-MM-DD'),
    ouKey,
  }),
  transformOutput: (response, actionParams) =>
    response
      .map(transformUnidentifiedLog)
      .filter(({ hasActivity }) =>
        isNil(actionParams.hasActivity) ? true : hasActivity === actionParams.hasActivity
      ),
  baseActionType: ACTION_TYPE.fetchUnidentifiedLogs,
  notificationsItemDescriptor: 'unidentified driving logs',
});

export const fetchUnidentifiedLog = makeRestApiAction({
  service: 'unidentifiedLogDetail',
  method: 'get',
  getId: ({ vehicleKey, date }) => `${vehicleKey}/${date}`,
  transformInput: () => {},
  transformOutput: transformUnidentifiedLog,
  baseActionType: ACTION_TYPE.fetchUnidentifiedLog,
  notificationsItemDescriptor: 'unidentified driving log',
});

export const fetchUnidentifiedLogForCanada = makeRestApiAction({
  service: 'unidentifiedLogDetail',
  method: 'get',
  getId: ({ vehicleKey, date }) => `${vehicleKey}/${date}?us=false`,
  transformInput: () => {},
  transformOutput: transformUnidentifiedLog,
  baseActionType: ACTION_TYPE.fetchUnidentifiedLog,
  notificationsItemDescriptor: 'unidentified driving log',
});

export const submitAssignLogDutyStatusEvent = makeRestApiAction({
  service: 'driverLogDutyStatus',
  method: 'put',
  baseActionType: ACTION_TYPE.submitAssignLogDutyStatusEvent,
  getId: ({ key }) => `${key}/reassign`,
  transformInput: ({ toDriverKey, movementType, annotation }) => ({
    toDriverKey,
    movementType,
    annotation,
  }),
  transformOutput: (responseData, requestData) => ({
    status: 'INACTIVE_CHANGE_REQUESTED',
    ...requestData,
  }),
  notificationsItemDescriptor: 'log event driver',
});

export const submitAssignLogDutyStatusEvents =
  ({ dutyIntervalKeys, logKey, toDriverKey, movementType, annotation }) =>
  async (dispatch) => {
    dutyIntervalKeys.forEach((key) => {
      submitAssignLogDutyStatusEvent({
        key,
        logKey,
        toDriverKey,
        movementType,
        annotation,
      })(dispatch);
    });
  };
