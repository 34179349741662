import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';

import { getFormatLabel } from '../../../utils/formats';

export const ReportSettingsForm = ({ content, control }) => (
  <form>
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid size={6}>
        <h3>Display Formats</h3>
        {Object.entries(content.display).map(([key, value]) => (
          <Controller
            key={key}
            name={key}
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel>{getFormatLabel(key)}</InputLabel>
                <Select {...field} sx={{ mb: 1 }}>
                  {value.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ))}
      </Grid>
      <Grid size={6}>
        <h3>Export Formats</h3>
        {Object.entries(content.export).map(([key, value]) => (
          <Controller
            key={key}
            name={key}
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel>{getFormatLabel(key)}</InputLabel>
                <Select {...field} sx={{ mb: 1 }} disabled={key === 'dateExportFormat'}>
                  {value.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ))}
      </Grid>
    </Grid>
  </form>
);

ReportSettingsForm.propTypes = {
  content: PropTypes.object,
  control: PropTypes.object,
};
