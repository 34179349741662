// NotificationTypeMalfunction.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { RenderVehicles } from '../../NotificationRenders/RenderVehicles';
import MalfunctionToggleGrid from '../shared/MalfunctionToggleGrid';

const NotificationTypeMalfunction = ({
  formControls,
  form,
  mode,
  vehicleOptions,
  Control,
}) => {
  // Mapping from API enum to UI label
  const MALFUNCTIONS_TO_FORM_TYPE = {
    POWER: 'Power',
    ENGINE_SYNC: 'Engine Sync',
    TIMING: 'Timing',
    POSITIONING: 'Positioning',
    DATA_RECORDING: 'Data Recording',
    DATA_TRANSFER: 'Data Transfer',
    OTHER: 'Other',
  };

  // Define the malfunction types to render
  const malfunctionTypes = [
    { label: 'Power', description: 'Issues receiving info from ECM.' },
    { label: 'Engine Sync', description: 'Issues connecting & receiving from ECM.' },
    { label: 'Timing', description: 'Unable to determine correct time.' },
    { label: 'Positioning', description: 'Unable to determine position.' },
    { label: 'Data Recording', description: 'Unable to record information.' },
    { label: 'Data Transfer', description: 'Unable to transfer info to authority.' },
    { label: 'Other', description: 'Other unexpected malfunction.' },
  ];

  // Get the current malfunction selections from formControls
  const raisedMalfunctionsArray = formControls.raisedMalfunctions || [];
  const clearedMalfunctionsArray = formControls.clearedMalfunctions || [];

  const raisedMalfunctionsSet = new Set(
    raisedMalfunctionsArray.map(apiVal => MALFUNCTIONS_TO_FORM_TYPE[apiVal] || apiVal)
  );
  const clearedMalfunctionsSet = new Set(
    clearedMalfunctionsArray.map(apiVal => MALFUNCTIONS_TO_FORM_TYPE[apiVal] || apiVal)
  );

  // Build rows for MalfunctionToggleGrid
  const rows = malfunctionTypes.map(({ label, description }) => ({
    label,
    description,
    isRaised: raisedMalfunctionsSet.has(label),
    isCleared: clearedMalfunctionsSet.has(label),
  }));

  // Handlers for toggling
  const onToggleRaised = (label, checked) => {
    const updated = new Set(raisedMalfunctionsSet);
    if (checked) {
      updated.add(label);
    } else {
      updated.delete(label);
    }
    const FORM_TYPE_TO_MALFUNCTIONS = Object.fromEntries(
      Object.entries(MALFUNCTIONS_TO_FORM_TYPE).map(([apiKey, uiLabel]) => [uiLabel, apiKey])
    );
    form.setControl(
      'raisedMalfunctions',
      Array.from(updated).map(lbl => FORM_TYPE_TO_MALFUNCTIONS[lbl])
    );
  };

  const onToggleCleared = (label, checked) => {
    const updated = new Set(clearedMalfunctionsSet);
    if (checked) {
      updated.add(label);
    } else {
      updated.delete(label);
    }
    const FORM_TYPE_TO_MALFUNCTIONS = Object.fromEntries(
      Object.entries(MALFUNCTIONS_TO_FORM_TYPE).map(([apiKey, uiLabel]) => [uiLabel, apiKey])
    );
    form.setControl(
      'clearedMalfunctions',
      Array.from(updated).map(lbl => FORM_TYPE_TO_MALFUNCTIONS[lbl])
    );
  };

  return (
    <div className="notification-criteria-one-column">
      <h3 className="section-title">Malfunction Notification</h3>
      <p className="section-description">
        Notify based on malfunctions encountered.
      </p>
      <div className="notification-criteria-fields">
        <MalfunctionToggleGrid
          rows={rows}
          onToggleRaised={onToggleRaised}
          onToggleCleared={onToggleCleared}
          Control={Control}
          mode={mode}
        />
      </div>
      <div className="notification-criteria-fields">
        <RenderVehicles
          formControls={formControls}
          form={form}
          mode={mode}
          vehicleOptions={vehicleOptions}
          Control={Control}
        />
      </div>
    </div>
  );
};

NotificationTypeMalfunction.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeMalfunction;
