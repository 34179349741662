import React from 'react';

import { configureRoute } from '../../../config';
import { BasicRouteFrame } from './RouteFrame';

import { FleetTracking } from '../../tracking';

import {
  SUPER_ADMIN_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
  GUEST_ROLE_KEY,
  MANAGER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
} from '../../../data/system/constants';

const fleetTracking = configureRoute({
  name: 'FleetTracking',
  path: '/fleetTracking',
  RouteFrame: BasicRouteFrame,
  permissions: [
    SUPER_ADMIN_ROLE_KEY,
    DISPATCHER_ROLE_KEY,
    MANAGER_ROLE_KEY,
    MECHANIC_ROLE_KEY,
    GUEST_ROLE_KEY,
  ],
  render: (props) => <FleetTracking {...props} />,
});

export const fleetTrackingRoutes = [fleetTracking];
