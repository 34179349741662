// NotificationTypeInspection.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ToggleGrid from '../shared/ToggleGrid';
import ToggleRow from '../shared/ToggleRow';
import { RenderVehicles } from '../../NotificationRenders/RenderVehicles';

const NotificationTypeInspection = ({
  formControls,
  form,
  mode,
  vehicleOptions,
  Control,
}) => {
  const { majorDefects, minorDefects, unsafeToDrive } = formControls;

  const unsafeError = useMemo(() => {
    if (!majorDefects && !minorDefects && !unsafeToDrive) {
      return 'At least one of major, minor, or unsafe must be selected.';
    }
    return null;
  }, [majorDefects, minorDefects, unsafeToDrive]);

  return (
    <div className="notification-criteria-one-column">
      <h3 className="section-title">Inspection Notification</h3>
      <p className="section-description">
        Notify based on inspection results (major defects, minor defects, or unsafe conditions).
      </p>
      <div className="notification-criteria-config">
        <ToggleGrid>
          <ToggleRow
            label="Major Defects"
            name="majorDefects"
            description="Triggered when major defects occur."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
          />
          <ToggleRow
            label="Minor Defects"
            name="minorDefects"
            description="Triggered when minor defects occur."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
          />
          <ToggleRow
            label="Unsafe"
            name="unsafeToDrive"
            description="Triggered when the vehicle is deemed unsafe."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={unsafeError}
          />
        </ToggleGrid>
      </div>
      <div className="notification-criteria-fields">
        <RenderVehicles
          formControls={formControls}
          form={form}
          mode={mode}
          vehicleOptions={vehicleOptions}
          Control={Control}
        />
      </div>
    </div>
  );
};

NotificationTypeInspection.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeInspection;
