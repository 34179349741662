import produce from 'immer';
import { sortBy } from 'lodash';

import {
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  actionTypeFailure,
  shallowMergeEntities,
  removeEntity,
  deepMergeEntities,
} from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchBreadcrumbs: 'reports/breadcrumbs/fetchBreadcrumbs',
  fetchRefinedBreadcrumbs: 'reports/breadcrumbs/fetchRefinedBreadcrumbs',
};

const initialState = {
  byVehicleKey: {},
  fetches: [],
  refinedFetches: [],
};

export const breadcrumbsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchBreadcrumbs,
        ACTION_TYPE.fetchRefinedBreadcrumbs,
      ])
    ) {
      if (payload.length > 0) {
        const { vehicleKey } = payload[0]; // all breadcrumbs will be from same vehicle
        draft.byVehicleKey[vehicleKey] = sortBy(
          deepMergeEntities(state.byVehicleKey[vehicleKey] || [], payload, 'sequence'), // TODO: determine when sequence resets (to prevent unintentional replacement)
          'eventAt'
        );
      }
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchBreadcrumbs)) {
      const { id, requestData } = request;
      draft.fetches = shallowMergeEntities(state.fetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchBreadcrumbs)) {
      draft.fetches = removeEntity(state.fetches, request);
    }

    if (actionTypeRequest(type, ACTION_TYPE.fetchRefinedBreadcrumbs)) {
      const { id, requestData } = request;
      draft.refinedFetches = shallowMergeEntities(state.refinedFetches, { id, requestData });
    }

    if (actionTypeFailure(type, ACTION_TYPE.fetchRefinedBreadcrumbs)) {
      draft.refinedFetches = removeEntity(state.refinedFetches, request);
    }
  });
