import { connect } from 'react-redux';
import { ShiftDetailReport } from './ShiftDetailReport';
import { shifts, breadcrumbs } from '../../../../data/reports';
import { selectSystemActiveOu } from '../../../../data/system';

export const ShiftDetailReportContainer = connect(
  (state, { shiftId }) => ({
    shift: shifts.selectors.selectShift(state, { id: shiftId }),
    activities: shifts.selectors.selectShiftActivities(state, { shiftId }),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
    ou: selectSystemActiveOu(state),
  }),
  {
    fetchShifts: shifts.actions.fetchShifts,
    fetchShift: shifts.actions.fetchShift,
    fetchShiftActivities: shifts.actions.fetchShiftActivities,
  }
)(ShiftDetailReport);
