/* eslint-disable no-console */
import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  RenderingType,
} from '@vis.gl/react-google-maps';
import lowerCase from 'lodash/lowerCase';

import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EntityProvider } from '../context/EntityContext';
import { TilesProvider } from '../context/TilesContext';
import { Geozones } from './Geozones';
import { MarkerCluster } from './MarkerCluster';
import { BookMarks } from './BookMarks';

import AutoComplete from './AutoComplete';
import BottomPanel from './BottomPanel';
import Modal from './Modal';
import SideBar from './SideBar';

import {
  DEFAULT_LOCATION,
  DEFAULT_MAP_ID,
  DEFAULT_UNIT_SYSTEM,
  DEFAULT_ZOOM,
  STORAGE_KEYS,
} from '../utils/constants';

import Config from './Config';

import '../css/FleetTrack.css';

const initialState = {
  entity: null,
  users: [],
  messages: [],
  setMessages: () => {},
};

const entityReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STORE':
      return { ...action.payload };
    default:
      return state;
  }
};

const FleetTrack = (props) => {
  const { api, currentSystemOus, currentSystemOu } = props;
  const [state, dispatch] = useReducer(entityReducer, initialState);
  const [isOpen, setIsOpen] = useState(false);

  const settings = JSON.parse(localStorage.getItem(STORAGE_KEYS.settings) || '[]');
  localStorage.setItem(STORAGE_KEYS.settings, JSON.stringify(settings));

  const showSearchBar = settings.includes('showSearchBar');

  return (
    <APIProvider apiKey={api.googleMapsApiKey}>
      <EntityProvider values={{ ...props, dispatch }}>
        <Map
          defaultCenter={DEFAULT_LOCATION}
          defaultZoom={DEFAULT_ZOOM}
          minZoom={3}
          mapId={DEFAULT_MAP_ID}
          mapTypeControlOptions={{
            position: ControlPosition.TOP_CENTER,
          }}
          fullscreenControlOptions={{
            position: ControlPosition.RIGHT_BOTTOM,
          }}
          reuseMaps
          renderingType={RenderingType.VECTOR}
        >
          <MapControl position={ControlPosition.TOP_CENTER}>
            {showSearchBar && <AutoComplete />}
          </MapControl>

          <MapControl position={ControlPosition.LEFT}>
            <SideBar ous={currentSystemOus} />
          </MapControl>
          <MapControl position={ControlPosition.TOP_CENTER}>
            <Geozones unitSystem={lowerCase(currentSystemOu?.unitSystem) || DEFAULT_UNIT_SYSTEM} />
          </MapControl>
          <MapControl position={ControlPosition.TOP_RIGHT}>
            <BookMarks />
          </MapControl>
          <MapControl position={ControlPosition.RIGHT_BOTTOM}>
            <button type="button" className="config__btn" onClick={() => setIsOpen(true)}>
              <FontAwesomeIcon icon={faGear} fontSize={18} />
            </button>
          </MapControl>
          <MarkerCluster
            unitSystem={lowerCase(currentSystemOu?.unitSystem) || DEFAULT_UNIT_SYSTEM}
          />
        </Map>
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={
            <>
              <FontAwesomeIcon icon={faGear} fontSize={14} />
              <span>Map Options</span>
            </>
          }
        >
          <Config onClose={() => setIsOpen(false)} />
        </Modal>
      </EntityProvider>
      <TilesProvider values={{ ...props, state }}>
        <BottomPanel />
      </TilesProvider>
    </APIProvider>
  );
};

FleetTrack.propTypes = {
  api: PropTypes.object.isRequired,
  currentSystemOus: PropTypes.array.isRequired,
  currentSystemOu: PropTypes.object.isRequired,
};

export default FleetTrack;
