import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

// stti-react-common (example imports)
import { Form, ProgressOverlay } from 'stti-react-common';

// Reusable components/logic
import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { Feature } from '../../../../commons/Feature';
import { TriggerInformation } from './NotificationRenders/RenderTriggerInformation';
import { NotificationCriteria } from './NotificationRenders/RenderNotificationCriteria';
import { NotificationDestinations } from './NotificationRenders/RenderNotificationDestinations';
import { SectionCard } from '../../../../commons/Notifications/SectionCard';
import { useApiError } from '../../../../../helpers/hooks';
import { getStatesByCountry } from '../../../../../helpers/admin/adminUtils';
import {
  mapNotificationToFormControls,
  buildTriggerConfiguration,
  NOTIFICATION_TYPE,
  TRIGGER_TYPE_TO_API_TYPE,
} from '../../../../../helpers/admin/notifications/notificationsUtils';
import { validateNotificationName as validateName } from '../../../../../helpers/form/validations';
// SCSS for styling
import './detail.scss';

// This map tells which resources to fetch based on triggerType
const TRIGGER_TYPE_RESOURCE_MAP = {
  GEOZONE: ['geozones', 'vehicles', 'users'],
  INSPECTION: ['vehicles', 'users'],
  LOAD_UNLOAD: ['vehicles', 'users'],
  STATE: ['vehicles', 'users'],
  MALFUNCTION: ['vehicles', 'users'],
  TIME_DEFERRAL: ['drivers', 'users'],
  REGULATION: ['drivers', 'users'],
};

/**
 * Custom hook to load resources needed by the current triggerType
 */
function useNotificationData({
  initialMode,
  notification,
  notificationId,
  fetchNotification,
  fetchGeozones,
  fetchVehicles,
  fetchDrivers,
  fetchUsers,
  fetchCountries,
  countriesList,
}) {
  const [loading, setLoading] = useState(true);
  const { apiError, setApiError } = useApiError();

  const [loadedData, setLoadedData] = useState({
    geozones: false,
    vehicles: false,
    drivers: false,
    users: false,
    countries: false,
  });

  // Helper to load any resources needed
  const loadResources = useCallback(
    async (resourcesNeeded = []) => {
      const tasks = resourcesNeeded.map((resource) => {
        if (!loadedData[resource]) {
          switch (resource) {
            case 'geozones':
              return fetchGeozones().then(() =>
                setLoadedData((prev) => ({ ...prev, geozones: true }))
              );
            case 'vehicles':
              return fetchVehicles().then(() =>
                setLoadedData((prev) => ({ ...prev, vehicles: true }))
              );
            case 'drivers':
              return fetchDrivers().then(() =>
                setLoadedData((prev) => ({ ...prev, drivers: true }))
              );
            case 'users':
              return fetchUsers().then(() => setLoadedData((prev) => ({ ...prev, users: true })));
            case 'countries':
              return fetchCountries().then(() =>
                setLoadedData((prev) => ({ ...prev, countries: true }))
              );
            default:
              return Promise.resolve();
          }
        }
        return Promise.resolve();
      });

      await Promise.all(tasks);
    },
    [loadedData, fetchGeozones, fetchVehicles, fetchDrivers, fetchUsers, fetchCountries]
  );

  // Initial fetch
  useEffect(() => {
    async function fetchAllData() {
      try {
        // If in view/edit mode (not create), and we have an ID, but no loaded notification
        if (initialMode !== 'create' && notificationId && !notification) {
          await fetchNotification({ id: notificationId });
        }

        // Always fetch countries first
        await loadResources(['countries']);
        setLoading(false);
      } catch (error) {
        setApiError(error.message);
        setLoading(false);
      }
    }

    fetchAllData();
  }, [initialMode, notificationId, notification, fetchNotification, loadResources, setApiError]);

  return {
    loading,
    apiError,
    setApiError,
    loadResources,
    countriesList,
  };
}

/**
 * Main Notification Detail Component
 */
export function NotificationDetailComponent({
  notificationId,
  notification,
  fetchNotification,
  deleteNotification,
  fetchGeozones,
  fetchVehicles,
  fetchDrivers,
  fetchUsers,
  fetchCountries,
  geozones,
  vehicles,
  drivers,
  users,
  countriesList,
  mode: initialMode = 'view',
  route,
  createNotification,
  updateNotification,
}) {
  // Our custom hook to load data
  const { loading, apiError, setApiError, loadResources } = useNotificationData({
    initialMode,
    notification,
    notificationId,
    fetchNotification,
    fetchGeozones,
    fetchVehicles,
    fetchDrivers,
    fetchUsers,
    fetchCountries,
    countriesList,
  });

  // Local states
  const [mode, setMode] = useState(initialMode);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [destinations, setDestinations] = useState({
    emails: [],
    phoneNumbers: [],
    userKeys: [],
  });

  const [availableProvinces, setAvailableProvinces] = useState([]);

  const { useFormController, Control } = Form;
  const form = useFormController({
    controls:
      initialMode !== 'create' && notification
        ? mapNotificationToFormControls(notification)
        : { name: '', triggerType: '', vehicles: [], geozones: [], drivers: [] },
  });
  const { controls: formControls, resetControls } = form;

  // On load, set up the form & load resources for the triggerType
  useEffect(() => {
    if (loading) return;

    (async function setupForm() {
      if (initialMode !== 'create' && notification) {
        const data = mapNotificationToFormControls(notification);
        resetControls(data);

        // Initialize destinations from the existing notification
        if (notification.destination) {
          setDestinations({
            emails: notification.destination.emails || [],
            phoneNumbers: notification.destination.phoneNumbers || [],
            userKeys: notification.destination.userKeys || [],
          });
        }
      }

      // Load resources for the chosen triggerType
      const triggerValue = formControls.triggerType || 'GEOZONE';
      await loadResourcesForTrigger(triggerValue);
    })();
  }, [loading, notification, initialMode]);

  // Update "availableProvinces" whenever country changes
  useEffect(() => {
    if (formControls.country) {
      const provinces = getStatesByCountry(countriesList, formControls.country) || [];
      setAvailableProvinces(provinces);

      // If existing provinceStateKeys are invalid, remove them
      if (formControls.provinceStateKeys?.length) {
        const valid = formControls.provinceStateKeys.filter((p) =>
          provinces.some((x) => x.code === p)
        );
        if (valid.length !== formControls.provinceStateKeys.length) {
          form.setControl('provinceStateKeys', valid);
        }
      }
    } else {
      setAvailableProvinces([]);
      if (formControls.provinceStateKeys?.length) {
        form.setControl('provinceStateKeys', []);
      }
    }
  }, [formControls.country, countriesList, formControls.provinceStateKeys, form]);

  // Loads resources for a specific trigger type
  const loadResourcesForTrigger = useCallback(
    async (triggerType) => {
      const upper = (triggerType || '').toUpperCase();
      const resourcesNeeded = TRIGGER_TYPE_RESOURCE_MAP[upper] || [];
      await loadResources([...resourcesNeeded, 'countries']);
    },
    [loadResources]
  );

  // If user changes the trigger type
  const handleChangeNotificationType = async (value) => {
    const newTriggerType = value ? value.toUpperCase() : '';
    form.setControl('triggerType', newTriggerType);
    resetFormCriteriaFields();
    await loadResourcesForTrigger(newTriggerType);
  };

  // Reset certain fields whenever the trigger type changes
  const resetFormCriteriaFields = () => {
    setDestinations({ emails: [], phoneNumbers: [], userKeys: [] });
    const resetFields = {
      geozones: [],
      provinceStateKeys: [],
      vehicles: [],
      drivers: [],
      destination: {},
      entered: false,
      exited: false,
      loadCompleted: false,
      unloadCompleted: false,
      majorDefects: false,
      minorDefects: false,
      unsafeToDrive: false,
      triggerCondition: '',
      country: '',
      raisedMalfunctions: [],
      clearedMalfunctions: [],
    };
    Object.entries(resetFields).forEach(([key, val]) => form.setControl(key, val));
  };

  // Handle create/update calls
  const handleSubmit = async (data, isUpdate = false) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (form.hasErrors) {
      setIsSubmitting(false);
      throw new Error('Validation failed');
    }

    // Build the final body for the API
    const userObjects = destinations.userKeys.map((key) => ({ key }));
    const triggerConfiguration = buildTriggerConfiguration(data);
    const body = {
      type: NOTIFICATION_TYPE.ALERT,
      triggerType: TRIGGER_TYPE_TO_API_TYPE[data.triggerType],
      triggerConfiguration,
      name: data.name,
      destination: {
        emails: destinations.emails,
        phoneNumbers: destinations.phoneNumbers,
        users: userObjects,
      },
    };

    try {
      if (isUpdate) {
        await updateNotification({ id: notificationId, body });
      } else {
        await createNotification(body);
      }
      route.close(); // close the view
    } catch (error) {
      setIsSubmitting(false);
      setApiError(
        error?.response?.data?.message ||
          error.message ||
          (isUpdate
            ? 'Failed to update notification trigger.'
            : 'Failed to create notification trigger.')
      );
    }
  };

  // Creates a new item
  const createItem = (data) => handleSubmit(data, false);
  // Updates existing item
  const updateItem = (data) => handleSubmit(data, true);

  // Handle deletion -> calls /notifications/triggers/{id}
  const handleDelete = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await deleteNotification({ id: notificationId });
      route.close();
    } catch (error) {
      setIsSubmitting(false);
      setApiError(
        error?.response?.data?.message || error.message || 'Failed to delete notification trigger.'
      );
    }
  };

  // Prepare user options for the "Users" select
  const userOptions = useMemo(
    () =>
      users.map((u) => ({
        value: u.key,
        label: u.fullName || `${u.firstName} ${u.lastName}`,
      })),
    [users]
  );

  // Define trigger types for the form
  const triggerTypes = useMemo(
    () => [
      { value: 'GEOZONE', label: 'Geozone' },
      { value: 'INSPECTION', label: 'Inspection' },
      { value: 'LOAD_UNLOAD', label: 'Load/Unload Activity' },
      { value: 'STATE', label: 'State/Province' },
      { value: 'TIME_DEFERRAL', label: 'Driver ELD Deferral Applied' },
      { value: 'REGULATION', label: 'Driver ELD Regulation Changed' },
      { value: 'MALFUNCTION', label: 'ELD Malfunction Raised/Cleared' },
    ],
    []
  );

  if (loading) {
    return (
      <div className="NotificationDetail">
        <ProgressOverlay isOpen={loading} />
      </div>
    );
  }

  return (
    <Feature.Wrapper className="NotificationDetail">
      <CrudAdminItem
        title="Notification"
        form={form}
        itemTitle={formControls.name || 'Untitled Notification'}
        hasError={form.hasErrors}
        isDirty={form.isDirty}
        mode={mode}
        onApiError={setApiError}
        setMode={setMode}
        data={formControls}
        createItem={createItem}
        updateItem={updateItem}
        deleteItem={handleDelete}
        onClose={route.close}
        resetControls={form.resetControls}
        saveDisabled={isSubmitting}
      >
        <Feature.Body>
          <ErrorSummary errorMsg={apiError} />

          {/* 1) Trigger Info */}
          <SectionCard
            title="1. Notification Information"
            description="Enter a custom name for this notification, and select the type."
          >
            <TriggerInformation
              form={form}
              mode={mode}
              triggerTypes={triggerTypes}
              validateName={validateName}
              Control={Control}
              onChangeNotificationType={handleChangeNotificationType}
            />
          </SectionCard>

          {/* 2) Criteria */}
          <SectionCard
            title="2. Notification Criteria"
            description="Set the criteria to trigger the notification. "
          >
            <NotificationCriteria
              form={form}
              mode={mode}
              geozones={geozones}
              vehicles={vehicles}
              drivers={drivers}
              countriesList={countriesList}
              availableProvinces={availableProvinces}
              Control={Control}
            />
          </SectionCard>

          {/* 3) Destinations */}
          <SectionCard
            title="3. Notification Recipients"
            description="Define who should recieve an Email or an SMS (US/CANADA) when this notification is triggered."
          >
            <NotificationDestinations
              form={form}
              mode={mode}
              userOptions={userOptions}
              destinations={destinations}
              setDestinations={setDestinations}
              Control={Control}
            />
          </SectionCard>
        </Feature.Body>
      </CrudAdminItem>
    </Feature.Wrapper>
  );
}

NotificationDetailComponent.propTypes = {
  notification: PropTypes.object,
  notificationId: PropTypes.string,
  fetchNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  fetchGeozones: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  fetchDrivers: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  geozones: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  route: PropTypes.object.isRequired,
  createNotification: PropTypes.func.isRequired,
  updateNotification: PropTypes.func.isRequired,
};

export default NotificationDetailComponent;
