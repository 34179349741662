import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { Controller } from 'react-hook-form';

import AutoComplete from '../../Autocomplete';

export const EmailReportForm = ({ content, control, currentSystemUser, methods }) => (
  <form>
    <AutoComplete
      initialValue={
        currentSystemUser?.fullName
          ? `${currentSystemUser.fullName} - ${currentSystemUser.email}`
          : ` - ${currentSystemUser?.email}`
      }
      name="toEmails"
      label="Deliver to"
      options={uniq(
        uniqBy(content, 'email').map(({ email, fullName }) => `${fullName} - ${email}`)
      ).sort()}
      multiple
      freeSolo
      methods={methods}
      rules={{
        required: true,
        requiredMessage: 'Email is required',
        pattern: /\S+@\S+\.\S+/,
        patternMessage: 'Please enter a valid email',
      }}
    />
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        mt: 2,
      }}
    >
      <Grid size={4}>
        <Controller
          name="attach"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel>File attachment</InputLabel>
              <Select {...field}>
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="pdf,csv">PDF and CSV</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={4}>
        <Controller
          name="orientation"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel>PDF orientation</InputLabel>
              <Select {...field}>
                <MenuItem value="portrait">Portrait</MenuItem>
                <MenuItem value="landscape">Landscape</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid size={4}>
        <FormControlLabel
          control={
            <Switch onChange={(e) => methods?.setValue('includeDetail', e.target.checked)} />
          }
          label="Include Detail Reports"
        />
      </Grid>
    </Grid>
    <p>Report must be saved before it can be scheduled.</p>
  </form>
);

EmailReportForm.propTypes = {
  content: PropTypes.array,
  control: PropTypes.object,
  currentSystemUser: PropTypes.object,
  methods: PropTypes.object,
};
