async function apiGet({ url, token }) {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    return null;
  }

  try {
    const data = response.status !== 204 ? await response.json() : [];
    return data;
  } catch (error) {
    console.error('apiGet', error);
    return null;
  }
}

async function apiPost({ url, token, body }) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return null;
  }

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('apiPost', error);
    return null;
  }
}

async function apiPut({ url, token, body }) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return null;
  }

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('apiPut', error);
    return null;
  }
}

async function apiPatch({ url, token, body }) {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return null;
  }

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('apiPatch', error);
    return null;
  }
}

async function apiDelete({ url, token }) {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    return response;
  } catch (error) {
    console.error('apiDelete', error);
    return null;
  }
}

export { apiGet, apiPost, apiPut, apiPatch, apiDelete };
