/**
 * selectQueuedNotifications(state) -> [notification]
 *
 * Selector for all queued notifications (i.e. not yet picked up by Notifier)
 */
export const selectQueuedNotifications = (state) => state.notifications.queued;

export const selectNotificationTriggers = (state) => state.notifications.data.triggers;

export const selectNotification = (state, notificationId) => {
  const triggers = state.notifications.data.triggers || [];
  return triggers.find((trigger) => trigger.id === notificationId) || null;
};
