import {
  NO_VIOLATION_STATUS,
  REVIEWED_VIOLATION_STATUS,
  UN_REVIEWED_VIOLATION_STATUS,
} from '../../../../../../data/reports/driverLogs/constants';

const activity = [
  { key: true, label: 'With Activity' },
  { key: false, label: 'Without Activity' },
];

const driver = [
  { key: true, label: 'Enabled' },
  { key: false, label: 'Disabled' },
];

const diagnostic = [
  { key: true, label: 'With Diagnostic' },
  { key: false, label: 'Without Diagnostic' },
];

const malfunction = [
  { key: true, label: 'With Malfunction' },
  { key: false, label: 'Without Malfunction' },
];

const signed = [
  { key: true, label: 'Yes' },
  { key: false, label: 'No' },
];

export const report = {
  code: 'DRIVER_LOGS_SUMMARY',
  title: 'Driver Log Summary Report',
  query: {
    controllers: ['ouMode', 'dateRangeFor', 'dateRangeMode'],
    readOnlyFor: ['dateRangeFor'],
    dataRangeIgnoredModes: ['monthly', 'quarterly'],
    eagerLoad: true,
    validationMode: 'onBlur',
    services: { base: '/api/driverLogs/', url: 'summaries', extra: ['logViolations'] },
  },
  filter: {
    controllers: [
      { id: 'driver', filter: 'driverName', label: 'Drivers', sort: 'name', uniq: 'key' },
      { id: 'ous', filter: 'ouNames', label: 'Organizations', sort: 'name', uniq: 'key' },
      { id: 'vehicles', filter: 'vehicleNames', label: 'Vehicles', sort: 'name', uniq: 'key' },
      { id: 'hasActivity', filter: 'hasActivity', label: 'Has Activity', options: activity },
      {
        id: 'enabled',
        filter: 'enabled',
        label: 'Driver Enabled',
        options: driver,
        disabled: true,
      }, // todo: add this filter once BE changes the API [remove the disabled prop]
      { id: 'logViolation', filter: 'logViolation', label: 'Log Violations', disabled: true }, // todo: add this filter once BE changes the API [remove the disabled prop]
      {
        id: 'diagnosticIndication',
        filter: 'diagnosticIndication',
        label: 'Diagnostic',
        options: diagnostic,
      },
      {
        id: 'diagnosticIndication',
        filter: 'diagnosticIndication',
        label: 'Diagnostic',
        options: diagnostic,
      },
      {
        id: 'malfunctionIndication',
        filter: 'malfunctionIndication',
        label: 'Malfunction',
        options: malfunction,
      },
      {
        id: 'isSigned',
        filter: 'isSigned',
        label: 'Signed?',
        options: signed,
      },
    ],
    title: 'Log Filter',
  },
  aggregation: {
    title: 'Aggregates',
    summaries: [
      { id: 'rows', label: 'logs,', function: 'countOf' },
      {
        id: 'hasActivity',
        label: 'with activity,',
        function: 'countTruthyOfField',
      },
      {
        id: 'violationStatus',
        label: 'with unreviewed violations,',
        function: 'countValueOfField',
        value: UN_REVIEWED_VIOLATION_STATUS,
      },
      {
        id: 'diagnosticIndication',
        label: 'with diagnostic indication,',
        function: 'countTruthyOfField',
      },
      {
        id: 'malfunctionIndication',
        label: 'with malfunction indication.',
        function: 'countTruthyOfField',
      },
    ],
    panels: [
      {
        label: 'Overview',
        width: 100,
        controllers: [
          { id: 'rows', label: 'Logs', function: 'countOf' },
          {
            id: 'hasActivity',
            label: 'With activity',
            function: 'countTruthyOfField',
          },
          { id: 'driver', label: 'Drivers', function: 'countUniqueOfField' },
        ],
      },
      {
        label: 'Violations',
        width: 95,
        controllers: [
          {
            id: 'violationStatus',
            label: 'Unreviewed',
            function: 'countValueOfField',
            value: UN_REVIEWED_VIOLATION_STATUS,
          },
          {
            id: 'violationStatus',
            label: 'Reviewed',
            function: 'countValueOfField',
            value: REVIEWED_VIOLATION_STATUS,
          },
          {
            id: 'violationStatus',
            label: 'None',
            function: 'countValueOfField',
            value: NO_VIOLATION_STATUS,
          },
        ],
      },
      {
        label: 'ELD State',
        width: 130,
        controllers: [
          { id: 'diagnosticIndication', label: 'With Diagnostic', function: 'countTruthyOfField' },
          {
            id: 'malfunctionIndication',
            label: 'With Malfunction',
            function: 'countTruthyOfField',
          },
        ],
      },
    ],
  },
};
