import React from 'react';
import { SelectInput, Chip } from 'stti-react-common';
import moment from 'moment-timezone';

import { deriveDateRangeFromMode } from '../../../../helpers/reports/reportUtils';
import { ReportControl } from '../ReportControl';

const getYears = () => {
  const currentYear = Number(moment().format('YYYY'));

  const years = [Number(currentYear - 2), Number(currentYear - 1), Number(currentYear)];

  return years;
};

export const YearSelectorInput = () => (
  <ReportControl
    name="selectedYear"
    group="query"
    label="Year"
    defaultValue={Number(moment().format('YYYY'))}
    render={(renderProps) => <SelectInput {...renderProps} options={getYears()} />}
    renderSummary={(renderProps, { value }) => (
      <Chip {...renderProps} color="primary" label={`of ${value}`} />
    )}
    printConfig={({ value }) => SelectInput.labelForValue(getYears(), value)}
    reducerCallback={(draft, value) => {
      [draft.controls.startDate, draft.controls.endDate] = deriveDateRangeFromMode(
        draft.controls.dateRangeMode,
        draft.controls.startDate,
        draft.controls.endDate,
        value
      );
    }}
    autoGridArea
  />
);
