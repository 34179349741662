import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

export const fetchVideoEventTypes = makeRestApiAction({
  service: 'videoEventTypes',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchVideoEventTypes,
  transformOutput: (videoEventTypesData, id) => ({
    events: videoEventTypesData,
    key: id || 'default', // add videoProvider to payload
  }),
  notificationsItemDescriptor: 'video types',
});

export const fetchVideoEventBehaviors = makeRestApiAction({
  service: 'videoEventBehaviors',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVideoEventBehaviors,
  notificationsItemDescriptor: 'video behaviors',
});

export const fetchVideoEventFileLocation = makeRestApiAction({
  service: 'videoEventFileLocations',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchVideoEventFileLocation,
  transformOutput: (videoEventFileLocationData, id) => ({
    ...videoEventFileLocationData,
    id, // add videoEventFileLocationId to payload
  }),
  notificationsItemDescriptor: 'video',
});
