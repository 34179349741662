import React from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';

import { Stack, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

import { DateTime } from './date';
import { getFormatDefinition } from './formats';

const sanitizeCellValue = ({ column, node, value }) => {
  const { data } = node;
  const { valueFormatter } = column.getColDef();

  const text = (() => {
    if (valueFormatter) {
      if (!value && !data) {
        return 'N/A';
      }
      if (!value && data) {
        return valueFormatter({ data, node });
      }
      return valueFormatter({ value, node });
    }
    return value ?? '';
  })();

  return { text };
};

const getHeaderToExport = (gridApi) => {
  const columns = gridApi.columnApi.getAllGridColumns();

  return columns.map((column) => {
    const { field, headerName } = column.getColDef();

    const sort = column.getSort();
    const headerNameUppercase = field ? field[0].toUpperCase() + field.slice(1) : headerName || '';
    const headerCell = {
      text: headerNameUppercase + (sort ? ` (${sort})` : ''),
    };
    return headerCell;
  });
};

const getRowsToExport = (gridApi) => {
  const columns = gridApi.columnApi.getAllGridColumns();

  const rowsToExport = [];
  gridApi.api.forEachNodeAfterFilterAndSort((node) => {
    const rowToExport = columns.map((column) => {
      const value = gridApi.api.getValue(column, node);
      return sanitizeCellValue({ column, node, value });
    });
    rowsToExport.push(rowToExport);
  });

  return rowsToExport;
};

export const getDocument = (gridApi) => {
  const columns = gridApi.columnApi.getAllGridColumns();

  const headerRow = getHeaderToExport(gridApi);
  const rows = getRowsToExport(gridApi);

  return {
    pageOrientation: 'landscape', // can also be 'portrait'
    content: [
      {
        table: {
          // the number of header rows
          headerRows: 1,

          // the width of each column, can be an array of widths
          widths: `${100 / columns.length}%`,

          // all the rows to display, including the header rows
          body: [headerRow, ...rows],

          // Header row is 40px, other rows are 15px
          heights: (rowIndex) => (rowIndex === 0 ? 40 : 15),
        },
      },
    ],
  };
};

export const reportOpenColumnDefs = [
  {
    field: 'name',
  },
  {
    field: 'description',
  },
  {
    field: 'ouKey',
    headerName: 'Shared With',
    cellRenderer: (params) => {
      const { context } = params;
      const { currentSystemOus } = context;

      const org = currentSystemOus.find((ou) => ou.key === params.value);
      return <div>{org?.name}</div>;
    },
  },
];

export const reportManageColumnDefs = [
  {
    field: 'name',
  },
  {
    field: 'description',
  },
  {
    field: 'ouKey',
    headerName: 'Shared With',
    flex: 1,
    cellRenderer: (params) => {
      const { context } = params;
      const { currentSystemOus } = context;

      const org = currentSystemOus.find((ou) => ou.key === params.value);
      return <div>{org?.name}</div>;
    },
  },
  {
    columnId: 'actions',
    suppressMenu: true,
    suppressSizeToFit: true,
    pinned: 'right',
    cellRenderer: (params) => {
      const {
        context: { setChildModal },
      } = params;

      return (
        <Stack spacing={2} direction="row">
          <IconButton
            aria-label="edit"
            onClick={() =>
              setChildModal({
                content: params.data,
                open: true,
                title: 'Edit Report',
                type: 'edit-report',
              })
            }
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() =>
              setChildModal({
                content: params.data,
                open: true,
                title: 'Confirm Delete Report',
                type: 'delete-report',
              })
            }
          >
            <Delete />
          </IconButton>
        </Stack>
      );
    },
  },
];

export const exportToCsv = (gridApi, code) => {
  const defaultFileName = `${code}_${DateTime.now().toLocal()}.csv`;
  gridApi.api.exportDataAsCsv({
    fileName: defaultFileName,
    processCellCallback: (params) => {
      const { text } = sanitizeCellValue({
        column: params.column,
        node: params.node,
        value: params.value,
      });
      return text;
    },
  });
};

export const exportToExcel = (gridApi, code) => {
  const defaultFileName = `${code}_${DateTime.now().toLocal()}.xlsx`;
  gridApi.api.exportDataAsExcel({
    fileName: defaultFileName,
    processCellCallback: (params) => {
      const { text } = sanitizeCellValue({
        column: params.column,
        node: params.node,
        value: params.value,
      });
      return text;
    },
  });
};

export const exportToPDF = (gridApi, code) => {
  const doc = getDocument(gridApi);
  const defaultFileName = `${code}_${DateTime.now().toLocal()}.pdf`;
  pdfMake.createPdf(doc).download(defaultFileName);
};

export const printToPDF = (gridApi) => {
  const doc = getDocument(gridApi);
  const pdfDoc = pdfMake.createPdf(doc);
  pdfDoc.getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  });
};

// TODO: currentSystemUser will define the default values for Distance, Volume and Weight
export const getColumnTypes = (settings, currentSystemUser) => ({
  timelessDate: {
    filter: 'agDateColumnFilter',
    valueFormatter(params) {
      const { value } = params;
      const { dateFormat } = settings || { dateFormat: 'MMM D, YYYY HH:mm' };
      const findCriteria = dateFormat === 'default' ? 'MMM D, YYYY HH:mm' : dateFormat;
      const formatDefinition = getFormatDefinition('date', findCriteria);

      if (!formatDefinition) {
        return value;
      }

      return value?.toFormat(formatDefinition.derived.timelessDate);
    },
  },
  datelessTime: {
    filter: 'agDateColumnFilter',
    valueFormatter(params) {
      const { value } = params;
      const { dateFormat } = settings || { dateFormat: 'MMM d, yyyy HH:mm' };
      const findCriteria = dateFormat === 'default' ? 'MMM d, yyyy HH:mm' : dateFormat;
      const formatDefinition = getFormatDefinition('date', findCriteria);

      if (!formatDefinition) {
        return value;
      }

      return value.toFormat(formatDefinition.derived.datelessTime);
    },
  },
  dateTime: {
    filter: 'agDateColumnFilter',
    valueFormatter(params) {
      const { value, data } = params;
      const { dateFormat } = settings || { dateFormat: 'MMM d, yyyy HH:mm' };
      const findCriteria = dateFormat === 'default' ? 'MMM d, yyyy HH:mm' : dateFormat;
      const formatDefinition = getFormatDefinition('date', findCriteria);

      if (!formatDefinition) {
        return value;
      }

      if (typeof value === 'string') {
        const valueDate = DateTime.fromISO(value, { zone: data?.timeZone ?? 'UTC' });
        if (valueDate.isValid) {
          return valueDate.toFormat(formatDefinition.key);
        }
        return value;
      }

      return value?.toFormat(formatDefinition.key);
    },
  },
  duration: {
    valueFormatter(params) {
      const { value } = params;
      const { dateFormat } = settings || { dateFormat: 'MMM d, yyyy HH:mm' };
      const findCriteria = dateFormat === 'default' ? 'MMM d, yyyy HH:mm' : dateFormat;
      const formatDefinition = getFormatDefinition('duration', findCriteria);

      if (!formatDefinition) {
        return value;
      }

      return value;
    },
  },
  boolean: {
    filter: 'agSetColumnFilter',
    valueFormatter(params) {
      const { value } = params;
      return value ? 'Yes' : 'No';
    },
  },
});
