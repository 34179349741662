import capitalize from 'lodash/capitalize';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';

import { createDateTime, getDaysBetweenDates, createDateInterval, getEndDateOrToday } from './date';

const quarters = [
  { key: 'First Quarter', value: '1q' },
  { key: 'Second Quarter', value: '2q' },
  { key: 'Third Quarter', value: '3q' },
  { key: 'Fourth Quarter', value: '4q' },
];

const dateRangeModeOptions = [
  { key: 'Custom range', value: 'custom' },
  { key: 'Today', value: 'today' },
  { key: 'Yesterday', value: 'yesterday' },
  { key: 'This Week', value: 'thisWeek' },
  { key: 'Last Week', value: 'lastWeek' },
  { key: 'Last 7 Days', value: 'last7Days' },
  { key: 'Last 14 Days', value: 'last14Days' },
  { key: 'This Month', value: 'thisMonth' },
  { key: 'Last Month', value: 'lastMonth' },
  { key: 'Last 30 Days', value: 'last30Days' },
];

export const defaultRangeModes = [
  'custom',
  'today',
  'yesterday',
  'thisWeek',
  'lastWeek',
  'last7Days',
  'last14Days',
  'thisMonth',
  'lastMonth',
  'last30Days',
];

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const getDateRangeModes = (ignoredModes = []) => {
  const monthArray = months.map((month) => ({
    key: capitalize(month),
    value: month,
  }));

  const quartersArray = quarters.map((quarter) => ({
    key: quarter.key,
    value: quarter.value,
  }));

  const returnedOptions = [
    ...dateRangeModeOptions,
    ...(ignoredModes.includes('monthly') ? [] : monthArray),
    ...(ignoredModes.includes('quarterly') ? [] : quartersArray),
  ];

  return returnedOptions;
};

export const showYearAllowed = [...months, ...quarters.map(({ value }) => value)];

export const getStepByMode = (ouMode, limits, daysDifference) => {
  if (ouMode === 'all') {
    return limits.forAllOus;
  }

  const calculatedStep =
    limits.forOu >= daysDifference ? limits.forOu : limits.forOu - (daysDifference - limits.forOu);

  return calculatedStep < 0 ? limits.forOu : calculatedStep;
};

export const getOuKeys = ({ ouMode, formData, currentSystemOu, memorizedOus }) => {
  if (ouMode === 'all') {
    return [ouMode];
  }
  if (ouMode === 'custom') {
    return formData.ousSelected.filter((ouKey) => !memorizedOus.includes(ouKey));
  }
  return [currentSystemOu?.key];
};

export const queryProcessor = ({ noDateRange, startDate, endDate, ouMode, ouKeys, limits }) => {
  const endDateFetch = getEndDateOrToday(endDate);
  const differenceByDays = getDaysBetweenDates(startDate, endDateFetch);
  const step = getStepByMode(ouMode, limits, differenceByDays);
  const dateStart = createDateTime(startDate);
  const dateEnd = createDateTime(endDateFetch);
  const interval = createDateInterval(dateStart, dateEnd);

  const intervals = interval.splitBy({ days: step });
  const ranges = isEmpty(intervals) ? [{ start: dateStart, end: dateEnd }] : intervals;

  const fetches = noDateRange
    ? [{ start: undefined, end: undefined }]
    : ranges.map((range) => ({
        start: range.start.toFormat('yyyy-MM-dd'), // TODO: ensure this format to be passed by queryPanelConfig
        end: range.end.toFormat('yyyy-MM-dd'), // TODO: ensure this format to be passed by queryPanelConfig
      }));

  const queries = [];

  forEach(ouKeys, (ouKey) => {
    forEach(fetches, (fetch) => {
      queries.push({ ...fetch, ouKey });
    });
  });
  return queries;
};

export const queryOus = (mode, currentSystemOu, currentSystemOus, selectedOus) => {
  if (!mode || mode === 'default') return [currentSystemOu?.key];
  if (mode === 'custom') return selectedOus;
  return currentSystemOus.map((ou) => ou.key);
};

export const getSelectedOus = ({ ouMode, ousSelected }, currentSystemOu, currentSystemOus) => {
  if (ouMode === 'default') {
    return currentSystemOu.name;
  }

  if (ouMode === 'custom') {
    if (!ousSelected || ousSelected.length === 0) return 'Selected organization(s)';
    if (ousSelected.length === 1) {
      const ou = currentSystemOus.find((row) => row.key === ousSelected[0]);
      return ou.name;
    }
    return `${ousSelected.length} organizations`;
  }

  return 'All organizations';
};

export const getIntervalDescription = (
  { startDate, endDate, dateRangeMode },
  dateFormatDefinition
) => {
  if (!dateFormatDefinition || !startDate || !endDate) return 'Select date range';

  if (dateRangeMode === 'custom' && startDate && endDate) {
    return `from ${startDate.toFormat(
      dateFormatDefinition.derived.timelessDate
    )} to ${endDate.toFormat(dateFormatDefinition.derived.timelessDate)}`;
  }

  if (includes(['1q', '2q', '3q', '4q'], dateRangeMode)) {
    const quarterMap = {
      '1q': 'first',
      '2q': 'second',
      '3q': 'third',
      '4q': 'fourth',
    };
    return `started ${quarterMap[dateRangeMode]} quarter of ${startDate.year}`;
  }
  const formattedDateRangeMode = dateRangeMode.replace(/([A-Z]|\d+)/g, ' $1').toLowerCase();
  return `started ${formattedDateRangeMode}`;
};
