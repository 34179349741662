import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { BasicDialog, Form, TextInput, SelectInput, DutyStatusChart } from 'stti-react-common';

import {
  updateIntervals,
  getChartEndDate,
  getStatusTotals,
  formatDutyStatusCodeToFriendly,
  sanitizeDutyStatus,
} from '../../../../../helpers/reports/driverLogsUtils';

import { useFormats } from '../../../../commons/Formats';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

const { useFormController, Control } = Form;
const { StatusLine } = DutyStatusChart;

const validFormat = 'YYYY-MM-DDTHH:mm';

const dutyStatusOptions = ['Off Duty', 'Sleeper Berth', 'Driving', 'On Duty Not Driving'];

export const EditLogDutyStatusEventDialog = ({
  isOpen,
  onClose,
  onConfirm,
  onRefresh,
  date,
  log,
  row = {},
  driverKey,
  user,
}) => {
  const { formatUnit } = useFormats();
  const { logDate, timeZone, movementIntervals, timeZoneStatus } = log || {};

  const form = useFormController({
    controls: {
      index: row.index,
      key: row.key,
      logKey: `${date}-${driverKey}`,
      dutyStatus: formatDutyStatusCodeToFriendly(row.dutyStatus),
      lastDutyStatus: sanitizeDutyStatus(row.previousDutyStatus),
      annotation: row.annotation,
      start: row.start && moment.tz(row.start, timeZone).format(validFormat),
      end: row.end && moment.tz(row.end, timeZone).format(validFormat),
    },
  });
  const { controls, hasErrors, isDirty, errors, resetControls } = form;

  const [intervals, setIntervals] = useState(movementIntervals || []);

  useEffect(() => {
    resetControls();
    setIntervals(movementIntervals || []);
  }, [isOpen]);

  useEffect(() => {
    if (
      controls.start &&
      controls.dutyStatus &&
      !errors.start &&
      !errors.end &&
      !errors.dutyStatus
    ) {
      setIntervals(updateIntervals(controls, log, row));
    } else {
      setIntervals(movementIntervals || []);
    }
  }, [controls, errors]);
  return (
    <BasicDialog
      className="DriverLogDetailReport__EditLogDutyStatusEventDialog"
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit Daily Log for: ${date}`}
      buttons={[
        {
          label: 'Cancel',
          onClick: onClose,
        },
        {
          label: 'Submit Changes',
          disabled: hasErrors || !isDirty,
          onClick: () => {
            onConfirm({
              ...controls,
              annotation: `*${controls.annotation} [${user.fullName}]`,
            });
            onRefresh({ ...row, hasChangeRequested: true });
            onClose();
          },
        },
      ]}
    >
      <Form form={form}>
        <span>
          These log edits will need to be approved by the driver before counting towards their Hours
          of Services
        </span>
        <span style={{ display: row.hasChangeRequested ? 'block' : 'none' }}>
          This record is currently pending acceptance from the driver. Additional changes could
          create unexpected results. Have the driver accept or reject the existing changes before
          continuing. Please see "Requested Changes" section for more information on pending
          changes.
        </span>
        <DutyStatusChart
          startDate={moment.tz(logDate, timeZone).toISOString()}
          endDate={getChartEndDate(logDate, timeZone).toISOString()}
          intervals={intervals}
          statusTotals={{
            offDuty: formatUnit('duration', getStatusTotals(intervals, 'Off Duty')),
            sleeper: formatUnit('duration', getStatusTotals(intervals, 'Sleeper Berth')),
            driving: formatUnit('duration', getStatusTotals(intervals, 'Driving')),
            onDuty: formatUnit('duration', getStatusTotals(intervals, 'On Duty Not Driving')),
          }}
        >
          <StatusLine intervals={intervals} timeZoneStatus={timeZoneStatus} />
        </DutyStatusChart>
        <Control
          Component={TextInput}
          name="annotation"
          label="Remarks"
          error={({ value }) => {
            if (!value) {
              return true;
            }
            if (value.length < 4) {
              return 'Remarks must be at least 4 characters';
            }

            if (value.length > 60) {
              return 'Remarks cannot be more than 60 characters';
            }
            return false;
          }}
        />
        <Control
          Component={SelectInput}
          name="dutyStatus"
          options={dutyStatusOptions}
          label="Status"
          error={({ value }) => !value}
        />
        {date && (
          <Control
            Component={TextInput}
            name="start"
            label="Time"
            disabled
            defaultValue={moment(date).startOf('day').format(validFormat)}
          />
        )}
      </Form>
    </BasicDialog>
  );
};

EditLogDutyStatusEventDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  driverKey: PropTypes.string.isRequired,
  row: PropTypes.shape({
    key: PropTypes.string.isRequired,
    annotation: PropTypes.string,
    start: PropTypes.string.isRequired,
    dutyStatus: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
  }),
  log: customPropTypes.detailedDriverLog.isRequired,
};
