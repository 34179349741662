import { intersection, castArray, find, isEmpty, isString, filter } from 'lodash';
import { createSelector } from 'reselect';

import { selectSystemUser, selectSystemActiveOu, selectFilteredOus } from '../../../system';

import { role } from '../roles';

import {
  SUPER_ADMIN_ROLE_KEY,
  ADMIN_ROLE_KEYS,
  DRIVER_ROLE_KEY,
  MECHANIC_ROLE_KEY,
} from '../../../system/constants';

const hasRole = (rl, type) => {
  const roleToString = isString(rl) ? rl : rl.key;
  return roleToString.includes(type);
};

const buildDetailedUser = (user, activeOu, ous, roles) => ({
  ...user,
  timeZone: activeOu.timeZone,
  isDriver: user.memberships.some((membership) =>
    membership.roles.some((rl) => hasRole(rl, DRIVER_ROLE_KEY))
  ),
  isSystem: user.memberships.some((membership) =>
    membership.roles.some((rl) => hasRole(rl, SUPER_ADMIN_ROLE_KEY))
  ),
  isMechanic: user.memberships.some((membership) =>
    membership.roles.some((rl) => hasRole(rl, MECHANIC_ROLE_KEY))
  ),
  memberships: user.memberships.map((membership) => ({
    ...membership,
    name: ((ous && ous.find((ou) => ou.key === membership.ou)) || {}).name,
    roles: membership.roles.map((roleKey) => {
      if (isString(roleKey)) {
        return {
          key: roleKey,
          name: ((roles && roles.find((rl) => rl.key === roleKey)) || {}).name,
        };
      }
      return roleKey;
    }),
  })),
});

const getUsers = (state) => state.administration.resources.users.data;
const getSystemOus = (state) => state.system.ous.data;

/**
 * selectUsersFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on users
 */
export const selectUsersFetchRequests = (state) => state.administration.resources.users.fetches;

/**
 * selectUsers(state) -> [user]
 *
 * Selector for users based on currentUsers permissions
 * Super Admin of tenant org returns all users
 * At minimum will return the currentUser
 */
export const selectUsers = createSelector(
  [
    getUsers,
    selectSystemActiveOu,
    selectSystemUser,
    getSystemOus,
    role.selectors.selectRoles,
    selectFilteredOus,
  ],
  (users, activeOu, currentUser, ous, roles, filteredOus) => {
    if (isEmpty(currentUser)) {
      return [];
    }

    if (
      currentUser.memberships.some(
        ({ role: rl, scope }) => scope === 'Tenant' && intersection(ADMIN_ROLE_KEYS, rl).length > 0
      )
    ) {
      // If super admin of the tenant organization we want to return all users
      // with a possibility to filter based on FE component
      if (filteredOus.length === 0)
        return users.map((user) => buildDetailedUser(user, activeOu, ous, roles));

      return users
        .map((user) => buildDetailedUser(user, activeOu, ous, roles))
        .filter(
          ({ memberships }) =>
            intersection(
              filteredOus,
              memberships.map(({ ou }) => ou)
            ).length > 0
        );
    }

    const availableOrganizationKeys = currentUser.memberships
      .filter(
        (membership) => intersection(ADMIN_ROLE_KEYS, membership.role).length > 0 && membership.ou
      )
      .map((filteredMemberships) => filteredMemberships.ou);

    // If user is not super admin, then we will return ony the users from available organizations
    return users
      .filter(
        (user) =>
          user.memberships.some((membership) =>
            availableOrganizationKeys.includes(membership.ou)
          ) || user.key === currentUser.key
      )
      .map((user) => buildDetailedUser(user, activeOu, ous, roles));
  }
);

/**
 * selectUserById(state, userKey) -> user
 *
 * Selector for single user by ID ("key" property)
 */
export const selectUserById = (state, userKey) => find(selectUsers(state), { key: userKey });

/**
 * selectPeerUsers(state) -> [user]
 *
 * Selector for all users that belongs to a certain membership based on their active OU
 */
export const selectPeerUsers = createSelector(
  [selectUsers, selectSystemUser, selectSystemActiveOu],
  (users, currentUser, activeOu) => {
    const membershipFilter = (userMemberships, currentUserMemberships, activeOuKey) =>
      intersection(currentUserMemberships, userMemberships, castArray(activeOuKey)).length > 0;

    return users.filter((user) =>
      membershipFilter(
        user.memberships && user.memberships.map((membership) => membership.ou),
        currentUser.memberships && currentUser.memberships.map((membership) => membership.ou),
        activeOu.key
      )
    );
  }
);

/**
 * selectDriverUsers(state) -> [user]
 *
 * Selector for all users who are drivers
 */
export const selectDriverUsers = createSelector([selectUsers], (users) =>
  filter(users, 'isDriver')
);
