import { connect } from 'react-redux';

import { NotificationDetailComponent } from './detail';
import { notification } from '../../../../../data/administration/configurations';
import { location, vehicle, user } from '../../../../../data/administration/resources';
import { selectCountries, fetchCountriesOptimized } from '../../../../../data/system';

export const NotificationDetailContainer = connect(
  (state, { notificationId }) => ({
    notification: notification.selectors.selectNotificationTrigger(state, notificationId),
    geozones: location.selectors.selectLocations(state),
    vehicles: vehicle.selectors.selectAllVehicles(state),
    drivers: user.selectors.selectDriverUsers(state),
    users: user.selectors.selectUsers(state),
    countriesList: selectCountries(state),
  }),
  {
    fetchNotification: notification.actions.fetchNotificationTrigger,
    fetchNotificationTriggers: notification.actions.fetchNotificationTriggers,
    fetchCountries: fetchCountriesOptimized,
    deleteNotification: notification.actions.deleteNotificationTrigger,
    createNotification: notification.actions.createNotificationTrigger,
    updateNotification: notification.actions.updateNotificationTrigger,
    fetchGeozones: location.actions.fetchLocationsOptimized,
    fetchVehicles: vehicle.actions.fetchVehiclesOptimized,
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchDrivers: user.actions.fetchUsersOptimized,
  }
)(NotificationDetailComponent);
