import { isString, filter } from 'lodash';

import { makeRestApiAction } from '../../apiActionSupport';

import { ACTION_TYPE } from './reducer';

const hasCoordinates = ({ latitude, longitude }) => latitude && longitude;

export const fetchBreadcrumbs = makeRestApiAction({
  service: 'breadcrumbs',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchBreadcrumbs,
  transformOutput: (breadcrumbs, { vehicleKey }) =>
    filter(
      breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        vehicleKey, // required in reducer (no vehicle info returned in breadcrumbs)
        sequence: Number(breadcrumb.sequence),
        other: isString(breadcrumb.other) ? JSON.parse(breadcrumb.other) : breadcrumb.other,
        eventType: breadcrumb.eventType.split('.')[1] || breadcrumb.eventType,
      })),
      hasCoordinates
    ),
  notificationsItemDescriptor: 'vehicle tracking data',
  enableProgress: true,
});

export const fetchRefinedBreadcrumbs = makeRestApiAction({
  service: 'refinedBreadcrumbs', // refinedBreadcrumbs are simply performanceRuleToggled events
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRefinedBreadcrumbs,
  transformOutput: (breadcrumbs, { vehicleKey }) =>
    filter(
      breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        vehicleKey, // required in reducer (no vehicle info returned in breadcrumbs)
        sequence: Number(breadcrumb.sequence),
        eventType: '', // property added to match standard breadcrumb shape
      })),
      hasCoordinates
    ),
  notificationsItemDescriptor: 'vehicle tracking data',
  enableProgress: true,
});
