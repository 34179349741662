import {
  isArray,
  isFunction,
  isString,
  isObject,
  isEmpty,
  uniqueId,
  startCase,
  omitBy,
} from 'lodash';

import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../helpers/moment';
import { getUncoveredDateRanges, getUncoveredTimelessDateRanges } from './cacheUtils';
import { api, ApiError } from './api';

const createNotification = (stringOrCallback, ...callbackParams) => {
  const message = isFunction(stringOrCallback)
    ? stringOrCallback(...callbackParams)
    : stringOrCallback;
  return message ? { message } : undefined;
};

export const makeOneTimeOptimizedAction =
  ({ selectFetches, filterFetches, fetchAction, baseActionType, fetchActionConfig = {} }) =>
  (actionParam) =>
  async (dispatch, getState) => {
    const allFetches = selectFetches(getState());
    const fetches = filterFetches(allFetches, actionParam);

    if (fetches.length === 0) {
      return dispatch(fetchAction(actionParam, fetchActionConfig));
    }

    dispatch({
      type: `${baseActionType}.skipped`,
      request: {
        skipped: true,
        actionParam, // for Redux devtools display, not processed
      },
    });

    return undefined;
  };

export const makeRestApiAction =
  ({
    service, // string: API service, used as api[service][method]
    method, // string: will be HTTP method if standard REST service
    baseActionType, // string: actions append .request/.progress/.success/.failure
    notifications: notificationsConfig, // optional object with keys: request, progress, success, failure; strings or functions; exclusive with notificationsItemDescriptor
    notificationsItemDescriptor, // optional string with descriptor (e.g. "trips") for standardized notifications; exclusive with notifications
    transformInput = (actionParam) => actionParam, // optional function: transformInput(actionParam, limitedAdditionalParams) -> apiRequestParamsOrBody
    transformOutput = (responseData) => responseData, // optional function: transformOutput(responseData, actionParam, apiRequestData) -> returnValueAndDispatchPayload
    getId, // optional function: invoked with getId(actionParam), must return entity ID for API request
    enableProgress, // optional boolean: when true, dispatch .progress action(s) (for paged queries)
    throwApiErrors, // optional boolean: when true, throw instances of ApiError
  }) =>
  (actionParam, actionConfig = {}) =>
  async (dispatch) => {
    // ensure makeRestApiAction args valid
    if (!api[service])
      throw new Error(`makeRestApiAction: service '${service}' is not a valid API service.`);
    if (!api[service][method])
      throw new Error(
        `makeRestApiAction: method '${method}' is not a valid method on service '${service}'.`
      );
    if (!isString(baseActionType))
      throw new Error(`'baseActionType' must be a string. ${baseActionType}`);
    if (notificationsItemDescriptor && notificationsConfig)
      throw new Error(
        "Supplying both 'notifications' and 'notificationsItemDescriptor' not allowed."
      );
    // set up notifications
    const notifications = (() => {
      if (notificationsConfig) {
        // use supplied notificationsConfig
        return notificationsConfig;
      }

      if (!notificationsItemDescriptor) {
        // neither notificationsConfig nor notificationsItemDescriptor supplied, do not use notifications
        return {};
      }

      // set up standard notifications using notificationsItemDescriptor
      const descriptor = startCase(notificationsItemDescriptor);
      switch (method) {
        case 'get':
          return {
            failure: (_, apiError) => `${descriptor} could not be retrieved (${apiError.message}).`,
          };
        case 'post':
          return {
            success: `${descriptor} created.`,
            failure: (_, apiError) => `${descriptor} could not be created (${apiError.message}).`,
          };
        case 'put':
        case 'patch':
          return {
            success: `${descriptor} updated.`,
            failure: (_, apiError) => `${descriptor} could not be updated (${apiError.message}).`,
          };
        case 'delete':
          return {
            success: `${descriptor} deleted.`,
            failure: (_, apiError) => `${descriptor} could not be deleted (${apiError.message}).`,
          };
        default:
          throw new Error(`Unable to configure notifications for service '${service}'.`);
      }
    })();

    // transform action arguments to API request data (search params or request body)
    const transformInputFn = actionConfig.transformInput // allow override by actionConfig
      ? actionConfig.transformInput
      : transformInput;
    const limitedAdditionalParams = {
      // limited secondary params for special cases
      originUrl: api.originUrl, // createReportSchedule, createReportOnDemand, startGeneralPurposeTransfer
    };
    const apiRequestData = transformInputFn(actionParam, limitedAdditionalParams);

    // create internal requestId
    const requestId = String(uniqueId());

    // dispatch request
    dispatch({
      type: `${baseActionType}.request`,
      request: {
        id: requestId,
        service,
        method,
        active: true,
        actionParam,
        requestData: apiRequestData,
        progress: 0,
      },
      payload: apiRequestData, // for eager updates
      notification: createNotification(notifications.request, actionParam, apiRequestData), // include notification if applicable
      // no payload
    });

    try {
      // create handleProgress callback; only used if enableProgress
      const handleProgress = (responseData, responseMetadata) => {
        // transform response data
        const transformedResponseData = transformOutput(responseData, actionParam, apiRequestData);

        // extract progress
        const { progress, total } = responseMetadata; // note: total will be undefined until included in API paging response

        // dispatch progress
        dispatch({
          type: `${baseActionType}.progress`,
          request: {
            id: requestId,
            progress,
            total,
          },
          notification: createNotification(
            notifications.progress,
            actionParam,
            transformedResponseData
          ), // include notification if applicable
          payload: transformedResponseData,
        });

        return transformedResponseData;
      };

      // initiate API request
      const responseData = await api[service][method]({
        id: getId && getId(actionParam),
        data: isObject(apiRequestData) ? apiRequestData : undefined, // assume non-object is a string ID and do not pass to API
        onProgress: enableProgress ? handleProgress : undefined,
      });

      // transform full response data
      const transformedResponseData = transformOutput(responseData, actionParam, apiRequestData);

      // dispatch success
      const total = isArray(responseData) ? responseData.length : 1;
      const request = {
        id: requestId,
        progress: total,
        total,
        active: false,
      };
      const payload = enableProgress && !isEmpty(responseData) ? [] : transformedResponseData; // if progress was dispatched, send empty payload

      dispatch({
        type: `${baseActionType}.success`,
        request,
        notification: createNotification(
          notifications.success,
          actionParam,
          transformedResponseData
        ), // include notification if applicable
        payload,
      });

      // return
      return transformedResponseData;
    } catch (err) {
      // only set notification if an ApiError
      let notification;
      if (err instanceof ApiError)
        notification = createNotification(notifications.failure, actionParam, err); // include notification if applicable

      // dispatch failure
      dispatch({
        type: `${baseActionType}.failure`,
        request: {
          id: requestId,
          active: false,
        },
        notification,
        payload: apiRequestData, // for reverting eager updates
      });

      if (err instanceof ApiError && !throwApiErrors) return undefined;

      throw err;
    }
  };

export const makeDateRangeOptimizedAction =
  ({ selectFetches, filterFetches, baseActionType, fetchAction, fetchActionConfig = {} }) =>
  (actionParam) =>
  async (dispatch, getState) => {
    // assumed actionParam properties
    const {
      startDate,
      endDate,
      timeZone, // when timeZone is supplied, will convert startDate and endDate from short strings to ISO
    } = actionParam;

    // get fetch requests using selector callback (may be active or completed)
    const allFetches = selectFetches(getState());

    // filter fetch requests using filtering callback
    const fetches = filterFetches(allFetches, actionParam);

    const coveredRanges = fetches.map((fetched) => [fetched.startDate, fetched.endDate]);

    const targetRange = timeZone
      ? [
          startDateWithTimeZoneToIso(startDate, timeZone),
          endDateWithTimeZoneToIso(endDate, timeZone),
        ]
      : [startDate, endDate];

    const usingTimelessDates =
      targetRange && targetRange[0] && targetRange[0].length === 'YYYY-MM-DD'.length; // abbreviated check for date type

    // subtract covered ranges from target range, yielding uncovered range(s)
    const getUncoveredRangesFn = usingTimelessDates
      ? getUncoveredTimelessDateRanges
      : getUncoveredDateRanges;
    const uncoveredRanges = getUncoveredRangesFn(targetRange, coveredRanges);

    if (uncoveredRanges.length === 0) {
      // no part of target range is un-fetched
      dispatch({
        type: `${baseActionType}.skipped`,
        request: {
          skipped: true,
          actionParam, // for Redux devtools display, not processed
        },
      });
      return undefined;
    }

    // one or more ranges is un-fetched
    return Promise.all(
      uncoveredRanges.map(([rangeStart, rangeEnd]) =>
        dispatch(
          fetchAction(
            { ...actionParam, startDate: rangeStart, endDate: rangeEnd },
            fetchActionConfig
          )
        )
      )
    );
  };

export const omitPrivateProperties = (entity) => omitBy(entity, (_, key) => key[0] === '_');
