import { get, isArray, isObject, uniq } from 'lodash';

import { columnDef } from '../../../commons/ControlledDataGrid';
import { driverLogs } from '../../../../data/reports';

export const columnDefs = (myLogsOnly) => [
  {
    headerName: 'Log',
    children: [
      columnDef.iconColumn({
        colId: 'openDriverLogDetailReport',
        headerName: 'Open Detail',
        cellRenderer: 'OpenDetail',
        headerComponent: 'OpenDetailHeader',
        width: 65,
      }),
      columnDef.dateColumn({
        field: 'startedAt',
        headerName: 'Date',
        variant: 'timelessDate',
      }),
      columnDef.booleanColumn({
        hide: myLogsOnly,
        field: 'isSigned',
        headerName: 'Signed?',
        cellRenderer: 'Signed',
        falseLabelText: 'No',
        trueLabelText: 'Yes',
        falseRank: 0,
        trueRank: 1,
        width: 90,
      }),
      columnDef.textColumn({
        hide: myLogsOnly,
        field: 'driver.name',
        headerName: 'Driver',
        cellRenderer: 'Driver',
      }),
      columnDef.listColumn({
        field: 'ous',
        headerName: 'Organizations',
        cellRenderer: 'Organizations',
        transformValueElement: (item) => item?.name,
      }),
      columnDef.listColumn({
        field: 'vehicles',
        headerName: 'Vehicles',
        cellRenderer: 'Vehicles',
        transformValueElement: (item) => item?.name,
      }),
    ],
  },
  {
    headerName: 'Day Summary',
    children: [
      columnDef.durationColumn({
        field: 'onDutyDrivingTotal',
        headerName: 'Driving',
        aggFunc: null,
      }),
      columnDef.durationColumn({
        field: 'onDutyTotal',
        headerName: 'On Duty',
        aggFunc: null,
      }),
      columnDef.durationColumn({
        field: 'onDutyRemaining',
        headerName: 'Remaining',
        aggFunc: null,
      }),
    ],
  },
  {
    headerName: 'Deferral',
    children: [
      columnDef.textColumn({
        field: 'deferralStatus',
        headerName: 'Status',
      }),
      columnDef.durationColumn({
        field: 'deferralTime',
        headerName: 'Time',
        aggFunc: null,
      }),
    ],
  },
  {
    headerName: 'Cycle',
    children: [
      columnDef.dateColumn({
        field: 'cycleStart',
        headerName: 'Start',
      }),
      columnDef.durationColumn({
        field: 'cycleOnDutyTime',
        headerName: 'Total',
        aggFunc: null,
      }),
      columnDef.durationColumn({
        field: 'cycleOnDutyRemaining',
        headerName: 'Remaining',
        aggFunc: null,
      }),
    ],
  },
  {
    headerName: 'Activity',
    children: [
      columnDef.booleanColumn({
        field: 'hasActivity',
        headerName: 'Activity',
        cellRenderer: 'Activity',
        falseLabelText: 'No',
        trueLabelText: 'Yes',
        falseRank: 0,
        trueRank: 1,
        width: 90,
      }),
      columnDef.rankedTextColumn({
        colId: 'logStanding',
        headerName: 'Standing',
        cellRenderer: 'Standing',
        width: 180,
        valueGetter: ({ data }) => {
          const value = get(data, 'logStanding');
          switch (value) {
            case driverLogs.constants.IN_VIOLATION:
              return { value, rank: 3, label: 'In Violation', color: 'red' };
            case driverLogs.constants.NO_CYCLE_TIME:
              return { value, rank: 2, label: 'No Cycle Time', color: 'amber' };
            case driverLogs.constants.GOOD:
              return { value, rank: 1, label: 'In Good Standing', color: 'green' };
            default:
              return { value, rank: 0, label: 'N/A', color: 'grey' };
          }
        },
      }),
      columnDef.rankedTextColumn({
        colId: 'violationStatus',
        headerName: 'Violations',
        cellRenderer: 'ViolationStatus',
        width: 150,
        valueGetter: ({ data }) => {
          const value = get(data, 'violationStatus');
          switch (value) {
            case driverLogs.constantsUN_REVIEWED_VIOLATION_STATUS:
              return { value, rank: 4, label: 'Unreviewed', color: 'red' };
            case driverLogs.constants.UNKNOWN_VIOLATION_STATUS:
              return { value, rank: 3, label: 'Unknown', color: 'amber' };
            case driverLogs.constants.REVIEWED_VIOLATION_STATUS:
              return { value, rank: 2, label: 'Reviewed', color: 'amber' };
            case driverLogs.constants.NO_VIOLATION_STATUS:
              return { value, rank: 1, label: 'None', color: 'green' };
            default:
              return { value, rank: 0, label: 'N/A', color: 'grey' };
          }
        },
      }),
      columnDef.textColumn({
        hide: myLogsOnly,
        field: 'reviewDetails.reviewerName',
        headerName: 'Reviewer',
      }),
    ],
  },
  {
    headerName: 'ELD State',
    children: [
      columnDef.textColumn({
        field: 'diagnosticIndication',
        headerName: 'Diagnostic',
        valueGetter: ({ data: { diagnosticIndication } }) => (diagnosticIndication ? 'Yes' : 'No'),
        width: 120,
      }),
      columnDef.textColumn({
        field: 'malfunctionIndication',
        headerName: 'Malfunction',
        valueGetter: ({ data: { malfunctionIndication } }) =>
          malfunctionIndication ? 'Yes' : 'No',
        width: 120,
      }),
      columnDef.textColumn({
        field: 'healthCodes',
        headerName: 'Health Codes',
        valueGetter: ({ data: { healthCodes } }) => {
          if (!isArray(healthCodes)) return '-';
          const sanitizedHealthCodes = uniq(
            healthCodes.map((item) => (isObject(item) ? item.code : item))
          );
          return sanitizedHealthCodes.join(', ');
        },
      }),
    ],
  },
];
