import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, BasicDialog } from 'stti-react-common';
import ChipList from '../../../../../commons/Notifications/ChipList';

import {
  validateNotificationDestinations,
  validateEmail,
  validatePhoneNumber,
} from '../../../../../../helpers/form/validations';

/**
 * Formats a North American phone number to +1-xxx-xxx-xxxx.
 * Returns an error string if it's not valid length.
 */
function formatNorthAmericanPhone(rawInput) {
  // Remove non-digit characters
  let digits = rawInput.replace(/\D/g, '');

  // If exactly 10 digits => +1-xxx-xxx-xxxx
  if (digits.length === 10) {
    return `+1 ${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  }

  // If 11 digits AND first digit is '1' => remove the leading '1'
  if (digits.length === 11 && digits.startsWith('1')) {
    digits = digits.substring(1); // drop leading '1'
    return `+1 ${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  }

  // Otherwise, invalid phone length
  return `Invalid phone number length. Please ensure 10 digits (or 11 starting with '1').`;
}

export function RenderDestinations({
  form,
  mode,
  destinations,
  setDestinations,
  userOptions,
  Control,
}) {
  const isReadOnly = mode === 'view';

  // State for BasicDialog-based error messages
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');

  /**
   * Show an error in a dialog.
   */
  const showErrorDialog = useCallback((msg) => {
    setErrorDialogMessage(msg);
    setErrorDialogOpen(true);
  }, []);

  /**
   * Safely updates the `destinations` state,
   * validating before committing changes.
   */
  const handleDestinationsChange = useCallback(
    (updater) => {
      setDestinations((prev) => {
        const current = prev || {};
        const nextDestinations =
          typeof updater === 'function' ? updater(current) : { ...current, ...updater };
        return nextDestinations;
      });
    },
    [setDestinations]
  );

  /**
   * Called when the user clicks "Add" for an email.
   */
  const onAddEmail = (newEmail) => {
    const error = validateEmail(newEmail);
    if (error) {
      showErrorDialog(error);
      return; // do not add
    }

    handleDestinationsChange((prev) => ({
      ...prev,
      emails: [...(prev.emails || []), newEmail],
    }));

    form.setControl('destination', {
      ...destinations,
      emails: [...destinations.emails, newEmail],
    });
  };

  /**
   * Called when the user removes an email by index.
   */
  const onRemoveEmail = (index) => {
    handleDestinationsChange((prev) => {
      const updated = [...(prev.emails || [])];
      updated.splice(index, 1);
      return { ...prev, emails: updated };
    });

    form.setControl('destination', {
      ...destinations,
      emails: destinations.emails.filter((_, i) => i !== index),
    });
  };

  /**
   * Called when the user clicks "Add" for a phone number.
   * We auto-format and then validate with validatePhoneNumber.
   */
  const onAddPhone = (unformatted) => {
    // Attempt to auto-format
    const maybeFormatted = formatNorthAmericanPhone(unformatted);
    // If we get a string that starts with "Invalid", show a dialog
    if (maybeFormatted.startsWith('Invalid')) {
      showErrorDialog(maybeFormatted);
      return; 
    }

    // Now validate the formatted phone
    const isValid = validatePhoneNumber(maybeFormatted);
    if (!isValid) {
      showErrorDialog(`Invalid phone: ${maybeFormatted}`);
      return;
    }

    // If all is good, add the phone (using the formatted version)
    handleDestinationsChange((prev) => ({
      ...prev,
      phoneNumbers: [...(prev.phoneNumbers || []), maybeFormatted],
    }));

    form.setControl('destination', {
      ...destinations,
      phoneNumbers: [...destinations.phoneNumbers, maybeFormatted],
    });
  };

  /**
   * Called when the user removes a phone number by index.
   */
  const onRemovePhone = (index) => {
    handleDestinationsChange((prev) => {
      const updated = [...(prev.phoneNumbers || [])];
      updated.splice(index, 1);
      return { ...prev, phoneNumbers: updated };
    });

    form.setControl('destination', {
      ...destinations,
      phoneNumbers: destinations.phoneNumbers.filter((_, i) => i !== index),
    });
  };

  /**
   * Called when the user changes the selected "Users".
   */
  const onChangeUsers = (value) => {
    handleDestinationsChange({ userKeys: value });
    form.setControl('destination', { ...destinations, userKeys: value });
  };

  return (
    <div className="destination-section">
      {/* Emails ChipList */}
      <ChipList
      type="email"
        label="Emails"
        items={destinations.emails}
        onAddItem={onAddEmail}
        onRemoveItem={onRemoveEmail}
        placeholder="Enter an email, then click Add"
        readOnly={isReadOnly}
      />

      {/* Phone Numbers ChipList */}
      <ChipList
      type="phone"
        label="SMS"
        items={destinations.phoneNumbers}
        onAddItem={onAddPhone}
        onRemoveItem={onRemovePhone}
        placeholder="Enter a phone number, then click Add"
        readOnly={isReadOnly}
      />

      {/* Users Select */}
      <Control
        Component={SelectInput}
        name="userKeys"
        label="Users"
        multiple
        options={userOptions}
        description="Select users to notify."
        readOnly={isReadOnly}
        value={destinations.userKeys}
        onChange={onChangeUsers}
      />

      {/* BasicDialog for Errors */}
      <BasicDialog
        title="Invalid Entry"
        isOpen={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
      >
        <p>{errorDialogMessage}</p>
      </BasicDialog>
    </div>
  );
}

RenderDestinations.propTypes = {
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  destinations: PropTypes.shape({
    emails: PropTypes.arrayOf(PropTypes.string),
    phoneNumbers: PropTypes.arrayOf(PropTypes.string),
    userKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setDestinations: PropTypes.func.isRequired,
  userOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default RenderDestinations;
