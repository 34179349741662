import produce from 'immer';
import { sortBy } from 'lodash';

import { actionTypeProgressOrSuccess, shallowMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchFuellings: 'reports/fuellings/fetchFuellings',
};

const initialState = {
  fuellings: [],
};

export const fuellingsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchFuellings]))
      draft.fuellings = sortBy(
        shallowMergeEntities(state.fuellings, payload),
        'startedAt'
      ).reverse();
  });
