import React, { useCallback } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';

import { getMarker, getMarkerInfo, markerLabelStyle } from '../utils/markers';

import './Marker.css';

const Marker = ({
  index,
  marker,
  onClick,
  onMouseEnter,
  onMouseLeave,
  setMarkerRef,
  showMarkerLabel,
  selectedMarkerKey,
}) => {
  const handleClick = useCallback(() => onClick(marker), [onClick, marker]);
  const handleOnMouseEnter = useCallback(() => onMouseEnter(marker), [onMouseEnter, marker]);
  const handleOnMouseLeave = useCallback(() => onMouseLeave(marker), [onMouseEnter, marker]);
  const ref = useCallback((item) => setMarkerRef(item, marker.key), [setMarkerRef, marker.key]);

  return (
    <AdvancedMarker
      key={index}
      position={marker?.vehicle?.location}
      ref={ref}
      onClick={handleClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      title={marker?.vehicle?.vehicleId}
    >
      {getMarker(marker, selectedMarkerKey)}
      {showMarkerLabel && <div style={markerLabelStyle}>{getMarkerInfo(marker)} </div>}
    </AdvancedMarker>
  );
};

export default Marker;

Marker.propTypes = {
  index: PropTypes.number,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  setMarkerRef: PropTypes.func,
  showMarkerLabel: PropTypes.bool,
  selectedMarkerKey: PropTypes.string,
};
