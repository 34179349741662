import { connect } from 'react-redux';
import { driverLogs } from '../../../../data/reports';
import { CanadianEldLogDetailReport } from './CanadianEldLogDetailReport';
import { setPrintMode, selectSystemUser, selectIsUserDriver } from '../../../../data/system';

export const CanadianEldLogDetailReportContainer = connect(
  (state, { driverKey, date }) => ({
    driverLog: driverLogs.selectors.selectDriverLog(state, { driverKey, date }),
    user: selectSystemUser(state),
    isDriver: selectIsUserDriver(state),
  }),
  {
    setPrintMode,
    fetchDriverLog: driverLogs.actions.fetchDriverLog,
    fetchDriverLogViolation: driverLogs.actions.fetchDriverLogViolation,
  }
)(CanadianEldLogDetailReport);
