import { connect } from 'react-redux';
import { VideoOptions } from './VideoOptions';
import { videoEvents } from '../../../../../data/reports';

export const VideoOptionsContainer = connect(
  (state) => ({
    videoEventTypes: videoEvents.selectors.selectVideoEventTypes(state),
    videoEventBehaviors: videoEvents.selectors.selectVideoEventBehaviors(state),
  }),
  {
    fetchVideoEventTypes: videoEvents.actions.fetchVideoEventTypes,
    fetchVideoEventBehaviors: videoEvents.actions.fetchVideoEventBehaviors,
  }
)(VideoOptions);
