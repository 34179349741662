import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'stti-react-common';
import { MAX_SELECTION } from '../../../../../../helpers/admin/notifications/notificationsUtils';

export function RenderDrivers({ formControls, form, mode, driverOptions, Control }) {
  const handleChange = useCallback(
    (value) => {
      form.setControl('drivers', value);
    },
    [form]
  );

  return (
    <Control
      Component={SelectInput}
      name="drivers"
      label="Drivers"
      multiple
      options={driverOptions}
      description="Select drivers to trigger on."
      error={({ value }) => {
        if (!value || value.length === 0) {
          return 'At least one driver must be selected.';
        }
        if (value.length > MAX_SELECTION) {
          return `Up to ${MAX_SELECTION} drivers allowed.`;
        }
        return null;
      }}
      readOnly={mode === 'view'}
      value={formControls.drivers || []}
      onChange={handleChange}
    />
  );
}

RenderDrivers.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  driverOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};
