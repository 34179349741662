import { connect } from 'react-redux';

import { NotificationSummary } from './summary';

import { notification } from '../../../../../data/administration/configurations';
import { vehicle, user } from '../../../../../data/administration/resources';

export const NotificationSummaryContainer = connect(
  (state) => ({
    notifications: notification.selectors.selectNotificationTriggers(state),
  }),
  {
    fetchNotificationTriggers: notification.actions.fetchNotificationTriggers,
    deleteNotification: notification.actions.deleteNotificationTrigger,
    fetchVehicle: vehicle.actions.fetchVehicleOptimized,
    fetchUser: user.actions.fetchUserOptimized,
    fetchDriver: user.actions.fetchUserOptimized,
  }
)(NotificationSummary);
