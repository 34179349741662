import React from 'react';
import PropTypes from 'prop-types';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import { TimeField } from '@mui/x-date-pickers/TimeField';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { DateField } from '@mui/x-date-pickers';
import AutoComplete from '../../Autocomplete';
import { createDateTime } from '../../../utils/date';

export const ScheduleReportForm = ({
  content,
  control,
  currentSystemUser,
  methods,
  reportData,
}) => (
  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-CA">
    <form>
      <AutoComplete
        initialValue={
          currentSystemUser?.fullName
            ? `${currentSystemUser.fullName} - ${currentSystemUser.email}`
            : ` - ${currentSystemUser?.email}`
        }
        name="toEmails"
        label="Deliver to"
        options={uniq(
          uniqBy(content, 'email').map(({ email, fullName }) => `${fullName} - ${email}`)
        ).sort()}
        multiple
        freeSolo
        methods={methods}
        rules={{
          required: true,
          requiredMessage: 'Email is required',
          pattern: /\S+@\S+\.\S+/,
          patternMessage: 'Please enter a valid email',
        }}
      />
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Grid size={4}>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel>Every</InputLabel>
                <Select {...field} disabled>
                  <MenuItem value="Day">Day</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={4}>
          <Controller
            name="hourAt"
            control={control}
            render={({ field }) => <TimeField label="At" variant="standard" fullWidth {...field} />}
          />
        </Grid>
        <Grid size={4}>
          <Controller
            name="endAt"
            control={control}
            render={({ field }) => (
              <DateField
                label="Until"
                {...field}
                variant="standard"
                fullWidth
                minDate={createDateTime().plus({ days: 1 }).startOf('day')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Grid size={4}>
          <Controller
            name="attach"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel>File attachment</InputLabel>
                <Select {...field}>
                  <MenuItem value="pdf">PDF</MenuItem>
                  <MenuItem value="pdf,csv">PDF and CSV</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={4}>
          <Controller
            name="orientation"
            control={control}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel>PDF orientation</InputLabel>
                <Select {...field}>
                  <MenuItem value="portrait">Portrait</MenuItem>
                  <MenuItem value="landscape">Landscape</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={4}>
          <FormControlLabel
            control={
              <Switch onChange={(e) => methods?.setValue('includeDetail', e.target.checked)} />
            }
            label="Include Detail Reports"
          />
        </Grid>
      </Grid>
      <p>Report has been modified and will be saved before scheduling.</p>
      <Controller
        name="report"
        control={control}
        render={({ field }) => (
          <FormControl variant="standard" fullWidth>
            <InputLabel>Save this report view as:</InputLabel>
            <Select {...field}>
              {sortBy(reportData, ['name']).map((report) => (
                <MenuItem key={report.id} value={report.id}>
                  {report.defaultView ? 'Default' : report.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </form>
  </LocalizationProvider>
);

ScheduleReportForm.propTypes = {
  content: PropTypes.array,
  control: PropTypes.object,
  currentSystemUser: PropTypes.object,
  methods: PropTypes.object,
  reportData: PropTypes.array,
};
