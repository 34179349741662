import { toLower } from 'lodash';

import { getEnvironment } from './environment/_environment';

const googleConfig = {
  client_id: '296415217359-a0vncfb3i5q1npqon15n97797s0vflj1.apps.googleusercontent.com',
  response_type: 'code',
  scope: 'email',
  access_type: 'online',
  redirect_uri: window.location.origin,
};

const googleSignInLink = `${'https://accounts.google.com/o/oauth2/auth?client_id='}${
  googleConfig.client_id
}&response_type=${googleConfig.response_type}&scope=${googleConfig.scope}&access_type=${
  googleConfig.access_type
}&redirect_uri=${googleConfig.redirect_uri}`;

const ENV = getEnvironment();
const { isLocal } = ENV;

export const CONSTANTS = {
  baseUrl: ENV.WC_API_BASE_URL,
  legacyBaseUrl: ENV.REPORTS_BASE_URL,
  originUrl: ENV.ORIGIN_URL,
  googleSignInLink,
  googleMapsApiKey: ENV.GOOGLE_API_KEY,
  rollBar: {
    accessToken: '14a31c7be321414f9a21189e3e3de635',
    maxItemsPerMinute: 5,
    captureUncaught: !isLocal,
    enabled: !isLocal,
    environment: toLower(ENV.NAME),
  },
};
