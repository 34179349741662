import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'stti-react-common';
import { MAX_SELECTION } from '../../../../../../helpers/admin/notifications/notificationsUtils';

export function RenderGeozones({ formControls, form, mode, geozoneOptions, Control }) {
  const handleChange = useCallback(
    (value) => {
      form.setControl('geozones', value);
    },
    [form]
  );

  return (
    <Control
      Component={SelectInput}
      name="geozones"
      label="Geozones"
      multiple
      options={geozoneOptions}
      description="Select one or many geozones."
      error={({ value }) => {
        if (!value || value.length === 0) {
          return 'At least one geozone must be selected.';
        }
        if (value.length > MAX_SELECTION) {
          return `You can select up to ${MAX_SELECTION} geozones.`;
        }
        if (value.length > 1 && formControls.vehicles?.length > 1) {
          return 'If multiple geozones, only one vehicle allowed.';
        }
        return null;
      }}
      readOnly={mode === 'view'}
      value={formControls.geozones || []}
      onChange={handleChange}
    />
  );
}

RenderGeozones.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  geozoneOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};
