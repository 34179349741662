import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { createDateTime, formatDateTime } from '../../utils/dates';

import '../../css/Tiles.css';

const OrderInfo = ({ shippingDocuments }) =>
  shippingDocuments?.length === 0 ? (
    <span style={{ fontSize: '16px' }}>No order information</span>
  ) : (
    shippingDocuments?.map((row) => <li key={uniqueId('order')}>{row}</li>)
  );

export const Order = ({ logData, entity }) => {
  const { data, isLoading } = logData || {};
  const { shippingDocuments, driver } = data || { shippingDocuments: [], driver: {} };

  const todayDate = formatDateTime(createDateTime().toISO(), 'yyyyMMdd');

  if (!entity?.driver) {
    return null;
  }

  return (
    <div className="Tiles__tile">
      <h4>Order Summary </h4>

      <div className="Tiles__tile__content">
        {isLoading ? (
          <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
        ) : (
          <OrderInfo shippingDocuments={shippingDocuments} />
        )}
      </div>

      <div className="Tiles__tile__footer">
        <a
          href={`#/reports/driverLogSummary/default/driverLogs/${driver?.key}-${todayDate}/default`}
          target="_blank"
          rel="noreferrer"
        >
          View Details
        </a>
      </div>
    </div>
  );
};

Order.propTypes = {
  logData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
  }),
  entity: PropTypes.object,
};

OrderInfo.propTypes = {
  shippingDocuments: PropTypes.array,
};
