import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, ProgressIndicator } from 'stti-react-common';

import { customPropTypes } from '../../../helpers/customPropTypes';
import { textColorByHexLuminance } from '../../../helpers/styleHelpers';
import { getRelativeTime, dateObject } from '../../../helpers/moment';
import { getRolesByUserMemberships } from '../../../helpers/roles';

import './notifications.scss';

const SENT_STATUS = 'SENT';
const READ_STATUS = 'READ';

export const AppNotifications = ({
  user,
  tenant,
  histories,
  fetchNotificationHistories,
  updateNotificationHistories,
  ous,
}) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  const { brandPrimary } = tenant;

  const userRoles = getRolesByUserMemberships(user, ous);

  const onUpdateItem = (id, status) => {
    if (status === READ_STATUS) return;
    setLoading(true);
    updateNotificationHistories({ id, ids: [id], status: READ_STATUS }).then(() =>
      setLoading(false)
    );
  };

  const onUpdateItems = () => {
    const ids = histories
      .filter((history) => history.status === SENT_STATUS)
      .map((history) => history.id);

    if (!ids.length) return;
    setLoading(true);
    updateNotificationHistories({ id: ids[0], ids, status: READ_STATUS }).then(() =>
      setLoading(false)
    );
  };

  // Sort histories from newest -> oldest and build out tab content
  const tabs = useMemo(() => {
    const sortedHistories = [...(histories || [])].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    let alerts;
    if (loading) {
      alerts = (
        <div className="AppNotifications__Loading">
          <ProgressIndicator />
        </div>
      );
    } else if (!sortedHistories.length) {
      alerts = (
        <div className="EmptyState">
          <h2>No new notifications</h2>
        </div>
      );
    } else {
      alerts = sortedHistories.map(({ id, status, title, createdAt, body }) => (
        <div className={`History__Item ${status?.toLowerCase()}`} key={id}>
          {/* Left status indicator for read/unread */}
          <div
            className="History__Item__Status"
            onClick={() => !loading && onUpdateItem(id, status)}
          />
          <div className="History__Item__Container">
            <div className="History__Item__Header">
              <span className="History__Title">{title}</span>
              <span className="History__Date">{getRelativeTime(createdAt)}</span>
            </div>
            <div className="History__Content">{body}</div>
          </div>
        </div>
      ));
    }

    // If you only want a single Alerts tab, omit or add more as needed
    return [{ label: 'Alerts', content: alerts }];
  }, [histories, loading]);

  // On mount, fetch notifications, then poll every 30s
  useEffect(() => {
    fetchNotificationHistories({
      startDate: dateObject().subtract(7, 'days').toISOString(),
      endDate: dateObject().toISOString(),
    });

    const intervalId = setInterval(() => {
      fetchNotificationHistories({
        startDate: dateObject().subtract(7, 'days').toISOString(),
        endDate: dateObject().toISOString(),
      });
    }, 30000);

    return () => clearInterval(intervalId);
  }, [fetchNotificationHistories]);

  // Close panel if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setNotificationOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const unreadNotifications = histories?.filter((history) => history.status === SENT_STATUS);

  return (
    <div className="AppNotifications">
      {/* Notifications icon button */}
      <Button
        onClick={() => setNotificationOpen(true)}
        icon="notifications"
        style={{
          background: brandPrimary,
          color: textColorByHexLuminance(brandPrimary),
        }}
      />

      {/* Unread badge */}
      {unreadNotifications?.length > 0 && (
        <div className="AppNotifications__Badge">{unreadNotifications.length}</div>
      )}

      {/* Notification panel */}
      {notificationOpen && (
        <>
          <div className="triangle" />
          <div className="AppNotifications__Panel" ref={ref}>
            <div className="AppNotifications__Panel__Header">
              <h3>Notifications</h3>
              <div className="AppNotifications__Panel__Header__Actions">
                {userRoles.isSystem && (
                  <Button
                    label="Create Notification"
                    icon="add"
                    onClick={() => {
                      window.location.href = '/#/administration/notifications/notification/new';
                    }}
                  />
                )}

                <Button
                  label="Mark All As Read"
                  onClick={onUpdateItems}
                  disabled={!unreadNotifications?.length}
                />
              </div>
            </div>
            <div className="AppNotifications__Body">
              <Tabs tabs={tabs} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

AppNotifications.propTypes = {
  user: customPropTypes.user,
  tenant: customPropTypes.tenant,
  histories: PropTypes.array.isRequired,
  fetchNotificationHistories: PropTypes.func.isRequired,
  updateNotificationHistories: PropTypes.func.isRequired,
  ous: customPropTypes.organizations,
};
