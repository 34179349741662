import React from 'react';

import { LocationSummaryContainer } from './Summary/connect';

import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';

import { locationTypeSummary } from '../LocationTypes/routes';

import {
  administrationGroups,
  configureRoute,
  ADMIN_ROLE_KEY,
  MANAGER_ROLE_KEY,
  DISPATCHER_ROLE_KEY,
} from '../../../../config';

export const locationSummary = configureRoute({
  name: 'Geozones / Locations',
  group: administrationGroups.resources,
  order: 1,
  path: '/locations',
  RouteFrame: BasicRouteFrame,
  permissions: [ADMIN_ROLE_KEY, MANAGER_ROLE_KEY, DISPATCHER_ROLE_KEY],
  render: (props) => (
    <LocationSummaryContainer {...props} openLocationTypes={locationTypeSummary.open} />
  ),
});
