/* ------------------------------------------------------------------
 * CONSTANTS & HELPER FUNCTIONS
 * ------------------------------------------------------------------ */

export const MAX_SELECTION = 10;
export const MAX_NAME_LENGTH = 60;

export const NOTIFICATION_TYPE = {
  ALERT: 'ALERT',
};

export const TRIGGER_TYPE_TO_FORM_TYPE = {
  GEOZONE: 'GEOZONE',
  INSPECTION: 'INSPECTION',
  LOAD_UNLOAD: 'LOAD_UNLOAD',
  STATE: 'STATE',
  TIME_DEFERRAL: 'TIME_DEFERRAL',
  REGULATION: 'REGULATION',
  MALFUNCTION: 'MALFUNCTION',
};

export const TRIGGER_TYPE_TO_API_TYPE = {
  GEOZONE: 'GEOZONE',
  INSPECTION: 'INSPECTION',
  LOAD_UNLOAD: 'LOAD_UNLOAD',
  STATE: 'STATE',
  TIME_DEFERRAL: 'TIME_DEFERRAL',
  REGULATION: 'REGULATION',
  MALFUNCTION: 'MALFUNCTION',
};

const MALFUNCTIONS_TO_FORM_TYPE = {
  POWER_SYNC: 'Power Sync',
  POWER_SYNC_LOSS: 'Power Sync Loss',
  TIMING: 'Timing',
  OTHER: 'Other',
  DATA_TRANSFER: 'Data Transfer',
  DATA_RECORDING: 'Data Recording',
  POSITIONING: 'Positioning',
  ENGINE_SYNC: 'Engine Sync',
  POWER: 'Power',
};

/**
 * Build the API `triggerConfiguration` object from form data.
 */
export function buildTriggerConfiguration(data) {
  const { triggerType } = data;

  // Helper to convert an array of keys into objects of the form { key: <value> }
  const mapKeysToObjects = (keys = []) => keys.map((k) => ({ key: k }));

  const tc = {};

  switch (triggerType) {
    case 'GEOZONE':
      // Expect vehicles and geozones as arrays of keys; map them into objects.
      tc.vehicles = mapKeysToObjects(data.vehicles || []);
      tc.geozones = mapKeysToObjects(data.geozones || []);
      tc.entered = Boolean(data.entered);
      tc.exited = Boolean(data.exited);
      break;

    case 'INSPECTION':
      // For INSPECTION, assume vehicles are provided.
      tc.vehicles = mapKeysToObjects(data.vehicles || []);
      tc.majorDefects = Boolean(data.majorDefects);
      tc.minorDefects = Boolean(data.minorDefects);
      tc.unsafeToDrive = Boolean(data.unsafeToDrive);
      break;

    case 'LOAD_UNLOAD':
      // For LOAD_UNLOAD, also map vehicles.
      tc.vehicles = mapKeysToObjects(data.vehicles || []);
      tc.triggerCondition = data.triggerCondition || '';
      tc.load = Boolean(data.loadCompleted);
      tc.unload = Boolean(data.unloadCompleted);
      break;

    case 'STATE':
      // For STATE triggers, map vehicles but leave provinces/states as plain strings.
      tc.vehicles = mapKeysToObjects(data.vehicles || []);
      tc.entered = Boolean(data.entered);
      tc.exited = Boolean(data.exited);

      // Use the provided country to determine whether to use provinces or states.
      if (data.country === 'CA') {
        tc.country = 'CANADA';
        // Expect an array of province codes (plain strings)
        tc.provinces = data.provinceStateKeys || [];
        tc.states = [];
      } else if (data.country === 'US') {
        tc.country = 'US';
        // Expect an array of state codes (plain strings)
        tc.states = data.provinceStateKeys || [];
        tc.provinces = [];
      } else {
        tc.country = data.country || null;
        tc.provinceStateKeys = data.provinceStateKeys || [];
      }
      break;

    case 'TIME_DEFERRAL':
    case 'REGULATION':
      // For driver-based triggers, map the drivers.
      tc.drivers = mapKeysToObjects(data.drivers || []);
      break;

    case 'MALFUNCTION':
      // For MALFUNCTION, map vehicles and pass through any malfunction details.
      tc.vehicles = mapKeysToObjects(data.vehicles || []);
      tc.raisedMalfunctions = (data.raisedMalfunctions || []).map((m) =>
        m.replace(' ', '_').toUpperCase()
      );
      tc.clearedMalfunctions = (data.clearedMalfunctions || []).map((m) =>
        m.replace(' ', '_').toUpperCase()
      );
      break;

    default:
      // Optionally handle or warn for unknown trigger types.
      break;
  }

  return tc;
}

/**
 * Map the server-side configuration for a STATE trigger into local form controls.
 */
function mapStateTriggerToFormControls(triggerConfiguration) {
  const data = {
    vehicles: (triggerConfiguration.vehicles || []).map((v) => v.key),
    entered: Boolean(triggerConfiguration.entered),
    exited: Boolean(triggerConfiguration.exited),
  };
  // Helper to normalize an array of province/state keys.
  const normalizeProvinceStateKeys = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
      return [];
    }
    if (typeof arr[0] === 'object') {
      return arr.map((item) => item.key);
    }
    return arr;
  };
  if (triggerConfiguration.country === 'CANADA') {
    data.country = 'CA';
    data.provinceStateKeys = normalizeProvinceStateKeys(triggerConfiguration.provinces);
  } else if (triggerConfiguration.country === 'US') {
    data.country = 'US';
    data.provinceStateKeys = normalizeProvinceStateKeys(triggerConfiguration.states);
  } else {
    data.provinceStateKeys = normalizeProvinceStateKeys(triggerConfiguration.provinceStateKeys);
  }
  return data;
}

/**
 * Convert a server‑side notification into local form fields.
 */
export function mapNotificationToFormControls(_notification) {
  if (!_notification) return {};

  const triggerConfiguration = _notification.triggerConfiguration || {};
  const data = {
    name: _notification.name || '',
    triggerType: _notification.triggerType || '',
  };

  switch (_notification.triggerType) {
    case 'GEOZONE':
      // Map each vehicle/geozone object to its key.
      data.vehicles = (triggerConfiguration.vehicles || []).map((v) => v.key);
      data.geozones = (triggerConfiguration.geozones || []).map((g) => g.key);
      data.entered = Boolean(triggerConfiguration.entered);
      data.exited = Boolean(triggerConfiguration.exited);
      break;

    case 'INSPECTION':
      data.vehicles = (triggerConfiguration.vehicles || []).map((v) => v.key);
      data.majorDefects = Boolean(triggerConfiguration.majorDefects);
      data.minorDefects = Boolean(triggerConfiguration.minorDefects);
      data.unsafeToDrive = Boolean(triggerConfiguration.unsafeToDrive);
      break;

    case 'LOAD_UNLOAD':
      data.vehicles = (triggerConfiguration.vehicles || []).map((v) => v.key);
      data.triggerCondition = triggerConfiguration.triggerCondition || '';
      data.loadCompleted = Boolean(triggerConfiguration.load);
      data.unloadCompleted = Boolean(triggerConfiguration.unload);
      break;

    case 'STATE':
      Object.assign(data, mapStateTriggerToFormControls(triggerConfiguration));
      break;

    case 'TIME_DEFERRAL':
    case 'REGULATION':
      data.drivers = (triggerConfiguration.drivers || []).map((d) => d.key);
      break;

    case 'MALFUNCTION':
      data.vehicles = (triggerConfiguration.vehicles || []).map((v) => v.key);
      // Map malfunction keys to readable form
      data.raisedMalfunctions = (triggerConfiguration.raisedMalfunctions || []).map(
        (m) => MALFUNCTIONS_TO_FORM_TYPE[m] || m
      ); // Default to key if not found

      data.clearedMalfunctions = (triggerConfiguration.clearedMalfunctions || []).map(
        (m) => MALFUNCTIONS_TO_FORM_TYPE[m] || m
      ); // Default to key if not found

      break;

    default:
      break;
  }

  return data;
}
