import React from 'react';
import PropTypes from 'prop-types';

// Import individual notification type components
import NotificationTypeGeozone from '../types/NotificationTypeGeozone';
import NotificationTypeInspection from '../types/NotiifcationTypeInspection';
import NotificationTypeLoadUnload from '../types/NotificationTypeLoadUnload';
import NotificationTypeState from '../types/NotificationTypeState';
import NotificationTypeDriverBased from '../types/NotificationTypeDriverBased';
import NotificationTypeMalfunction from '../types/NotificationTypeMalfunction';

import './NotificationTypeFields.scss';

export const NotificationTypeFields = (props) => {
  const { formControls } = props;

  // Map trigger types to their corresponding components.
  // (For TIME_DEFERRAL and REGULATION we use the driver-based component.)
  const componentMap = {
    GEOZONE: NotificationTypeGeozone,
    INSPECTION: NotificationTypeInspection,
    LOAD_UNLOAD: NotificationTypeLoadUnload,
    STATE: NotificationTypeState,
    TIME_DEFERRAL: NotificationTypeDriverBased,
    REGULATION: NotificationTypeDriverBased,
    MALFUNCTION: NotificationTypeMalfunction,
  };

  const SelectedTypeComponent = componentMap[formControls.triggerType] || null;

  if (!SelectedTypeComponent) {
    return <div>Select a notification type</div>;
  }

  return (
    <div className="notification-type-container">
      <SelectedTypeComponent {...props} />
    </div>
  );
};

NotificationTypeFields.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  geozoneOptions: PropTypes.array.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  driverOptions: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  availableProvinces: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeFields;
