import { filter, uniq, uniqueId } from 'lodash';
import { RateLimit } from 'async-sema';

import { ACTION_TYPE } from './reducer';
import { api } from '../../api';
import { dateObject, getMinMaxDatesFromArray } from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { transformDriverLogs, dateProperties, driverObject, transformReviewDetails } from './utils';

const limit = RateLimit(1);

export const fetchDriverLogs = makeRestApiAction({
  service: 'driverLogs',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    start: startDate.split('T')[0],
    end: endDate.split('T')[0],
    ouKey,
  }),
  transformOutput: transformDriverLogs,
  baseActionType: ACTION_TYPE.fetchDriverLogs,
  notificationsItemDescriptor: 'driver logs',
});

export const fetchMyDriverLogs = makeRestApiAction({
  service: 'myDriverLogs',
  method: 'get',
  transformInput: ({ startDate, endDate }) => ({
    start: startDate.split('T')[0],
    end: endDate.split('T')[0],
  }),
  transformOutput: transformDriverLogs,
  baseActionType: ACTION_TYPE.fetchMyDriverLogs,
  notificationsItemDescriptor: 'driver logs',
});

export const fetchDriverLog = makeRestApiAction({
  service: 'driverLogDetail',
  method: 'get',
  getId: ({ driverKey, date }) => `${driverKey}/${date}`,
  transformInput: ({ eld }) => ({ us: eld === 'us' }),
  transformOutput: ({
    logDate,
    timeZone,
    dutyStatusChanges,
    reviewStatus: reviewDetailsKey,
    ...rest
  }) => ({
    ...rest,
    reviewDetailsKey,
    dutyStatusChanges: dutyStatusChanges.map((dutyStatusChange) => ({
      timeZone,
      ...dutyStatusChange,
    })),
    ...dateProperties(logDate, timeZone),
    driver: driverObject(rest),
    coDrivers:
      rest &&
      rest.coDrivers &&
      rest.coDrivers.map((coDriver) => driverObject({ driver: coDriver })),
  }),
  baseActionType: ACTION_TYPE.fetchDriverLog,
  notificationsItemDescriptor: 'driver log',
});

export const fetchDriverLogViolation = makeRestApiAction({
  service: 'driverLogViolations',
  method: 'get',
  getId: (key) => key,
  transformOutput: transformReviewDetails,
  baseActionType: ACTION_TYPE.fetchDriverLogReviewDetailsByKey,
  notificationsItemDescriptor: 'driver log violation',
});

export const fetchDriverLogsReviewDetails = makeRestApiAction({
  service: 'driverLogViolations',
  method: 'get',
  // transformInput: ({ ouKey }) => ({ ou: ouKey }),
  transformOutput: (logViolations) => logViolations.map(transformReviewDetails),
  baseActionType: ACTION_TYPE.fetchDriverLogsReviewDetails,
  notificationsItemDescriptor: 'driver log violations',
});

export const submitCreateLogDutyStatusEvent = makeRestApiAction({
  service: 'driverLogDutyStatus',
  method: 'post',
  baseActionType: ACTION_TYPE.submitCreateLogDutyStatusEvent,
  transformInput: ({ start, end, timeZone, ...rest }) => ({
    start: dateObject(start, timeZone).toISOString(),
    end: end && dateObject(end, timeZone).toISOString(),
    ...rest,
  }),
  transformOutput: (responseData, { key, start, end, reason, timeZone, ...rest }) => {
    const dateMap = !end ? [start] : [start, end];

    return {
      adminStatusChanges: dateMap.map(
        (date) =>
          date && {
            date: dateObject(date, timeZone).toISOString(),
            key: uniqueId(),
            status: 'INACTIVE_CHANGE_REQUESTED',
            origin: 'Other User(3)',
            ...rest,
          }
      ),
      key,
    };
  },
  notificationsItemDescriptor: 'log event',
});

export const submitUpdateLogDutyStatusEvent = makeRestApiAction({
  service: 'driverLogDutyStatus',
  method: 'put',
  baseActionType: ACTION_TYPE.submitUpdateLogDutyStatusEvent,
  getId: ({ key }) => key,
  transformInput: ({ dutyStatus, annotation }) => ({
    dutyStatus,
    annotation,
  }),
  transformOutput: (responseData, { logKey, start, reason, timeZone, ...rest }) => ({
    // TODO: Revisit this once server implements a response shape so we can use the real key
    adminStatusChanges: [
      {
        date: dateObject(start, timeZone).toISOString(),
        key: uniqueId(),
        status: 'INACTIVE_CHANGE_REQUESTED',
        origin: 'Other User(3)',
        ...rest,
      },
    ],
    key: logKey,
  }),
  notificationsItemDescriptor: 'log event',
});

export const submitReassignLogDutyStatusEvent = makeRestApiAction({
  service: 'driverLogDutyStatus',
  method: 'put',
  baseActionType: ACTION_TYPE.submitReassignLogDutyStatusEvent,
  getId: ({ key }) => `${key}/reassign`,
  transformInput: ({ toDriverKey, annotation }) => ({
    toDriverKey,
    annotation,
  }),
  transformOutput: (responseData, { logKey, start, annotation }) => ({
    adminStatusChanges: [
      {
        date: dateObject(start).toISOString(),
        key: uniqueId(),
        status: 'INACTIVE_CHANGE_REQUESTED',
        annotation,
      },
    ],
    key: logKey,
  }),
  notificationsItemDescriptor: 'log event driver',
});

export const reviewViolation = makeRestApiAction({
  service: 'driverLogViolations',
  method: 'put',
  baseActionType: ACTION_TYPE.reviewViolation,
  getId: ({ key }) => key,
  transformOutput: (responseData, { logKey }) => ({
    // Need to append key to we can merge into driverLog state
    key: logKey,
    reviewDetails: {
      ...responseData,
    },
  }),
  notifications: {
    success: 'Violation reviewed.',
    failure: (actionParam, apiError) => `Couldn't review violation: ${apiError.message}`,
  },
});

export const startGeneralPurposeTransfer = makeRestApiAction({
  service: 'reportOnDemand',
  method: 'post',
  baseActionType: ACTION_TYPE.startGeneralPurposeTransfer,
  transformInput: ({ title, orientation, urls, toEmails, userKey }, { originUrl }) => ({
    reportName: title,
    userKey,
    urls: urls.map((url) => `${originUrl}${url}?print=true&attach=pdf`),
    toEmails,
    includeDetail: true, // ensure falsely is explicit false
    config: {
      landscape: orientation === 'landscape',
    },
  }),
  notifications: {
    request: 'Emailing report...',
    success: 'Report email requested.',
    failure: (_, apiError) => `Report could not be emailed (${apiError.message}).`,
  },
});

export const startDataTransfer =
  ({
    logs, // array of driver logs: { driver.key, startedAt, endedAt }
    jurisdiction, // dropped for now
    comment,
    mechanism,
    email,
  }) =>
  async (dispatch) => {
    await limit();

    // get unique driver keys
    const driverKeys = uniq(logs.map(({ driver }) => driver && driver.key));

    // each driver will have it's own id and data
    const minimalRanges = [];
    driverKeys.forEach((driverKey) => {
      const driverRanges = filter(logs, ({ driver: { key } }) => key === driverKey);
      const { minStartDate, maxEndDate } = getMinMaxDatesFromArray(driverRanges);

      minimalRanges.push({
        driverKey,
        start: minStartDate.toISOString(),
        end: maxEndDate.toISOString(),
      });
    });

    // payload and dispatch strictly for Redux logging
    const payload = {
      minimalRanges,
      jurisdiction,
      comment,
      mechanism,
      email,
    };

    dispatch({
      type: `${ACTION_TYPE.startDataTransfer}.request`,
      notification: { message: 'Requesting data transfer...' },
      payload,
    });

    let fulfilledRequestCount = 0;
    let rejectedRequestCount = 0;

    await Promise.all(
      minimalRanges
        .map(async ({ driverKey, start, end }) =>
          api.driverLogDataTransfer.post({
            id: driverKey,
            data: {
              start,
              end,
              comment,
              jurisdiction,
              email,
              mechanism,
            },
          })
        )
        .map((apiPromise) =>
          apiPromise
            .then(() => {
              fulfilledRequestCount += 1;
            })
            .catch(() => {
              rejectedRequestCount += 1;
            })
        )
    );

    if (rejectedRequestCount === 0) {
      dispatch({
        type: `${ACTION_TYPE.startDataTransfer}.success`,
        notification: { message: 'Data transfer initiated.' },
        payload,
      });
    } else if (fulfilledRequestCount === 0) {
      dispatch({
        type: `${ACTION_TYPE.startDataTransfer}.failure`,
        notification: { message: 'Data transfer could not be initiated.' },
        payload,
      });
    } else {
      dispatch({
        type: `${ACTION_TYPE.startDataTransfer}.failure`,
        notification: { message: 'Data transfer was partially initiated, with some errors.' },
        payload,
      });
    }
  };

export const signDriverLog = makeRestApiAction({
  service: 'driverLogSign',
  method: 'post',
  transformInput: ({ comment, logDate, signature }) => ({ comment, logDate, signature }),
  transformOutput: (responseData, { key, signature }) => ({
    key,
    certificationStatus: { signature },
    ...responseData,
  }),
  baseActionType: ACTION_TYPE.signDriverLog,
  notificationsItemDescriptor: 'log sign',
});

export const addDriverLogComment = makeRestApiAction({
  service: 'driverLogDailyComment',
  method: 'post',
  transformInput: ({ comment, logDate, driverKey }) => ({ comment, logDate, driverKey }),
  baseActionType: ACTION_TYPE.addDriverLogComment,
  notificationsItemDescriptor: 'log comment',
});

export const deleteDriverLogComment = makeRestApiAction({
  service: 'driverLogDailyComment',
  method: 'delete',
  getId: ({ key }) => key,
  transformInput: ({ key }) => key,
  baseActionType: ACTION_TYPE.deleteDriverLogComment,
  notificationsItemDescriptor: 'log comment',
});
