import { columnDef } from '../../../../commons/ControlledDataGrid';

// Mapping of trigger types to friendly names
const triggerTypeFriendlyNames = {
  GEOZONE: 'Geozone',
  INSPECTION: 'Inspection',
  LOAD_UNLOAD: 'Load/Unload Activity',
  STATE: 'State/Province Entered/Exited',
  TIME_DEFERRAL: 'Driver ELD Deferral Applied',
  REGULATION: 'Driver ELD Regulation Changed',
  MALFUNCTION: 'ELD Malfunction Raised/Cleared',
};

// Helper function to safely join array values
const joinArray = (arr) => (arr && arr.length > 0 ? arr.join(', ') : 'N/A');

// Helper function to extract a list of 'name' from an array of objects
const joinNames = (arr) =>
  arr && arr.length > 0 ? arr.map((item) => item.name).join(', ') : 'N/A';

// Helper function to extract and format configuration details
const formatConfiguration = (params) => {
  const config = params.data.triggerConfiguration || {};
  const { triggerType } = params.data;

  switch (triggerType) {
    case 'GEOZONE': {
      const entered = config.entered ? 'Entered' : '';
      const exited = config.exited ? 'Exited' : '';
      const actions = [entered, exited].filter(Boolean).join('/');
      return `Action: ${actions || 'N/A'}`;
    }

    case 'INSPECTION': {
      const majorDefects = config.majorDefects ? 'Yes' : 'No';
      const minorDefects = config.minorDefects ? 'Yes' : 'No';
      const unsafeToDrive = config.unsafeToDrive ? 'Yes' : 'No';
      const filters = [
        `Major Defects: ${majorDefects}`,
        `Minor Defects: ${minorDefects}`,
        `Unsafe to Drive: ${unsafeToDrive}`,
      ].join(' | ');
      return `Filters: ${filters}`;
    }

    case 'LOAD_UNLOAD': {
      const triggerCondition = config.triggerCondition || 'N/A';
      const load = config.loadCompleted ? 'Load Completed' : '';
      const unload = config.unloadCompleted ? 'Unload Completed' : '';
      const actions = [load, unload].filter(Boolean).join('/');
      return `Trigger Condition: ${triggerCondition} | Actions: ${actions || 'N/A'}`;
    }

    case 'STATE': {
      const country = config.country || 'N/A';
      const provinces = joinArray(config.provinceStateKeys);
      const entered = config.entered ? 'Entered' : '';
      const exited = config.exited ? 'Exited' : '';
      const actions = [entered, exited].filter(Boolean).join('/');
      return `Country: ${country} | Provinces/States: ${provinces} | Action: ${actions || 'N/A'}`;
    }

    case 'TIME_DEFERRAL':
    case 'REGULATION': {
      const drivers = joinNames(config.drivers);
      return `Drivers: ${drivers}`;
    }

    case 'MALFUNCTION': {
      const raised = joinArray(config.raisedMalfunctions);
      const cleared = joinArray(config.clearedMalfunctions);
      return `Raised Malfunctions: ${raised} | Cleared Malfunctions: ${cleared}`;
    }

    default:
      return 'N/A';
  }
};

export const columnDefs = [
  // 1) Icon column: "Open"
  columnDef.iconColumn({
    field: 'openNotification',
    headerName: 'Open',
    cellRenderer: 'NotificationLink',
    headerComponent: 'NotificationHeader',
    width: 80,
  }),

  // 2) Name Column
  columnDef.textColumn({
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    enableRowGroup: false,
    sortable: true,
    filter: true,
  }),

  // 3) Type Column with Friendly Names
  columnDef.textColumn({
    field: 'triggerType',
    headerName: 'Type',
    minWidth: 200,
    enableRowGroup: false,
    sortable: true,
    filter: true,
    valueGetter: (params) =>
      triggerTypeFriendlyNames[params.data.triggerType] || params.data.triggerType || 'N/A',
  }),

  // === GROUP COLUMN: "Configuration" ===
  {
    headerName: 'Configuration',
    marryChildren: true,
    children: [
      // Trigger Condition
      columnDef.textColumn({
        field: 'triggerCondition',
        headerName: 'Trigger Condition',
        minWidth: 180,
        sortable: false,
        filter: false,
        valueGetter: formatConfiguration,
      }),

      // Vehicles
      columnDef.textColumn({
        field: 'vehicles',
        headerName: 'Vehicles',
        minWidth: 180,
        enableRowGroup: false,
        sortable: false,
        filter: false,
        valueGetter: (params) => {
          const vehicles = params?.data?.triggerConfiguration?.vehicles;
          return joinNames(vehicles);
        },
      }),

      // Geozones
      columnDef.textColumn({
        field: 'geozones',
        headerName: 'Geozones',
        minWidth: 180,
        enableRowGroup: false,
        sortable: false,
        filter: false,
        valueGetter: (params) => {
          const geozones = params?.data?.triggerConfiguration?.geozones;
          return joinNames(geozones);
        },
      }),

      // Drivers
      columnDef.textColumn({
        field: 'drivers',
        headerName: 'Drivers',
        minWidth: 180,
        enableRowGroup: false,
        sortable: false,
        filter: false,
        valueGetter: (params) => {
          const drivers = params?.data?.triggerConfiguration?.drivers;
          return joinNames(drivers);
        },
      }),
    ],
  },

  // === GROUP COLUMN: "Destinations" ===
  {
    headerName: 'Destinations',
    marryChildren: true,
    children: [
      // Emails
      columnDef.textColumn({
        field: 'emails',
        headerName: 'Emails',
        minWidth: 200,
        enableRowGroup: false,
        sortable: false,
        filter: false,
        valueGetter: (params) => joinArray(params?.data?.destination?.emails) || 'N/A',
      }),
      // Phone Numbers
      columnDef.textColumn({
        field: 'phoneNumbers',
        headerName: 'Phone Numbers',
        minWidth: 200,
        enableRowGroup: false,
        sortable: false,
        filter: false,
        valueGetter: (params) => joinArray(params?.data?.destination?.phoneNumbers) || 'N/A',
      }),
      // Users
      columnDef.textColumn({
        field: 'users',
        headerName: 'Users',
        minWidth: 200,
        enableRowGroup: false,
        sortable: false,
        filter: false,
        valueGetter: (params) => {
          // If destination.users is an array of objects with .name
          const users = params?.data?.destination?.users;
          return joinNames(users) || 'N/A';
        },
      }),
    ],
  },

  // 9) Created At Column
  columnDef.dateColumn({
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 180,
    enableRowGroup: false,
    sortable: true,
    filter: true,
    valueGetter: (params) => params.data.createdAt,
  }),

  // 10) Actions Column
  columnDef.actionButtonsColumn({
    cellRenderer: 'Actions', // uses Actions from NotificationSummary
    width: 100,
  }),
];
