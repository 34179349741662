import React, { createContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';

const FormContext = createContext();

const FormProvider = ({ children, values }) => {
  const { defaultValues, mode, onSubmit } = values;

  const methods = useForm({ mode, defaultValues });
  const value = useMemo(() => methods, [methods]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      methods.reset(defaultValues);
    }

    return () => {
      isMounted = false;
    };
  }, [defaultValues]);

  return (
    <FormContext.Provider value={value}>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-CA">
        <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          {children}
          <input type="submit" />
        </form>
      </LocalizationProvider>
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node,
  values: PropTypes.object,
  onSubmit: PropTypes.func,
};

export { FormContext, FormProvider };
