import { connect } from 'react-redux';

import { unidentifiedLogs, driverLogs } from '../../../../data/reports';
import { UnidentifiedEldLogDetailReport } from './UnidentifiedEldLogDetailReport';
import { setPrintMode, selectSystemUser, selectIsUserDriver } from '../../../../data/system';

import { user } from '../../../../data/administration/resources';

export const UnidentifiedEldLogDetailReportContainer = connect(
  (state, { vehicleKey, date }) => ({
    unidentifiedLog: unidentifiedLogs.selectors.selectUnidentifiedLog(state, { vehicleKey, date }),
    user: selectSystemUser(state),
    users: user.selectors.selectUsers(state),
    isDriver: selectIsUserDriver(state),
  }),
  {
    setPrintMode,
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchUnidentifiedLog: unidentifiedLogs.actions.fetchUnidentifiedLog,
    fetchDriverLogViolation: driverLogs.actions.fetchDriverLogViolation,
  }
)(UnidentifiedEldLogDetailReport);
