import { find, upperCase, camelCase } from 'lodash';

import { localReportViews } from '../../localforage';
import { makeRestApiAction } from '../../apiActionSupport';
import { selectReportViews } from './selectors';
import { ACTION_TYPE } from './reducer';
import { FEATURES } from '../../../features';

const reportCodeByReportType = (reportType) => upperCase(reportType).replace(/ /g, '_');

const reportTypeByReportCode = (reportCode) => camelCase(reportCode);

const transformForServer = ({
  title = '',
  description = '',
  reportType,
  ouKey,
  state,
  defaultView = false,
  hidden = false,
}) => {
  const payload = {
    name: title,
    description,
    reportCode: reportCodeByReportType(reportType),
    state,
    ouKey,
    defaultView,
  };

  if (FEATURES.reports.hideOnDemandReportsFromListing) {
    return { ...payload, hidden };
  }

  return payload;
};

const transformDefaultForServer = ({ title, description, defaultView, ouKey, ...rest }) =>
  transformForServer({
    ...rest,
    title: 'Default view',
    description: 'Default view',
    ouKey: null,
    defaultView: true,
  });

const transformForStore = ({ name, description, reportCode, defaultView, ...rest }) => ({
  title: defaultView ? '' : name,
  description: defaultView ? '' : description,
  reportType: reportTypeByReportCode(reportCode),
  defaultView: !!defaultView,
  ...rest,
});

export const fetchReportView = makeRestApiAction({
  service: 'reportViews',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchReportView,
  getId: (id) => id,
  transformInput: () => ({}),
  transformOutput: transformForStore,
  notifications: {
    failure: (actionParam, apiError) => `Couldn't retrieve saved report: ${apiError.message}`,
  },
});

export const fetchReportViewsByReportType = makeRestApiAction({
  service: 'reportViews',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchReportViews,
  transformInput: (reportType) => ({ reportCode: reportCodeByReportType(reportType) }),
  transformOutput: (reportViews) => reportViews.map(transformForStore),
  notifications: {
    failure: 'Could not retrieve saved reports.',
  },
});

export const fetchDefaultReportViewsByReportType = makeRestApiAction({
  service: 'reportViews',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchReportViews,
  transformInput: (reportType) => ({
    reportCode: reportCodeByReportType(reportType),
    defaultView: true,
  }),
  transformOutput: (reportViews) => reportViews.map(transformForStore),
  notifications: {
    failure: (actionParam, apiError) => `Couldn't retrieve default report: ${apiError.message}`,
  },
});

export const createTemporaryDefaultReportView = (reportView) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPE.createTemporaryDefaultReportView,
    payload: { ...reportView, __local: true },
  });
};

export const createReportView = makeRestApiAction({
  service: 'reportViews',
  method: 'post',
  baseActionType: ACTION_TYPE.createReportView,
  transformInput: transformForServer,
  transformOutput: transformForStore,
  notifications: {
    success: 'New report saved.',
    failure: 'Could not save new report.',
  },
});

export const updateReportView = makeRestApiAction({
  service: 'reportViews',
  method: 'patch',
  baseActionType: ACTION_TYPE.updateReportView,
  getId: ({ id }) => id,
  transformInput: transformForServer,
  transformOutput: transformForStore,
  notifications: {
    success: 'Report saved.',
    failure: 'Could not save report.',
  },
});

const createDefaultReportView = makeRestApiAction({
  service: 'reportViews',
  method: 'post',
  baseActionType: ACTION_TYPE.createDefaultReportView,
  transformInput: transformDefaultForServer,
  transformOutput: transformForStore,
  notifications: {
    success: 'Default saved.',
    failure: 'Could not save default.',
  },
});

const updateDefaultReportView = makeRestApiAction({
  service: 'reportViews',
  method: 'patch',
  baseActionType: ACTION_TYPE.updateDefaultReportView,
  getId: ({ id }) => id,
  transformInput: transformDefaultForServer,
  transformOutput: transformForStore,
  notifications: {
    success: 'Default saved.',
    failure: 'Could not save default.',
  },
});

export const createOrUpdateDefaultReportView =
  (newDefaultReportView) => async (dispatch, getState) => {
    const { reportType } = newDefaultReportView;
    let existingDefaultReportView = find(selectReportViews(getState()), {
      reportType,
      defaultView: true,
    });

    if (!existingDefaultReportView) {
      // no default in the store, fetch for it
      [existingDefaultReportView] = await fetchDefaultReportViewsByReportType(reportType);
    }

    if (existingDefaultReportView && !existingDefaultReportView.__local) {
      // existing persisted default exists, update it
      const { id } = existingDefaultReportView;
      return dispatch(updateDefaultReportView({ ...newDefaultReportView, id }));
    }

    // no persisted default exists, create one; local default will be destroyed
    return dispatch(createDefaultReportView(newDefaultReportView));
  };

export const deleteReportView = makeRestApiAction({
  service: 'reportViews',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteReportView,
  getId: ({ id }) => id,
  transformInput: ({ id }) => id,
  transformOutput: (response, reportView) => reportView, // "output" original reportView so payload is deleted reportView
  notifications: {
    success: 'Report deleted.',
    failure: 'Could not delete report.',
  },
});

export const createLocalReportView = (reportView) => async () =>
  localReportViews.create(reportView);

export const fetchLocalReportView = (id) => async () => localReportViews.get(id);
