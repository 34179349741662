import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GoogleMap } from 'stti-react-common';
import moment from 'moment-timezone';

import { useReport } from '../useReport';

import { breadcrumbs as reportBreadcrumbs } from '../../../../data/reports';

import { sliceBreadcrumbs } from '../../../../helpers/maps/breadcrumbUtils';

const { useMap } = GoogleMap;

const REFINE_AT_ZOOM = 12;

export const FetchBreadcrumbs = connect(
  (state) => ({
    breadcrumbsByVehicleKey: reportBreadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
  }),
  {
    fetchBreadcrumbs: reportBreadcrumbs.actions.fetchBreadcrumbsOptimized,
    fetchRefinedBreadcrumbs: reportBreadcrumbs.actions.fetchRefinedBreadcrumbsOptimized,
  }
)(
  ({
    breadcrumbsByVehicleKey,
    fetchBreadcrumbs,
    fetchRefinedBreadcrumbs,
    startAt,
    endAt,
    vehicleKey,
    noRefinements,
  }) => {
    const { registerFetch, fetchDependency } = useReport();

    const { map } = useMap() || { map: undefined };
    let bounds = null;

    if (map && map.getBounds()) {
      bounds = {
        latLngBounds: [
          {
            lat: map.getBounds().getSouthWest().lat(),
            lng: map.getBounds().getSouthWest().lng(),
          },
        ],
        zoom: map.zoom,
      };
    }

    useEffect(() => {
      if (!startAt || !endAt || !vehicleKey) return registerFetch();
      return registerFetch(
        fetchBreadcrumbs({
          startDate: moment(startAt).toISOString(),
          endDate: moment(endAt)
            .add(1, 'ms') // because endpoint returns less-than endedAt and we want the final event
            .toISOString(),
          vehicleKey,
        })
      );
    }, [startAt, endAt, vehicleKey, fetchDependency]);

    useEffect(() => {
      if (!startAt || !endAt || !vehicleKey || !bounds) return;

      const { zoom, latLngBounds } = bounds;

      if (zoom < REFINE_AT_ZOOM || !latLngBounds) return; // not zoomed in enough

      const breadcrumbs = sliceBreadcrumbs(breadcrumbsByVehicleKey[vehicleKey], startAt, endAt);

      let rangeStartDate = null;

      breadcrumbs.forEach((breadcrumb, index) => {
        const isPointInBounds = latLngBounds.some(
          (item) => item.lat < breadcrumb.latitude && item.lng < breadcrumb.longitude
        );

        if (isPointInBounds && !rangeStartDate) {
          rangeStartDate = breadcrumbs[Math.max(index - 1, 0)].eventAt; // use preceding breadcrumb, or first breadcrumb
        }

        if (
          rangeStartDate &&
          (!isPointInBounds || index === breadcrumbs.length - 1) &&
          !noRefinements
        ) {
          fetchRefinedBreadcrumbs({
            startDate: rangeStartDate,
            endDate: breadcrumb.eventAt,
            vehicleKey,
          });
          rangeStartDate = null;
        }
      });
    }, [startAt, endAt, breadcrumbsByVehicleKey[vehicleKey], bounds]);

    return null;
  }
);

FetchBreadcrumbs.propTypes = {
  // TODO: why aren't these propTypes working?
  startAt: PropTypes.string,
  endAt: PropTypes.string,
  vehicleKey: PropTypes.string,
  noRefinements: PropTypes.bool,
};
