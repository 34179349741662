import { connect } from 'react-redux';

import { FuelTaxDetailReport } from './detail';
import { selectSystemActiveOu } from '../../../../../../data/system';
import { fuelTax, breadcrumbs } from '../../../../../../data/reports';

export const FuelTaxDetailContainer = connect(
  (state, { fuelTaxId }) => ({
    fuelTaxDetails: fuelTax.selectors.selectFuelTaxDetails(state, fuelTaxId),
    taxRates: fuelTax.selectors.selectFuelTaxRates(state),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
    activeOu: selectSystemActiveOu(state),
  }),
  {
    fetchFuelTax: fuelTax.actions.fetchFuelTax,
    fetchFuelTaxExtraSegments: fuelTax.actions.fetchFuelTaxExtraSegments,
    postFuelTaxEdits: fuelTax.actions.postFuelTaxEdits,
  }
)(FuelTaxDetailReport);
