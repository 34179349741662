const ORIGIN_URL = 'https://www.mynavistream.com';
const REPORTS_BASE_URL = 'https://wcapi.mynavistream.com/v2/';
const WC_API_BASE_URL = 'https://wcapi.mynavistream.com/v1/rest/';
const GOOGLE_API_KEY = 'AIzaSyD_rOAmJbuxF3Cw58fLYV9yR6N7z9P-4RI';
const NAME = 'PROD';

export const PRODUCTION = {
  ORIGIN_URL,
  REPORTS_BASE_URL,
  WC_API_BASE_URL,
  GOOGLE_API_KEY,
  NAME,
};
