import { DateTime } from 'luxon';

const createDateTime = (date) => (date ? DateTime.fromISO(date).toJSDate() : null);

const humanizeDate = (dateString) => {
  const date = new Date(dateString);
  const dateTime = DateTime.fromJSDate(date);

  return dateTime.toRelative();
};

const deHumanizeDate = (dateString) => {
  const dt = DateTime.fromISO(dateString);

  const now = DateTime.now();

  if (dt.hasSame(now, 'day')) {
    return `Today at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  if (dt.plus({ days: 1 }).hasSame(now, 'day')) {
    return `Yesterday at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  return dt.toLocaleString(DateTime.DATE_FULL);
};

export { createDateTime, humanizeDate, deHumanizeDate };
