// feature flag file

export const FEATURES = {
  reports: {
    hideTaxReportUnknownFuelType: false,
    useAllJuridisctionFilterOnDetailTaxReport: false,
    useDynamicTaxReportCalculation: true,
    useSimplifiedReassignRules: true,
    hideOnDemandReportsFromListing: true,
    disableDateRangeAndDetailReportsOnSchedule: true,
    useJurisdictionTaxRates: true,
  },
  admin: {
    hideCameraMethods: false,
    hideVehicleSharingFromSettings: false,
    hideActivationCodesFromMainMenu: true,
    hideRolesFromMainMenu: true,
    hideVehicleTypesFromMainMenu: true,
    hideLocationTypesFromMainMenu: true,
    hideProfileFromMainMenu: true,
    hidePersonalUseFromTenant: true,
    hideYardMovesFromTenant: true,
    hideInspectionTasksFromMainMenu: true,
    hideTenantTimeZone: false,
    hideTabletCardContainer: true,
  },
};
