import { connect } from 'react-redux';

import { fuelTax, breadcrumbs } from '../../../../../../data/reports';
import { FuelTaxMapReport } from './map';

export const FuelTaxMapContainer = connect(
  (state, { fuelTaxId }) => ({
    fuelTaxDetails: fuelTax.selectors.selectFuelTaxDetails(state, fuelTaxId),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
  }),
  { fetchFuelTax: fuelTax.actions.fetchFuelTax }
)(FuelTaxMapReport);
