import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'stti-react-common';

import { dateObject } from '../../../helpers/moment';

export const HosSummary = ({ selectedData, formatUnit }) => (
  <div className="tile">
    <h4>HOS Summary </h4>

    <div className="tile__content">
      <div className="tile__content__item hos">
        <strong>{formatUnit('duration', selectedData?.onDutyDrivingRemaining, 'h:mm')}</strong>
        <span>Driving Remaining</span>
      </div>
      <div className="tile__content__item hos">
        <strong>{formatUnit('duration', selectedData?.onDutyRemaining, 'h:mm')}</strong>
        <span>On Duty Remaining</span>
      </div>
    </div>
    <div className="tile__footer">
      <a
        href={`#/reports/driverLogSummary/default/driverLogs/${
          selectedData?.driver?.key
        }-${dateObject().format('YYYYMMDD')}/default`}
        target="_blank"
        rel="noreferrer"
      >
        View Details
      </a>
      {selectedData?.activeMalfunctions?.length > 0 && <Icon icon="report" />}
    </div>
  </div>
);

HosSummary.propTypes = {
  selectedData: PropTypes.any,
  formatUnit: PropTypes.func,
};
