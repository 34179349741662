import { connect } from 'react-redux';

import { selectSystemOus } from '../../../../../data/system/systemSelectors';
import { camera } from '../../../../../data/administration/devices';
import { vehicle } from '../../../../../data/administration/resources';
import { trips } from '../../../../../data/reports';

import { CameraRecordings } from './recordings';

export const CameraRecordingsContainer = connect(
  (state, { vehicleId }) => ({
    vehicle: vehicle.selectors.selectVehicle(state, vehicleId),
    cameraRecords: camera.selectors.selectCameraRecords(state),
    cameraTrips: camera.selectors.selectCameraTrips(state),
    cameraBreadcrumbs: camera.selectors.selectCameraBreadcrumbs(state),
    virtualEvents: camera.selectors.selectCameraVirtualEvents(state),
    mediaEvent: camera.selectors.selectCameraVirtualEventsMedia(state),
    ous: selectSystemOus(state),
    trips: trips.selectors.selectTrips(state),
  }),
  {
    fetchCameraRecords: camera.actions.fetchCameraRecords,
    fetchCameraRecordsStreaming: camera.actions.fetchCameraRecordsStreaming,
    fetchCameraVirtualEvents: camera.actions.fetchCameraVirtualEvents,
    fetchVehicle: vehicle.actions.fetchVehicleOptimized,
    fetchMediaEvent: camera.actions.fetchCameraVirtualEventsMediaDownload,
    fetchCameraTrips: camera.actions.fetchCameraTrips,
    fetchCameraBreadcrumbs: camera.actions.fetchCameraBreadcrumbsOptimized,
    postCameraVirtualEvent: camera.actions.postCameraVirtualEvent,
    fetchTrips: trips.actions.fetchTrips,
    fetchCameraWakeUp: camera.actions.fetchCameraWakeUp,
  }
)(CameraRecordings);
