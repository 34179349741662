import { connect } from 'react-redux';
import { driverLogs } from '../../../../data/reports';
import { DriverLogDetailReport } from './DriverLogDetailReport';
import { selectSystemUser, selectIsUserDriver } from '../../../../data/system';

export const DriverLogDetailReportContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    isDriver: selectIsUserDriver(state),
    makeDetailedDriverLog: driverLogs.selectors.makeDetailedDriverLog,
  }),
  {
    submitCreateLogDutyStatusEvent: driverLogs.actions.submitCreateLogDutyStatusEvent,
    submitUpdateLogDutyStatusEvent: driverLogs.actions.submitUpdateLogDutyStatusEvent,
    submitReassignLogDutyStatusEvent: driverLogs.actions.submitReassignLogDutyStatusEvent,
    reviewViolation: driverLogs.actions.reviewViolation,
    addDriverLogComment: driverLogs.actions.addDriverLogComment,
    deleteDriverLogComment: driverLogs.actions.deleteDriverLogComment,
    fetchDriverLog: driverLogs.actions.fetchDriverLog,
    fetchDriverLogViolation: driverLogs.actions.fetchDriverLogViolation,
  }
)(DriverLogDetailReport);
