import keyBy from 'lodash/keyBy';

import { includes } from 'lodash';
import { createDateTime } from './date';

const formatsConfig = {
  date: [
    {
      index: 'MMM D, YYYY HH:mm',
      key: 'MMM d, yyyy HH:mm',
      description: createDateTime().toFormat('MMM d, yyyy HH:mm'),
      export: true,
      derived: {
        timelessDate: 'MMM d, yyyy',
        datelessTime: 'HH:mm',
      },
    },
    {
      index: 'MMM D, YYYY HH:mm:ss',
      key: 'MMM d, yyyy HH:mm:ss',
      description: createDateTime().toFormat('MMM d, yyyy HH:mm:ss'),
      export: true,
      derived: {
        timelessDate: 'MMM d, yyyy',
        datelessTime: 'HH:mm:ss',
      },
    },
    {
      index: 'MMM D, YYYY HH:mm z',
      key: 'MMM d, yyyy HH:mm ZZZZ',
      description: createDateTime().toFormat('MMM d, yyyy HH:mm ZZZZ'),
      export: true,
      derived: {
        timelessDate: 'MMM d, yyyy',
        datelessTime: 'HH:mm ZZZZ',
      },
    },
    {
      index: 'MMM D, YYYY h:mmA',
      key: 'MMM D, yyyy h:mma',
      description: createDateTime().toFormat('MMM d, yyyy h:mma'),
      export: true,
      derived: {
        timelessDate: 'MMM d, yyyy',
        datelessTime: 'h:mma',
      },
    },
    {
      index: 'MMM D, YYYY h:mmA z',
      key: 'MMM d, yyyy h:mma ZZZZ',
      description: createDateTime().toFormat('MMM d, yyyy h:mma ZZZZ'),
      export: true,
      derived: {
        timelessDate: 'MMM d, yyyy',
        datelessTime: 'h:mma ZZZZ',
      },
    },
    {
      index: 'YYYY-MM-DD HH:mm',
      key: 'yyyy-MM-dd HH:mm',
      description: createDateTime().toFormat('yyyy-MM-dd HH:mm'),
      export: true,
      derived: {
        timelessDate: 'yyyy-MM-dd',
        datelessTime: 'HH:mm',
      },
    },
    {
      index: 'YYYY-MM-DD HH:mm z',
      key: 'yyyy-MM-dd HH:mm ZZZZ',
      description: createDateTime().toFormat('yyyy-MM-dd HH:mm ZZZZ'),
      export: true,
      derived: {
        timelessDate: 'yyyy-MM-dd',
        datelessTime: 'HH:mm ZZZZ',
      },
    },
    {
      index: 'YYYY-MM-DDTHH:mm:ssZZ',
      key: 'yyyy-MM-dd HH:mm:ssZZZ',
      description: createDateTime().toFormat('yyyy-MM-dd HH:mm:ssZZZ'),
      export: true,
      derived: {
        timelessDate: 'yyyy-MM-dd',
        datelessTime: 'HH:mm:ssZZZ',
      },
    },
  ],
  duration: [
    { key: 'h', description: 'Hours', suffix: '', unit: 'hours', display: false },
    { key: 'm', description: 'Minutes', suffix: '', unit: 'mins', display: false },
    { key: 'h:mm', description: 'H:MM', unit: 'hours', export: false },
    { key: 'h:mm:ss', description: 'H:MM:SS', unit: 'hours', export: false },
    { key: 'h.000', description: 'H.hhh', unit: 'hours', export: false },
    { key: 'h.0', description: 'H.h', unit: 'hours', export: false },
  ],
  distance: [
    { key: 'km', description: 'km, km/h', suffix: ' km', precision: 1 },
    { key: 'mi', description: 'mi, mph', suffix: ' mi', precision: 1 },
  ],
  distanceEld: [
    { key: 'km', description: 'km, km/h', suffix: ' km', precision: 0 },
    { key: 'mi', description: 'mi, mph', suffix: ' mi', precision: 0 },
  ],
  speed: [
    // speed keys match distance, i.e. there are no speedFormat or speedExportFormat controls
    { key: 'km', description: 'km/h', suffix: ' km/h', precision: 0 },
    { key: 'mi', description: 'mph', suffix: ' mph', precision: 0 },
  ],
  pressure: [
    // defaulting to psi for now until we have a more detailed unit system
    { key: 'psi', description: 'PSI', suffix: ' PSI', precision: 0 },
    { key: 'kpa', description: 'kPa', suffix: ' kPa', precision: 0 },
  ],
  volume: [
    { key: 'l', description: 'L', suffix: ' L', precision: 1 },
    { key: 'gal_i', description: 'Gal (Imperial)', suffix: ' gal', precision: 1 },
    { key: 'gal_us', description: 'Gal (US)', suffix: ' gal', precision: 1 },
  ],
  weight: [
    { key: 'kg', description: 'kg', suffix: ' kg', precision: 1 },
    { key: 'lb', description: 'lb', suffix: ' lb', precision: 1 },
    { key: 'l', description: 'L', suffix: ' L', precision: 0 },
  ],
  mileage: [
    { key: 'km/l', description: 'km/L', suffix: ' km/L', precision: 2 },
    { key: 'l/100km', description: 'L/100km', suffix: ' L/100km', precision: 0 },
    { key: 'mpg_i', description: 'MPG (Imperial)', suffix: ' MPG', precision: 0 },
    { key: 'mpg_us', description: 'MPG (US)', suffix: ' MPG', precision: 0 },
  ],
};

const formatWithDefaults = (format) => ({
  unit: (format.suffix || '').trim(),
  display: true,
  export: true,
  ...format,
});

const formatDefinitions = Object.entries(formatsConfig).reduce(
  (acc, [formatType, formats]) => ({
    ...acc,
    [formatType]: {
      keyed: keyBy(formats.map(formatWithDefaults), 'key'),
      ordered: formats.map(formatWithDefaults),
    },
  }),
  {}
);

export const getFormatDefinitions = (formatType) => formatDefinitions[formatType].ordered;

export const getFormatDefinition = (target, findCriteria) =>
  getFormatDefinitions(target).find(
    ({ index, key }) => index === findCriteria || key === findCriteria
  );

export const getFormat = (criteria, value) => {
  if (!criteria || !value) {
    return 'default';
  }
  const register = getFormatDefinitions(criteria).find(
    (item) => item.index === value || item.key === value
  );

  if (!register) {
    return 'default';
  }

  return register.key;
};

export const getFormatLabel = (criteria) => {
  if (!criteria) return '';
  if (includes(['dateFormat', 'dateExportFormat'], criteria)) return 'Time';
  if (includes(['distanceFormat', 'distanceExportFormat'], criteria)) return 'Distance, Speed';
  if (includes(['durationFormat', 'durationExportFormat'], criteria)) return 'Duration';
  if (includes(['mileageFormat', 'mileageExportFormat'], criteria)) return 'Mileage';
  if (includes(['volumeFormat', 'volumeExportFormat'], criteria)) return 'Volume';
  if (includes(['weightFormat', 'weightExportFormat'], criteria)) return 'Weight';
  return 'N/A';
};

export const convertObjectStringsToBoolean = (obj) => {
  const clone = { ...obj };
  for (const key in clone) {
    if (typeof clone[key] === 'string') {
      if (clone[key].toLowerCase() === 'true') {
        clone[key] = true;
      } else if (clone[key].toLowerCase() === 'false') {
        clone[key] = false;
      }
    } else if (typeof clone[key] === 'object') {
      convertObjectStringsToBoolean(clone[key]);
    }
  }
  return clone;
};

export const convertStringToBoolean = (string) => {
  if (typeof string === 'string') {
    if (string.toLowerCase() === 'true') {
      return true;
    }
    if (string.toLowerCase() === 'false') {
      return false;
    }
  }
  return string;
};
