import { connect } from 'react-redux';

import { DriverLogsSummaryReport } from './DriverLogsSummaryReport';
import { driverLogs } from '../../../../data/reports';
import { selectSystemUser, selectSystemOus, selectSystemActiveOu } from '../../../../data/system';

export const DriverLogsSummaryReportContainer = connect(
  (state) => ({
    activeOu: selectSystemActiveOu(state),
    ous: selectSystemOus(state),
    driverLogs: driverLogs.selectors.selectDriverLogs(state),
    user: selectSystemUser(state),
    limits: { name: 'driverlogs', forAllOus: 14, forOu: 59 },
  }),
  {
    fetchDriverLogs: driverLogs.actions.fetchDriverLogs,
    fetchMyDriverLogs: driverLogs.actions.fetchMyDriverLogs,
    fetchDriverLogsReviewDetails: driverLogs.actions.fetchDriverLogsReviewDetails,
  }
)(DriverLogsSummaryReport);
