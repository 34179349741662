import produce from 'immer';

import {
  actionTypeFailure,
  actionTypeProgressOrSuccess,
  actionTypeRequest,
  deepMergeEntities,
  removeEntity,
  shallowMergeEntities,
} from '../apiReducerSupport';

export const ACTION_TYPE = {
  deleteFleetTrackingBookmark: 'fleetTracker/deleteFleetTrackingBookmark',
  fetchFleetTrackedData: 'fleetTracker/fetchFleetTrackedData',
  fetchFleetTrackingBookmark: 'fleetTracker/fetchFleetTrackingBookmark',
  fetchFleetTrackingShiftDetails: 'fleetTracker/fetchFleetTrackingShiftDetails',
  postFleetTrackingBookmark: 'fleetTracker/postFleetTrackingBookmark',
  setFleetTrackFilters: 'fleetTracker/setFleetTrackFilters',
  updateFleetTrackingBookmark: 'fleetTracker/updateFleetTrackingBookmark',
};

const initialState = () => ({
  data: {
    driverActivity: [],
    vehicleLocations: [],
  },
  currentShiftDetail: {
    data: [],
    fetches: [],
  },
  bookmarks: [],
  vehicleTypeFilters: [],
});

export const fleetTrackReducer = (state = initialState(), { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchFleetTrackingBookmark])) {
      draft.bookmarks = payload;
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchFleetTrackedData])) {
      const driverActivity = deepMergeEntities(
        state.data.driverActivity,
        payload?.driverActivity,
        'key'
      );
      const vehicleLocations = deepMergeEntities(
        state.data.vehicleLocations,
        payload?.vehicleLocations,
        'vehicle'
      );

      draft.data = { driverActivity, vehicleLocations };
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchFleetTrackingShiftDetails])) {
      const currentShiftDetails = deepMergeEntities(
        state.currentShiftDetail.data,
        payload,
        'driver'
      );

      draft.currentShiftDetail.data = currentShiftDetails;
    }

    if (actionTypeRequest(type, [ACTION_TYPE.fetchFleetTrackingShiftDetails])) {
      const { id, requestData } = request;
      draft.currentShiftDetail.fetches = shallowMergeEntities(state.currentShiftDetail.fetches, {
        id,
        requestData,
      });
    }

    if (actionTypeFailure(type, [ACTION_TYPE.fetchFleetTrackingShiftDetails])) {
      draft.currentShiftDetail.fetches = removeEntity(state.currentShiftDetail.fetches, request);
    }

    if (type === ACTION_TYPE.setFleetTrackFilters) {
      draft.vehicleTypeFilters = payload;
    }
  });
