// NotificationTypeGeozone.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ToggleGrid from '../shared/ToggleGrid';
import ToggleRow from '../shared/ToggleRow';
import { RenderVehicles } from '../../NotificationRenders/RenderVehicles';
import { RenderGeozones } from '../../NotificationRenders/RenderGeozones';

const NotificationTypeGeozone = ({
  formControls,
  form,
  mode,
  vehicleOptions,
  geozoneOptions,
  Control,
}) => {
  // Ensure at least one toggle is selected
  const toggleError = useMemo(() => {
    if (!formControls.entered && !formControls.exited) {
      return 'At least one of entered or exited must be selected.';
    }
    return null;
  }, [formControls.entered, formControls.exited]);

  return (
    <div className="notification-criteria-one-column">
      <h3 className="section-title">Geozone Notification</h3>
      <p className="section-description">
        Notify based on entry or exit into specified geozones.
      </p>

      <div className="notification-criteria-config">
        <ToggleGrid>
          <ToggleRow
            label="Geozone Entry"
            name="entered"
            description="Triggered when a geozone is entered."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={toggleError}
          />
          <ToggleRow
            label="Geozone Exit"
            name="exited"
            description="Triggered when a geozone is exited."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={toggleError}
          />
        </ToggleGrid>
      </div>

      <div className="notification-criteria-fields">
        <RenderVehicles
          formControls={formControls}
          form={form}
          mode={mode}
          vehicleOptions={vehicleOptions}
          Control={Control}
        />
        <RenderGeozones
          formControls={formControls}
          form={form}
          mode={mode}
          geozoneOptions={geozoneOptions}
          Control={Control}
        />
      </div>
    </div>
  );
};

NotificationTypeGeozone.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  geozoneOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeGeozone;
