import { filter, isObject, pick, isNil, isEmpty, head } from 'lodash';
import { ACTION_TYPE } from './systemReducer';
import { makeRestApiAction, makeOneTimeOptimizedAction } from '../apiActionSupport';

import {
  selectRegulationsFetchRequests,
  selectCountriesFetchRequests,
  selectEnvironmentsFetchRequests,
  selectVideoConfigsFetchRequests,
} from './systemSelectors';
import { VEHICLE_CONFIG_PROPS, OU_EXPECTED_PAYLOAD } from './constants';

/*
PRIVATE METHODS AND HELPERS --------------------------------------------------------------------------------------------------
*/
const appendOrganizationKeyProperties = ({ parent, carrier, principal, ...rest }) => ({
  ...rest,
  parentKey: isObject(parent) ? parent.key : parent,
  carrierKey: carrier,
  principalKey: principal,
});

/*
PRIVATE FETCH METHODS --------------------------------------------------------------------------------------------------------
*/
const fetchCountries = makeRestApiAction({
  service: 'countries',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCountries,
  notificationsItemDescriptor: 'countries',
});

const fetchEnvironments = makeRestApiAction({
  service: 'environments',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchEnvironments,
  notificationsItemDescriptor: 'environments',
});

const fetchRegulations = makeRestApiAction({
  service: 'regulations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRegulations,
  notificationsItemDescriptor: 'regulations',
});

const fetchVideoConfigs = makeRestApiAction({
  service: 'videoConfigs',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVideoConfigs,
  notificationsItemDescriptor: 'video configs',
});

/*
PUBLIC METHODS: FETCHES ------------------------------------------------------------------------------------------------------
*/
export const fetchCountriesOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectCountriesFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchCountries,
  baseActionType: ACTION_TYPE.fetchCountries,
});

export const fetchCurrentOrganizationUnits = makeRestApiAction({
  service: 'currentOrganizationalUnits',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCurrentOrganizationUnits,
  transformOutput: (responseData) => responseData.map(appendOrganizationKeyProperties),
  notificationsItemDescriptor: 'organizational units',
});

export const fetchCurrentOrganizationUnit = makeRestApiAction({
  service: 'organizationalUnits',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCurrentOrganizationUnit,
  getId: (key) => key,
  transformInput: () => ({
    fetch: `parent,children,members.role,members.user`,
  }),
  notificationsItemDescriptor: 'organizational unit',
});

export const fetchCurrentPermissions = makeRestApiAction({
  service: 'currentPermissions',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCurrentPermissions,
  notificationsItemDescriptor: 'permissions',
});

export const fetchCurrentTenant = makeRestApiAction({
  service: 'currentTenant',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCurrentTenant,
  transformOutput: (responseData) => head(responseData),
  notificationsItemDescriptor: 'tenant',
});

export const fetchCurrentUser = makeRestApiAction({
  service: 'currentUser',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchCurrentUser,
  transformOutput: (responseData) => head(responseData),
  notificationsItemDescriptor: 'user',
});

export const fetchEnvironmentsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectEnvironmentsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchEnvironments,
  baseActionType: ACTION_TYPE.fetchEnvironments,
});

export const fetchRegulationsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectRegulationsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchRegulations,
  baseActionType: ACTION_TYPE.fetchRegulations,
});

export const fetchSearchLimits = makeRestApiAction({
  service: 'searchLimits',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchSearchLimits,
  notificationsItemDescriptor: 'search limits',
});

export const fetchTenantVehicleConfig = makeRestApiAction({
  service: 'organizationalUnits',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTenantVehicleConfig,
  getId: (key) => key,
  transformOutput: (responseData) => responseData,
  notificationsItemDescriptor: 'tenant vehicle config',
});

export const fetchVideoConfigsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectVideoConfigsFetchRequests,
  filterFetches: (fetches, id) => filter(fetches, { actionParam: id }),
  fetchAction: fetchVideoConfigs,
  baseActionType: ACTION_TYPE.fetchVideoConfigs,
});

/*
PUBLIC METHODS: UPDATES ------------------------------------------------------------------------------------------------------
*/
export const updateCurrentOrganizationUnit = makeRestApiAction({
  service: 'organizationalUnits',
  method: 'put',
  baseActionType: ACTION_TYPE.updateCurrentOrganizationUnit,
  getId: ({ key }) => key,
  notificationsItemDescriptor: 'organizational unit settings',
});

export const updateCurrentUser = makeRestApiAction({
  service: 'currentUser',
  method: 'post',
  baseActionType: ACTION_TYPE.updateCurrentUser,
  transformInput: ({ email, firstName, lastName, unitSystem, signature, password }) => ({
    email, // email is required into post request as BE team request
    firstName,
    lastName,
    unitSystem,
    ...(!isNil(signature) && { signature }),
    ...(!isNil(password) && !isEmpty(password) && { password }),
  }),
  transformOutput: (responseData, { password, ...rest }) => rest,
  throwApiErrors: true,
});

export const updateTenantVehicleConfig = makeRestApiAction({
  service: 'organizationalUnits',
  method: 'put',
  baseActionType: ACTION_TYPE.updateTenantVehicleConfig,
  getId: ({ key }) => key,
  transformInput: (data) => pick(data, OU_EXPECTED_PAYLOAD),
  transformOutput: (responseData) => pick(responseData, VEHICLE_CONFIG_PROPS),
  notificationsItemDescriptor: 'tenant vehicle config',
});

/*
PUBLIC METHODS: SETS ---------------------------------------------------------------------------------------------------------
*/
export const setFilteredOuMode = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.setFilteredOuMode, payload });
};

export const setFilteredOus = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.setFilteredOus, payload });
};

export const setMenu = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.setMenu, payload });
};

export const setSystem = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.setSystem, payload });
};

export const setActiveOu = (payload) => async (dispatch) => {
  dispatch({ type: ACTION_TYPE.setActiveOu, payload });
};

/*
PUBLIC METHODS: AUTHENTICATION -----------------------------------------------------------------------------------------------
*/

export const signIn = makeRestApiAction({
  service: 'signIn',
  method: 'post',
  baseActionType: ACTION_TYPE.signIn,
  getId: ({ provider }) => `?provider=${provider}`,
  transformInput: ({ username, password, code, redirectUri }) => ({
    ...(username && { username }),
    ...(password && { password }),
    ...(code && { code }),
    ...(redirectUri && { redirect_uri: redirectUri }),
  }),
  transformOutput: (responseData) => {
    window.localStorage.setItem('oauth', JSON.stringify(responseData));
    window.location.reload(); // this reload is necessary to activate route.authenticate(user);
  },
  notifications: {
    failure: () => 'Failed to authenticate using these credentials!',
  },
  throwApiErrors: true,
});
