import { connect } from 'react-redux';

import { inspections } from '../../../../data/reports';
import { InspectionLocationReport } from './InspectionLocationReport';

export const InspectionLocationReportContainer = connect(
  (state, { inspectionId }) => ({
    inspection: inspections.selectors.selectInspectionById(state, inspectionId),
  }),
  {
    fetchInspection: inspections.actions.fetchInspection,
  }
)(InspectionLocationReport);
