import React from 'react';
import PropTypes from 'prop-types';
import { RenderDestinations } from './RenderDestinations';

export function NotificationDestinations({
  form,
  mode,
  userOptions,
  destinations,
  setDestinations,
  Control,
}) {
  return (
    <RenderDestinations
      form={form}
      mode={mode}
      destinations={destinations}
      setDestinations={setDestinations}
      userOptions={userOptions}
      Control={Control}
    />
  );
}

NotificationDestinations.propTypes = {
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  userOptions: PropTypes.array.isRequired,
  destinations: PropTypes.shape({
    emails: PropTypes.arrayOf(PropTypes.string),
    phoneNumbers: PropTypes.arrayOf(PropTypes.string),
    userKeys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setDestinations: PropTypes.func.isRequired,
  Control: PropTypes.elementType.isRequired,
};
