import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToggleGrid.module.scss';

function ToggleGrid({ children }) {
  return (
    <div className={styles['toggle-grid']}>
      <div className={styles['toggle-grid-header']}>
        <div className={styles['toggle-grid-cell']}>Type</div>
        <div className={styles['toggle-grid-cell']}>Toggle</div>
        <div className={styles['toggle-grid-cell']}>Description</div>
      </div>
      {children}
    </div>
  );
}

ToggleGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToggleGrid;
