import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, Chip } from 'stti-react-common';
import { sortBy, orderBy, isNil, flatten, uniqBy } from 'lodash';

import {
  ReportControl,
  ControlGroupSummary,
  SectionPanel,
  SectionPanelMenu,
  SelectFilterInput,
} from '../../../commons/ReportsCommon';
import {
  filterRowsByIntersection,
  filterRowsByPredicate,
} from '../../../../helpers/reports/standardFilters';

import { driverLogs } from '../../../../data/reports';

const hasActivityOptions = [
  {
    label: 'With activity',
    value: true,
  },
  {
    label: 'Without activity',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

const violationStatusOptions = [
  {
    label: 'Unreviewed violations',
    value: driverLogs.constants.UN_REVIEWED_VIOLATION_STATUS,
  },
  {
    label: 'Reviewed violations',
    value: driverLogs.constants.REVIEWED_VIOLATION_STATUS,
  },
  {
    label: 'No violations',
    value: driverLogs.constants.NO_VIOLATION_STATUS,
  },
  {
    label: 'With any violations',
    value: 'with',
  },
  {
    label: 'Without any violations',
    value: 'without',
  },
  {
    label: undefined,
    value: null,
  },
];

const enabledOptions = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

const diagnosticOptions = [
  {
    label: 'With Diagnostic',
    value: true,
  },
  {
    label: 'Without Diagnostic',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

const malfunctionOptions = [
  {
    label: 'With Malfunction',
    value: true,
  },
  {
    label: 'Without Malfunction',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

const signedOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
  {
    label: undefined,
    value: null,
  },
];

const isInViolation = (violationStatus) =>
  [
    driverLogs.constants.UN_REVIEWED_VIOLATION_STATUS,
    driverLogs.constants.REVIEWED_VIOLATION_STATUS,
    driverLogs.constants.UNKNOWN_VIOLATION_STATUS,
  ].includes(violationStatus);

export const FiltersPanel = ({ rows }) => {
  const drivers = uniqBy(orderBy(rows, 'startedAt', 'desc'), 'driver.key');
  const vehicles = sortBy(uniqBy(flatten(rows.map((row) => row.vehicles)), 'key'), 'name');
  const ous = sortBy(uniqBy(flatten(rows.map((row) => row.ous)), 'key'), 'name');

  const getOptions = useMemo(
    () => ({
      driverName: () => drivers.map(({ driver: { key, name } }) => ({ label: name, value: key })),
      vehicleNames: () => vehicles.map(({ key, name }) => ({ label: name, value: key })),
      ouNames: () => ous.map(({ key, name }) => ({ label: name, value: key })),
    }),
    [rows]
  );

  return (
    <SectionPanel
      name="filtersPanel"
      title="Log Filters"
      renderSummaries={() => <ControlGroupSummary group="filters" />}
      summaryEnd={<SectionPanelMenu group="filters" />}
    >
      <SelectFilterInput
        name="driverName"
        field="driver.key"
        displayValue="driver.name"
        group="filters"
        filterGroup="filters"
        label="Drivers"
        summaryPrefix="Driver: "
        itemPlural="drivers"
        autoGridArea
        getOptions={getOptions.driverName}
      />
      <SelectFilterInput
        name="organizationNames"
        field="ous"
        fieldSubMap="key" // property used for complex objects
        displayValue="name"
        group="filters"
        filterGroup="filters"
        label="Organizations"
        summaryPrefix="Organization: "
        itemPlural="organizations"
        autoGridArea
        getOptions={getOptions.ouNames}
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) => row.ous.find(({ key }) => value.includes(key)),
          type: filterRowsByPredicate,
          disabled: ({ value }) => isNil(value) || value.length === 0,
        }}
      />
      <SelectFilterInput
        name="vehicleNames"
        field="vehicles"
        fieldSubMap="key" // property used for complex objects
        displayValue="name"
        group="filters"
        filterGroup="filters"
        label="Vehicles"
        summaryPrefix="Vehicle: "
        itemPlural="vehicles"
        autoGridArea
        getOptions={getOptions.vehicleNames}
        filterConfig={{
          group: 'filters',
          test: ({ row, value }) => row.vehicles.find(({ key }) => value.includes(key)),
          type: filterRowsByPredicate,
          disabled: ({ value }) => isNil(value) || value.length === 0,
        }}
      />
      <ReportControl
        name="hasActivity"
        group="filters"
        label="Activity"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={hasActivityOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return (
            <Chip {...renderProps} label={SelectInput.labelForValue(hasActivityOptions, value)} />
          );
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(hasActivityOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'hasActivity',
        }}
      />
      <ReportControl
        name="violationStatus"
        group="filters"
        label="Violations"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={violationStatusOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return (
            <Chip
              {...renderProps}
              label={SelectInput.labelForValue(violationStatusOptions, value)}
            />
          );
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(violationStatusOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByPredicate,
          test: ({ value, row: { violationStatus } }) => {
            switch (value) {
              case driverLogs.constants.UN_REVIEWED_VIOLATION_STATUS:
              case driverLogs.constants.REVIEWED_VIOLATION_STATUS:
              case driverLogs.constants.NO_VIOLATION_STATUS:
                return violationStatus === value;
              case 'with':
                return isInViolation(violationStatus);
              case 'without':
                return !isInViolation(violationStatus);
              default:
                return true;
            }
          },
          disabled: ({ value }) => !value,
        }}
      />
      <ReportControl
        name="enabled"
        group="filters"
        label="Driver Enabled"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={enabledOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return <Chip {...renderProps} label={SelectInput.labelForValue(enabledOptions, value)} />;
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(enabledOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'driver.enabled',
        }}
      />
      <ReportControl
        name="diagnosticIndication"
        group="filters"
        label="Diagnostic"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={diagnosticOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return (
            <Chip {...renderProps} label={SelectInput.labelForValue(diagnosticOptions, value)} />
          );
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(diagnosticOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'diagnosticIndication',
        }}
      />
      <ReportControl
        name="malfunctionIndication"
        group="filters"
        label="Malfunction"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={malfunctionOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return (
            <Chip {...renderProps} label={SelectInput.labelForValue(malfunctionOptions, value)} />
          );
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(malfunctionOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'malfunctionIndication',
        }}
      />
      <ReportControl
        name="isSigned"
        group="filters"
        label="Signed?"
        canDeleteSummary
        render={(renderProps) => <SelectInput {...renderProps} options={signedOptions} />}
        renderSummary={(renderProps, { value }) => {
          if (isNil(value)) return null;
          return <Chip {...renderProps} label={SelectInput.labelForValue(signedOptions, value)} />;
        }}
        printConfig={({ value }) =>
          !isNil(value) && SelectInput.labelForValue(signedOptions, value)
        }
        filterConfig={{
          group: 'filters',
          type: filterRowsByIntersection,
          field: 'isSigned',
        }}
      />
    </SectionPanel>
  );
};

FiltersPanel.propTypes = {
  rows: PropTypes.array.isRequired,
};
