import { cloneDeep } from 'lodash';

import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

import { getLegSum, getLegConsumption } from './utils';

const transformForStore = ({ key, drivers, ou, ...rest }, { timeZone }) => {
  const document = cloneDeep({
    timeZone,
    ouKey: ou.key,
    ouName: ou.name,
    driver: drivers[0],
    ...rest,
  });
  if (document.loaded) {
    document.loaded.consumption = getLegConsumption(document.loaded);
    document.loaded.idleTime = parseFloat(
      ((document.loaded.idlingDuration / document.loaded.engineDuration) * 100).toFixed(2)
    );
    document.loaded.usedFuel = document.loaded.consumedFuel + document.loaded.idlingFuel;
    document.loaded.idleFuel = parseFloat(
      ((document.loaded.idlingFuel / document.loaded.usedFuel) * 100).toFixed(2)
    );
  }
  if (document.empty) {
    document.empty.consumption = getLegConsumption(document.empty);
    document.empty.idleTime = parseFloat(
      ((document.empty.idlingDuration / document.empty.engineDuration) * 100).toFixed(2)
    );
    document.empty.usedFuel = document.empty.consumedFuel + document.empty.idlingFuel;
    document.empty.idleFuel = parseFloat(
      ((document.empty.idlingFuel / document.empty.usedFuel) * 100).toFixed(2)
    );
  }

  document.overall = {
    idlingFuel: getLegSum(document, 'idlingFuel'),
    idlingDuration: getLegSum(document, 'idlingDuration'),
    consumedFuel: getLegSum(document, 'consumedFuel'),
    distance: getLegSum(document, 'distance'),
    idleTime: parseFloat(getLegSum(document, 'idleTime').toFixed(2)),
    usedFuel: getLegSum(document, 'usedFuel'),
    idleFuel: parseFloat(getLegSum(document, 'idleFuel').toFixed(2)),
    engineDuration: getLegSum(document, 'engineDuration'),
  };
  document.overall.consumption = getLegConsumption(document.overall);
  return document;
};

// fetchFuelConsumptions({ startDate, endDate, ouKey })
export const fetchFuelConsumptions = makeRestApiAction({
  service: 'fuelConsumptions',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFuelConsumptions,
  transformInput: ({ startDate, endDate, ouKey: ou, timeZone }) => ({
    startDate: startDateWithTimeZoneToIso(startDate, timeZone),
    endDate: endDateWithTimeZoneToIso(endDate, timeZone),
    ou,
    tripType: 'Standard',
  }),
  transformOutput: (responseData, actionParams) =>
    responseData.map((row) => transformForStore(row, actionParams)),
  notificationsItemDescriptor: 'fuelConsumptions',
  enableProgress: true,
});

export const fetchFuelConsumption = makeRestApiAction({
  service: 'fuelConsumptions',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchFuelConsumption,
  transformOutput: transformForStore,
});
