import { connect } from 'react-redux';

import { reportViews } from '../../../../data/reports';

import { ReportLoader } from './ReportLoader';

export const ReportLoaderContainer = connect(
  (state) => ({
    reportViews: reportViews.selectors.selectReportViews(state),
  }),
  {
    fetchReportView: reportViews.actions.fetchReportView,
    fetchLocalReportView: reportViews.actions.fetchLocalReportView,
    fetchDefaultReportViewsByReportType: reportViews.actions.fetchDefaultReportViewsByReportType,
    createTemporaryDefaultReportView: reportViews.actions.createTemporaryDefaultReportView,
  }
)(ReportLoader);
