import React from 'react';
import PropTypes from 'prop-types';

import { customPropTypes } from '../../../../helpers/customPropTypes';
import { OusSelector } from './OusSelector';
import { DateRangeModeInput } from './DateRangeModeInput';
import { StartDateInput } from './StartDateInput';
import { EndDateInput } from './EndDateInput';
import { YearSelectorInput } from './YearSelectorInput';
import { ControlGroupSummary } from '../ControlGroupSummary';
import { SectionPanel } from '../SectionPanel';
import { SectionPanelMenu } from '../SectionPanelMenu';

import './QueryPanel.scss';

const yearConditions = [
  '1q',
  '2q',
  '3q',
  '4q',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const QueryPanel = ({
  group,
  queryFor,
  defaultDateRangeFor,
  canSelectDateRangeFor,
  disableQueryPanel,
  children,
  hideAllOrganizations,
  onlyAllOrganizations,
  onlySelectedOrganization,
  minAllowedDate,
  maxRangeInDays,
  disableFuture,
  disablePast,
  disableOneYearMaxRange,
  enableOnlyStartDateDays,
  enableOnlyEndDateLastDays,
  filterDateRange,
  disableStartDate,
  disableEndDate,
  disableDateRange,
  selectedDateRange,
  disableNonAccessibleMessage,
  isUtc,
}) => {
  const shouldShowYear = selectedDateRange && yearConditions.includes(selectedDateRange);

  return (
    <SectionPanel
      name="queryPanel"
      title="Query"
      renderSummaries={() => <ControlGroupSummary group={group} />}
      summaryEnd={<SectionPanelMenu group={group} />}
      disabled={disableQueryPanel}
    >
      <OusSelector
        group={group}
        hideAllOrganizations={hideAllOrganizations}
        onlyAllOrganizations={onlyAllOrganizations}
        onlySelectedOrganization={onlySelectedOrganization}
        disableNonAccessibleMessage={disableNonAccessibleMessage}
      />
      <DateRangeModeInput
        group={group}
        queryFor={queryFor}
        defaultDateRangeFor={defaultDateRangeFor}
        canSelectDateRangeFor={canSelectDateRangeFor}
        filterDateRange={filterDateRange}
        disableDateRange={disableDateRange}
        isUtc={isUtc}
      />
      {shouldShowYear && <YearSelectorInput />}
      <StartDateInput
        group={group}
        min={minAllowedDate}
        disableFuture={disableFuture}
        disablePast={disablePast}
        shouldDisableDate={enableOnlyStartDateDays}
        onMonthChange={(date) => date}
        showOnlyFirstAndLastDayInMonth
        disableStartDate={disableStartDate || shouldShowYear}
      />
      <EndDateInput
        group={group}
        maxRangeInDays={maxRangeInDays}
        min={minAllowedDate}
        disableOneYearMaxRange={disableOneYearMaxRange}
        disableFuture={disableFuture}
        disablePast={disablePast}
        shouldDisableDate={enableOnlyEndDateLastDays}
        onMonthChange={(date) => date}
        showOnlyFirstAndLastDayInMonth
        disableEndDate={disableEndDate || shouldShowYear}
      />
      {children}
    </SectionPanel>
  );
};

QueryPanel.propTypes = {
  group: PropTypes.string.isRequired,
  queryFor: PropTypes.string.isRequired,
  defaultDateRangeFor: PropTypes.oneOf(['startedAt', 'endedAt']),
  canSelectDateRangeFor: PropTypes.bool,
  disableQueryPanel: PropTypes.bool,
  children: PropTypes.node,
  hideAllOrganizations: PropTypes.bool,
  onlyAllOrganizations: PropTypes.bool,
  onlySelectedOrganization: PropTypes.bool,
  minAllowedDate: PropTypes.oneOfType([customPropTypes.date, PropTypes.oneOf(['last12Months'])]),
  maxRangeInDays: PropTypes.number,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disableOneYearMaxRange: PropTypes.bool,
  enableOnlyStartDateDays: PropTypes.oneOf(['all', 'firstDay']),
  enableOnlyEndDateLastDays: PropTypes.oneOf(['all', 'lastDay']),
  filterDateRange: PropTypes.array,
  disableStartDate: PropTypes.bool,
  disableEndDate: PropTypes.bool,
  disableDateRange: PropTypes.bool,
  selectedDateRange: PropTypes.string,
  disableNonAccessibleMessage: PropTypes.bool,
  isUtc: PropTypes.bool,
};

QueryPanel.defaultProps = {
  defaultDateRangeFor: 'endedAt',
  disableQueryPanel: false,
  minAllowedDate: 'last12Months',
  disableFuture: false,
  disablePast: false,
  disableOneYearMaxRange: false,
  enableOnlyStartDateDays: 'all',
  enableOnlyEndDateLastDays: 'all',
  filterDateRange: [],
  disableNonAccessibleMessage: false,
  isUtc: false,
};
