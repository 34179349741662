import { connect } from 'react-redux';

import { ExceptionsSummaryReport } from './ExceptionsSummaryReport';
import { reportViews, exceptions, trips } from '../../../../data/reports';
import { selectSystemTenant, selectSearchLimits } from '../../../../data/system';

export const ExceptionsSummaryReportContainer = connect(
  (state) => ({
    exceptions: exceptions.selectors.selectUnitedExceptions(state),
    tenant: selectSystemTenant(state),
    limits: selectSearchLimits(state, 'exceptions'),
  }),
  {
    fetchAnnotations: exceptions.actions.fetchAnnotations,
    fetchExceptions: exceptions.actions.fetchExceptions,
    fetchTrips: trips.actions.fetchTrips,
    createLocalReportView: reportViews.actions.createLocalReportView,
  }
)(ExceptionsSummaryReport);
