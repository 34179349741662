import React, { useState, useRef } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Card, Button, DutyStatusChart, useBooleanState } from 'stti-react-common';
import { isEmpty } from 'lodash';

import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { HidePrint } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';
import { EditLogDutyStatusEventDialog } from '../Dialogs/EditLogDutyStatusEventDialog';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { getChartEndDate } from '../../../../../helpers/reports/driverLogsUtils';
import { driverEventsColumnDefs } from './columnDefs';
import { ReassignLogDutyStatusEventDialog } from '../Dialogs/ReassignLogDutyStatusEventDialog';
import { CreateLogDutyStatusEventDialog } from '../Dialogs/CreateLogDutyStatusEventDialog';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;
const { StatusLine } = DutyStatusChart;

const getDisplayWarningIcon = (event) => {
  if (!event.hasChangeRequested) {
    return 'hidden';
  }

  return 'visible';
};

export const EventsCard = ({
  log,
  submitCreateLogDutyStatusEvent,
  submitUpdateLogDutyStatusEvent,
  submitReassignLogDutyStatusEvent,
  user,
  hidePrint,
}) => {
  const { formatUnit } = useFormats();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useBooleanState();
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBooleanState();
  const [isReassignDialogOpen, openReassignDialog, closeReassignDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState();
  const [dataTable, setDataTable] = useState(null);
  const changesViewRef = useRef(null);

  const {
    canManageLogDutyStatuses,
    driver,
    timeZone,
    logDate,
    movementIntervals,
    driverEvents,
    offDutySleeperBerthTotal,
    offDutyNotSleeperBerthTotal,
    onDutyDrivingTotal,
    onDutyNotDrivingTotal,
    timeZoneStatus,
  } = log || {};

  const dutyIntervalsDataGridController = useDataGridController({
    columnDefs: driverEventsColumnDefs,
    gridOptions,
  });

  const dutyIntervalsDataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <div className="DriverLogDetailReport__Actions">
        <Button
          onClick={() => {
            changesViewRef.current.scrollIntoView({ behavior: 'smooth' });
          }}
          tooltip="Requested Changes"
          icon="warning"
          style={{
            color: '#FFAA00',
            visibility: getDisplayWarningIcon(data),
          }}
        />
        <Button
          onClick={() => {
            openEditDialog();
            setTargetRow(data);
          }}
          disabled={!data.canEdit && !canManageLogDutyStatuses}
          tooltip="Edit"
          icon="edit"
        />
        <Button
          onClick={() => {
            openReassignDialog();
            setTargetRow(data);
          }}
          tooltip="Reassign"
          disabled={!data.canReassign && !canManageLogDutyStatuses}
          icon="assignment_return"
        />
      </div>
    ),
  });

  const chartTotals =
    offDutyNotSleeperBerthTotal +
    offDutySleeperBerthTotal +
    onDutyDrivingTotal +
    onDutyNotDrivingTotal;

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__EventsCard" fullWidth>
        {logDate && timeZone && movementIntervals && (
          <DutyStatusChart
            startDate={moment.tz(logDate, timeZone).toISOString()}
            endDate={getChartEndDate(logDate, timeZone).toISOString()}
            intervals={movementIntervals}
            statusTotals={{
              offDuty: formatUnit('duration', offDutyNotSleeperBerthTotal),
              sleeper: formatUnit('duration', offDutySleeperBerthTotal),
              driving: formatUnit('duration', onDutyDrivingTotal),
              onDuty: formatUnit('duration', onDutyNotDrivingTotal),
              total: formatUnit('duration', chartTotals),
            }}
          >
            <StatusLine intervals={movementIntervals} timeZoneStatus={timeZoneStatus} />
          </DutyStatusChart>
        )}
        <div className="DriverLogDetailReport__EventsCard__eventsHeader">
          <h4>Driver Record Events</h4>
          {canManageLogDutyStatuses && (
            <Button icon="add" label="New" variant="contained" onClick={openCreateDialog} />
          )}
        </div>
        {driverEvents && (
          <ControlledDataTable
            className="DriverLogDetailReport__EventsCard__eventsTable"
            controller={dutyIntervalsDataGridController}
            components={dutyIntervalsDataGridComponents}
            rows={dataTable || driverEvents}
            rowIdProperty="key"
          />
        )}
      </Card>
      {driver && !isEmpty(log) && (
        <>
          <CreateLogDutyStatusEventDialog
            isOpen={isCreateDialogOpen}
            onClose={closeCreateDialog}
            onConfirm={submitCreateLogDutyStatusEvent}
            date={logDate}
            driverKey={driver.key}
            user={user}
            log={log}
          />
          <EditLogDutyStatusEventDialog
            isOpen={isEditDialogOpen}
            onClose={closeEditDialog}
            onConfirm={submitUpdateLogDutyStatusEvent}
            onRefresh={(response) => {
              const newDataTable = driverEvents.reduce((acc, curr) => {
                if (curr.key === response.key) {
                  return [...acc, response];
                }
                return [...acc, curr];
              }, []);

              setDataTable(newDataTable);
            }}
            date={logDate}
            driverKey={driver.key}
            user={user}
            log={log}
            row={targetRow}
          />
          <ReassignLogDutyStatusEventDialog
            isOpen={isReassignDialogOpen}
            onClose={closeReassignDialog}
            onConfirm={submitReassignLogDutyStatusEvent}
            date={logDate}
            driverKey={driver.key}
            user={user}
            log={log}
            row={targetRow}
          />
        </>
      )}
    </HidePrint>
  );
};

EventsCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  submitCreateLogDutyStatusEvent: PropTypes.func.isRequired,
  submitUpdateLogDutyStatusEvent: PropTypes.func.isRequired,
  submitReassignLogDutyStatusEvent: PropTypes.func.isRequired,
  user: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  hidePrint: PropTypes.bool,
};
