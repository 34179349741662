import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressOverlay, ExpansionPanel, ToggleInput } from 'stti-react-common';
import { isEmpty, isNil } from 'lodash';

import { Feature } from '../../../../commons/Feature';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { useDebounce } from '../../../../../helpers/hooks';
import { AdminSearchPanel, AdminExportDataMenu } from '../../../../commons/AdminCommon';
import { ColorChip } from '../../../../commons/ColorChip';
import { AggregatesCard } from '../../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './summary.scss';

const getUptimeCountByStatus = (ecmReports, status) => {
  if (status === 'working') {
    return ecmReports.filter(({ uptime }) => uptime > 50).length;
  }

  if (status === 'partial') {
    return ecmReports.filter(({ uptime }) => uptime >= 5 && uptime < 50).length;
  }

  if (status === 'notWorking') {
    return ecmReports.filter(({ uptime }) => uptime < 5).length;
  }

  return 0;
};

export const EcmReportSummary = ({ ecmReports, fetchEcmReports, currentUser, route }) => {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [showOnlyNotWorking, setShowOnlyNotWorking] = useState(false);
  const [showOnlyPartial, setShowOnlyPartial] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500, '');

  useEffect(() => {
    setLoading(true);
    fetchEcmReports().then(() => setLoading(false));
  }, []);

  const shouldShowThePage = useMemo(
    () => !loading && !isEmpty(currentUser),
    [loading, ecmReports, currentUser]
  );

  const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

  const dataGridComponents = useDataGridComponents({
    Working: ({ value }) => {
      if (value === 'Not Measured') return <ColorChip label={value} color="gray" />;
      if (value === 'Working') return <ColorChip label={value} color="green" />;
      if (value === 'Partial') return <ColorChip label={value} color="amber" />;
      return <ColorChip label={value} color="red" />;
    },
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  const preFilteredReports = useMemo(() => {
    const getStatusByUptime = (uptime) => {
      if (isNil(uptime)) return 'Not Measured';
      if (uptime >= 50) return 'Working';
      if (uptime >= 5 && uptime < 50) return 'Partial';
      return 'Not Working';
    };

    const remappedEcmReports = ecmReports.map((report) => ({
      key: report.device.key,
      status: getStatusByUptime(report.uptime),
      ...report,
    }));

    const filterByUptime = (reports) => {
      if (showOnlyNotWorking && showOnlyPartial) {
        return reports.filter(({ uptime }) => uptime < 50);
      }

      if (showOnlyNotWorking) {
        return reports.filter(({ uptime }) => uptime < 5);
      }

      if (showOnlyPartial) {
        return reports.filter(({ uptime }) => uptime >= 5 && uptime < 50);
      }

      return reports;
    };

    return filterByUptime(remappedEcmReports);
  }, [ecmReports, showOnlyNotWorking, showOnlyPartial]);

  if (!shouldShowThePage) {
    return <ProgressOverlay isOpen={loading} />;
  }

  // RENDER
  return (
    <Feature.Wrapper className="EcmReportSummary">
      <Feature.Header onClose={route && route.close} title="NaviLinks" service="ecmReports">
        <AdminExportDataMenu dataGridController={dataGridController} filename="Ecm Reports" />
      </Feature.Header>

      <Feature.Body>
        <div className="EcmReportSummary__Aggregates">
          <ExpansionPanel
            title="Aggregates"
            onChange={() => setExpanded(!expanded)}
            value={expanded}
            summary={
              expanded ? null : (
                <div>
                  {ecmReports.length} total devices, {getUptimeCountByStatus(ecmReports, 'working')}{' '}
                  working devices, {getUptimeCountByStatus(ecmReports, 'partial')} partially working
                  devices, {getUptimeCountByStatus(ecmReports, 'notWorking')} not working devices
                </div>
              )
            }
          >
            <AggregatesCard
              title="Overview"
              name="overview"
              fields={[
                {
                  header: 'Total Devices',
                  value: ecmReports.length,
                },
                {
                  header: 'Working',
                  value: getUptimeCountByStatus(ecmReports, 'working'),
                },
                {
                  header: 'Partial',
                  value: getUptimeCountByStatus(ecmReports, 'partial'),
                },
                {
                  header: 'Not Working',
                  value: getUptimeCountByStatus(ecmReports, 'notWorking'),
                },
              ]}
            />
          </ExpansionPanel>
        </div>
        <AdminSearchPanel
          searchPlaceHolder="Search By"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
          renderPanelActions={
            <>
              <ToggleInput
                label="Show Only Not Working"
                value={showOnlyNotWorking}
                onChange={() => {
                  setShowOnlyNotWorking(!showOnlyNotWorking);
                }}
              />
              <ToggleInput
                label="Show Only Partial"
                value={showOnlyPartial}
                onChange={() => {
                  setShowOnlyPartial(!showOnlyPartial);
                }}
              />
            </>
          }
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={preFilteredReports}
          searchText={debouncedSearchText}
          rowIdProperty="key"
          enableCellTextSelection
          sizeColumnsToFit
          autoHeightColumns
        />
      </Feature.Body>
    </Feature.Wrapper>
  );
};

EcmReportSummary.propTypes = {
  ecmReports: customPropTypes.activationCodes.isRequired,
  fetchEcmReports: PropTypes.func.isRequired,
  currentUser: customPropTypes.user,
  route: PropTypes.object,
};
