import { connect } from 'react-redux';

import { UnidentifiedLogsSummaryReport } from './UnidentifiedLogsSummaryReport';
import { unidentifiedLogs } from '../../../../data/reports';
import { selectSystemOus } from '../../../../data/system';

export const UnidentifiedLogsSummaryReportContainer = connect(
  (state) => ({
    ous: selectSystemOus(state),
    logs: unidentifiedLogs.selectors.selectUnidentifiedLogs(state),
    limits: { forAllOus: 15, forOu: 60 },
  }),
  { fetchUnidentifiedLogs: unidentifiedLogs.actions.fetchUnidentifiedLogs }
)(UnidentifiedLogsSummaryReport);
