import { createSelector } from 'reselect';
import moment from 'moment-timezone';

const getReportSchedules = (state) => state.reports.reportSchedules.data;

/**
 * selectReportSchedules(state) -> [reportSchedules]
 *
 * Selector for all reportSchedules
 */
export const selectReportSchedules = createSelector(getReportSchedules, (reportSchedules) =>
  reportSchedules.map((reportSchedule) => {
    const { hour, timeZone } = reportSchedule;
    const hourAt = moment()
      .tz(timeZone)
      .hour(hour)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString();
    return {
      ...reportSchedule,
      hourAt,
    };
  })
);
