import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Clickable, useBooleanState, BasicDialog } from 'stti-react-common';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '../../../../../helpers/hooks';

import { Feature } from '../../../../commons/Feature';
import { AdminSearchPanel, DeleteAdminItemDialog } from '../../../../commons/AdminCommon';
import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { columnDefs } from './columnDefs';
import './summary.scss';

const { useDataGridController, useDataGridComponents } = ControlledDataGrid;

export const NotificationSummary = ({
  route,
  notifications = [],
  fetchNotificationTriggers,
  openNotificationCreate,
  deleteNotification,
}) => {
  const history = useHistory();

  const [localNotifications, setLocalNotifications] = useState([]);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  // Delete dialog
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBooleanState();
  const [targetRow, setTargetRow] = useState(null);

  // Basic error dialog for deletion failures
  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');

  // Data Grid
  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs,
  });

  // Fetch triggers on mount
  useEffect(() => {
    fetchNotificationTriggers();
  }, [fetchNotificationTriggers]);

  // Update local notifications from store
  useEffect(() => {
    setLocalNotifications(notifications);
  }, [notifications]);

  // Hook into the data grid’s state
  useEffect(() => {
    dataGridController.setState((prev) => ({
      ...prev,
      rowData: localNotifications,
    }));
  }, [localNotifications, dataGridController]);

  // Close route if provided
  const handleRouteClose = () => {
    if (route?.close) {
      route.close();
    }
  };

  // Handle edit action
  const handleEdit = (notification) => {
    history.push({
      pathname: `/administration/notifications/notification/${notification.key}`,
      state: { notification },
    });
  };

  // Open delete dialog
  const handleDeleteClick = (notification) => {
    setTargetRow(notification);
    openDeleteDialog();
  };

  // Show error dialog
  const openErrorDialog = (msg) => {
    setErrorDialogMessage(msg);
    setErrorDialogOpen(true);
  };

  // Close error dialog
  const closeErrorDialog = () => {
    setErrorDialogMessage('');
    setErrorDialogOpen(false);
  };

  /**
   * Actually perform the delete after the user confirms
   */
  const handleConfirmDelete = async (rowData) => {
    closeDeleteDialog();
    try {
      // Call the API to delete
      await deleteNotification({ id: rowData.id });

      // Remove from local state immediately
      setLocalNotifications((prev) => prev?.filter((n) => n?.id !== rowData?.id) ?? []);
    } catch (err) {
      openErrorDialog(err.message || 'Failed to delete the notification trigger.');
    }
  };

  // Data grid custom components
  const dataGridComponents = useDataGridComponents({
    NotificationHeader: () => <Icon icon="launch" />,
    NotificationLink: ({ data }) => (
      <Clickable onClick={() => handleEdit(data)}>
        <Icon icon="launch" />
      </Clickable>
    ),
    Actions: ({ data }) => (
      <>
        <Button
          icon="edit"
          variant="text"
          tooltip="Edit Notification"
          onClick={() => handleEdit(data)}
        />
        <Button
          icon="delete"
          variant="text"
          color="secondary"
          tooltip="Delete Notification"
          onClick={() => handleDeleteClick(data)}
        />
      </>
    ),
  });

  return (
    <div className="NotificationsSummary">
      <Feature.Header onClose={handleRouteClose} title="Notifications" service="notifications">
        <Button
          icon="add"
          variant="contained"
          label="New Notification"
          onClick={openNotificationCreate}
        />
      </Feature.Header>

      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Notifications"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
        />
        <div className="NotificationsSummary__gridWrapper">
          <ControlledDataGrid
            theme="balham"
            controller={dataGridController}
            components={dataGridComponents}
            rowIdProperty="key"
            rows={localNotifications}
            searchText={debouncedSearchText}
            sizeColumnsToFit
          />
        </div>
      </Feature.Body>

      {/* Delete Confirmation Dialog */}
      <DeleteAdminItemDialog
        dialogTitle="Notification"
        itemTitle={targetRow?.name ?? ''}
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={handleConfirmDelete}
        rowData={targetRow}
      />

      {/* Error Dialog if deletion fails */}
      <BasicDialog
        isOpen={isErrorDialogOpen}
        onClose={closeErrorDialog}
        title="Deletion Error"
        actions={
          <Button variant="contained" onClick={closeErrorDialog}>
            Close
          </Button>
        }
      >
        {errorDialogMessage}
      </BasicDialog>
    </div>
  );
};

NotificationSummary.propTypes = {
  route: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  fetchNotificationTriggers: PropTypes.func.isRequired,
  openNotificationCreate: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};

export default NotificationSummary;
