import {
  dateObject,
  dateObjectDuration,
  startDateObjectWithTimeZoneToIso,
  endDateObjectWithTimeZoneToIso,
} from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

export const fetchSpeedViolations = makeRestApiAction({
  service: 'speedViolations',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchSpeedViolations,
  transformInput: ({ startDate, endDate, ouKey, timeZone }) => ({
    start: startDateObjectWithTimeZoneToIso(startDate, timeZone),
    end: endDateObjectWithTimeZoneToIso(endDate, timeZone),
    ou: ouKey,
    type: 'speedtracking.SpeedViolationReport',
  }),
  transformOutput: (responseData, { ouKey, timeZone }) => {
    const formatted = [];
    responseData.forEach(({ violations, start, end, ...rest }) => {
      violations.forEach((violation) => {
        const violationStart = violation.start;
        const violationEnd = dateObject(violation.start)
          .add(violation.duration, 'seconds')
          .format();
        formatted.push({
          ouKey,
          startedAt: violationStart,
          endedAt: violationEnd,
          timeZone,
          duration: dateObjectDuration(violationEnd, violationStart).asSeconds(),
          ...rest,
          ...violation,
        });
      });
    });
    return formatted;
  },
  notificationsItemDescriptor: 'speedViolations',
});
