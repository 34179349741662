import { isString, filter } from 'lodash';

import { makeRestApiAction, makeDateRangeOptimizedAction } from '../../apiActionSupport';
import { selectBreadcrumbsFetches, selectRefinedBreadcrumbsFetches } from './selectors';

import { ACTION_TYPE } from './reducer';

const hasCoordinates = ({ latitude, longitude }) => latitude && longitude;

// fetchBreadcrumbs({ startDate, endDate, vehicleKey })
const fetchBreadcrumbs = makeRestApiAction({
  service: 'breadcrumbs',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchBreadcrumbs,
  transformOutput: (breadcrumbs, { vehicleKey }) =>
    filter(
      breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        vehicleKey, // required in reducer (no vehicle info returned in breadcrumbs)
        sequence: Number(breadcrumb.sequence),
        other: isString(breadcrumb.other) ? JSON.parse(breadcrumb.other) : breadcrumb.other,
        eventType: breadcrumb.eventType.split('.')[1] || breadcrumb.eventType,
      })),
      hasCoordinates
    ),
  notificationsItemDescriptor: 'vehicle tracking data',
  enableProgress: true,
});

// fetchBreadcrumbsOptimized({ startDate, endDate, vehicleKey })
export const fetchBreadcrumbsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectBreadcrumbsFetches,
  filterFetches: (fetches, { vehicleKey }) => filter(fetches, { vehicleKey }),
  baseActionType: ACTION_TYPE.fetchBreadcrumbs,
  fetchAction: fetchBreadcrumbs,
});

// fetchRefinedBreadcrumbs({ startDate, endDate, vehicleKey })
const fetchRefinedBreadcrumbs = makeRestApiAction({
  service: 'refinedBreadcrumbs', // refinedBreadcrumbs are simply performanceRuleToggled events
  method: 'get',
  baseActionType: ACTION_TYPE.fetchRefinedBreadcrumbs,
  transformOutput: (breadcrumbs, { vehicleKey }) =>
    filter(
      breadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        vehicleKey, // required in reducer (no vehicle info returned in breadcrumbs)
        sequence: Number(breadcrumb.sequence),
        eventType: '', // property added to match standard breadcrumb shape
      })),
      hasCoordinates
    ),
  notificationsItemDescriptor: 'vehicle tracking data',
  enableProgress: true,
});

// fetchRefinedBreadcrumbsOptimized({ startDate, endDate, vehicleKey, detailed? })
export const fetchRefinedBreadcrumbsOptimized = makeDateRangeOptimizedAction({
  selectFetches: selectRefinedBreadcrumbsFetches,
  filterFetches: (fetches, { vehicleKey, detailed }) => {
    const filterCriteria = detailed
      ? { vehicleKey, detailed } // a detailed query must only look at detailed fetches for vehicle
      : { vehicleKey }; // a non-detailed query can look at all fetches for vehicle
    return filter(fetches, filterCriteria);
  },
  baseActionType: ACTION_TYPE.fetchRefinedBreadcrumbs,
  fetchAction: fetchRefinedBreadcrumbs,
});
