import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import PropTypes from 'prop-types';

import Marker from './Marker';
import MarkerInfo from './MarkerInfo';
import { config } from '../utils/config';
import { DEFAULT_LOCATION, DEFAULT_ZOOM } from '../utils/constants';

export const MarkerCluster = ({ rows, showMarkerLabel, unitSystem }) => {
  const [markers, setMarkers] = useState({});
  const [selectedMarkerKey, setSelectedMarkerKey] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const map = useMap();

  const cluster = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  /**
   * This useEffect is used to clear the existing markers and add the new markers
   * to the MarkerClusterer whenever the list of markers changes.
   */
  useEffect(() => {
    if (cluster) {
      cluster.clearMarkers();
      cluster.addMarkers(Object.values(markers));
    }
  }, [markers]);

  /**
   * This useEffect is used to set the center of the map to the location of the
   * selected marker when the selected marker key changes.
   */
  useEffect(() => {
    if (!selectedMarkerKey) {
      return;
    }

    const selectedRow = rows.find((row) => row.key === selectedMarkerKey);
    map.setCenter(selectedRow.vehicle.location);
    map.setZoom(14);
  }, [selectedMarkerKey, rows]);

  const setMarkerRef = useCallback(
    (item, key) => {
      setMarkers((items) => {
        if (!items) return {};

        if ((item && items[key]) || (!item && !items[key])) return items;

        if (item) {
          return { ...items, [key]: item };
        }

        const { [key]: _, ...newMarkers } = items || {};

        return newMarkers;
      });
    },
    [rows]
  );

  const handleClick = useCallback(
    (item) => {
      const isSelected = selectedMarkerKey === item?.key;

      if (isSelected) {
        setSelectedMarkerKey(null);
        if (config.ZOOM_OUT_MAP_AFTER_DESELECTION) {
          map.setCenter(DEFAULT_LOCATION);
          map.setZoom(DEFAULT_ZOOM);
        }
      } else {
        if (item?.vehicle?.location) map.setCenter(item.vehicle.location);
        setSelectedMarkerKey(item?.key);
      }
    },
    [selectedMarkerKey]
  );

  const handleMarkerMouseEnter = useCallback((item) => {
    setHoveredMarker(item);
  }, []);

  const handleMarkerMouseLeave = useCallback(() => {
    setHoveredMarker(null);
  }, []);

  return (
    <>
      {rows
        .filter(({ vehicle }) => vehicle && vehicle?.location)
        .map((row, index) => (
          <Marker
            marker={row}
            index={index}
            key={index}
            onClick={handleClick}
            setMarkerRef={setMarkerRef}
            onMouseEnter={handleMarkerMouseEnter}
            onMouseLeave={handleMarkerMouseLeave}
            showMarkerLabel={showMarkerLabel}
            selectedMarkerKey={selectedMarkerKey}
          />
        ))}
      {hoveredMarker && hoveredMarker?.key && (
        <MarkerInfo
          anchor={markers[hoveredMarker?.key]}
          data={hoveredMarker}
          unitSystem={unitSystem}
        />
      )}
    </>
  );
};

MarkerCluster.propTypes = {
  rows: PropTypes.array.isRequired,
  showMarkerLabel: PropTypes.bool,
  unitSystem: PropTypes.string,
};
