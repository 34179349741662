import produce from 'immer';
import { actionTypeProgressOrSuccess, deepMergeEntities } from '../../../apiReducerSupport';

const initialState = {
  data: {
    histories: [],
    triggers: [],
  },
  fetches: {
    histories: [],
    triggers: [],
  },
};

const basePath = 'administration/configurations/notifications';

export const ACTION_TYPE = {
  fetchNotificationTriggers: `${basePath}/fetchNotificationTriggers`,
  fetchNotificationTrigger: `${basePath}/fetchNotificationTrigger`,
  createNotificationTrigger: `${basePath}/createNotificationTrigger`,
  updateNotificationTrigger: `${basePath}/updateNotificationTrigger`,
  deleteNotificationTrigger: `${basePath}/deleteNotificationTrigger`,
  fetchNotificationHistories: `${basePath}/fetchNotificationHistories`,
  updateNotificationHistories: `${basePath}/updateNotificationHistories`,
};
export const notificationsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchNotificationTriggers])) {
      const triggersWithKey = (payload || []).map((trigger) => ({
        ...trigger,
        key: trigger.id,
      }));
      draft.data.triggers = deepMergeEntities(state.data.triggers, triggersWithKey);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchNotificationHistories,
        ACTION_TYPE.updateNotificationHistories,
      ])
    ) {
      draft.data.histories = deepMergeEntities(state.data.histories, payload);
    }

    // -- CREATE --
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.createNotificationTrigger])) {
      const newTrigger = { ...payload, key: payload.id };
      draft.data.triggers = deepMergeEntities(draft.data.triggers, [newTrigger]);
    }

    // -- UPDATE --
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.updateNotificationTrigger])) {
      const updatedTrigger = { ...payload, key: payload.id };
      draft.data.triggers = deepMergeEntities(draft.data.triggers, [updatedTrigger]);
    }

    // -- DELETE --
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.deleteNotificationTrigger])) {
      draft.data.triggers = draft.data.triggers.filter((trigger) => trigger.id !== payload.id);
    }
  });
