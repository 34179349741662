import React from 'react';
import PropTypes from 'prop-types';
import { RenderDrivers } from '../../NotificationRenders/RenderDrivers';

function NotificationTypeDriverBased({ formControls, form, mode, driverOptions, Control }) {
  return (
    <div className="notification-criteria-one-column">
      <h3 className="section-title">Driver-Based Notification</h3>
      <p className="section-description">
        Notify based on driver-related conditions or regulations.
      </p>

      <div className="notification-criteria-fields">
        <RenderDrivers
          formControls={formControls}
          form={form}
          mode={mode}
          driverOptions={driverOptions}
          Control={Control}
        />
      </div>
    </div>
  );
}

NotificationTypeDriverBased.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  driverOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeDriverBased;
