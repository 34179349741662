import React, { useContext, useState } from 'react';
import isNil from 'lodash/isNil';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { ReportContext } from '../../context/ReportContext';

const handleDelete = (controller, setFilters) => {
  setFilters((prev) => {
    if (Array.isArray(prev[controller])) {
      return { ...prev, [controller]: [] };
    }

    const newFilters = { ...prev };
    newFilters[controller] = ''; // Clear the filter with (blank value)
    return newFilters;
  });
};

const chipValueDisplay = (controller, keys) => {
  const { lookup, label, col } = controller;

  // If a lookup is provided, we know the column is an array of objects, so we check if the
  // column is an array of objects, then return the selected keys
  if (lookup && Array.isArray(col)) {
    const results = col.filter((item) => keys.includes(item.key)).map((item) => item[lookup]);
    return results?.length === 1 ? results[0] : `${results.length} ${label}`;
  }

  // If no lookup is provided, we assume the column is a primitive value, so we check if the
  // column is an array of objects or array of primitives, then return the selected keys the
  // lookup in this case is always name
  if (Array.isArray(col)) {
    if (!keys) return '';
    if (typeof keys === 'boolean') {
      const results = col.find((item) =>
        keys?.toString().includes(typeof item === 'object' ? item.key : item)
      );

      return results?.name;
    }
    const results = col.find((item) => keys.includes(typeof item === 'object' ? item.key : item));
    return results?.name;
  }

  return col;
};

export const ReportFilter = () => {
  const { controllers, report, filters, setFilters, selectedReport } = useContext(ReportContext);
  const [expanded, setExpanded] = useState(false);

  if (!selectedReport) {
    return (
      <Accordion expanded={expanded} disabled>
        <AccordionSummary>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            spacing={2}
          >
            <Typography>Loading filters...</Typography>
            <CircularProgress />
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }

  return (
    <Accordion expanded={expanded} onChange={(ev, isExpanded) => setExpanded(isExpanded)}>
      <AccordionSummary>
        <Typography>{report.filter.title}</Typography>
        {!expanded && (
          <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
            {Object.keys(controllers).map((controller) =>
              (Array.isArray(filters[controller]) && filters[controller].length > 0) ||
              (!Array.isArray(filters[controller]) && !!filters[controller]) ? (
                <Chip
                  key={controller}
                  label={`${controllers[controller].label}:  ${chipValueDisplay(
                    controllers[controller],
                    filters[controller]
                  )}`}
                  onDelete={() => handleDelete(controller, setFilters)}
                />
              ) : null
            )}
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          spacing={2}
        >
          {Object.keys(controllers).map((controller) => (
            <Grid size={3} key={controller}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>{controllers[controller].label}</InputLabel>
                <Select
                  variant="standard"
                  multiple={!controllers[controller].isBoolean}
                  value={isNil(filters[controller]) ? [] : filters[controller]}
                  onChange={(e) => {
                    setFilters({ ...filters, [controller]: e.target.value });
                  }}
                  disabled={controllers[controller].disabled}
                  renderValue={() =>
                    (Array.isArray(filters[controller]) && filters[controller].length > 0) ||
                    (!Array.isArray(filters[controller]) && !!filters[controller]) ? (
                      <Chip
                        label={chipValueDisplay(controllers[controller], filters[controller])}
                      />
                    ) : null
                  }
                  endAdornment={
                    Array.isArray(filters[controller]) &&
                    filters[controller].length > 0 && (
                      <InputAdornment position="end" sx={{ position: 'absolute', right: 30 }}>
                        <CloseIcon
                          onClick={() => handleDelete(controller, setFilters)}
                          sx={{ cursor: 'pointer' }}
                          fontSize="small"
                        />
                      </InputAdornment>
                    )
                  }
                >
                  {controllers[controller].col.map((item, index) => (
                    <MenuItem key={index} value={item?.key} sx={{ height: 35 }} disableRipple>
                      {!controllers[controller].isBoolean && (
                        <Checkbox
                          disableRipple
                          checked={filters[controller]?.includes(item?.key)}
                          size="small"
                        />
                      )}
                      <ListItemText primary={item?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
