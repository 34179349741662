/**
 * selectNotificationTriggers(state) -> [triggers]
 *
 * Selector for all notifications triggers
 */
export const selectNotificationTriggers = (state) =>
  state.administration.configurations.notifications.data.triggers;

/**
 * selectNotificationTrigger(state, notificationTriggerId) -> trigger
 *
 * Selector for single admin notification trigger by key
 */
export const selectNotificationTrigger = (state, notificationTriggerId) =>
  state.administration.configurations.notifications.data.triggers.find(
    (item) => item.key === notificationTriggerId
  );

/**
 * selectNotificationHistories(state) -> [histories]
 *
 * Selector for all notifications histories
 */
export const selectNotificationHistories = (state) =>
  state.administration.configurations.notifications.data.histories;

/**
 * selectNotificationHistoriesFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on notifications histories
 */
export const selectNotificationHistoriesFetchRequests = (state) =>
  state.administration.configurations.notifications.fetches.histories;
