/* eslint-disable no-undef */

import React, { useEffect, useState } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';

const style = {
  background: 'none padding-box rgb(255, 255, 255)',
  display: 'table-cell',
  border: 0,
  margin: 0,
  padding: '0px 17px',
  textTransform: 'none',
  appearance: 'none',
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
  overflow: 'hidden',
  textAlign: 'center',
  height: 40,
  verticalAlign: 'middle',
  color: 'rgb(86, 86, 86)',
  fontFamily: 'Roboto, Arial, sans-serif',
  fontSize: 18,
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
  minWidth: 66,
  top: 10,
};

const createDrawingInstance = (drawing, map, setDrawMode) => {
  const newDrawingManager = new drawing.DrawingManager({
    map,
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      editable: true,
      draggable: true,
    },
  });

  setDrawMode('POLYGON');

  const onPolygonComplete = (polygon) => {
    polygon.setOptions({
      fillColor: '#FF0000',
      strokeColor: '#000000',
      strokeOpacity: 0.5,
      strokeWeight: 1,
    });
    polygon.setEditable(false);
    const path = polygon.getPath();
    const coordinates = [];
    path.forEach((latLng) => {
      coordinates.push([latLng.lat(), latLng.lng()]);
    });
    // console.log('Polygon complete:', coordinates);
    setDrawMode('COMPLETED');
  };

  google.maps.event.clearListeners(newDrawingManager, 'polygoncomplete');
  google.maps.event.addListener(newDrawingManager, 'polygoncomplete', onPolygonComplete);

  return newDrawingManager;
};

const cleanupPolygons = (polygons) => {
  polygons.forEach((polygon) => {
    google.maps.event.clearListeners(polygon, 'mouseover');
    google.maps.event.clearListeners(polygon, 'mouseout');
    polygon.setMap(null);
  });
};

function showPolygonInfo({ infoWindow }, event, map, row) {
  infoWindow.setContent(row.name);
  infoWindow.setPosition(event.latLng);
  infoWindow.open(map);
}

function closePolygonInfo({ infoWindow }) {
  infoWindow.close();
}

export const Geozones = ({ locations }) => {
  const [instance, setInstance] = useState(null);
  const [drawMode, setDrawMode] = useState('STANDBY');
  const drawing = useMapsLibrary('drawing');
  const map = useMap();

  useEffect(() => {
    if (!locations || !map) return null;

    const polygons = locations
      .filter((row) => row && row.locations)
      .map((row) => {
        const polygon = new google.maps.Polygon({
          paths: row.locations.map(
            (location) => new google.maps.LatLng(location.latitude, location.longitude)
          ),
          strokeColor: '#000000',
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: row.color,
          fillOpacity: 0.35,
          infoWindow: new google.maps.InfoWindow({ headerDisabled: true }),
        });
        polygon.addListener('mouseover', (event) => showPolygonInfo(polygon, event, map, row));
        polygon.addListener('mouseout', () => closePolygonInfo(polygon));
        polygon.setMap(map);
        return polygon;
      });

    return () => cleanupPolygons(polygons);
  }, [locations, map]);

  useEffect(() => {
    if (drawMode === 'COMPLETED') {
      setDrawMode('STANDBY');
      instance.setMap(null);
    }
  }, [drawMode]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <button
        style={style}
        type="button"
        onClick={() => setInstance(createDrawingInstance(drawing, map, setDrawMode))}
        disabled={drawMode !== 'STANDBY'}
      >
        + New Geozone
      </button>
      {drawMode === 'POLYGON' && (
        <button
          type="button"
          aria-label="Complete"
          onClick={() => setDrawMode('COMPLETED')}
          style={{ ...style, minWidth: 40 }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z" />
          </svg>
        </button>
      )}
    </div>
  );
};

Geozones.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};
