import produce from 'immer';
import { sortBy } from 'lodash';

import { actionTypeProgressOrSuccess, shallowMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchSpeedViolations: 'reports/speedViolations/fetchSpeedViolations',
};

const initialState = {
  speedViolations: [],
};

export const speedViolationsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchSpeedViolations])) {
      draft.speedViolations = sortBy(
        shallowMergeEntities(state.speedViolations, payload, 'key'),
        'startedAt'
      );
    }
  });
