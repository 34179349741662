import { columnDef } from '../../../../commons/ControlledDataGrid';

export const makeColumnDefs = (isRefreshing, isSystemTenant) => [
  columnDef.iconColumn({
    field: 'routeMap',
    headerName: 'Open',
    cellRenderer: 'AssetTag',
    headerComponent: 'AssetHeader',
  }),
  columnDef.textColumn({
    field: 'assetTag',
    headerName: 'Asset Tag',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'vehicleName',
    headerName: 'Vehicle Assignment',
    cellRenderer: 'Vehicle',
    hide: isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'tenantName',
    headerName: 'Tenant',
    hide: !isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'ouName',
    headerName: 'Managed By',
    hide: isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'location',
    headerName: 'Last Known location',
    cellRenderer: 'LocationMap',
    headerComponent: 'LocationMapHeader',
    valueGetter: ({ data }) => (data?.location ? JSON.stringify(data?.location) : 'N/A'),
    width: 40,
    hide: isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'status',
    headerName: 'Status',
    cellRenderer: 'Status',
    hide: isSystemTenant,
  }),
  columnDef.dateColumn({
    field: 'lastSeenOnline',
    headerName: 'Last Seen Online',
    hide: isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'deviceModel',
    headerName: 'Device Model',
    hide: isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'firmwareVersion',
    headerName: 'Firmware Version',
    hide: isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'iccid',
    headerName: 'IICID',
    enableRowGroup: false,
    hide: true,
  }),
  columnDef.textColumn({
    field: 'imei',
    headerName: 'IMEI',
    enableRowGroup: false,
    hide: !isSystemTenant,
  }),
  columnDef.textColumn({
    field: 'provider',
    headerName: 'Provider',
    enableRowGroup: true,
    hide: !isSystemTenant,
  }),
  columnDef.actionButtonsColumn({
    cellRenderer: isRefreshing ? 'ActionsRefresh' : 'Actions',
    width: 138,
  }),
];
