import React, { useContext, useMemo, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import { summaryProcessor, panelProcessor } from '../../utils/aggregation';

import { ReportContext } from '../../context/ReportContext';

import '../../css/Report.css';

export const ReportAggregate = () => {
  const { report, filteredDataSource, selectedReport } = useContext(ReportContext);
  const [expanded, setExpanded] = useState(false);

  const summary = useMemo(() => {
    if (!selectedReport || !report?.aggregation || !report.aggregation.summaries) {
      return null;
    }

    let phrase = '';

    report.aggregation.summaries.forEach((controller, index) => {
      if (index > 0) {
        phrase += ' ';
      }
      phrase += ` ${summaryProcessor(controller, filteredDataSource)}`;
    });

    return phrase;
  }, [report, filteredDataSource, selectedReport]);

  if (!selectedReport) {
    return (
      <Accordion expanded={expanded} disabled>
        <AccordionSummary>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            spacing={2}
          >
            <Typography>Loading aggregates...</Typography>
            <CircularProgress />
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }

  return (
    <Accordion expanded={expanded} onChange={(ev, isExpanded) => setExpanded(isExpanded)}>
      <AccordionSummary>
        <Typography>{report.aggregation.title}</Typography>
        {!expanded && (
          <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
            {summary}
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
          }}
          spacing={2}
        >
          {report.aggregation.panels.map((panel, index) => (
            <Paper key={index} className="ReportAggregate__Panel">
              <div className="Panel__title">{panel?.label}</div>
              {panel?.controllers?.map((controller, idx) => (
                <div key={idx} style={{ width: panel.width || '100%', padding: 5 }}>
                  {panelProcessor(controller, filteredDataSource)}
                </div>
              ))}
            </Paper>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
