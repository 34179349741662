// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuBIJr1wwUXpQjfsVghR0Q\\=\\={display:flex;align-items:center;padding:8px 16px;border-bottom:1px solid #e0e0e0}.hefUtl8VMlNc\\+L-jhSycRA\\=\\={padding:4px 8px;font-size:14px;color:#333}.hefUtl8VMlNc\\+L-jhSycRA\\=\\=:first-child{flex:0 0 25%;font-weight:bold}.hefUtl8VMlNc\\+L-jhSycRA\\=\\=:nth-child(2){flex:0 0 25%;text-align:center}.hefUtl8VMlNc\\+L-jhSycRA\\=\\=:nth-child(3){flex:0 0 50%}.AuBIJr1wwUXpQjfsVghR0Q\\=\\=:last-child{border-bottom:none}", "",{"version":3,"sources":["webpack://./src/components/administration/configurations/Notifications/Detail/NotificationTypes/shared/ToggleRow.module.scss"],"names":[],"mappings":"AAEA,4BACI,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,+BAAA,CAGF,6BACE,eAAA,CACA,cAAA,CACA,UAAA,CAIF,yCACE,YAAA,CACA,gBAAA,CAIF,0CACE,YAAA,CACA,iBAAA,CAIF,0CACE,YAAA,CAIF,uCACE,kBAAA","sourcesContent":["/* ToggleRow.module.scss */\n\n.toggle-grid-row {\n    display: flex;\n    align-items: center;\n    padding: 8px 16px;\n    border-bottom: 1px solid #e0e0e0;\n  }\n  \n  .toggle-grid-cell {\n    padding: 4px 8px;\n    font-size: 14px;\n    color: #333;\n  }\n  \n  /* First cell (Label) */\n  .toggle-grid-cell:first-child {\n    flex: 0 0 25%;\n    font-weight: bold;\n  }\n  \n  /* Second cell (Toggle) */\n  .toggle-grid-cell:nth-child(2) {\n    flex: 0 0 25%;\n    text-align: center;\n  }\n  \n  /* Third cell (Description) */\n  .toggle-grid-cell:nth-child(3) {\n    flex: 0 0 50%;\n  }\n  \n  /* Remove bottom border on the last row */\n  .toggle-grid-row:last-child {\n    border-bottom: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-grid-row": "AuBIJr1wwUXpQjfsVghR0Q==",
	"toggle-grid-cell": "hefUtl8VMlNc+L-jhSycRA=="
};
export default ___CSS_LOADER_EXPORT___;
