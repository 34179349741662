import React from 'react';
import PropTypes from 'prop-types';
import { ToggleInput } from 'stti-react-common';
import styles from './MalfunctionToggleGrid.module.scss';

/**
 * Renders a single row with two toggles (Raised & Cleared) for a malfunction type.
 * The first cell now displays "Name: Description".
 */
const MalfunctionToggleRow = ({
  label,
  description,
  isRaised,
  isCleared,
  onToggleRaised,
  onToggleCleared,
  Control,
  mode,
}) => {
  return (
    <div className={styles['toggle-grid-row']}>
      <div className={styles['toggle-grid-cell']}>
        {label}: {description}
      </div>
      <div className={styles['toggle-grid-cell']}>
        <Control
          Component={ToggleInput}
          name={`${label}_raised`}
          checked={isRaised}
          onChange={(checked) => onToggleRaised(label, checked)}
          readOnly={mode === 'view'}
        />
      </div>
      <div className={styles['toggle-grid-cell']}>
        <Control
          Component={ToggleInput}
          name={`${label}_cleared`}
          checked={isCleared}
          onChange={(checked) => onToggleCleared(label, checked)}
          readOnly={mode === 'view'}
        />
      </div>
    </div>
  );
};

MalfunctionToggleRow.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isRaised: PropTypes.bool.isRequired,
  isCleared: PropTypes.bool.isRequired,
  onToggleRaised: PropTypes.func.isRequired,
  onToggleCleared: PropTypes.func.isRequired,
  Control: PropTypes.elementType.isRequired,
  mode: PropTypes.string.isRequired,
};

/**
 * MalfunctionToggleGrid renders a grid with a header row and a list of malfunction toggle rows.
 * The layout now has 3 columns:
 *  - Column 1 (50%): "Name: Description"
 *  - Column 2 (25%): Raised toggle
 *  - Column 3 (25%): Cleared toggle
 */
const MalfunctionToggleGrid = ({
  rows,
  onToggleRaised,
  onToggleCleared,
  Control,
  mode,
}) => {
  return (
    <div className={styles['toggle-grid']}>
      <div className={styles['toggle-grid-header']}>
        <div className={styles['toggle-grid-cell']}>Malfunction</div>
        <div className={styles['toggle-grid-cell']}>Raised</div>
        <div className={styles['toggle-grid-cell']}>Cleared</div>
      </div>
      {rows.map((row) => (
        <MalfunctionToggleRow
          key={row.label}
          label={row.label}
          description={row.description}
          isRaised={row.isRaised}
          isCleared={row.isCleared}
          onToggleRaised={onToggleRaised}
          onToggleCleared={onToggleCleared}
          Control={Control}
          mode={mode}
        />
      ))}
    </div>
  );
};

MalfunctionToggleGrid.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isRaised: PropTypes.bool.isRequired,
      isCleared: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onToggleRaised: PropTypes.func.isRequired,
  onToggleCleared: PropTypes.func.isRequired,
  Control: PropTypes.elementType.isRequired,
  mode: PropTypes.string.isRequired,
};

export default MalfunctionToggleGrid;
