// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../img/surfsight-video.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CameraSummary .Feature__Body{padding:20px}.CameraSummary .Feature__Body .presentation{display:grid;grid-template-columns:22% 78%;grid-column-gap:10px;grid-row-gap:10px;background:#fff}.CameraSummary .Feature__Body .status{width:10px;height:10px;display:inline-block;border-radius:10px;position:relative;top:1px;margin-right:3px}.CameraSummary .Feature__Body .status.standby{background:#ab830d}.CameraSummary .Feature__Body .status.offline{background:#777}.CameraSummary .Feature__Body .status.online{background:#0dab0d}.CameraSummary .Feature__Body .cameraImage{width:auto;height:341px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat}.CameraSummary__Aggregates{padding:10px}.CameraSummary__Modal .MuiDialog-paperWidthLg{width:900px}.CameraSummary__Modal .MuiDialog-paperWidthLg .GoogleMap{height:500px}.CameraSummary .ag-react-container .Clickable.MapIcon span{margin-top:5px}", "",{"version":3,"sources":["webpack://./src/components/administration/devices/Cameras/Summary/summary.scss"],"names":[],"mappings":"AACE,8BACE,YAAA,CAEA,4CACE,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sCACE,UAAA,CACA,WAAA,CACA,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,OAAA,CACA,gBAAA,CAEA,8CACE,kBAAA,CAEF,8CACE,eAAA,CAEF,6CACE,kBAAA,CAIJ,2CACE,UAAA,CACA,YAAA,CACA,mEAAA,CAIJ,2BACE,YAAA,CAIA,8CACE,WAAA,CACA,yDACE,YAAA,CAOF,2DACE,cAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
