import React from 'react';
import PropTypes from 'prop-types';

import './Skeleton.scss';

const Skeleton = ({ width, height }) => <div className="skeleton" style={{ width, height }} />;
Skeleton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Skeleton;
