import { connect } from 'react-redux';

import { PerformanceRuleSummary } from './summary';
import { selectSystemActiveOu } from '../../../../../data/system';
import { performanceRule } from '../../../../../data/administration/configurations';
import { vehicleType } from '../../../../../data/administration/resources';

export const PerformanceRuleSummaryContainer = connect(
  (state) => ({
    activeOu: selectSystemActiveOu(state),
    rules: performanceRule.selectors.selectRules(state),
    rulesPredefined: performanceRule.selectors.selectRulesPredefined(state),
    ruleSets: performanceRule.selectors.selectRuleSets(state),
    vehicleTypes: vehicleType.selectors.selectVehicleTypes(state),
  }),
  {
    createRules: performanceRule.actions.createRules,
    createRuleSet: performanceRule.actions.createRuleSet,
    deleteRule: performanceRule.actions.deleteRule,
    deleteRuleSet: performanceRule.actions.deleteRuleSet,
    fetchRules: performanceRule.actions.fetchRulesOptimized,
    fetchRuleSets: performanceRule.actions.fetchRuleSetsOptimized,
    fetchRulesPredefined: performanceRule.actions.fetchRulesPredefined,
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
    updateRuleConfiguration: performanceRule.actions.updateRuleConfiguration,
  }
)(PerformanceRuleSummary);
