import { columnDef } from '../../../commons/ControlledDataGrid';
import { getConsumedFuelOverTime } from '../../../../helpers/admin/adminUtils';

const legChildren = (category) => [
  columnDef.distanceColumn({
    field: `${category}.distance`,
    headerName: 'Distance',
  }),
  columnDef.durationColumn({
    field: `${category}.duration`,
    headerName: 'Duration',
  }),
  columnDef.durationColumn({
    field: `${category}.waitDuration`,
    headerName: 'Waiting',
    hide: true,
  }),
  columnDef.fuelColumn({
    field: `${category}.addedFuel`,
    headerName: 'Added Fuel',
    hide: true,
  }),
  columnDef.fuelColumn({
    field: `${category}.consumedFuel`,
    headerName: 'Consumed Fuel',
    hide: true,
  }),
  columnDef.fuelColumn({
    field: `${category}.idlingFuel`,
    headerName: 'Idle Fuel',
    hide: true,
  }),
  columnDef.simpleMileageColumn({
    field: `${category}.mileage`,
    headerName: 'Mileage',
    hide: true,
  }),
  columnDef.durationColumn({
    field: `${category}.engineDuration`,
    headerName: 'Engine Time',
    hide: true,
  }),
  columnDef.durationColumn({
    field: `${category}.idlingDuration`,
    headerName: 'Idle Time',
    hide: true,
  }),
  columnDef.fuelColumn({
    colId: `consumedFuel${category}`,
    headerName: 'Consumed Fuel Per Hour',
    valueGetter: ({ data }) => getConsumedFuelOverTime(data, category),
    hide: true,
  }),
  columnDef.speedColumn({
    field: `${category}.averageSpeed`,
    headerName: 'Avg Speed',
    hide: true,
  }),
];

export const columnDefs = [
  {
    headerName: 'Open',
    children: [
      columnDef.iconColumn({
        field: 'openCycleDetailReport',
        headerName: 'Open Detail',
        cellRenderer: 'OpenDetail',
        essential: true,
        headerComponent: 'OpenDetailHeader',
        width: 65,
      }),
    ],
  },
  {
    headerName: 'Route',
    children: [
      columnDef.textColumn({
        field: 'origin.name',
        headerName: 'Origin',
        cellRenderer: 'Origin',
      }),
      columnDef.textColumn({
        field: 'firstLoad.name',
        headerName: 'First Load',
        cellRenderer: 'FirstLoad',
      }),
      columnDef.textColumn({
        field: 'lastUnload.name',
        headerName: 'Last Unload',
        cellRenderer: 'LastUnload',
      }),
    ],
  },
  {
    headerName: 'Trips',
    children: [
      columnDef.numberColumn({
        field: 'count.contributingTrips',
        headerName: 'Contributing',
      }),
      columnDef.numberColumn({
        field: 'count.exactTrips',
        headerName: 'Exact',
      }),
    ],
  },
  {
    headerName: 'Trip Resources',
    children: [
      columnDef.textColumn({
        colId: 'vehiclesNames',
        headerName: 'Vehicle(s)',
        valueGetter: ({ data }) => data.vehiclesNames.join(', '),
      }),
      columnDef.textColumn({
        colId: 'trailersNames',
        headerName: 'Trailers(s)',
        valueGetter: ({ data }) => data.trailersNames.join(', '),
      }),
      columnDef.textColumn({
        field: 'ouNames',
        headerName: 'Organization(s)',
        valueGetter: ({ data }) => data?.trips?.map((trip) => trip?.ou?.ou?.name).join(', '),
      }),
      columnDef.textColumn({
        colId: 'driversNames',
        headerName: 'Driver(s)',
        valueGetter: ({ data }) => data.driversNames.join(', '),
      }),
    ],
  },
  {
    headerName: 'Full Cycle',
    children: legChildren('fullCycle'),
  },
  {
    headerName: 'Empty',
    children: legChildren('empty'),
  },
  {
    headerName: 'Loading',
    children: legChildren('loading'),
  },
  {
    headerName: 'Loaded',
    children: legChildren('loaded'),
  },
  {
    headerName: 'Unloading',
    children: legChildren('unloading'),
  },
];
