import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProgressOverlay } from 'stti-react-common';
import { isEmpty } from 'lodash';

export const AppLoginProvider = ({ signIn, user, route, setMenu, ous }) => {
  const url = window.location.href;
  const hash = url?.split('#')[1];
  const params = hash.split('?')[1]?.split('&');
  const encodedCode = params?.find((param) => param.startsWith('code=')).split('=')[1];

  const code = encodedCode && atob(decodeURIComponent(encodedCode));

  const oauth = localStorage.getItem('oauth');

  useEffect(() => {
    if (code && isEmpty(user) && (!oauth || isEmpty(oauth))) {
      signIn({
        provider: 'google',
        code,
        redirectUri: window.origin,
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      route.authenticate(user, setMenu, ous);
    }
  }, [user]);

  return (
    <div style={{ textAlign: 'center' }}>
      <h3>Checking user authentication and loading application, please wait...</h3>
      <ProgressOverlay isOpen />
    </div>
  );
};

AppLoginProvider.propTypes = {
  signIn: PropTypes.func.isRequired,
  user: PropTypes.object,
  route: PropTypes.object,
  setMenu: PropTypes.func,
  ous: PropTypes.array,
};
