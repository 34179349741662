import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ToggleGrid from '../shared/ToggleGrid';
import ToggleRow from '../shared/ToggleRow';
import { RenderVehicles } from '../../NotificationRenders/RenderVehicles';

const NotificationTypeLoadUnload = ({
  formControls,
  form,
  mode,
  vehicleOptions,
  Control,
}) => {
  const loadUnloadError = useMemo(() => {
    if (!formControls.loadCompleted && !formControls.unloadCompleted) {
      return 'At least one of load or unload must be selected.';
    }
    return null;
  }, [formControls.loadCompleted, formControls.unloadCompleted]);

  return (
    <div className="notification-criteria-one-column">
      <h3 className="section-title">Load/Unload Notification</h3>
      <p className="section-description">
        Notify based on load/unload completion events.
      </p>

      <div className="notification-criteria-config">
        <ToggleGrid>
          <ToggleRow
            label="Load Completed"
            name="loadCompleted"
            description="Triggered when a load operation is completed."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={loadUnloadError}
          />
          <ToggleRow
            label="Unload Completed"
            name="unloadCompleted"
            description="Triggered when an unload operation is completed."
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={loadUnloadError}
          />
        </ToggleGrid>
      </div>

      <div className="notification-criteria-fields">
        <RenderVehicles
          formControls={formControls}
          form={form}
          mode={mode}
          vehicleOptions={vehicleOptions}
          Control={Control}
        />
      </div>
    </div>
  );
};

NotificationTypeLoadUnload.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeLoadUnload;
