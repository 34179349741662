import { connect } from 'react-redux';

import { TripsSummaryReport } from './TripsSummaryReport';
import { trips } from '../../../../data/reports';
import { selectSearchLimits } from '../../../../data/system';

export const TripsSummaryReportContainer = connect(
  (state) => ({
    trips: trips.selectors.selectTrips(state),
    limits: selectSearchLimits(state, 'trips'),
  }),
  { fetchTrips: trips.actions.fetchTrips }
)(TripsSummaryReport);
