import React from 'react';
import PropTypes from 'prop-types';
import { isArray, find, castArray, isPlainObject, isFunction, includes } from 'lodash';

import { useReport } from '../useReport';
import { useFormats } from '../../Formats';
import { NA_VALUE } from '../../../../helpers/formats';

import './ControlsTablePrint.scss';

function customSort(array) {
  if (!array || !array.length) return [];

  // sort printControls based on order defined on NAV-729, if we don't have selected year, then sort up to dateRangeFor plus the rest
  const order = ['ouKeys', 'dateRangeFor', 'selectedYear', 'dateRangeMode'];

  return array.sort((a, b) => {
    const indexA = order.indexOf(a.key);
    const indexB = order.indexOf(b.key);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }
    return 0;
  });
}

export const ControlsTablePrint = ({ additional = [] }) => {
  const { controls, controlDefs, filteredRows, infoFieldDefs, dataGrid } = useReport();
  const { formats, formatUnit, formatConvertedUnit } = useFormats();

  const printControls = [];
  const printErrors = [];

  const processControl = ({ key, label, value }) => {
    // if value is falsey or empty array, do not print
    if (!value || (isArray(value) && value.length === 0)) return;

    // look for key in existing printControls
    const existingPrintConfig = key && find(printControls, { key });

    if (existingPrintConfig) {
      // add value(s) to existing print control
      existingPrintConfig.value = castArray(existingPrintConfig.value).concat(value);
    } else {
      // add entire controlPrintConfig as new print control
      printControls.push({ key, label, value });
    }
  };

  Object.entries(controlDefs).forEach(([name, controlDef]) => {
    if (controlDef.printConfig) {
      // generate controlPrintConfig from control callback
      let controlPrintConfig = controlDef.printConfig({
        label: controlDef.label,
        name,
        value: controls[name],
        controls,
        formats,
        formatUnit,
        formatConvertedUnit,
        filteredRows,
      });
      // "cast" non-object controlPrintConfig (a string, number, or array) to object with value property
      if (!isPlainObject(controlPrintConfig)) controlPrintConfig = { value: controlPrintConfig };

      // add default key if applicable
      if (!controlPrintConfig.key) controlPrintConfig.key = name;

      // add default label if applicable
      if (!controlPrintConfig.label) controlPrintConfig.label = controlDef.label;

      processControl(controlPrintConfig);
    }

    if (controlDef.error) {
      const errorState = isFunction(controlDef.error)
        ? controlDef.error({ value: controls[name], controls, filteredRows })
        : controlDef.error;
      if (errorState)
        printErrors.push({
          key: name,
          label: controlDef.label,
          error: errorState,
        });
    }
  });

  // add additional "controls"
  additional.forEach(processControl);

  // add InfoFields
  Object.values(infoFieldDefs).forEach(({ name, label, value }) =>
    processControl({ key: name, label, value })
  );

  // add data grid filter notice if applicable
  if (Object.keys(dataGrid.filterModel).length !== 0)
    printControls.push({
      key: 'dataGridFiltersActive',
      label: 'Data Table',
      value: 'Filters active',
    });

  if (printErrors.length > 0) {
    return (
      <table className="Report__ControlsTablePrint">
        <thead>
          <tr>
            <th colSpan="2">The following errors were found:</th>
          </tr>
        </thead>
        <tbody>
          {printErrors.map(({ key, label, error }) => (
            <tr key={key}>
              <th>{label}</th>
              <td>{error}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  const formatValueToPrint = (value) => {
    if (isArray(value)) return value.join(', ');
    if (includes(value, 'data:image/png;base64')) return <img alt="" src={value} />;
    return value || NA_VALUE;
  };

  return (
    <table className="Report__ControlsTablePrint">
      <tbody>
        {customSort(printControls).map(({ key, label, value }) => (
          <tr key={key}>
            <th>{label}</th>
            <td>{formatValueToPrint(value)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

ControlsTablePrint.propTypes = {
  additional: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]), // falsy value results in non-printing
    })
  ),
};
