import { combineReducers } from 'redux';

import { breadcrumbsReducer as breadcrumbs } from './breadcrumbs/reducer';
import { driverLogsReducer as driverLogs } from './driverLogs/reducer';
import { exceptionsReducer as exceptions } from './exceptions/reducer';
import { fuelConsumptionsReducer as fuelConsumptions } from './fuelConsumptions/reducer';
import { fuellingsReducer as fuellings } from './fuellings/reducer';
import { fuelTaxesReducer as fuelTaxes } from './fuelTax/reducer';
import { geozonesReducer as geozones } from './geozones/reducer';
import { inspectionsReducer as inspections } from './inspections/reducer';
import { performancesReducer as performances } from './performances/reducer';
import { reportSchedulesReducer as reportSchedules } from './reportSchedules/reducer';
import { reportViewsReducer as reportViews } from './reportViews/reducer';
import { shiftsReducer as shifts } from './shifts/reducer';
import { speedViolationsReducer as speedViolations } from './speedViolations/reducer';
import { tripsReducer as trips } from './trips/reducer';
import { unidentifiedLogsReducer as unidentifiedLogs } from './unidentifiedLogs/reducer';
import { videoEventsReducer as videoEvents } from './videoEvents/reducer';

export const reportsReducer = combineReducers({
  breadcrumbs,
  driverLogs,
  exceptions,
  fuelConsumptions,
  fuellings,
  fuelTaxes,
  geozones,
  inspections,
  performances,
  reportSchedules,
  reportViews,
  shifts,
  speedViolations,
  trips,
  unidentifiedLogs,
  videoEvents,
});
