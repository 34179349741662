/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { round, concat, isNil, kebabCase } from 'lodash';

import { Button, SelectInput, BasicDialog, Form, ToggleInput } from 'stti-react-common';

import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { columnDefs } from './columnDefs';
import { useDebounce } from '../../../../../helpers/hooks';
import { Feature } from '../../../../commons/Feature';
import { AdminSearchPanel, DeleteAdminItemDialog } from '../../../../commons/AdminCommon';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { customPropTypes } from '../../../../../helpers/customPropTypes';

import './summary.scss';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;
const { Control, useFormController } = Form;

const setAllSelectionValues = (col, setFn, value) => {
  const array = col.map(({ description }) => ({
    key: kebabCase(description),
    value,
  }));

  const obj = {};
  array.forEach((item) => {
    obj[item.key] = item.value;
  });

  setFn(obj);
};

export const PerformanceRuleSummary = ({
  activeOu,
  createRules,
  createRuleSet,
  deleteRule,
  deleteRuleSet,
  fetchRules,
  fetchRuleSets,
  fetchRulesPredefined,
  fetchVehicleTypes,
  openRuleCreate,
  openRuleDetail,
  route,
  rules,
  ruleSets,
  rulesPredefined,
  updateRuleConfiguration,
  vehicleTypes,
}) => {
  const [currentDialog, setCurrentDialog] = useState(null);
  const [targetRow, setTargetRow] = useState({});
  const [searchText, setSearchText] = useState('');
  const [ruleSet, setRuleSet] = useState('default');
  const [ruleSetOptions, setRuleSetOptions] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [showVCPerformancePoints, setShowVCPerformancePoints] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500, '');

  useEffect(() => {
    fetchRules('default');
    fetchRuleSets();
    fetchVehicleTypes();
    fetchRulesPredefined();
  }, []);

  useEffect(() => {
    const array = rulesPredefined.map(({ description }) => ({
      key: kebabCase(description),
      value: false,
    }));

    const obj = {};
    array.forEach((item) => {
      obj[item.key] = item.value;
    });

    setSelectedRules(obj);
  }, [rulesPredefined]);

  useEffect(() => {
    // This effect will get newly created rule and get the list again so we have the correct key for it.
    // If we have a new endpoint in the future that returns the whole entity or at least the created key,
    // we can remove this useEffect.
    // rule.key.length > 16 = When we create a customized uuid it has a length greater than 16 chars so we
    // need to find it.
    const newRuleOnList = rules.find((rule) => rule.key.length > 16);
    if (newRuleOnList) {
      fetchRules(newRuleOnList.vehicleType || 'default');
    }
  }, [rules]);

  useEffect(() => {
    const options = concat(
      { key: 'default', name: 'Default RuleSet' },
      ...ruleSets.map(({ key, name }) => ({ key, name }))
    );
    setRuleSetOptions(options);
  }, [ruleSets]);

  useEffect(() => {
    setShowVCPerformancePoints(activeOu.showVCPerformancePoints);
  }, [activeOu.showVCPerformancePoints]);

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          icon="edit"
          onClick={() => {
            openRuleDetail({
              ruleSetId: data.vehicleType || 'default',
              ruleId: data.key,
              mode: 'edit',
            });
          }}
          disabled={data.key.length > 16} // we need to check the key to see if it is custom > 16 or server created.
        />
        <Button
          icon="delete"
          onClick={() => {
            setTargetRow(data);
            setCurrentDialog('delete');
          }}
          disabled={data.key.length > 16} // we need to check the key to see if it is custom > 16 or server created.
        />
      </>
    ),
    PointsPerMinute: ({ data }) => `${round(data.pointsPerSecond * 60, 1)} ppm`,
  });

  const dataGridController = useDataGridController({ gridOptions, columnDefs });

  const getRuleSetNameById = (ruleSetId) =>
    (ruleSets.find((item) => item.key === ruleSetId) || {}).name || 'default';

  const getVehicleTypeNameByKey = (key) =>
    (vehicleTypes.find((vt) => vt.key === key) || {}).name || '';

  const form = useFormController({
    controls: {
      ...targetRow,
      ...(targetRow &&
        targetRow.pointsPerSecond && { pointsPerSecond: round(targetRow.pointsPerSecond * 60, 1) }),
    },
  });

  const { controls, resetControls, hasErrors } = form;

  const createNewRuleset = () => {
    if (!controls.typeFrom) {
      setRuleSet(controls.typeTo);
      fetchRules(controls.typeTo);
      setCurrentDialog('addMultipleRules');
      return;
    }
    createRuleSet({
      ...controls,
      ...(controls.typeFrom === 'default' && { typeFrom: null }),
      ...(controls.typeTo && {
        name: (vehicleTypes.find((vt) => vt.key === controls.typeTo) || {}).name,
      }),
    });
    setCurrentDialog(null);
  };

  return (
    <div className="PerformanceRuleSummary">
      <Feature.Header onClose={route.close} title="Performance Rules" service="rules">
        <Button
          icon="share"
          variant="contained"
          label="Configuration"
          disabled={activeOu.parent !== null}
          onClick={() => {
            setTargetRow({});
            setCurrentDialog('changeConfiguration');
          }}
        />
        {rules && rules.length === 0 && (
          <Button
            icon="addchart"
            variant="contained"
            label="Create System Rules"
            onClick={() => {
              setTargetRow({});
              setCurrentDialog('addMultipleRules');
            }}
          />
        )}
        <Button
          icon="add"
          variant="contained"
          label="Create a New Rule"
          onClick={() => openRuleCreate({ ruleSetId: ruleSet })}
        />
      </Feature.Header>
      <Feature.Body>
        <AdminSearchPanel
          searchPlaceHolder="Search Performance Rules"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
          renderPanelActions={
            <>
              <span className="AdminSearchPanel__span">RuleSet: </span>
              <SelectInput
                className="AdminSearchPanel__SelectInput--aligned"
                value={ruleSet}
                options={objectsToSelectOptions(ruleSetOptions, 'name', 'key') || []}
                onChange={(value) => {
                  setRuleSet(value);
                  fetchRules(value);
                }}
              />
              <Button
                icon="add"
                variant="contained"
                onClick={() => {
                  setTargetRow({});
                  setCurrentDialog('createRuleSet');
                }}
              />
              <Button
                icon="delete"
                variant="contained"
                onClick={() => {
                  setTargetRow({});
                  setCurrentDialog('deleteRuleSet');
                }}
                disabled={ruleSet === 'default'}
              />
            </>
          }
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rowIdProperty="key"
          rows={rules}
          searchText={debouncedSearchText}
          sizeColumnsToFit
        />
      </Feature.Body>
      <DeleteAdminItemDialog
        dialogTitle="Rule"
        itemTitle={targetRow.description}
        isOpen={currentDialog === 'delete'}
        onClose={() => setCurrentDialog(null)}
        onConfirm={() => deleteRule({ key: targetRow.key })}
        rowData={targetRow}
      />
      <BasicDialog
        title="Please confirm"
        isOpen={currentDialog === 'deleteRuleSet'}
        onClose={() => setCurrentDialog(null)}
        buttons={[
          { label: 'Cancel', onClick: () => setCurrentDialog(null) },
          {
            label: 'Delete',
            onClick: () => {
              deleteRuleSet({ vehicleType: ruleSet });
              setRuleSet('default');
              fetchRules('default');
              setCurrentDialog(null);
            },
            disabled: hasErrors,
          },
        ]}
      >
        <span>Are you sure you want to delete {getRuleSetNameById(ruleSet)}?</span>
      </BasicDialog>
      <BasicDialog
        title="Create New RuleSet"
        isOpen={currentDialog === 'createRuleSet'}
        onOpen={resetControls}
        onClose={() => setCurrentDialog(null)}
        buttons={[
          { label: 'Cancel', onClick: () => setCurrentDialog(null) },
          {
            label: 'Create',
            onClick: () => createNewRuleset(),
            disabled: hasErrors,
          },
        ]}
      >
        <Form form={form} className="PerformanceRuleSummary__BasicDialog__Form--inline">
          <Control
            Component={SelectInput}
            name="typeTo"
            label="Vehicle Type"
            options={objectsToSelectOptions(vehicleTypes, 'name', 'key') || []}
            error={({ value }) => !value}
          />
          <Control
            Component={SelectInput}
            name="typeFrom"
            label="Copy From"
            options={objectsToSelectOptions(ruleSetOptions, 'name', 'key') || []}
          />
        </Form>
      </BasicDialog>
      <BasicDialog
        className="PerformanceRuleSummary__BasicDialog"
        title="Performance Configuration"
        isOpen={currentDialog === 'changeConfiguration'}
        onOpen={resetControls}
        onClose={() => setCurrentDialog(null)}
        buttons={[
          { label: 'Cancel', onClick: () => setCurrentDialog(null) },
          {
            label: 'Save',
            onClick: () => {
              updateRuleConfiguration({ ...controls, ouKey: activeOu.key });
              setShowVCPerformancePoints(controls.value);
              setCurrentDialog(null);
            },
            disabled: hasErrors,
          },
        ]}
      >
        <p>Applies to all tablets under {activeOu.name} and its sub organizations.</p>
        <Form form={form} className="PerformanceRuleSummary__BasicDialog__Form">
          <Control
            className="PerformanceRuleSummary__BasicDialog__Form__SelectInput--fullWidth"
            Component={SelectInput}
            name="value"
            label="Show performance points on tablet dashboard"
            options={[
              { label: 'On', value: true },
              { label: 'Off', value: false },
            ]}
            defaultValue={showVCPerformancePoints}
            error={({ value }) => isNil(value)}
          />
        </Form>
      </BasicDialog>
      <BasicDialog
        className="PerformanceRuleSummary__BasicDialog"
        title={`Add Default Rules for ${getVehicleTypeNameByKey(ruleSet)} ruleSet`}
        isOpen={currentDialog === 'addMultipleRules'}
        onOpen={resetControls}
        onClose={() => setCurrentDialog(null)}
        buttons={[
          {
            label: 'Select All',
            onClick: () => setAllSelectionValues(rulesPredefined, setSelectedRules, true),
          },
          {
            label: 'Deselect All',
            onClick: () => setAllSelectionValues(rulesPredefined, setSelectedRules, false),
          },
          {
            label: 'Cancel',
            onClick: () => {
              setAllSelectionValues(rulesPredefined, setSelectedRules, false);
              setCurrentDialog(null);
            },
          },
          {
            label: 'Add Rules',
            onClick: () => {
              createRules({
                rules: rulesPredefined
                  .filter(({ description }) => selectedRules[kebabCase(description)] === true)
                  .map((selectedRule) => ({
                    ...selectedRule,
                    vehicleType: ruleSet === 'default' ? null : ruleSet,
                  })),
              }).then(fetchRuleSets);

              setCurrentDialog(null);
            },
            disabled: hasErrors || Object.values(selectedRules).every((value) => value === false),
          },
        ]}
      >
        <p>You will be able to modify these rules after they have been created.</p>
        <div className="MuiDialogActions-root">
          <Button
            label="Select All"
            onClick={() => setAllSelectionValues(rulesPredefined, setSelectedRules, true)}
          />
          <Button
            label="Deselect All"
            onClick={() => setAllSelectionValues(rulesPredefined, setSelectedRules, false)}
          />
          <Button
            label="Cancel"
            onClick={() => {
              setAllSelectionValues(rulesPredefined, setSelectedRules, false);
              setCurrentDialog(null);
            }}
          />
          <Button
            label="Add Rules"
            disabled={hasErrors || Object.values(selectedRules).every((value) => value === false)}
            onClick={() => {
              createRules({
                rules: rulesPredefined
                  .filter(({ description }) => selectedRules[kebabCase(description)] === true)
                  .map((selectedRule) => ({
                    ...selectedRule,
                    vehicleType: ruleSet === 'default' ? null : ruleSet,
                  })),
              }).then(fetchRuleSets);
              setCurrentDialog(null);
            }}
          />
        </div>
        <table className="PerformanceRuleSummary__BasicDialog__Table">
          <thead>
            <tr>
              <th>#</th>
              <th>Description</th>
              <th>PPM</th>
            </tr>
          </thead>
          <tbody>
            {rulesPredefined.map((rule) => (
              <tr key={rule.description}>
                <td>
                  <ToggleInput
                    name={kebabCase(rule.description)}
                    checked={selectedRules[kebabCase(rule.description)]}
                    onChange={(event) => {
                      const clone = { ...selectedRules };
                      clone[kebabCase(rule.description)] = event;
                      setSelectedRules(clone);
                    }}
                  />
                </td>
                <td>{rule.description}</td>
                <td>{round(rule.pointsPerSecond * 60, 1)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </BasicDialog>
    </div>
  );
};

PerformanceRuleSummary.propTypes = {
  activeOu: PropTypes.object.isRequired,
  createRules: PropTypes.func.isRequired,
  createRuleSet: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
  deleteRuleSet: PropTypes.func.isRequired,
  fetchRules: PropTypes.func.isRequired,
  fetchRuleSets: PropTypes.func.isRequired,
  fetchRulesPredefined: PropTypes.func.isRequired,
  fetchVehicleTypes: PropTypes.func.isRequired,
  openRuleCreate: PropTypes.func.isRequired,
  openRuleDetail: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  rules: customPropTypes.performanceRules.isRequired,
  ruleSets: customPropTypes.performanceRuleSets.isRequired,
  rulesPredefined: PropTypes.any,
  updateRuleConfiguration: PropTypes.func.isRequired,
  vehicleTypes: customPropTypes.vehicleTypes.isRequired,
};
