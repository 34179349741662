import produce from 'immer';

import { actionTypeProgressOrSuccess, shallowMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchVideoEventTypes: 'reports/videoEvents/fetchVideoEventTypes',
  fetchVideoEventBehaviors: 'reports/videoEvents/fetchVideoEventBehaviors',
  fetchVideoEventFileLocation: 'reports/videoEvents/fetchVideoEventFileLocation',
};

const initialState = {
  types: [],
  behaviors: [],
  fileLocationsById: {},
};

export const videoEventsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    // types
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoEventTypes)) {
      draft.types = shallowMergeEntities(state.types, payload, 'key');
    }

    // behaviors
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoEventBehaviors)) {
      draft.behaviors = payload.sort();
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchVideoEventFileLocation)) {
      draft.fileLocationsById[payload.id] = payload;
    }
  });
