// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../img/navistream_logo_white.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppLogin{display:flex;flex-direction:column;align-items:center;justify-content:center;position:fixed;left:0;top:0;width:100vw;height:100vh;background:linear-gradient(to bottom right, #a14b28, #d06424)}.AppLogin header{width:300px;text-align:center;padding-bottom:30px}.AppLogin header .navbar-brand{display:block;width:100%;height:55px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;color:rgba(0,0,0,0)}.AppLogin .AppLogin_FormContainer{padding:20px;background-color:#fff;border:1px solid #ededed}.AppLogin .AppLogin_FormContainer .AppLogin_FormContainer__controls{display:inline-block;width:500px}.AppLogin .AppLogin_FormContainer .AppLogin_FormContainer__controls .TextInput{width:480px;padding:10px}.AppLogin .AppLogin_FormContainer .AppLogin_FormContainer__controls .ButtonContainer{margin-top:20px;display:grid;grid-template-columns:repeat(2, 1fr)}", "",{"version":3,"sources":["webpack://./src/components/commons/AppLogin/AppLogin.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,YAAA,CACA,6DAAA,CAEA,iBACE,WAAA,CACA,iBAAA,CACA,mBAAA,CAEA,+BACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,mEAAA,CACA,mBAAA,CAGJ,kCACE,YAAA,CACA,qBAAA,CACA,wBAAA,CAEA,oEACE,oBAAA,CACA,WAAA,CACA,+EACE,WAAA,CACA,YAAA,CAEF,qFACE,eAAA,CACA,YAAA,CACA,oCAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
