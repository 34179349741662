import { connect } from 'react-redux';
import {
  selectDriverLog,
  submitCreateLogDutyStatusEvent,
  submitUpdateLogDutyStatusEvent,
  submitReassignLogDutyStatusEvent,
  reviewViolation,
  addDriverLogComment,
  deleteDriverLogComment,
  fetchDriverLogOptimized,
  fetchDriverLogViolationOptimized,
} from '../../../../data/reports';
import { DriverLogDetailReport } from './DriverLogDetailReport';
import { selectSystemUser, selectIsUserDriver } from '../../../../data/system';

export const DriverLogDetailReportContainer = connect(
  (state, { driverKey, date }) => ({
    driverLog: selectDriverLog(state, { driverKey, date }),
    user: selectSystemUser(state),
    isDriver: selectIsUserDriver(state),
  }),
  {
    submitCreateLogDutyStatusEvent,
    submitUpdateLogDutyStatusEvent,
    submitReassignLogDutyStatusEvent,
    reviewViolation,
    addDriverLogComment,
    deleteDriverLogComment,
    fetchDriverLog: fetchDriverLogOptimized,
    fetchDriverLogViolation: fetchDriverLogViolationOptimized,
  }
)(DriverLogDetailReport);
