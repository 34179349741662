import React from 'react';
import _, { intersection, uniqBy, isNil, startCase, snakeCase } from 'lodash';

import { convertToParticipants } from './messages';
import { humanizeDate, deHumanizeDate, humanizeDateStatus } from './dates';

import {
  CATEGORIES,
  DISCONNECTED,
  LOADED,
  NA,
  NO_DRIVER,
  NO_TRAILERS_CONNECTED,
  NO_TRAILERS,
  NO_VEHICLE,
  NOT_AVAILABLE,
  STORAGE_KEYS,
  UNKNOWN_TRAILER,
  UNLOADED,
} from './constants';

const markerLabelStyle = {
  position: 'absolute',
  boxShadow: '0 0 6px #999',
  backgroundColor: 'rgba(255, 255, 255, .8)',
  left: 35,
  top: 25,
  padding: 2,
  whiteSpace: 'nowrap',
};

const gridStyle = { display: 'flex', flexDirection: 'row', gap: 8, marginBottom: 10 };

const secondaryColor = '#FFF';

function getMinutes(dateString) {
  const now = new Date();
  const ms = now.getTime() - new Date(dateString).getTime();
  return Math.floor(ms / 60000);
}

function getMarkerColorByMinutes(minutes, isLoggedIn) {
  if (minutes >= 0 && minutes < 30) {
    return '#0DAB0D';
  }

  if (minutes >= 30 && minutes < 60) {
    return '#AB830D';
  }

  if (minutes >= 60 && minutes < 1440 && isLoggedIn) {
    return '#AB0D0D';
  }

  return '#777777';
}

function setColorByCategoryAndDate({ date, driver, vehicle }) {
  const minutes = getMinutes(date);
  const isLoggedIn = driver && vehicle;

  return getMarkerColorByMinutes(minutes, isLoggedIn);
}

function getMarker(marker, selectedMarkerKey) {
  if (!marker || !marker.vehicle) return null;

  const isSelected = marker.key === selectedMarkerKey;
  const color = setColorByCategoryAndDate(marker);

  const useRadarEffectToSelectedMarker = localStorage
    .getItem(STORAGE_KEYS.settings)
    .includes('useRadarEffectToSelectedMarker');

  const width = !useRadarEffectToSelectedMarker && isSelected ? 45 : 35;
  const height = !useRadarEffectToSelectedMarker && isSelected ? 45 : 35;

  return (
    <svg
      className={useRadarEffectToSelectedMarker && isSelected ? 'is-selected' : undefined}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="-0.5 -0.25 25 25"
      preserveAspectRatio="xMidYMid meet"
      style={{
        transform: `translateY(20px) rotate(${marker.vehicle.heading || 0}deg)`,
        borderRadius: '50%',
      }}
      shapeRendering="geometricPrecision"
    >
      <rect
        x="10%"
        y="10%"
        width="70%"
        height="70%"
        fill={marker.vehicle.loaded ? color : secondaryColor}
        rx="50%" // rounded to cover only the 1st path
      />
      <path
        fill={marker.vehicle.loaded ? secondaryColor : color}
        d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z"
      />
      <path
        fill={marker.vehicle.loaded ? secondaryColor : color}
        d="m7.293 11.293 1.414 1.414L11 10.414V16h2v-5.586l2.293 2.293 1.414-1.414L12 6.586l-4.707 4.707z"
      />
    </svg>
  );
}

function getMarkerInfo({ category, ...rest }) {
  const { vehicle, trailers } = rest;

  const groupVehicleAndAttachedTrailers = localStorage
    .getItem(STORAGE_KEYS.settings)
    .includes('groupVehiclesAndAttachedTrailersMarkersForTrailerCategory');

  if (category === CATEGORIES.TRAILERS) {
    if (!groupVehicleAndAttachedTrailers) {
      return rest?.title;
    }

    return (
      vehicle?.connectedTrailersNames?.join(', ') ||
      trailers.map(({ vehicleId }) => vehicleId)?.join(', ')
    );
  }

  return vehicle?.vehicleId;
}

function getActiveStatus(dateString, category, hasEntityAssigned) {
  const minutes = getMinutes(dateString);

  if (category !== CATEGORIES.DRIVER) {
    return hasEntityAssigned ? minutes < 1440 : minutes < 60;
  }

  return minutes < 1440;
}

const createMapDataset = (
  { driverActivity, vehicleLocations, messages },
  ou,
  filteredOus,
  filteredAssetTypes,
  currentSystemUser,
  groupVehicleAndAttachedTrailers
) => {
  const users = driverActivity?.map(({ key, driverName }) => ({ key, fullName: driverName })) || [];

  const msgs = uniqBy(convertToParticipants(users, ou, messages), 'key')?.filter(
    ({ conversations }) => conversations && !conversations.hasBeenRead
  );

  const filteredData = {
    driverActivity: driverActivity.filter(
      ({ deleted, enabled, ous }) =>
        deleted !== true && enabled !== false && intersection(ous, filteredOus).length > 0
    ),
    vehicleLocations: vehicleLocations.filter(
      ({ deleted, enabled, trailerClass, ous, vehicleType }) =>
        deleted !== true &&
        enabled !== false &&
        trailerClass === false &&
        intersection(ous, filteredOus).length > 0 &&
        filteredAssetTypes.includes(vehicleType)
    ),
    trailerLocations: vehicleLocations.filter(
      ({ deleted, enabled, trailerClass, ous, vehicleType }) =>
        deleted !== true &&
        enabled !== false &&
        trailerClass === true &&
        intersection(ous, filteredOus).length > 0 &&
        filteredAssetTypes.includes(vehicleType)
    ),
  };

  // Drivers -------------------------------------------------------------------------------
  const drivers = filteredData.driverActivity.map((driver) => {
    const unreadThread = msgs.find(({ key }) => key === driver?.driverKey);

    const hasUnreadMessages = isNil(unreadThread?.conversations?.hasBeenRead)
      ? false
      : !unreadThread?.conversations?.hasBeenRead &&
        unreadThread?.conversations?.messages &&
        unreadThread?.conversations?.messages[0].author !== currentSystemUser.key;
    const unreadMessageKey = unreadThread?.conversations?.messages[0]?.key;

    const vehicleFound = filteredData.vehicleLocations.find(
      (item) => item.driver === driver?.driverKey
    );

    const assignedDate = new Date(
      new Date(vehicleFound?.date || driver.date) > new Date(driver.date)
        ? vehicleFound?.date
        : driver.date
    )
      .toISOString()
      .replace('Z', '+0000');

    return {
      api: {
        driverLog: driver.driverKey,
        asset: vehicleFound?.vehicle,
        unreadMessageKey,
      },
      category: CATEGORIES.DRIVER,
      date: assignedDate,
      driver: {
        ...driver,
        hasUnreadMessages,
        unreadMessageKey,
      },
      extra: vehicleFound?.vehicleId || NOT_AVAILABLE,
      isActive: getActiveStatus(assignedDate, CATEGORIES.DRIVER),
      key: driver.driverKey, // driver key here is the UID for google map marker
      lastSeen: humanizeDateStatus(driver.date),
      statusColor: setColorByCategoryAndDate({
        date: assignedDate,
        driver,
        vehicle: vehicleFound,
      }),
      title: driver.driverName,
      ...(vehicleFound && {
        vehicle: {
          ...vehicleFound,
          location: {
            lat: vehicleFound?.location?.latitude,
            lng: vehicleFound?.location?.longitude,
          },
          connectedTrailersNames: vehicleFound?.connectedTrailers.map(
            (key) =>
              filteredData.trailerLocations.find((trailer) => trailer.vehicle === key)?.vehicleId
          ),
        },
      }),
    };
  });

  // Vehicles -------------------------------------------------------------------------------
  const vehicles = filteredData.vehicleLocations.map((vehicle) => {
    const driverFound = filteredData.driverActivity.find(
      (item) => item.driverKey === vehicle.driver
    );

    const assignedDate = new Date(
      new Date(driverFound?.date || vehicle.date) > new Date(vehicle.date)
        ? driverFound?.date
        : vehicle.date
    )
      .toISOString()
      .replace('Z', '+0000');

    const connectedTrailersNames = vehicle.connectedTrailers.map(
      (key) => filteredData.trailerLocations.find((trailer) => trailer.vehicle === key)?.vehicleId
    );

    return {
      api: {
        driverLog: driverFound?.key,
        asset: vehicle.vehicle,
      },
      category: CATEGORIES.VEHICLE,
      date: assignedDate,
      ...(driverFound && { driver: { ...driverFound } }),
      extra:
        connectedTrailersNames?.length > 0
          ? connectedTrailersNames?.join(', ')
          : NO_TRAILERS_CONNECTED,
      isActive: getActiveStatus(assignedDate, CATEGORIES.DRIVER, driverFound),
      key: vehicle.vehicle, // vehicle key here is the UID for google map
      lastSeen: humanizeDateStatus(vehicle.date),
      statusColor: setColorByCategoryAndDate({
        date: assignedDate,
        driver: driverFound,
        vehicle,
      }),
      title: vehicle.vehicleId,
      vehicle: {
        ...vehicle,
        location: { lat: vehicle?.location?.latitude, lng: vehicle?.location?.longitude },
        connectedTrailersNames,
      },
    };
  });

  // Trailers -------------------------------------------------------------------------------
  const connectedTrailers = groupVehicleAndAttachedTrailers
    ? filteredData.vehicleLocations
        .filter((item) => item.connectedTrailers.length > 0)
        .map((vehicle) => {
          const trailersFound = vehicle.connectedTrailers
            .map((key) => filteredData.trailerLocations.find((trailer) => trailer.vehicle === key))
            .filter(Boolean);

          const driverFound = filteredData.driverActivity.find(
            (item) => item.driverKey === vehicle.driver
          );

          const assignedDate = new Date(
            new Date(driverFound?.date || vehicle.date) > new Date(vehicle.date)
              ? driverFound?.date
              : vehicle.date
          )
            .toISOString()
            .replace('Z', '+0000');

          const connectedTrailersNames = vehicle.connectedTrailers.map(
            (key) =>
              filteredData.trailerLocations.find((trailer) => trailer.vehicle === key)?.vehicleId ||
              UNKNOWN_TRAILER
          );

          return {
            api: {
              driverLog: driverFound?.key,
              asset: vehicle.vehicle,
            },
            category: CATEGORIES.TRAILERS,
            date: assignedDate,
            ...(driverFound && { driver: { ...driverFound } }),
            extra: startCase(snakeCase(vehicle.unhookReason || vehicle.vehicleId || DISCONNECTED)),
            isActive: getActiveStatus(assignedDate, CATEGORIES.DRIVER, driverFound),
            key: vehicle.connectedTrailers.join('-'), // vehicle connectedTrailers here is the UID for google map for CONNECTED trailers
            ...(trailersFound && {
              trailers: trailersFound.map((item) => ({
                ...item,
                connectedToName: vehicle?.vehicleId,
                location: item.location.latitude
                  ? { lat: item?.location?.latitude, lng: vehicle?.location?.longitude }
                  : null,
              })),
            }),
            lastSeen: humanizeDateStatus(vehicle.date),
            statusColor: setColorByCategoryAndDate({
              date: assignedDate,
              driver: driverFound,
              vehicle,
            }),
            title: connectedTrailersNames.join(', '),
            vehicle: {
              ...vehicle,
              location: vehicle?.location?.latitude
                ? { lat: vehicle?.location?.latitude, lng: vehicle?.location?.longitude }
                : null,
              connectedTrailersNames,
            },
          };
        })
    : [];

  const alreadyMappedTrailers = [
    ...new Set(connectedTrailers.flatMap((item) => item.key.split('-'))),
  ];

  const disconnectedTrailers = filteredData.trailerLocations
    .filter((trailer) => !alreadyMappedTrailers.includes(trailer.vehicle))
    .map((trailer) => {
      const driverFound = filteredData.driverActivity.find(
        (item) => item.driverKey === trailer.driver
      );

      const vehicleAssigned = filteredData.vehicleLocations.find(
        (item) => item.vehicle === trailer.connectedTo
      );

      const vehicleFound = vehicleAssigned
        ? {
            ...vehicleAssigned,
            location: {
              lat: vehicleAssigned.location?.latitude,
              lng: vehicleAssigned.location?.longitude,
            },
            connectedTrailersNames: vehicleAssigned?.connectedTrailers.map(
              (key) => filteredData.trailerLocations.find((item) => item.vehicle === key)?.vehicleId
            ),
          }
        : {
            vehicleId: NO_VEHICLE,
            heading: trailer?.heading,
            location: trailer?.location?.latitude
              ? { lat: trailer?.location?.latitude, lng: trailer?.location?.longitude }
              : null,
            camera: trailer?.camera,
          };

      const assignedDate = new Date(
        new Date(driverFound?.date || trailer.date) > new Date(trailer.date)
          ? driverFound?.date
          : trailer.date
      )
        .toISOString()
        .replace('Z', '+0000');

      const connectedToName =
        vehicleFound?.vehicleId === NO_VEHICLE ? DISCONNECTED : vehicleFound?.vehicleId;

      return {
        api: {
          driverLog: driverFound?.key,
          asset: trailer.vehicle,
        },
        category: CATEGORIES.TRAILERS,
        date: assignedDate,
        ...(driverFound && { driver: { ...driverFound } }),
        extra: startCase(snakeCase(trailer.unhookReason || connectedToName)),
        isActive: getActiveStatus(assignedDate, CATEGORIES.DRIVER, driverFound),
        key: trailer.vehicle, // trailer key here is the UID for google map
        lastSeen: humanizeDateStatus(trailer.date),
        statusColor: setColorByCategoryAndDate({
          date: assignedDate,
          driver: driverFound,
          vehicle: vehicleFound,
        }),
        title: trailer.vehicleId,
        ...(vehicleFound && {
          vehicle: vehicleFound,
          trailers: [
            {
              ...trailer,
              location: trailer?.location?.latitude
                ? { lat: trailer?.location?.latitude, lng: trailer?.location?.longitude }
                : null,
              connectedToName,
            },
          ],
        }),
      };
    });

  const uniqDrivers = _.chain(drivers).sortBy('date').reverse().uniqBy('key').value();
  const uniqVehicles = _.chain(vehicles).sortBy('date').reverse().uniqBy('key').value();
  const uniqConnectedTrailers = _.chain(connectedTrailers)
    .sortBy('date')
    .reverse()
    .uniqBy('key')
    .value();

  const uniqDisconnectedTrailers = _.chain(disconnectedTrailers)
    .sortBy('date')
    .reverse()
    .uniqBy('key')
    .value();

  return [...uniqDrivers, ...uniqVehicles, ...uniqConnectedTrailers, ...uniqDisconnectedTrailers]
    .filter(Boolean)
    .sort((a, b) => {
      if (a.driver?.hasUnreadMessages === b.driver?.hasUnreadMessages) {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      }
      return a.driver?.hasUnreadMessages ? -1 : 1;
    });
};

const markerFormats = {
  getDriverName: ({ driver }) => driver?.driverName || NO_DRIVER,
  getDutyStatus: ({ driver }) => driver?.dutyStatusDescription || NA,
  getVehicleName: ({ vehicle }) =>
    vehicle?.vehicleId ? (
      <>
        <b>{vehicle?.vehicleId}</b>
        <span>{vehicle?.loaded ? `(${LOADED})` : `(${UNLOADED})`}</span>
      </>
    ) : (
      NO_VEHICLE
    ),
  getTrailerNames: ({ vehicle, category, title }) => {
    if (category === CATEGORIES.TRAILERS) {
      return vehicle?.connectedTrailersNames?.join(', ') || title;
    }

    return vehicle?.connectedTrailersNames?.join(', ') || NO_TRAILERS;
  },
  getSpeedValue: ({ vehicle }, unitSystem) => {
    if (!vehicle?.speed) return `0 ${unitSystem?.toLowerCase() === 'imperial' ? 'mph' : 'km/h'}`;

    const speedInMps = vehicle.speed;
    const speedValue =
      unitSystem === 'imperial'
        ? (speedInMps * 3.6) / 1.609 // Convert m/s to mph
        : speedInMps * 3.6; // Convert m/s to km/h

    return `${speedValue.toFixed(0)} ${unitSystem?.toLowerCase() === 'imperial' ? 'mph' : 'km/h'}`;
  },
  getCameraInfo: ({ vehicle }) => (vehicle?.camera?.provider ? 'Navistream' : 'No camera'),
  getHumanizedDate: ({ date }) => humanizeDate(date),
  getNonHumanizedDate: ({ date }) => deHumanizeDate(date),
};

export {
  createMapDataset,
  markerFormats,
  getMarker,
  getMarkerInfo,
  gridStyle,
  markerLabelStyle,
  setColorByCategoryAndDate,
};
