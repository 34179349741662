import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { orderBy, find } from 'lodash';

const extractId = (_, id) => id;
const getFuelConsumptions = (state) => state.reports.fuelConsumptions.fuelConsumptions;
const getOus = (state) => state.system.ous.data;

const getTimeZone = (fuelConsumption, ous) => {
  if (!fuelConsumption) return '';
  if (fuelConsumption.timeZone) return fuelConsumption.timeZone;
  return ous && (ous.find((ou) => ou.key === fuelConsumption.ouKey) || {}).timeZone;
};

/**
 * selectFuelConsumptions(state) -> [fuelConsumptions]
 *
 * Selector for all fuelConsumptions
 */
export const selectFuelConsumptions = createSelector(
  [getFuelConsumptions, getOus],
  (fuelConsumptions, ous) =>
    orderBy(
      fuelConsumptions.map((fuelConsumption) => ({
        ...fuelConsumption,
        timeZone: getTimeZone(fuelConsumption, ous),
      })),
      [(row) => new Date(row.endedAt), 'vehicle.name'],
      ['asc', 'desc']
    )
);

/**
 * selectFuelConsumptionsByKey(state, { id }) -> fuelConsumption
 *
 * Selector for single fuel consumption by id
 */
export const selectFuelConsumptionsById = createCachedSelector(
  [selectFuelConsumptions, extractId],
  (fuelConsumptions, id) => find(fuelConsumptions, { id })
)(extractId);
