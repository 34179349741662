import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ExceptionDetailReport } from './ExceptionDetailReport';
import { breadcrumbs, exceptions, trips, videoEvents } from '../../../../data/reports';

export const ExceptionDetailReportContainer = connect(
  (state, { exceptionType, tripId, exceptionId }) => ({
    exception: exceptions.selectors.selectUnitedException(state, {
      exceptionType,
      tripId,
      exceptionId,
    }),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
  }),
  {
    createAnnotation: exceptions.actions.createAnnotation,
    updateAnnotation: exceptions.actions.updateAnnotation,
    fetchTrip: trips.actions.fetchTrip,
    fetchException: exceptions.actions.fetchException,
    fetchAnnotations: exceptions.actions.fetchAnnotations,
    fetchVideoEventFileLocation: videoEvents.actions.fetchVideoEventFileLocation,
  }
)(ExceptionDetailReport);

ExceptionDetailReportContainer.propTypes = {
  exceptionType: PropTypes.string.isRequired,
  tripId: PropTypes.string,
  exceptionId: PropTypes.string,
};
