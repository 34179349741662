import { isEmpty } from 'lodash';

export const setFilterByCategory = (row, category, search, showInactive) => {
  if (category === 'driver') {
    return !isEmpty(search)
      ? row.driver?.driverName?.toLowerCase().includes(search?.toLowerCase()) &&
          row.category === 'driver' &&
          (showInactive ? true : row.isActive === true)
      : row.category === 'driver' && (showInactive ? true : row.isActive === true);
  }

  if (category === 'vehicle') {
    return !isEmpty(search)
      ? row?.vehicle?.vehicleId?.toLowerCase().includes(search.toLowerCase()) &&
          row.category === 'vehicle' &&
          (showInactive ? true : row.isActive === true)
      : row.category === 'vehicle' && (showInactive ? true : row.isActive === true);
  }

  const trailerNames = row?.trailers?.map((trailer) => trailer?.vehicleId.toLowerCase());

  return !isEmpty(search)
    ? trailerNames?.some((trailerName) => trailerName.includes(search.toLowerCase())) &&
        row.category === 'trailer' &&
        (showInactive ? true : row.isActive === true)
    : row.category === 'trailer' && (showInactive ? true : row.isActive === true);
};
