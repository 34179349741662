import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput, Form, Icon, Clickable, ProgressOverlay } from 'stti-react-common';
import { isEmpty } from 'lodash';

import { CONSTANTS } from '../../../../config/constants';

import './AppLogin.scss';

function sanitizeInputs(input) {
  if (typeof input === 'object') {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [key, sanitizeInputs(value)])
    );
  }

  return input ? input.replace(/<script.*>/g, '') : '';
}

export const AppLogin = ({ signIn, user, route, setMenu, ous }) => {
  const [visibility, setVisibility] = useState(false);
  const { Control, useFormController } = Form;
  const form = useFormController({
    controls: { username: '', password: '' },
  });

  const { controls: formControls, isDirty, hasErrors } = form;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (route) {
      route.authenticate(user, setMenu, ous);
    } else {
      let timerId;
      if (isEmpty(user)) {
        timerId = setTimeout(() => {
          window.location.href = '#/login';
        }, 5000);
      }
      return () => clearTimeout(timerId);
    }
  }, [user, setMenu, ous, route]);

  return isEmpty(route) ? (
    <>
      <h3>Checking user authentication and loading page, please wait...</h3>
      <ProgressOverlay isOpen />
    </>
  ) : (
    <div className="AppLogin">
      <div className="AppLogin__container">
        <header>
          <a className="navbar-brand" href="/#">
            Navistream
          </a>
        </header>
        <div className="form">
          <Form form={form}>
            <div className="form__controls">
              <Control
                Component={TextInput}
                name="username"
                label="Username"
                error={({ value }) => isDirty && !value}
                inputProps={{
                  'data-test': 'username',
                  name: 'username',
                }}
                autoComplete="on"
                autoFocus
              />
              <Control
                Component={TextInput}
                name="password"
                defaultValue=""
                type={visibility ? 'text' : 'password'}
                label="Password"
                error={({ value }) => (isDirty && !value) || (isDirty && !value.trim())}
                endAdornment={
                  <Clickable onClick={() => setVisibility(!visibility)}>
                    <Icon icon={visibility ? 'visibility' : 'visibility_off'} />
                  </Clickable>
                }
                onKeyPress={(e) =>
                  e.key === 'Enter' &&
                  !hasErrors &&
                  isDirty &&
                  signIn({ provider: 'navistream', ...sanitizeInputs(formControls) })
                }
                inputProps={{
                  'data-test': 'password',
                }}
              />
              <div className="ButtonContainer">
                <Button
                  icon="cloud"
                  variant="contained"
                  label="Sign in with Google"
                  tooltip="If Google Sign-in service is used, the username must match the email address on Google's linked account."
                  color="primary"
                  onClick={() => {
                    window.location.href = CONSTANTS.googleSignInLink;
                  }}
                  data-test="signinGoogle"
                />
                <Button
                  icon="login"
                  variant="contained"
                  label="Sign in"
                  color="secondary"
                  disabled={!isDirty || hasErrors}
                  onClick={() =>
                    signIn({ provider: 'navistream', ...sanitizeInputs(formControls) })
                  }
                  data-test="signin"
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

AppLogin.propTypes = {
  signIn: PropTypes.func.isRequired,
  user: PropTypes.object,
  route: PropTypes.object,
  setMenu: PropTypes.func,
  ous: PropTypes.array,
};
