import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from 'stti-react-common';
import { includes, isEmpty } from 'lodash';
import ReactGA from 'react-ga';

import { administrationRoutes } from '../AppRouter/adminRoutes';
import { AppConfig } from '../AppConfig';
import { AppHeader } from '../AppHeader';
import { AppLogin } from '../AppLogin';
import { AppRouter, filterRoutesByPermissions } from '../AppRouter';
import { AppStyle } from '../AppStyle';
import { DataProvider } from '../DataProvider';
import { fleetTrackRoutes } from '../AppRouter/fleetTrackRoutes';
import { FormatsProvider } from '../Formats';
import { getRolesByUserMemberships } from '../../../helpers/roles';
import { Interval } from '../AppInterval';
import { Notifier } from '../Notifier';
import { PrintSupport } from '../PrintSupport';
import { reportRoutes } from '../AppRouter/reportRoutes';
import { RestApi as Api } from '../../../data/api';
import { Sidebar } from '../Sidebar';
import { systemRoutes } from '../AppRouter/systemRoutes';

import { CONSTANTS } from '../../../../config/constants';

import '../../../normalize.scss';
import './AppRoot.scss';

const NotAuthenticated = () => <AppLogin />;
const RouteNotFound = () => <div>Route not found.</div>;

export const AppRoot = ({ menu, user, tenant, activeOu, ous, accessToken }) => {
  const { category: menuCategory, option: menuOption } = menu;

  const availableRoutes = useMemo(() => {
    const allowedRoutes = [
      ...administrationRoutes.map((route) => ({ ...route, category: 'administration' })),
      ...reportRoutes.map((route) => ({ ...route, category: 'reports' })),
      ...fleetTrackRoutes.map((route) => ({ ...route, category: 'reports' })),
    ];

    return filterRoutesByPermissions(user, tenant, ous, activeOu, allowedRoutes);
  }, [menu, user, ous, tenant]);

  const appConfig = useMemo(
    () => ({
      user,
      tenant,
      activeOu,
      ous,
    }),
    [user, tenant, activeOu, ous]
  );

  const api = useMemo(
    () =>
      new Api({
        accessToken,
        baseUrl: CONSTANTS.baseUrl,
        legacyBaseUrl: CONSTANTS.legacyBaseUrl,
        originUrl: CONSTANTS.originUrl,
      }),
    [accessToken]
  );

  const tabs = useMemo(() => {
    const userRoles = getRolesByUserMemberships(user, ous);

    if (userRoles.isSuperAdmin) {
      return [
        { icon: 'public', name: 'Fleet Tracking', key: 'fleetTracking' },
        { icon: 'summarize', name: 'Reports', key: 'reports' },
        { icon: 'build', name: 'Administration', key: 'administration' },
      ];
    }

    if (userRoles.isAdminRole) {
      return [{ icon: 'build', name: 'Administration', key: 'administration' }];
    }

    if (userRoles.isDispatcher || userRoles.isManager || userRoles.isMechanic) {
      return [
        { icon: 'public', name: 'Fleet Tracking', key: 'fleetTracking' },
        { icon: 'summarize', name: 'Reports', key: 'reports' },
      ];
    }

    if (userRoles.isDriver) {
      return [{ icon: 'summarize', name: 'Reports', key: 'reports' }];
    }

    if (userRoles.isGuest) {
      return [{ icon: 'public', name: 'Fleet Tracking', key: 'fleetTracking' }];
    }
    return [];
  }, [user]);

  const groupCategory = useMemo(
    () =>
      menuCategory === 'fleetTracking'
        ? 'fleetTracking'
        : availableRoutes.find(({ path }) => includes(path, menuCategory))?.category,
    [menuCategory, availableRoutes]
  );

  const sideBarRoutes = useMemo(() => {
    const allowedCategories = ['administration', 'reports', 'fleetTracking', 'system'];

    if (allowedCategories.includes(menuCategory)) {
      return availableRoutes.filter(({ category }) => category === menuCategory);
    }

    return availableRoutes.filter(({ category }) => category === groupCategory);
  }, [menuCategory, availableRoutes, groupCategory]);

  const Fallback = isEmpty(user) ? NotAuthenticated : RouteNotFound;

  ReactGA.initialize('UA-46304298-4');

  return (
    <div className="AppRoot">
      <AppStyle fonts={['roboto']} />
      <GoogleMap.ScriptLoader apiKey={CONSTANTS.googleMapsApiKey} />
      <DataProvider api={api}>
        <AppConfig {...appConfig} />
        <FormatsProvider>
          <AppHeader tabs={tabs} tenant={tenant} ous={ous} activeOu={activeOu} />
          <Interval />
          <div className="AppRoot__Content">
            {menuCategory !== 'fleetTracking' && (
              <Sidebar
                user={user}
                menuCategory={groupCategory}
                menuOption={menuOption}
                availableRoutes={sideBarRoutes.concat(systemRoutes)}
              />
            )}
            <AppRouter
              Fallback={Fallback}
              availableRoutes={availableRoutes.concat(systemRoutes)}
              menuCategory={groupCategory}
            />
            <PrintSupport />
            <Notifier />
          </div>
        </FormatsProvider>
      </DataProvider>
    </div>
  );
};

AppRoot.propTypes = {
  menu: PropTypes.shape({
    category: PropTypes.string,
    option: PropTypes.string,
  }),
  user: PropTypes.object,
  tenant: PropTypes.object,
  activeOu: PropTypes.object,
  ous: PropTypes.array,
  accessToken: PropTypes.string,
  setMenu: PropTypes.func,
};
