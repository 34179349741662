import {
  get,
  find,
  filter,
  isUndefined,
  groupBy,
  sortBy,
  forEach,
  isNull,
  sumBy,
  isEmpty,
  uniqueId,
} from 'lodash';

import { dateObject } from '../../../helpers/moment';
import { omitPrivateProperties } from '../../apiActionSupport';

const getExcessiveRPMSum = (aggregation) => ({
  count: {
    harsh: get(aggregation, 'RPM.count.harsh', 0) + get(aggregation, 'Holdback.count.harsh', 0),
  },
  points: {
    harsh: get(aggregation, 'RPM.points.harsh', 0) + get(aggregation, 'Holdback.points.harsh', 0),
  },
  distance: {
    harsh:
      get(aggregation, 'RPM.distance.harsh', 0) + get(aggregation, 'Holdback.distance.harsh', 0),
  },
  duration: {
    harsh:
      get(aggregation, 'RPM.duration.harsh', 0) + get(aggregation, 'Holdback.duration.harsh', 0),
  },
});

const getTypeOrGroup = ({ ruleGroup, rule }, rules) => {
  if (ruleGroup) {
    return ruleGroup;
  }
  const ruleByKey = find(rules, { key: rule });
  return ruleByKey.type;
};

const getField = ({ ruleGroup, rule }, rules) => {
  if (ruleGroup) {
    return ruleGroup === 'Idling' ? 'normal' : 'harsh';
  }
  const ruleByKey = find(rules, { key: rule });
  return ruleByKey.harsh ? 'harsh' : 'normal';
};

const getAggregationData = (incidents, rules) =>
  incidents.reduce((acc, item) => {
    const aggregation = { ...acc };
    const ruleTypeOrGroup = getTypeOrGroup(item, rules);
    const field = getField(item, rules);

    if (isUndefined(aggregation[ruleTypeOrGroup])) {
      aggregation[ruleTypeOrGroup] = {
        count: {
          normal: 0,
          harsh: 0,
          total: 0,
        },
        points: {
          normal: 0,
          harsh: 0,
          total: 0,
        },
        duration: {
          normal: 0,
          harsh: 0,
          total: 0,
        },
        distance: {
          normal: 0,
          harsh: 0,
          total: 0,
        },
      };
    }
    aggregation[ruleTypeOrGroup].count[field] += item.count;
    aggregation[ruleTypeOrGroup].points[field] += item.points;
    aggregation[ruleTypeOrGroup].duration[field] += item.duration;
    aggregation[ruleTypeOrGroup].distance[field] += item.distance;

    aggregation[ruleTypeOrGroup].count.total += item.count;
    aggregation[ruleTypeOrGroup].points.total += item.points;
    aggregation[ruleTypeOrGroup].duration.total += item.duration;
    aggregation[ruleTypeOrGroup].distance.total += item.distance;
    return aggregation;
  }, {});

const getRangeData = (rules, incidents, type) => {
  const ranges = [];
  const rulesByType = filter(rules, { type, isDeleted: false });

  const boundType = type === 'Brake' ? 'Speed' : type;

  forEach(rulesByType, (rule) => {
    const bounds = find(rule.conditions, { type: boundType });
    let ruleRange = find(ranges, { bounds });

    if (isUndefined(ruleRange)) {
      ruleRange = {
        bounds: isUndefined(bounds) ? {} : bounds,
        count: 0,
        duration: 0,
        distance: 0,
        points: 0,
      };
      ranges.push(ruleRange);
    }

    ruleRange.count += get(incidents[rule.key], 'length', 0);
    ruleRange.duration += sumBy(incidents[rule.key], 'duration');
    ruleRange.distance += sumBy(incidents[rule.key], 'distance');
    ruleRange.points += sumBy(incidents[rule.key], 'points');
  });

  /** Display as 90+ instead of null/undefined * */
  forEach(ranges, (range) => {
    if (range && range.bounds) {
      if (isNull(range.bounds, 'upperBound') || range.bounds.upperBound === 0) {
        // eslint-disable-next-line no-param-reassign
        range.bounds.upperBound = '+';
      }
    }
  });

  return {
    incidentCount: sumBy(ranges, 'count'),
    totalDuration: sumBy(ranges, 'duration'),
    data: sortBy(
      filter(ranges, (range) => !isEmpty(range.bounds)),
      ['bounds.lowerBound']
    ).reverse(),
  };
};

export const transformPerformancesForStore = (
  responseData,
  { ouKey, timeZone, myPerformancesOnly, driverKey, ous, ouName }
) => {
  const [first] = responseData;
  const { shifts } = first;
  const performances = [...shifts];

  const storedPerformances = performances.map((performance) => {
    const mappedPerformance = { ...omitPrivateProperties(performance) };
    if (mappedPerformance.shift) {
      mappedPerformance.shift = { ...omitPrivateProperties(mappedPerformance.shift) };
      mappedPerformance.shift.duration = dateObject(performance.shift.end).diff(
        performance.shift.start,
        'seconds'
      );
    }

    if (mappedPerformance.incidents) {
      mappedPerformance.incidents = mappedPerformance.incidents.map((incident) =>
        omitPrivateProperties(incident)
      );
    }

    const driverOuKey =
      performance.shift && performance.shift.driverOuKeys && performance.shift.driverOuKeys[0];

    const ouInfo = ous && (ous.find(({ key }) => key === String(driverOuKey)) || {});

    mappedPerformance.id = performance.shift.key;
    mappedPerformance.key = performance.shift.key.substr(-12);
    mappedPerformance.ouKey = ouKey || String(driverOuKey);
    mappedPerformance.startedAt = dateObject(performance.shift.start, timeZone).toISOString();
    mappedPerformance.endedAt = dateObject(performance.shift.end, timeZone).toISOString();
    mappedPerformance.timeZone = timeZone || ouInfo.timeZone;
    mappedPerformance.score = undefined;

    if (mappedPerformance.engineTime > 0) {
      mappedPerformance.score =
        mappedPerformance.pointsAccumulated / (mappedPerformance.engineTime / 3600);
    }

    mappedPerformance.ouName = ouName || ouInfo.name;

    return mappedPerformance;
  });

  return myPerformancesOnly
    ? storedPerformances.filter(({ driver }) => driver === driverKey)
    : storedPerformances;
};

export const transformPerformanceDetailForStore = (responseData, id) => {
  const ouKeyMatch = id.match(/ouKey=(\d+)/i)[1];
  const shiftIdMatch = id.match(/shiftId=([\w-]+)/i)[1];

  const ouKey = ouKeyMatch || null;
  const paramShiftId = shiftIdMatch || null;

  const [first] = responseData; // we receive an array with only one position, that is why we get the 1st position
  const { shifts, rules } = first;

  const getFilter = ({ shift: { key } }) => key === paramShiftId;
  const performance = shifts.find(getFilter) || shifts[0]; // we will return the 1st position if filter fails

  const incidents = groupBy(performance.incidents, 'rule');

  const results = sortBy(
    rules
      .map((detail) => {
        const mappedDetail = { ...omitPrivateProperties(detail) };
        const data = { ...incidents[detail.key] }[0];
        mappedDetail.id = `${uniqueId()}-${detail.key.toString()}`;
        mappedDetail.key = detail.key.toString();
        mappedDetail.ouKey = ouKey;
        mappedDetail.driverName = performance.driverName;
        mappedDetail.data = data;
        mappedDetail.vehicleName = performance.vehicleName;
        if (data && data.points) {
          mappedDetail.data.points = parseFloat(data.points.toFixed(2));
        }
        mappedDetail.totals = {
          distanceDriven: performance.distanceDriven,
          shiftDuration:
            performance.shift && performance.shift.end
              ? dateObject(performance.shift.end).diff(performance.shift.start, 'seconds')
              : 0,
          engineTime: performance.engineTime,
          engineIdleTime: performance.idleTime,
          pointsAccumulated: performance.pointsAccumulated,
        };
        mappedDetail.aggregation = performance.incidents
          ? getAggregationData(performance.incidents, rules)
          : {};
        mappedDetail.aggregation.exRPM = mappedDetail.aggregation
          ? getExcessiveRPMSum(mappedDetail.aggregation)
          : {};
        mappedDetail.rangeData = {
          Speed: getRangeData(rules, incidents, 'Speed'),
          Brake: getRangeData(rules, incidents, 'Brake'),
          RPM: getRangeData(rules, incidents, 'RPM'),
        };
        mappedDetail.startedAt = performance.shift.start;
        mappedDetail.endedAt = performance.shift.end;

        return mappedDetail;
      })
      .filter(
        (detail) => detail.data || dateObject(detail.createdAt).isBefore(performance.shift.end)
      ),
    ['type', 'description']
  );

  return results;
};
