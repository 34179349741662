import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Clickable, BasicDialog } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../commons/PrintLayout';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { daysBetweenDates } from '../../../../helpers/moment';

import { FiltersPanel } from './FiltersPanel';
import { AggregatesPanel } from './AggregatesPanel';
import { AggregatesContent } from './AggregatesContent';

import {
  ControlsTablePrint,
  DateRangeAnalytics,
  DetailReportPrintingLinks,
  fetchReportDataOnDemand,
  LaunchReport,
  QueryPanel,
  ReportFragment,
  ReportLoader,
  ReportProvider,
  SectionPrint,
  SignalWebclientViewReady,
  useReportController,
} from '../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './UnidentifiedLogsSummaryReport.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: {
    dateRangeMode: 'last14Days',
    hasActivity: true,
  },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['unidentifiedLogs'];

export const UnidentifiedLogsSummaryReport = ({
  fetchUnidentifiedLogs,
  limits,
  logs,
  ous,
  reportViewId,
  route,
  unidentifiedLogDetailReportRoute,
}) => {
  // INITIALIZE REPORT
  const [isOpen, setIsOpen] = useState(false);

  const report = useReportController({
    reportType: 'unidentifiedLogsSummary',
    reportTypeName: 'Unidentified Driver Log Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: logs,
      chain: ['query', 'filters'],
    },
  });
  const { debouncedControls, setControl, filteredRows, dataGridController, reportType } = report;

  const { ouKeys, startDate, endDate, ouMode, ousSelected, hasActivity } = debouncedControls;

  // GRID CELL COMPONENTS

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    OpenDetail: ({
      data: {
        vehicle: { key: vehicleKey },
        logDate: date,
      },
    }) => (
      <LaunchReport
        onClick={() =>
          unidentifiedLogDetailReportRoute.open({ vehicleKey, date, reportViewId: 'default' })
        }
      />
    ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleName', [data.vehicle.key])}>{value}</Clickable>
    ),
    Activity: {
      renderer: ({ value: { value, label } }) => (
        <Clickable onClick={() => setControl('hasActivity', value)}>{label}</Clickable>
      ),
      groupRenderer: true,
    },
  });

  useEffect(() => {
    const count = daysBetweenDates(startDate, endDate);

    if (count > 15 && !hasActivity) {
      setIsOpen(true);
      return;
    }

    if (!count || !ouMode || (ouMode === 'custom' && ousSelected.length === 0)) return;

    if (limits && ouMode) {
      const { forAllOus } = limits;

      const shouldFetchOnDemand =
        (ouMode && ouMode === 'default') || (ouMode && ouMode !== 'default' && count <= forAllOus);

      if (shouldFetchOnDemand) {
        fetchReportDataOnDemand({
          fetchAction: fetchUnidentifiedLogs,
          report,
          isOptimized: true,
          limits,
          ous,
          args: {
            hasActivity,
          },
        });
      }

      if (!shouldFetchOnDemand) {
        setIsOpen(true);
      }
    }
  }, [ouKeys, ouMode, startDate, endDate, ousSelected, hasActivity]);

  // RENDER

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="UnidentifiedLogsSummaryReport">
        <ReportFragment.Header route={route} services={requestServices} />
        <QueryPanel
          queryFor="log"
          group="query"
          defaultDateRangeFor="startedAt"
          minAllowedDate="2018-01-01"
          disableFuture
        />
        <FiltersPanel rows={filteredRows.query} />
        <AggregatesPanel rows={filteredRows.dataGrid} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} rowIdProperty="key" />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.dataGrid} />
        </SectionPrint>
        <ControlledDataTable
          controller={dataGridController}
          rows={filteredRows.dataGrid}
          rowIdProperty="key"
        />
      </PrintLayout>
      <DetailReportPrintingLinks
        getDetailReportPath={({ vehicle: { key: vehicleKey }, logDate: date }) =>
          unidentifiedLogDetailReportRoute.makeTargetPath({
            vehicleKey,
            date,
            reportViewId: 'default',
          })
        }
      />
      <BasicDialog
        title="Query is too wide"
        maxWidth="md"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div>
          <h4 style={{ color: 'red', fontWeight: 400 }}>
            Please note that querying driver logs <strong>Without Activity</strong> may result in a
            very large amount of data being returned at once, which could cause your browser to
            consume too much memory and potentially crash.
          </h4>
          <h4 style={{ fontWeight: 400 }}>
            For this reason we recommend to query driver logs <strong>Without Activity</strong> in
            intervals of 14 days or less. If you still need to see more data, you can do so in
            intervals of 14 days.
          </h4>
          <h4 style={{ fontWeight: 400 }}>
            <strong>With Activity</strong> date can be queried on a much larger interval.
          </h4>
        </div>
      </BasicDialog>
    </ReportProvider>
  );
};

UnidentifiedLogsSummaryReport.propTypes = {
  ous: customPropTypes.organizations,
  logs: customPropTypes.unidentifiedLogs.isRequired,
  reportViewId: PropTypes.string.isRequired,
  unidentifiedLogDetailReportRoute: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  fetchUnidentifiedLogs: PropTypes.func.isRequired,
  limits: PropTypes.object,
};
