/**
 * selectVideoEventTypes(state) -> [string]
 *
 * Selector for all video event's possible eventTypes as strings (video events have exactly one)
 */
export const selectVideoEventTypes = (state) => state.reports.videoEvents.types;

/**
 * selectVideoEventBehaviors(state) -> [string]
 *
 * Selector for all video event's possible behaviors as strings (video events have zero or more)
 */
export const selectVideoEventBehaviors = (state) => state.reports.videoEvents.behaviors;

/**
 * selectVideoEventFileLocation(state, { id }) -> { id: string, url: string, expiresAt: string }
 *
 * Selector for single video by id
 */
export const selectVideoEventFileLocation = (state, { id }) =>
  state.reports.videoEvents.fileLocationsById[id];
