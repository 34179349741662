import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faCaretLeft,
  faCaretRight,
  faPlus,
  faStar as faStarSolid,
  faPencil,
  faClose,
  faCheck,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { useMap } from '@vis.gl/react-google-maps';

import { apiPost, apiDelete, apiPut } from '../utils/api';
import { EntityContext } from '../context/EntityContext';

import '../css/BookMarks.css';

const localStorageElement = localStorage.getItem('fleetTrackingBookmark');
const BOOK_MARK_REGISTER = localStorageElement ? JSON.parse(localStorageElement) : {};

const setBookmarkLocation = async (bookmarkCoordinates) => {
  if (!BOOK_MARK_REGISTER?.key) {
    localStorage.setItem('fleetTrackingBookmark', JSON.stringify(bookmarkCoordinates));
    return;
  }

  BOOK_MARK_REGISTER?.key === bookmarkCoordinates.key
    ? localStorage.removeItem('fleetTrackingBookmark')
    : localStorage.setItem('fleetTrackingBookmark', JSON.stringify(bookmarkCoordinates));
};

export const BookMarks = () => {
  const { bookmarks, setBookmarks, token, api } = useContext(EntityContext);
  const map = useMap();

  const [collapse, setCollapse] = useState(true);
  const [selected, setSelected] = useState(BOOK_MARK_REGISTER);
  const [edited, setEdited] = useState('');
  const [loading, setLoading] = useState(false);

  if (collapse) {
    return (
      <div className="BookMarks-collapsed">
        <div className="BookMarks__header" onClick={() => setCollapse(!collapse)}>
          <FontAwesomeIcon icon={faBookmark} fontSize={14} />
          <h2>
            {bookmarks.length > 0 ? bookmarks.length : ''} Bookmark{bookmarks.length > 1 ? 's' : ''}
          </h2>
          <FontAwesomeIcon icon={faCaretLeft} fontSize={14} />
        </div>
      </div>
    );
  }

  return (
    <div className="BookMarks">
      <div className="BookMarks__header" onClick={() => setCollapse(!collapse)}>
        <h2>
          {bookmarks.length > 0 ? bookmarks.length : ''} Bookmark{bookmarks.length > 1 ? 's' : ''}
        </h2>
        <FontAwesomeIcon icon={faCaretRight} fontSize={14} />
      </div>
      <div className="BookMarks__list">
        {bookmarks
          .slice()
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((bookmark) => (
            <div className="BookMarks__list__item" key={bookmark.key}>
              {selected?.key === bookmark.key && selected?.edit ? (
                <div className="row">
                  <input
                    type="text"
                    value={edited || bookmark.name}
                    onChange={(e) => setEdited(e.target.value)}
                  />
                  {loading ? (
                    <div className="button-wrapper">
                      <FontAwesomeIcon icon={faSpinner} spin fontSize={14} />
                      <FontAwesomeIcon icon={faSpinner} spin fontSize={14} />
                      <FontAwesomeIcon icon={faSpinner} spin fontSize={14} />
                    </div>
                  ) : (
                    <div className="button-wrapper">
                      <FontAwesomeIcon
                        icon={faCheck}
                        fontSize={14}
                        onClick={() => {
                          setLoading(true);
                          const url = `${api.originUrl}/api/fleettracking/bookmarks/${bookmark.key}`;
                          apiPut({
                            url,
                            token,
                            body: { ...bookmark, name: edited || bookmark.name },
                          }).then((response) => {
                            setBookmarks(
                              bookmarks.map((b) => (b.key === bookmark.key ? response : b))
                            );
                            setSelected(null);
                            setLoading(false);
                          });
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faClose}
                        fontSize={14}
                        onClick={() => {
                          setSelected(null);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        fontSize={14}
                        onClick={() => {
                          setLoading(true);
                          const url = `${api.originUrl}/api/fleettracking/bookmarks/${bookmark.key}`;
                          apiDelete({ url, token }).then(() => {
                            setBookmarks(bookmarks.filter((b) => b.key !== bookmark.key));
                            setSelected(null);
                            setLoading(false);
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="row"
                  onClick={() => {
                    map.setCenter({
                      lat: bookmark?.centre?.latitude,
                      lng: bookmark?.centre?.longitude,
                    });

                    map.setZoom(bookmark?.zoom);
                  }}
                >
                  <span>{bookmark.name}</span>
                  <div className="button-wrapper">
                    <FontAwesomeIcon
                      icon={selected?.key === bookmark?.key ? faStarSolid : faStar}
                      fontSize={14}
                      onClick={() => {
                        setBookmarkLocation(bookmark);
                        selected?.key === bookmark?.key ? setSelected(null) : setSelected(bookmark);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faPencil}
                      fontSize={14}
                      onClick={() => setSelected({ ...bookmark, edit: true })}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="BookMarks__footer">
        <input
          type="text"
          placeholder="New Bookmark"
          onChange={(e) => setEdited(e.target.value)}
          disabled={loading}
          value={edited}
        />

        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin fontSize={14} />
        ) : (
          <FontAwesomeIcon
            style={edited ? { cursor: 'pointer' } : { opacity: 0.5, cursor: 'not-allowed' }}
            icon={faPlus}
            fontSize={14}
            onClick={() => {
              if (!edited) return;
              const url = `${api.originUrl}/api/fleettracking/bookmarks`;
              const payload = {
                name: edited,
                zoom: map.getZoom(),
                centre: {
                  latitude: map.getCenter().lat(),
                  longitude: map.getCenter().lng(),
                },
              };

              setLoading(true);
              apiPost({ url, token, body: payload }).then((response) => {
                setBookmarks([...bookmarks, response]);
                setLoading(false);
                setEdited('');
              });
            }}
          />
        )}
      </div>
    </div>
  );
};
