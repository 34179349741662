const ORIGIN_URL = 'https://dev.nav2dev.ca';
const REPORTS_BASE_URL = 'https://wcapi.nav2dev.ca/v2/';
const WC_API_BASE_URL = 'https://wcapi.nav2dev.ca/v1/rest/';
const GOOGLE_API_KEY = 'AIzaSyAMsjbMPhyXTVMBg46ApqREnhnwpRSv0Vs';
const NAME = 'DEVELOPMENT';

export const DEVELOPMENT = {
  ORIGIN_URL,
  REPORTS_BASE_URL,
  WC_API_BASE_URL,
  GOOGLE_API_KEY,
  NAME,
};
