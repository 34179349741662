/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { APIProvider, ControlPosition, Map, MapControl } from '@vis.gl/react-google-maps';

import { setFilterByCategory } from '../utils/filters';
import { createMapDataset, mapStyle } from '../utils/markers';
import { deepMergeEntities } from '../utils/entities';
import {
  CATEGORIES,
  DEFAULT_LOCATION,
  DEFAULT_MAP_ID,
  DEFAULT_UNIT_SYSTEM,
  DEFAULT_ZOOM,
  SIDEBAR_TITLE,
} from '../utils/constants';

import { MarkerCluster } from './MarkerCluster';
import { Geozones } from './Geozones';

const now = new Date();
const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));
const since = oneYearAgo.toISOString();

const FleetTrack = (props) => {
  const { api, token, currentSystemOus, currentSystemOu } = props;

  const [trackedData, setTrackedData] = useState({ driverActivity: [], vehicleLocations: [] });
  const [locations, setLocations] = useState([]);
  const [category, setCategory] = useState(CATEGORIES.DRIVER);
  const [filteredOus, setFilteredOus] = useState([]);
  const [search, setSearch] = useState('');
  const [showInactive, setshowInactive] = useState(false);

  const currentOUs = useMemo(() => currentSystemOus.map(({ key }) => key), [currentSystemOus]);

  const showMarkerLabel = true;

  useEffect(() => {
    let isMounted = true;

    const fetchData = async (param) => {
      const response = await fetch(
        `${api.originUrl}/api/fleettracking/trackedData?since=${param}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!isMounted) return [];

      const data = await response.json();

      return data;
    };

    const fetchLocations = async () => {
      const response = await fetch(`${api.originUrl}/api/admin/locations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!isMounted) return [];
      const data = await response.json();

      return data;
    };

    fetchData(since).then(setTrackedData);
    fetchLocations().then(setLocations);

    const interval = setInterval(() => {
      const today = new Date();
      const thirtyOneSecondsAgo = new Date(today.getTime() - 31000);
      const updatedSince = thirtyOneSecondsAgo.toISOString();

      fetchData(updatedSince)
        .then((newData) =>
          setTrackedData((prevState) => {
            if (!isMounted) return null;

            return {
              driverActivity: deepMergeEntities(
                prevState.driverActivity,
                newData.driverActivity,
                'key'
              ),
              vehicleLocations: deepMergeEntities(
                prevState.vehicleLocations,
                newData.vehicleLocations,
                'vehicle'
              ),
            };
          })
        )
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('BE ERROR', error);
        });
    }, 30000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setFilteredOus(currentOUs);
  }, [currentOUs]);

  const filteredDataset = useMemo(() => {
    console.groupCollapsed('FLEET TRACK DATA INFO UPDATE');
    console.log('trackedData received from endpoint', trackedData);

    const dataset = createMapDataset(trackedData, filteredOus);
    const result = dataset?.filter((row) =>
      setFilterByCategory(row, category, search, showInactive)
    );

    console.log(`dataset created from received trackedData for ${category} category`, dataset);
    console.log(`markers being rendered based on filtered dataset`, result);
    console.groupEnd();

    return result;
  }, [trackedData.driverActivity, trackedData.vehicleLocations, category, search, showInactive]);

  return (
    <APIProvider apiKey={api.googleMapsApiKey}>
      <Map
        defaultCenter={DEFAULT_LOCATION}
        defaultZoom={DEFAULT_ZOOM}
        minZoom={3}
        mapId={DEFAULT_MAP_ID}
        mapTypeControlOptions={{
          position: ControlPosition.TOP_CENTER,
        }}
        fullscreenControlOptions={{
          position: ControlPosition.RIGHT_BOTTOM,
        }}
      >
        <MapControl position={ControlPosition.LEFT}>
          <div style={mapStyle}>
            <h2>{SIDEBAR_TITLE}</h2>
            <ul>
              <li>
                <button
                  type="button"
                  disabled={category === CATEGORIES.DRIVER}
                  onClick={() => setCategory(CATEGORIES.DRIVER)}
                >
                  {CATEGORIES.DRIVERS}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  disabled={category === CATEGORIES.VEHICLE}
                  onClick={() => setCategory(CATEGORIES.VEHICLE)}
                >
                  {CATEGORIES.VEHICLES}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  disabled={category === CATEGORIES.TRAILER}
                  onClick={() => setCategory(CATEGORIES.TRAILER)}
                >
                  {CATEGORIES.TRAILERS}
                </button>
              </li>
            </ul>

            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            <button type="button" onClick={() => setshowInactive(!showInactive)}>
              {showInactive ? 'Hide Inactive' : 'Show Inactive'}
            </button>
          </div>
        </MapControl>
        <MapControl position={ControlPosition.TOP_CENTER}>
          <Geozones locations={locations} />
        </MapControl>
        <MarkerCluster
          rows={filteredDataset}
          showMarkerLabel={showMarkerLabel}
          unitSystem={currentSystemOu?.unitSystem || DEFAULT_UNIT_SYSTEM}
        />
      </Map>
    </APIProvider>
  );
};

FleetTrack.propTypes = {
  api: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  currentSystemOus: PropTypes.array.isRequired,
  currentSystemOu: PropTypes.object.isRequired,
};

export default FleetTrack;
