import React from 'react';
import { SelectInput, Chip } from 'stti-react-common';
import moment from 'moment-timezone';
import { capitalize, lowerCase } from 'lodash';

import { pluralize } from '../../../helpers/pluralize/pluralizeUtils';

export const dateRangeModeOptions = [
  { label: 'Custom range', value: 'custom' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'This week', value: 'thisWeek' },
  { label: 'Last week', value: 'lastWeek' },
  { label: 'Last 7 days', value: 'last7Days' },
  { label: 'Last 14 days', value: 'last14Days' },
  { label: 'This month', value: 'thisMonth' },
  { label: 'Last month', value: 'lastMonth' },
  { label: 'Last 30 days', value: 'last30Days' },
  { label: 'January', value: 'january' },
  { label: 'February', value: 'february' },
  { label: 'March', value: 'march' },
  { label: 'April', value: 'april' },
  { label: 'May', value: 'may' },
  { label: 'June', value: 'june' },
  { label: 'July', value: 'july' },
  { label: 'August', value: 'august' },
  { label: 'September', value: 'september' },
  { label: 'October', value: 'october' },
  { label: 'November', value: 'november' },
  { label: 'December', value: 'december' },
  { label: 'First Quarter', value: '1q' },
  { label: 'Second Quarter', value: '2q' },
  { label: 'Third Quarter', value: '3q' },
  { label: 'Fourth Quarter', value: '4q' },
];

export const dateRangeForOptions = (queryFor, isUtc = false) => [
  { label: `${capitalize(queryFor)} start ${isUtc ? '(UTC)' : ''}`, value: 'startedAt' },
  { label: `${capitalize(queryFor)} end ${isUtc ? '(UTC)' : ''}`, value: 'endedAt' },
];

export const renderDateRangeSummary = (renderProps, { value, controls, formatUnit }, queryFor) => {
  if (!controls.startDate || !controls.endDate)
    return <Chip {...renderProps} variant="outlined" label="Select date range" />;

  let label = `${pluralize(capitalize(queryFor))} ${
    controls.dateRangeFor === 'startedAt' ? 'started' : 'ended'
  } `;

  if (value === 'custom') {
    label = `${label}${formatUnit('timelessDate', moment(controls.startDate))} to ${formatUnit(
      'timelessDate',
      moment(controls.endDate)
    )}`;
  } else {
    const labelForValue = SelectInput.labelForValue(dateRangeModeOptions, value);
    label = label ? `${label}${lowerCase(labelForValue)}` : labelForValue;
  }

  return <Chip {...renderProps} color="primary" label={label} />;
};
