import React, { useContext, useEffect, useMemo, useState } from 'react';

import { RateLimit } from 'async-sema';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import Grid from '@mui/material/Grid';

import { includes, debounce, isEqual, uniq, sortBy } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import forEach from 'lodash/forEach';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';

import { ReportContext } from '../../context/ReportContext';

import { DateTime, createDateTime, monthNameToNumber, getQuarterMonths } from '../../utils/date';
import {
  defaultRangeModes,
  getDateRangeModes,
  getIntervalDescription,
  getOuKeys,
  getSelectedOus,
  months,
  queryProcessor,
  showYearAllowed,
} from '../../utils/query';
import { getFormatDefinitions, getFormatDefinition } from '../../utils/formats';
import { DATA_TEST } from '../../utils/constants';

const limit = RateLimit(1);

const onSubmit = (
  formData,
  memorizedOus,
  { token, currentSystemOu, currentSystemOus, limits, urls, setDataSource, setExtraData }
) => {
  if (!formData || (formData.ouMode === 'custom' && formData.ousSelected?.length === 0)) return;

  const { ouMode } = formData;
  const ouKeys = getOuKeys({ ouMode, formData, currentSystemOu, currentSystemOus, memorizedOus });
  const queries = queryProcessor({ ...formData, ouKeys, limits });

  if (!token || !queries || queries.length === 0) {
    return;
  }

  forEach(queries, async (query) => {
    await limit();
    const response = await fetch(
      `${urls.main}?start=${query.start}&end=${query.end}${
        query.ouKey !== 'all' ? `&ouKey=${query.ouKey}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    let reData = null;

    // The BE sometimes returns date fields as separate day, month and year strings.
    // This code normalizes these fields into a single date time value for the
    // report query panel to use in its filters.
    if (data.length > 0) {
      const header = data[0];
      if (header.day && header.month && header.year) {
        reData = data.map((row) => ({
          ...row,
          startDate: DateTime.fromObject({
            year: row.year,
            month: row.month,
            day: row.day,
          }).startOf('day'),
          endDate: DateTime.fromObject({
            year: row.year,
            month: row.month,
            day: row.day,
          }).endOf('day'),
        }));
      }
    }
    setDataSource((prevState) => uniqBy(flatten([...prevState, reData || data]), 'key'));
  });

  forEach(urls.extras, async (url) => {
    await limit();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setExtraData((prevState) => uniqBy(flatten([...prevState, data]), 'key'));
  });
};

export const ReportQuery = () => {
  const {
    api,
    currentSystemOu,
    currentSystemOus,
    limits,
    report,
    selectedReport,
    setDataSource,
    setExtraData,
    setQuery,
    settings,
    token,
  } = useContext(ReportContext);

  const [previousSubmission, setPreviousSubmission] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [memorizedOus, setMemorizedOus] = useState([]);

  const startDateRef = (element) => {
    if (element) {
      element.setAttribute(DATA_TEST, 'startDate');
    }
  };

  const endDateRef = (element) => {
    if (element) {
      element.setAttribute(DATA_TEST, 'endDate');
    }
  };

  const dateFormatDefinition = useMemo(() => {
    if (!settings) return null;

    const { dateFormat } = settings || { dateFormat: 'MMM d, yyyy HH:mm' };
    const findCriteria = dateFormat === 'default' ? 'MMM d, yyyy HH:mm' : dateFormat;

    return getFormatDefinitions('date').find(({ key }) => key === findCriteria);
  }, [settings]);

  const urls = useMemo(() => {
    if (!api) return [];

    const extras = report?.query?.services.extra.map((url) => {
      const reportUrl = `${api.originUrl}${report?.query?.services.base}${url}`;
      return reportUrl;
    });

    return {
      main: `${api.originUrl}${report?.query?.services.base}${report?.query?.services.url}`,
      extras,
    };
  }, [api, report]);

  const { control, watch, handleSubmit, getValues, setValue, setError, formState, clearErrors } =
    useForm({
      mode: report?.query?.validationMode,
      defaultValues: {},
    });

  const formValues = watch();

  const defaultValues = useMemo(() => {
    const { controls } = (selectedReport && selectedReport.state) || {};
    if (!controls) return null;

    const today = DateTime.now().toLocal();

    const sanitizedDateRangeMode =
      controls.dateRangeMode === 'today' && createDateTime(controls.startDate).hasSame(today, 'day')
        ? 'today'
        : 'custom';

    const formPayload = {
      ousSelected: controls.ousSelected || [],
      dateRangeFor: controls.dateRangeFor || 'startedAt',
      dateRangeMode: sanitizedDateRangeMode,
      ouMode: controls.ouMode || 'default',
      startDate: createDateTime(controls.startDate),
      endDate: createDateTime(controls.endDate),
      year: createDateTime(controls.year),
    };

    Object.entries(formPayload).forEach(([name, value]) => {
      setValue(name, value);
    });

    if (!isEqual(formPayload, formValues)) {
      handleSubmit(
        onSubmit(formPayload, memorizedOus, {
          currentSystemOu,
          currentSystemOus,
          limits,
          setDataSource,
          setExtraData,
          token,
          urls,
        })
      );
    }

    return formPayload;
  }, [selectedReport?.state]);

  const dateFormatPattern = useMemo(() => {
    const { dateFormat } = settings || { dateFormat: 'MMM D, YYYY HH:mm' };
    const findCriteria = dateFormat === 'default' ? 'MMM D, YYYY HH:mm' : dateFormat;
    const formatDefinition = getFormatDefinition('date', findCriteria);

    if (!formatDefinition) {
      return 'MMM D, YYYY';
    }

    return formatDefinition.derived.timelessDate;
  });

  const { dateRangeMode, dateRangeFor, year, ouMode, ousSelected, startDate, endDate } =
    formValues || {};

  const shouldShowYear = includes(showYearAllowed, dateRangeMode);

  const debouncedSubmit = debounce(() => {
    const currentValues = getValues();

    if (!defaultValues || (!previousSubmission && isEqual(defaultValues, currentValues))) return;

    if (!previousSubmission || !isEqual(previousSubmission, currentValues)) {
      setPreviousSubmission(currentValues);

      let memoryState = memorizedOus;

      if (ouMode === 'custom') {
        if (!previousSubmission) {
          setMemorizedOus(ousSelected);
        } else if (
          previousSubmission &&
          !isEqual(previousSubmission?.dateRangeMode, currentValues.dateRangeMode)
        ) {
          memoryState = [];
          setMemorizedOus([]);
        } else {
          setMemorizedOus((prevState) => uniq(flatten([...prevState, ousSelected])));
        }
      }

      handleSubmit(
        onSubmit(currentValues, memoryState, {
          currentSystemOu,
          currentSystemOus,
          limits,
          setDataSource,
          setExtraData,
          token,
          urls,
        })
      );
    }
  }, 500);

  useEffect(() => {
    if (startDate > endDate) {
      setError('endDate', {
        type: 'manual',
        message: 'End Date must be on or after Start Date.',
      });
    } else if (endDate?.isValid) {
      clearErrors('endDate');
    }

    setQuery({
      startDate: startDate?.startOf('day'),
      endDate: endDate?.endOf('day'),
      ousSelected,
      ouMode,
      dateRangeMode,
      dateRangeFor,
    });
  }, [startDate, endDate, ousSelected, ouMode, dateRangeMode]);

  useEffect(() => {
    if (dateRangeMode) {
      if (dateRangeMode === 'today') {
        setValue('startDate', createDateTime().startOf('day'));
        setValue('endDate', createDateTime().endOf('day'));
      }

      if (dateRangeMode === 'yesterday') {
        setValue('startDate', createDateTime().minus({ days: 1 }).startOf('day'));
        setValue('endDate', createDateTime().minus({ days: 1 }).endOf('day'));
      }

      if (dateRangeMode === 'thisWeek') {
        setValue('startDate', createDateTime().startOf('week'));
        setValue('endDate', createDateTime().endOf('week'));
      }

      if (dateRangeMode === 'lastWeek') {
        setValue('startDate', createDateTime().minus({ weeks: 1 }).startOf('week'));
        setValue('endDate', createDateTime().minus({ weeks: 1 }).endOf('week'));
      }

      if (dateRangeMode === 'last7Days') {
        setValue('startDate', createDateTime().minus({ days: 7 }));
        setValue('endDate', createDateTime());
      }

      if (dateRangeMode === 'last14Days') {
        setValue('startDate', createDateTime().minus({ days: 14 }));
        setValue('endDate', createDateTime());
      }

      if (dateRangeMode === 'thisMonth') {
        setValue('startDate', createDateTime().startOf('month'));
        setValue('endDate', createDateTime().endOf('month'));
      }

      if (dateRangeMode === 'lastMonth') {
        setValue('startDate', createDateTime().minus({ months: 1 }).startOf('month'));
        setValue('endDate', createDateTime().minus({ months: 1 }).endOf('month'));
      }

      if (dateRangeMode === 'last30Days') {
        setValue('startDate', createDateTime().minus({ days: 30 }));
        setValue('endDate', createDateTime());
      }

      if (includes(months, dateRangeMode) && year) {
        const val = year.toFormat('yyyy');

        const start = DateTime.fromFormat(`${dateRangeMode} ${val}`, 'MMMM yyyy').startOf('month');
        const end = DateTime.fromFormat(`${dateRangeMode} ${val}`, 'MMMM yyyy').endOf('month');

        setValue('startDate', start);
        setValue('endDate', end);
      }

      if (includes(['1q', '2q', '3q', '4q'], dateRangeMode) && year) {
        const val = year.toFormat('yyyy');
        const [start, end] = getQuarterMonths(dateRangeMode, val);
        setValue('startDate', start);
        setValue('endDate', end);
      }
    }
  }, [dateRangeMode, year]);

  useEffect(() => {
    if (ouMode === 'custom' && ousSelected?.length === 0) return;

    if (
      report?.query?.eagerLoad &&
      startDate?.isValid &&
      endDate?.isValid &&
      startDate <= endDate
    ) {
      debouncedSubmit();
    }
  }, [ouMode, ousSelected, startDate, endDate]);

  const filteredDateRangeModes = useMemo(() => {
    const currentDay = createDateTime();
    const currentYear = currentDay.year;
    const currentMonth = currentDay.month;
    const formYear = year?.toFormat('yyyy');

    const dateRangeModes = getDateRangeModes(report?.query?.dataRangeIgnoredModes);

    const modes = dateRangeModes.filter((item) => {
      const itemMode = item.value;

      if (includes(defaultRangeModes, itemMode) || !itemMode) {
        return true;
      }

      if (itemMode.endsWith('q')) {
        if (Number(formYear) < Number(currentYear)) return true;

        const currentQuarterMode = Math.ceil(currentMonth / 3);
        const quarterArray = [];
        for (let i = 1; i <= currentQuarterMode; i += 1) {
          quarterArray.push(`${i}q`);
        }
        return quarterArray.includes(itemMode);
      }

      const monthNumber = monthNameToNumber(itemMode);
      const stringDate = `${formYear}-${monthNumber}-01`;

      const monthDate = createDateTime(stringDate);

      return monthDate <= currentDay;
    });

    if (!modes.map((item) => item.value).includes(dateRangeMode)) {
      setValue('dateRangeMode', modes[0]?.value);
    }

    return modes;
  }, [year]);

  if (!defaultValues) {
    return (
      <Accordion expanded={expanded} disabled>
        <AccordionSummary>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            spacing={2}
          >
            <Typography>Query</Typography>
            <CircularProgress />
          </Grid>
        </AccordionSummary>
      </Accordion>
    );
  }

  return (
    <Accordion expanded={expanded} onChange={(ev, isExpanded) => setExpanded(isExpanded)}>
      <AccordionSummary>
        <Typography>Query</Typography>
        {!expanded && (
          <Stack spacing={1} direction="row" sx={{ ml: 2 }}>
            <Chip
              label={getSelectedOus({ ...formValues }, currentSystemOu, currentSystemOus)}
              color={ouMode === 'custom' && ousSelected.length === 0 ? 'default' : 'primary'}
            />
            <Chip
              label={getIntervalDescription({ ...formValues }, dateFormatDefinition)}
              color="primary"
            />
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-CA">
          <form
            noValidate
            autoComplete="off"
            {...(!report?.query?.eagerLoad
              ? {
                  onSubmit: handleSubmit((currentValues) =>
                    onSubmit(currentValues, [], {
                      currentSystemOu,
                      currentSystemOus,
                      limits,
                      setDataSource,
                      setExtraData,
                      token,
                      urls,
                    })
                  ),
                }
              : {})}
          >
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              spacing={2}
            >
              {report?.query?.controllers?.includes('ouMode') && (
                <Grid size="grow">
                  <Controller
                    name="ouMode"
                    control={control}
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Organization</InputLabel>
                        <Select {...field}>
                          <MenuItem value="default">Selected Organization</MenuItem>
                          <MenuItem value="all">All Organizations</MenuItem>
                          <MenuItem value="custom">Custom Selection</MenuItem>
                        </Select>
                        <FormHelperText>&nbsp;</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}

              {report?.query?.controllers?.includes('ouMode') && ouMode === 'custom' && (
                <Grid size="grow">
                  <Controller
                    name="ousSelected"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl error={ousSelected?.length === 0} variant="standard" fullWidth>
                        <InputLabel>Selected Organizations</InputLabel>
                        <Select
                          multiple
                          renderValue={(selected) => {
                            if (selected.length === 1) {
                              return currentSystemOus?.find((ou) => ou?.key === selected[0])?.name;
                            }
                            if (selected.length > 1) {
                              return `${selected.length} organizations`;
                            }
                            return selected.join(', ');
                          }}
                          {...field}
                        >
                          {sortBy(currentSystemOus, 'name')?.map(({ key, name }) => (
                            <MenuItem key={key} value={key} sx={{ height: 30 }}>
                              <Checkbox checked={ousSelected?.includes(key)} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          sx={{
                            fontSize: 10,
                            height: 15,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {ousSelected?.length === 0
                            ? 'At least one organization is required.'
                            : ''}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}

              {report?.query?.controllers?.includes('dateRangeFor') && (
                <Grid size="grow">
                  <Controller
                    name="dateRangeFor"
                    control={control}
                    disabled={report?.query?.readOnlyFor?.includes('dateRangeFor')}
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Based On</InputLabel>
                        <Select {...field}>
                          <MenuItem value="startedAt">Log Start</MenuItem>
                          <MenuItem value="endedAt">Log End</MenuItem>
                        </Select>
                        <FormHelperText>&nbsp;</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}

              {report?.query?.controllers?.includes('dateRangeMode') && (
                <Grid size="grow">
                  <Controller
                    name="dateRangeMode"
                    control={control}
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Date Range</InputLabel>
                        <Select {...field}>
                          {filteredDateRangeModes.map(({ key, value }) => (
                            <MenuItem key={key} value={value}>
                              {key}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>&nbsp;</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}

              {shouldShowYear && (
                <Grid size="grow">
                  <Controller
                    name="year"
                    control={control}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          label="Year"
                          maxDate={createDateTime().endOf('year')}
                          minDate={createDateTime().minus({ years: 5 }).startOf('year')}
                          slotProps={{ textField: { variant: 'standard' } }}
                          sx={{ width: '100%' }}
                          views={['year']}
                          yearsOrder="desc"
                          {...field}
                        />
                        <FormHelperText>&nbsp;</FormHelperText>
                      </>
                    )}
                  />
                </Grid>
              )}

              <Grid size="grow">
                <Controller
                  control={control}
                  name="startDate"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <DatePicker
                        disabled={dateRangeMode !== 'custom'}
                        inputRef={startDateRef}
                        label="Start Date"
                        slotProps={{
                          textField: {
                            variant: 'standard',
                            error: !field.value || !!formState.errors?.startDate,
                          },
                        }}
                        sx={{ width: '100%' }}
                        format={dateFormatPattern}
                        {...field}
                      />
                      <FormHelperText error>
                        {formState.errors?.startDate?.message || ' '}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>

              <Grid size="grow">
                <Controller
                  name="endDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <DatePicker
                        disabled={dateRangeMode !== 'custom'}
                        disableFuture={dateRangeMode === 'custom'}
                        inputRef={endDateRef}
                        label="End Date"
                        slotProps={{
                          textField: {
                            variant: 'standard',
                            error: !field.value || !!formState.errors?.endDate,
                          },
                        }}
                        sx={{ width: '100%' }}
                        format={dateFormatPattern}
                        {...field}
                      />
                      <FormHelperText error>
                        {formState.errors?.endDate?.message || ' '}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>
              {!report?.query?.eagerLoad && (
                <Grid>
                  <Button type="submit" variant="outlined">
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </LocalizationProvider>
      </AccordionDetails>
    </Accordion>
  );
};
