import { connect } from 'react-redux';

import { AppNotifications } from './notifications';
import { selectSystemUser, selectSystemTenant, selectSystemOus } from '../../../data/system';
import { notification } from '../../../data/administration/configurations';

export const AppNotificationsContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    tenant: selectSystemTenant(state),
    histories: notification.selectors.selectNotificationHistories(state),
    ous: selectSystemOus(state),
  }),
  {
    fetchNotificationHistories: notification.actions.fetchNotificationHistories,
    updateNotificationHistories: notification.actions.updateNotificationHistories,
  }
)(AppNotifications);
