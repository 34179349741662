export const columnDefs = [
  {
    headerName: 'Log',
    children: [
      {
        colId: 'startedAt', // Column ID must be unique and must exist when we don't have field
        type: 'timelessDate', // Column type is handled by ReportGrid component
        headerName: 'Date',
        valueGetter: ({ data }) => data?.startDate, // Value getter is used when the field is a non primitive
      },
      {
        field: 'isSigned',
        type: 'boolean',
        headerName: 'Signed?',
      },
      {
        field: 'driver.name',
        headerName: 'Driver',
      },
      {
        field: 'ous',
        headerName: 'Organizations',
        valueFormatter: ({ value }) => value?.map((item) => item.name)?.join(', '),
      },
      {
        field: 'vehicles',
        headerName: 'Vehicles',
        valueFormatter: ({ value }) => value?.map((item) => item.name)?.join(', '),
      },
    ],
  },
  {
    headerName: 'Day Summary',
    children: [
      {
        field: 'onDutyDrivingTotal',
        type: 'duration',
        headerName: 'Driving',
      },
      {
        field: 'onDutyTotal',
        type: 'duration',
        headerName: 'On Duty',
      },
      {
        field: 'onDutyRemaining',
        type: 'duration',
        headerName: 'On Duty',
      },
    ],
  },
  {
    headerName: 'Deferral',
    children: [
      {
        field: 'deferralStatus',
        headerName: 'Status',
      },
      {
        field: 'deferralTime',
        type: 'duration',
        headerName: 'Time',
      },
    ],
  },
  {
    headerName: 'Cycle',
    children: [
      {
        field: 'cycleStart',
        type: 'dateTime',
        headerName: 'Start',
      },
      {
        field: 'cycleOnDutyTime',
        type: 'duration',
        headerName: 'Total',
      },
      {
        field: 'cycleOnDutyRemaining',
        type: 'duration',
        headerName: 'Remaining',
      },
    ],
  },
  {
    headerName: 'Activity',
    children: [
      {
        field: 'hasActivity',
        headerName: 'Activity',
      },
      {
        field: 'logStanding',
        headerName: 'Standing',
      },
      {
        field: 'violationStatus',
        headerName: 'Violations',
      },
    ],
  },
  {
    headerName: 'ELD State',
    children: [
      {
        field: 'diagnosticIndication',
        headerName: 'Diagnostic',
      },
      {
        field: 'malfunctionIndication',
        headerName: 'Malfunction',
      },
      {
        field: 'healthCodes',
        headerName: 'Health Codes',
      },
    ],
  },
];
