import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';

import { agGridLicense } from '../../utils/constants';
import { debugMode } from '../../utils/debug';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';

LicenseManager.setLicenseKey(agGridLicense);

const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left',
    },
  ],
};

export const DataGrid = ({
  columnDefs,
  height,
  mode,
  recoverState = false,
  rowData,
  rowId = 'key',
  rowSelection = 'single',
  theme = 'balham',
  ...props
}) => {
  const gridRef = useRef();

  const defaultColDef = useMemo(
    () => ({
      minWidth: 100,
      filter: true,
      sortable: true,
    }),
    []
  );

  return (
    <div className={`ag-theme-${theme}`} style={{ height: height || '80%', width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        getRowId={(params) => params?.data[rowId]}
        statusBar={debugMode ? statusBar : null}
        defaultColDef={defaultColDef}
        rowData={rowData}
        rowSelection={rowSelection}
        {...props}
      />
    </div>
  );
};

DataGrid.propTypes = {
  columnDefs: PropTypes.array,
  height: PropTypes.number,
  mode: PropTypes.string,
  recoverState: PropTypes.bool,
  rowData: PropTypes.array,
  rowId: PropTypes.string,
  rowSelection: PropTypes.oneOf(['single', 'multiple']),
  theme: PropTypes.oneOf(['balham', 'material', 'alpine']),
};
