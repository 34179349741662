import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'stti-react-common';
import { MAX_SELECTION } from '../../../../../../helpers/admin/notifications/notificationsUtils';

export function RenderVehicles({ formControls, form, mode, vehicleOptions, Control }) {
  const handleChange = useCallback(
    (value) => {
      form.setControl('vehicles', value);
    },
    [form]
  );

  return (
    <Control
      Component={SelectInput}
      name="vehicles"
      label="Vehicles"
      multiple
      options={vehicleOptions}
      description="Select vehicles to trigger on."
      error={({ value }) => {
        if (!value || value.length === 0) {
          return 'At least one vehicle must be selected.';
        }
        if (value.length > MAX_SELECTION) {
          return `Up to ${MAX_SELECTION} vehicles allowed.`;
        }
        return null;
      }}
      readOnly={mode === 'view'}
      value={formControls.vehicles || []}
      onChange={handleChange}
    />
  );
}

RenderVehicles.propTypes = {
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};
