import produce from 'immer';
import { sortBy } from 'lodash';

import { actionTypeProgressOrSuccess, shallowMergeEntities } from '../../apiReducerSupport';

const initialState = {
  data: { summary: [], activities: [] },
};

export const ACTION_TYPE = {
  fetchGeozones: 'reports/geozones/fetchGeozones',
  fetchGeozoneActivities: 'reports/geozones/fetchGeozoneActivities',
};

export const geozonesReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchGeozones)) {
      draft.data.summary = sortBy(shallowMergeEntities(state.data.summary, payload, 'key'), [
        'geozoneName',
      ]);
    }

    if (actionTypeProgressOrSuccess(type, ACTION_TYPE.fetchGeozoneActivities)) {
      draft.data.activities = shallowMergeEntities(state.data.activities, payload, 'key');
    }

    return draft;
  });
