import produce from 'immer';
import { sortBy } from 'lodash';

import { actionTypeProgressOrSuccess, shallowMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchTrips: 'reports/trips/fetchTrips',
  fetchTrip: 'reports/trips/fetchTrip',
};

const initialState = {
  trips: [],
};

export const tripsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchTrips, ACTION_TYPE.fetchTrip]))
      draft.trips = sortBy(shallowMergeEntities(state.trips, payload), 'startedAt');
  });
