import { connect } from 'react-redux';

import { TripRouteReport } from './TripRouteReport';
import { breadcrumbs, trips } from '../../../../data/reports';

export const TripRouteReportContainer = connect(
  (state, { tripId }) => ({
    trip: trips.selectors.selectTrip(state, { id: tripId }),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
  }),
  { fetchTrip: trips.actions.fetchTrip }
)(TripRouteReport);
