import { connect } from 'react-redux';
import { driverLogs } from '../../../../data/reports';
import { UsEldLogDetailReport } from './UsEldLogDetailReport';
import { setPrintMode, selectSystemUser, selectIsUserDriver } from '../../../../data/system';

import { user } from '../../../../data/administration/resources';

export const UsEldLogDetailReportContainer = connect(
  (state, { driverKey, date }) => ({
    driverLog: driverLogs.selectors.selectDriverLog(state, { driverKey, date }),
    user: selectSystemUser(state),
    users: user.selectors.selectUsers(state),
    isDriver: selectIsUserDriver(state),
  }),
  {
    setPrintMode,
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchDriverLog: driverLogs.actions.fetchDriverLog,
    fetchDriverLogViolation: driverLogs.actions.fetchDriverLogViolation,
  }
)(UsEldLogDetailReport);
