import { intersection, sortBy, uniq, find, get, set, isNull } from 'lodash';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import { ADMIN_ROLE_KEYS, SUPER_ADMIN_ROLE_KEY, DRIVER_ROLE_KEY } from './constants';
import { getRolesByUserMemberships } from '../../helpers/roles';

const getOus = (state) => state.system.ous.data;

/**
 * selectActiveOu(state) -> ou
 *
 * Selector for active (i.e. currently selected) OU
 */
export const selectSystemActiveOu = (state) => state.system.activeOu;

/**
 * selectCurrentOus(state) -> [ous]
 *
 * Selector for system ous
 */
export const selectCurrentOus = (state) => state.system.ous.data;

/**
 * selectSystemTenant(state) -> tenant
 *
 * Selector for current system tenant (note that fields are different than OU)
 */
export const selectSystemTenant = (state) => state.system.tenant;

/**
 * selectVideoConfigs(state) -> videoConfigs
 *
 * Selector for current tenant (note that fields are different than OU)
 */
export const selectVideoConfigs = (state) => state.system.videoConfigs.data;

/**
 * selectVideoConfigsFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for videoConfigs
 */
export const selectVideoConfigsFetchRequests = (state) => state.system.videoConfigs.fetches;

/**
 * selectOuFetchRequests(state) -> [fetches]
 *
 * Selector for all Fetches that have occurred on ous
 */
export const selectOuFetchRequests = (state) => state.system.ous.fetches;

/**
 * selectSystemUser(state) -> user
 *
 * Selector for current system user
 */
export const selectSystemUser = (state) => state.system.user;

/**
 * selectSystemUserRoles(state) -> roles
 *
 * Selector for current system user roles
 */
export const selectSystemUserRole = createSelector([getOus, selectSystemUser], (ous, user) => {
  const userRoles = getRolesByUserMemberships(user, ous);
  return userRoles;
});

/**
 * selectSystemOus(state) -> [ous]
 *
 * Selector for all OUs, including permissions based on user role(s)
 */
export const selectSystemOus = createSelector([getOus, selectSystemUser], (ous, user) =>
  ous.map((ou) => {
    if (!user || !user.memberships) {
      return {
        ...ou,
        permissions: {
          isAdmin: false,
        },
      };
    }

    const isAdmin = user.memberships.reduce(
      (detectedAdmin, membership) =>
        detectedAdmin ||
        !!(
          intersection(ADMIN_ROLE_KEYS, membership.role).length &&
          (membership.ou === ou.key || membership.ou === ou.parent)
        ),
      false
    );
    return {
      ...ou,
      permissions: {
        isAdmin,
      },
    };
  })
);

/**
 * Helper for converting properties to entity's OU time zone and adding timeZone property
 */
export const inOuBasedTime = (
  entity, // object, e.g. trip, shift
  ous, // result from selectOrganizations
  ouKey = undefined, // optional: defaults to entity.ou.key
  convertProperties = ['startedAt', 'endedAt'],
  users
) => {
  const entityOuKey = ouKey || (entity && entity.ou && entity.ou.key);

  const ou = find(ous, { key: entityOuKey }) || { timeZone: 'UTC' };
  const { timeZone } = ou;

  const getDriverInfo = (item) => {
    if (!item) {
      return null;
    }

    return {
      ...item,
      enabled: users?.find(({ key }) => key === item.key)?.enabled,
    };
  };

  const updatedEntity = {
    ...entity,
    timeZone,
    ...(entity?.driver && { driver: getDriverInfo(entity?.driver) }),
    ...(entity?.drivers && { drivers: entity?.drivers.map(getDriverInfo) }),
  };
  updatedEntity.ou = { ou: entity.ou, name: ou.name }; // appending ou name in updatedEntity since it comes empty from BE

  convertProperties.forEach((propertyName) => {
    const propertyValue = get(entity, propertyName);
    set(updatedEntity, `${propertyName}Utc`, propertyValue); // move original value to 'Utc'-suffixed property
    set(
      updatedEntity,
      propertyName,
      propertyValue
        ? moment(propertyValue).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
        : propertyValue // just copy straight value when falsey
    );
  });

  return updatedEntity;
};

/**
 * selectAvailableTimeZones(state) -> [timeZoneString]
 *
 * Selector for array of unique time zones for OUs, sorted
 */
export const selectAvailableTimeZones = createSelector(getOus, (ous) =>
  sortBy(uniq(ous.map((ou) => ou.timeZone)))
);

/**
 * selectRegulations(state) -> [regulation]
 *
 * Selector for array of available system regulations
 */
export const selectRegulations = (state) => state.system.regulations.data;

/**
 * selectRegulationsRequests(state) -> [fetch]
 *
 * Selector for all fetches for regulations
 */
export const selectRegulationsFetchRequests = (state) => state.system.regulations.fetches;

/**
 * selectCountries(state) -> [country]
 *
 * Selector for array of US/Canadian countries
 */
export const selectCountries = (state) => state.system.countries.data;

/**
 * selectCountriesFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for countries
 */
export const selectCountriesFetchRequests = (state) => state.system.countries.fetches;

/**
 * selectEnvironments(state) -> [environment]
 *
 * Selector for array off possible environments (DEV, QA, ALPHA, BETA, PRODUCTION)
 */
export const selectEnvironments = (state) => state.system.environments.data;

/**
 * selectEnvironmentsFetchRequests(state) -> [fetch]
 *
 * Selector for all fetches for environments
 */
export const selectEnvironmentsFetchRequests = (state) => state.system.environments.fetches;

/**
 * selectPrintMode(state) -> { print: boolean or string, attach: array, includeDetail: boolean, suppressMap: boolean }
 *
 * print: boolean, string/array-of-strings hint for which view(s) to print (where multiple views are mounted)
 * attach: array, possible values 'pdf', 'csv'
 * includeDetail: whether to include detail reports (will also have been param set/sent to server)
 * suppressMap: whether to exclude NaviMap
 * webclientViewReadyDelay: optional override of default delay time (in ms) used to ensure complete render before invocation of window.webclientViewReady
 *
 */
export const selectPrintMode = (state) => state.system.printMode;

/**
 * selectFormats(state) -> {
 *   unitSystem: String,
 *   date: String,
 *   duration: String,
 *   distance: String,
 *   mileage: String,
 *   volume: String,
 *   pressure: String,
 * }
 *
 * Selector for system formats
 */
export const selectFormats = (state) => state.system.formats;

/**
 * selectSystemTenantOu(state) -> systemTenantOu
 *
 * Selector for current tenant in organizations context (note that fields are different than Tenant)
 *
 * Attention: We have an external call that handle us organizations from currentOrganizationalUnits endpoint.
 * In case we need to call organizationalUnits endpoint, we merge both lists, that's why the filter bellow
 * will try to filter by scope, in case we don't have this information merged and only have the organizationalUnits
 * list, in this case we will filter the tenant by parent prop.
 */
export const selectSystemTenantOu = createSelector(
  getOus,
  (ous) => ous.find((ou) => ou.scope === 'Tenant' || isNull(ou.parent)) || {}
);

/**
 * selectIsUserSuperAdmin(state) -> boolean
 *
 * Selector returns true when the current user is a Super Admin of any of their OUs.
 *
 * Note that this is a crude way of detecting privileges as it is not OU-specific; this is an active problem
 * in Navistream permissions management.
 */
export const selectIsUserSuperAdmin = createSelector(selectSystemUser, (user) => {
  if (!user || !user.memberships) return false;
  return user.memberships.some(({ role }) => role && role.includes(SUPER_ADMIN_ROLE_KEY));
});

/**
 * selectIsUserAdmin(state) -> boolean
 *
 * Selector returns true when the current user is an Admin or Super Admin of any of their OUs.
 *
 * Note that this is a crude way of detecting privileges as it is not OU-specific; this is an active problem
 * in Navistream permissions management.
 */
export const selectIsUserAdmin = createSelector(selectSystemUser, (user) => {
  if (!user || !user.memberships) return undefined;
  return user.memberships.some(({ role }) => intersection(ADMIN_ROLE_KEYS, role).length > 0);
});

/**
 * selectIsUserDriver(state) -> boolean
 *
 * Selector returns true when the current user is a Driver of any of their OUs.
 *
 * Note that this is a crude way of detecting privileges as it is not OU-specific; this is an active problem
 * in Navistream permissions management.
 */
export const selectIsUserDriver = createSelector(selectSystemUser, (user) => {
  if (!user || !user.memberships) return false;
  return user.memberships.some(({ role }) => role.includes(DRIVER_ROLE_KEY));
});

/**
 * selectMenu(state) -> [menu]
 *
 * Selector for current menu state
 */
export const selectMenu = (state) => state.system.menu;

/**
 * selectFilteredOus(state) -> [filtered Ous]
 *
 * Selector for current filtered ous state
 */
export const selectFilteredOus = (state) => state.system.filters.ous;

/**
 * selectFilteredOuMode(state) -> filtered Ou Mode
 *
 * Selector for current filtered ou mode state
 */
export const selectFilteredOuMode = (state) => state.system.filters.ouMode;

/**
 * selectIsUserTenantSuperAdmin(state) -> boolean
 *
 * Selector returns true when the current user is a Tenant Super Admin of any of their OUs.
 *
 * Note that this is a crude way of detecting privileges as it is not OU-specific; this is an active problem
 * in Navistream permissions management.
 */
export const selectIsUserTenantSuperAdmin = createSelector(
  [getOus, selectSystemUser],
  (ous, user) => {
    if (!user || !user.memberships) return undefined;

    const tenantOu = ous.find(({ scope }) => scope === 'Tenant');
    if (!tenantOu) return undefined;

    return user.memberships.some(
      ({ ou, role }) => ou === tenantOu.key && role && role.includes(SUPER_ADMIN_ROLE_KEY)
    );
  }
);

/**
 * selectSearchLimits(state) -> [searchLimits]
 *
 * Selector for current filtered ou mode state
 */
export const selectSearchLimits = ({ system }, lookUp) => {
  const GENERIC_LIMITS = { forAllOus: 5, forOu: 30 };
  // If we don't find searchLimits in state.system we return a generic object
  if ((!system && !system.searchLimits) || !lookUp) {
    return GENERIC_LIMITS;
  }
  const limitByLookUp = find(system.searchLimits, { name: lookUp });
  // if we don't have a match, return a generic object
  if (!limitByLookUp) {
    return GENERIC_LIMITS;
  }
  return limitByLookUp;
};

/**
 * selectCredentials(state) -> credentials
 *
 * Selector for current credentials
 */
export const selectCredentials = (state) => state.system.oauth;

/**
 * selectAccessToken(state) -> accessToken
 *
 * Selector for current accessToken
 */
export const selectAccessToken = (state) => state.system.oauth.access_token;

/**  ----------  SELECTORS BELOW ARE NEW ARCHITECTURE WE WILL BRING THE RELEVANT SELECTORS ABOVE IN THE FUTURE */
// TODO: organize selectors in a better way
/**
 * selectApi(state) -> api
 *
 * Selector for current system API
 */
export const selectApi = (state) => state.system.api;
