import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { reportViews, reportSchedules } from '../../../../data/reports';

import {
  selectSystemActiveOu,
  selectSystemTenant,
  selectSystemUser,
} from '../../../../data/system';

import { ReportMenu } from './ReportMenu';

export const ReportMenuContainer = connect(
  (state, props) => ({
    reportViews: reportViews.selectors.selectVisibleReportViews(state, props),
    activeOu: selectSystemActiveOu(state),
    tenantKey: selectSystemTenant(state).key,
    userKey: selectSystemUser(state).key,
  }),
  {
    fetchReportViewsByReportType: reportViews.actions.fetchReportViewsByReportType,
    createReportView: reportViews.actions.createReportView,
    updateReportView: reportViews.actions.updateReportView,
    createOrUpdateDefaultReportView: reportViews.actions.createOrUpdateDefaultReportView,
    createReportSchedule: reportSchedules.actions.createReportSchedule,
    createReportOnDemand: reportSchedules.actions.createReportOnDemand,
  }
)(ReportMenu);

ReportMenuContainer.propTypes = {
  reportType: PropTypes.string.isRequired,
};
