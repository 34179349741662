import { DateTime, Interval } from 'luxon';

// DATE UTILS
/**
 * @function createDateObject
 * @description Creates a DateTime object from a string date.
 * @param {String} date - The string date.
 * @returns {Object} A DateTime object.
 */
export const createDateTime = (date) =>
  date && DateTime.fromISO(date).isValid ? DateTime.fromISO(date) : DateTime.now();

/**
 * Converts a month name to a month number.
 * @param {String} monthName - The month name.
 * @returns {Number} The month number.
 */
export const monthNameToNumber = (monthName) => {
  const dt = DateTime.fromFormat(monthName, 'MMMM');
  const monthNumber = dt.month;
  return monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
};

/**
 * Calculates the number of days between two dates.
 * @param {String} startDate - The start date. If invalid, throws an error.
 * @param {String} endDate - The end date. If invalid, throws an error.
 * @returns {Number} The number of days between the start date and the end date.
 * @throws {Error} If startDate or endDate is invalid.
 */
export const getDaysBetweenDates = (startDate, endDate) => {
  const start = createDateTime(startDate);
  const end = createDateTime(endDate);

  return end.diff(start, 'days').days;
};

/**
 * @function createDateInterval
 * @description Creates an interval between two DateTime objects.
 * @param {Object} now - The starting DateTime object.
 * @param {Object} later - The ending DateTime object.
 * @returns {Object} An Interval object representing the time span between the two DateTime objects.
 */
export const createDateInterval = (now, later) => Interval.fromDateTimes(now, later);

/**
 * Returns the end date if it is in the past, otherwise returns today's date.
 * @param {String|Date} endDate - The end date.
 * @returns {String} The end date in ISO string format.
 */
export const getEndDateOrToday = (endDate) =>
  createDateTime() < createDateTime(endDate)
    ? createDateTime().toISO()
    : createDateTime(endDate).toISO();

/**
 * @function getQuarterMonths
 * @description Gets the months of a given quarter.
 * @param {String} quarter - The quarter in the format 'QX' where X is the quarter number.
 * @param {Number} year - The year.
 * @returns {Array} An array of two DateTime objects. The first is the first day of the quarter, the second is the last day of the quarter.
 */
export const getQuarterMonths = (quarter, year) => {
  const quarterNumber = parseInt(quarter.replace('Q', ''), 10);
  const startMonth = (quarterNumber - 1) * 3 + 1;
  const endMonth = startMonth + 2;

  const startDate = DateTime.fromObject({ month: startMonth, year });
  const endDate = DateTime.fromObject({ month: endMonth, year }).endOf('month');

  return [startDate, endDate];
};

export { DateTime };
