import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import { DateField } from '@mui/x-date-pickers/DateField';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import AutoComplete from '../../Autocomplete';

import { countOf, countUniqueOfField } from '../../../utils/aggregation';
import { mechanismOptions } from '../../../utils/report';

export const DataTransferForm = ({
  clearErrors,
  content,
  control,
  currentSystemUser,
  formState,
  isSingleRow,
  methods,
  query,
  setError,
  setValue,
  type,
}) => {
  const [transfer, setTransfer] = useState(false);

  return (
    <div>
      {!isSingleRow && (
        <h3>
          {countOf(content)} logs, {countUniqueOfField(content, 'driver.key')} drivers
        </h3>
      )}
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-CA">
        <form>
          <Stack spacing={2}>
            {isSingleRow && (
              <Grid
                container
                spacing={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid size={4}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DateField
                        {...field}
                        onError={() => setError('startDate', { message: 'Invalid Date' })}
                        label="Start Date"
                        variant="standard"
                        fullWidth
                        color={formState.errors.startDate ? 'error' : 'primary'}
                      />
                    )}
                  />
                </Grid>
                <Grid size={4}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DateField
                        {...field}
                        onError={() => setError('endDate', { message: 'Invalid Date' })}
                        label="End Date"
                        variant="standard"
                        fullWidth
                        disabled={!transfer}
                        color={formState.errors.endDate ? 'error' : 'primary'}
                      />
                    )}
                  />
                </Grid>
                <Grid size={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => {
                          setTransfer(e.target.checked);

                          if (!e.target.checked) {
                            setValue('endDate', query.endDate);
                            setTimeout(() => clearErrors('endDate'), 100);
                          }
                        }}
                      />
                    }
                    label="Transfer for Multiple Days"
                  />
                </Grid>
              </Grid>
            )}
            {type === 'general-purpose' && (
              <>
                <AutoComplete
                  initialValue={currentSystemUser?.email}
                  name="toEmails"
                  label="Deliver to"
                  options={[currentSystemUser?.email]}
                  multiple
                  freeSolo
                  methods={methods}
                  rules={{
                    required: true,
                    requiredMessage: 'Email is required',
                    pattern: /\S+@\S+\.\S+/,
                    patternMessage: 'Please enter a valid email',
                  }}
                />
                <AutoComplete
                  initialValue="US"
                  name="records"
                  label="Records to transfer"
                  options={['Canada', 'US']}
                  multiple
                  methods={methods}
                  rules={{
                    required: true,
                    requiredMessage: 'Record is required',
                  }}
                />
                <Controller
                  name="orientation"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="standard">
                      <InputLabel>PDF orientation</InputLabel>
                      <Select {...field}>
                        <MenuItem value="portrait">Portrait</MenuItem>
                        <MenuItem value="landscape">Landscape</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </>
            )}
            <Controller
              name="comment"
              control={control}
              render={({ field }) => <TextField {...field} label="Comment" variant="standard" />}
            />
            {type !== 'general-purpose' && (
              <Controller
                name="agency"
                control={control}
                render={({ field }) => (
                  <FormControl variant="standard">
                    <InputLabel>Agency</InputLabel>
                    <Select {...field} disabled>
                      <MenuItem value="FMCSA">FMCSA (US)</MenuItem>
                      <MenuItem value="CVSE">CVSE (CAN)</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {type === 'us-audit' && (
              <Controller
                name="mechanism"
                control={control}
                render={({ field }) => (
                  <FormControl variant="standard">
                    <InputLabel>Submit Method</InputLabel>
                    <Select {...field}>
                      {mechanismOptions.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {type === 'canada-audit' && (
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: { value: /\S+@\S+\.\S+/, message: 'Please enter a valid email' },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Agent’s Email"
                    variant="standard"
                    error={!!formState.errors.email || field.value === ''}
                    helperText={formState.errors.email?.message}
                  />
                )}
              />
            )}
          </Stack>
        </form>
      </LocalizationProvider>
    </div>
  );
};

DataTransferForm.propTypes = {
  clearErrors: PropTypes.func,
  content: PropTypes.array,
  control: PropTypes.object,
  currentSystemUser: PropTypes.object,
  formState: PropTypes.object,
  isSingleRow: PropTypes.bool,
  methods: PropTypes.object,
  query: PropTypes.object,
  setError: PropTypes.func,
  setValue: PropTypes.func,
  type: PropTypes.string,
};
