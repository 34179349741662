import produce from 'immer';
import { castArray } from 'lodash';

import { actionTypeProgressOrSuccess, deepMergeEntities } from '../../apiReducerSupport';
import { unitedExceptionId } from './utils';

export const ACTION_TYPE = {
  fetchExceptions: 'reports/exceptions/fetchExceptions',
  fetchException: 'reports/exceptions/fetchException',
  fetchAnnotations: 'reports/exceptions/fetchAnnotations',
  createAnnotation: 'reports/exception/createAnnotation',
  updateAnnotation: 'reports/exceptions/updateAnnotation',
};

const initialState = {
  exceptions: [],
  annotations: {
    byPseudoId: {},
  },
};

export const exceptionsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [ACTION_TYPE.fetchExceptions, ACTION_TYPE.fetchException])
    )
      draft.exceptions = deepMergeEntities(state.exceptions, payload);

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchAnnotations,
        ACTION_TYPE.createAnnotation,
        ACTION_TYPE.updateAnnotation,
      ])
    ) {
      const annotations = castArray(payload);
      annotations.forEach((annotation) => {
        const { exceptionId, tripId, exceptionType } = annotation;
        const pseudoId = unitedExceptionId({ exceptionType, tripId, exceptionId });
        draft.annotations.byPseudoId[pseudoId] = annotation;
      });
    }
  });
