import { sortBy } from 'lodash';

/**
 * selectFuelTaxes(state) -> [fuelTaxes]
 *
 * Selector for all fuel taxes
 */
export const selectFuelTaxes = (state) => sortBy(state.reports.fuelTaxes.summaryData, 'startedAt');

/**
 * selectFuelTax(state, id) -> {fuelTax}
 *
 * Selector for specific fuel tax
 */
export const selectFuelTaxDetails = (state, uuid) => {
  const selectFilter = ({ fuelTaxId, parentUUID }) => parentUUID === uuid || fuelTaxId === uuid;
  return state.reports.fuelTaxes.detailData.filter(selectFilter);
};

/**
 * selectFuelTaxRates(state) -> [fuelTaxRates]
 *
 * Selector for all fuel tax rates
 */
export const selectFuelTaxRates = (state) => state.reports.fuelTaxes.taxRates;
