import { connect } from 'react-redux';

import { InspectionsSummaryReport } from './InspectionsSummaryReport';

import { inspections, reportViews } from '../../../../data/reports';

import {
  selectSystemActiveOu,
  selectSystemUser,
  setPrintMode,
  selectSystemOus,
} from '../../../../data/system';

export const InspectionsSummaryReportContainer = connect(
  (state) => ({
    activeOu: selectSystemActiveOu(state),
    inspections: inspections.selectors.selectInspections(state),
    user: selectSystemUser(state),
    limits: { forAllOus: 7, forOu: 30 },
    ous: selectSystemOus(state),
  }),
  {
    fetchInspections: inspections.actions.fetchInspections,
    fetchMyInspections: inspections.actions.fetchMyInspections,
    fetchInspectorsLatestInspections: inspections.actions.fetchInspectorsLatestInspections,
    fetchVehiclesLatestInspections: inspections.actions.fetchVehiclesLatestInspections,
    createLocalReportView: reportViews.actions.createLocalReportView,
    setPrintMode,
  }
)(InspectionsSummaryReport);
