import { connect } from 'react-redux';

import { CycleDetailReport } from './CycleDetailReport';
import { trips } from '../../../../data/reports';

export const CycleDetailReportContainer = connect(
  (state) => ({
    trips: trips.selectors.selectTrips(state),
  }),
  {
    fetchTrips: trips.actions.fetchTrips,
  }
)(CycleDetailReport);
