import qs from 'qs';

export class Location {
  constructor(href = window.location.href) {
    try {
      let queryString;
      [
        this._href,
        this.origin,
        this.pathname,
        // eslint-disable-next-line prefer-const
        queryString,
      ] = /^(https?:\/\/.+?)\/#([^?]*)\/?\??(.*)$/.exec(href) || [];

      // strip pathname trailing slash
      this.pathname = this.pathname?.replace(/\/$/, '');

      // convert search string to object
      this.query = qs.parse(queryString) || {};

      const params = qs.parse(window.location.search.substr(1)) || {};

      // if we have params and if it code and prompt means we are trying to login using a provider such as google
      if (params.code && params.prompt) {
        const url = window.location.href;

        const pattern = /\?code=([^&]+)&scope=([^&]+)&authuser=([^&]+)&hd=([^&]+)&prompt=([^&]+)/;

        // this will remove any hashes at the end of the url added by the router
        const lastHashIndex = url.lastIndexOf('#');
        const sanitizedUrl = url.slice(0, lastHashIndex);

        // this will redirect us to the correct react component
        const code = encodeURIComponent(btoa(params.code));
        const replacement = `#login/provider/callback?code=${code}`;
        const providerCallbackUrl = sanitizedUrl.replace(pattern, replacement);

        window.history.pushState({}, '', providerCallbackUrl);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Location error', error);
    }
  }

  get href() {
    return this._href;
  }

  toString() {
    const queryString = qs.stringify(this.query, { addQueryPrefix: true });

    return `${this.origin}/#${this.pathname ? this.pathname : '/'}${queryString}`;
  }

  deleteQueryParam(queryParamKey) {
    delete this.query[queryParamKey];
    window.location.replace(this.toString());
  }
}
