import { filter } from 'lodash';
import { v4 as uuid } from 'uuid';

import { makeOneTimeOptimizedAction, makeRestApiAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectTabletsFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const fetchTablets = makeRestApiAction({
  service: 'devices',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTablets,
  notificationsItemDescriptor: 'tablets',
  transformOutput: (responseData) =>
    responseData.map((tablet) => ({
      ...tablet,
      debugHistory: [],
    })),
});

export const fetchTabletsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTabletsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchTablets,
  baseActionType: ACTION_TYPE.fetchTablets,
});

export const fetchTablet = makeRestApiAction({
  service: 'devices',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTablet,
  getId: (key) => key,
  notificationsItemDescriptor: 'tablet',
});

export const fetchTabletOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTabletsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchTablet,
  baseActionType: ACTION_TYPE.fetchTablet,
});

export const deleteTablet = makeRestApiAction({
  service: 'devices',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteTablet,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'tablet',
});

export const updateTablet = makeRestApiAction({
  service: 'devices',
  method: 'put',
  baseActionType: ACTION_TYPE.updateTablet,
  getId: ({ key }) => key,
  transformInput: ({ apkEnvironmentName, ...rest }) => rest,
  transformOutput: (responseData, actionParam) => ({
    ...actionParam,
    /**
     * server doesn't return apkEnvironmentName and returns apkEnvironment as a readable string and not its key.
     * Knowing this we need to assign apkEnvironment from the server response to apkEnvironmentName
     */
    apkEnvironmentName: responseData.apkEnvironment,
  }),
  notificationsItemDescriptor: 'tablet',
  throwApiErrors: true,
});

const fetchTabletDiagnosticConfigs = makeRestApiAction({
  service: 'remoteActions',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchTabletDiagnosticConfigs,
  getId: (key) => key,
  transformOutput: (responseData, actionParam) => ({
    key: actionParam,
    debugHistory: responseData,
  }),
  notificationsItemDescriptor: 'tablet diagnostic configuration',
});

export const fetchTabletDiagnosticConfigsOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectTabletsFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchTabletDiagnosticConfigs,
  baseActionType: ACTION_TYPE.fetchTabletDiagnosticConfigs,
});

export const updateTabletDiagnosticConfigs = makeRestApiAction({
  service: 'remoteActions',
  method: 'post',
  baseActionType: ACTION_TYPE.updateTabletDiagnosticConfigs,
  transformInput: ({ key, ...rest }) => ({
    deviceKey: key,
    ...rest,
  }),
  transformOutput: ({ deviceKey, params, ...rest }) => ({
    key: deviceKey,
    debugHistory: [
      {
        params: params.map(({ key: paramKey, value }) => ({
          paramKey,
          paramValue: value,
          key: uuid(), // Server doesnt return us a key so we temporarily create one
        })),
        ...rest,
      },
    ],
  }),
  notificationsItemDescriptor: 'tablet diagnostic configuration',
  throwApiErrors: true,
});
