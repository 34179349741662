import { connect } from 'react-redux';

import { ReportSchedulesAdmin } from './ReportSchedulesAdmin';
import { selectSystemUser } from '../../../data/system';
import { reportSchedules } from '../../../data/reports';

export const ReportSchedulesAdminContainer = connect(
  (state) => ({
    reportSchedules: reportSchedules.selectors.selectReportSchedules(state),
    user: selectSystemUser(state),
  }),
  {
    deleteReportSchedule: reportSchedules.actions.deleteReportSchedule,
    fetchReportSchedulesByUserKey: reportSchedules.actions.fetchReportSchedulesByUserKey,
  }
)(ReportSchedulesAdmin);
