// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../img/surfsight-video.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EcmReportSummary .Feature__Body{padding:20px}.EcmReportSummary .presentation{display:grid;grid-template-columns:22% 78%;grid-column-gap:10px;grid-row-gap:10px;background:#fff}.EcmReportSummary .cameraImage{width:auto;height:341px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat}", "",{"version":3,"sources":["webpack://./src/components/administration/devices/EcmReports/Summary/summary.scss"],"names":[],"mappings":"AACE,iCACE,YAAA,CAGF,gCACE,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,iBAAA,CACA,eAAA,CAGF,+BACE,UAAA,CACA,YAAA,CACA,mEAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
