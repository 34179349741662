import moment from 'moment-timezone';

import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

import { getMonthInterval } from '../../../helpers/moment';

export const fetchGeozones = makeRestApiAction({
  service: 'geozones',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchGeozones,
  notificationsItemDescriptor: 'Geozones',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    start: moment(startDate).format('YYYY-MM'),
    end: moment(endDate).format('YYYY-MM'),
    ...(ouKey && { ouKey }),
  }),
  transformOutput: (responseData, { timeZone }) =>
    responseData.map((row) => {
      // Endpoint response does not give us startedAt or endedAt,
      // so we need to build it based on props [year, month] provided.
      const referenceDate = moment([row.year, row.month - 1]).format('YYYY-MM-DD');
      const referenceMonth = moment([row.year, row.month - 1]).format('YYYY-MM');

      const newRow = { ...row };
      newRow.id = `${row.geozoneKey}-${row.key}-${referenceMonth}`;
      newRow.key = `${row.key}-${referenceMonth}`;
      newRow.ouKey = row.vehicleOuKey;
      newRow.startedAt = getMonthInterval(referenceDate).start;
      newRow.endedAt = getMonthInterval(referenceDate).end;
      newRow.monthYear = `${row.month}-${row.year}`;
      newRow.timeZone = timeZone;
      return newRow;
    }),
});

export const fetchGeozoneActivities = makeRestApiAction({
  service: 'geozoneActivities',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchGeozoneActivities,
  getId: ({ ouKey, startDate }) => `${ouKey}/${moment(startDate).format('YYYY-MM')}`,
  // endpoint always return an array with only one position,
  // so we will extract the first position
  transformInput: ({ geozoneKey }) => ({
    geozoneKey,
  }),
  transformOutput: (responseData, { timeZone }) => {
    const [first] = responseData;
    const { activity } = first;

    if (!activity) return [];
    return activity.map((row) => {
      const newRow = { ...row };
      newRow.id = `${row.driverKey}-${row.key}`;
      newRow.startedAt = getMonthInterval(row.dateEntered).start;
      newRow.endedAt = getMonthInterval(row.dateEntered).end;
      newRow.ouKey = row.vehicleOuKey;
      newRow.timeZone = timeZone;
      return newRow;
    });
  },
  notificationsItemDescriptor: 'Geozones',
});
