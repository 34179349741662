import React, { useMemo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';

import { DataGrid } from '../DataGrid';
import { DataTransferForm } from './Forms/DataTransfer';
import { EmailReportForm } from './Forms/EmailReport';
import { ReportContext } from '../../context/ReportContext';
import { ReportEditForm } from './Forms/ReportEdit';
import { ReportSaveAsForm } from './Forms/ReportSaveAs';
import { ReportSettingsForm } from './Forms/ReportSettings';
import { ScheduleReportForm } from './Forms/ScheduleReport';

import { countOf, countUniqueOfField } from '../../utils/aggregation';
import {
  dataTransferModes,
  defaultModalState,
  defaultValuesProcessor,
  deleteReport,
  editReport,
  emailReport,
  onRowClickedOpenReport,
  saveAsReport,
  scheduleReport,
} from '../../utils/report';
import { getFormat } from '../../utils/formats';
import { handleNonEldSubmit, handleEldSubmit } from '../../utils/dataTransfer';
import { reportOpenColumnDefs, reportManageColumnDefs } from '../../utils/datagrid';

export const ReportModal = ({ config, dataSource, setModal, setSnackbar }) => {
  const { content, open, title, type, route } = config;
  const [childModal, setChildModal] = useState(defaultModalState);

  const {
    agGridApi,
    api,
    currentSystemOu,
    currentSystemOus,
    currentSystemUser,
    filters,
    loading,
    query,
    reportData,
    selectedReport,
    setLoading,
    setReportData,
    setSelectedReport,
    setSettings,
    settings,
    token,
  } = useContext(ReportContext);

  const { handleSubmit, control, ...methods } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValuesProcessor({ selectedReport, type }),
  });

  const { formState, watch, setError, clearErrors, setValue, reset } = methods;

  // This effect is used to reset the form when the modal is closed
  useEffect(
    () => () => {
      reset();
    },
    []
  );

  // This effect is used to set the default values for the form and also react to query panel changes
  useEffect(() => {
    if (query) {
      setValue('startDate', query.startDate);
      setValue('endDate', query.endDate);
    }

    if (settings) {
      const {
        dateFormat,
        distanceFormat,
        durationFormat,
        mileageFormat,
        volumeFormat,
        weightFormat,
        dateExportFormat,
        distanceExportFormat,
        durationExportFormat,
        mileageExportFormat,
        volumeExportFormat,
        weightExportFormat,
      } = settings;

      setValue('dateFormat', getFormat('date', dateFormat));
      setValue('distanceFormat', getFormat('distance', distanceFormat));
      setValue('durationFormat', getFormat('duration', durationFormat));
      setValue('mileageFormat', getFormat('mileage', mileageFormat));
      setValue('volumeFormat', getFormat('volume', volumeFormat));
      setValue('weightFormat', getFormat('weight', weightFormat));

      setValue('dateExportFormat', getFormat('date', dateExportFormat));
      setValue('distanceExportFormat', getFormat('distance', distanceExportFormat));
      setValue('durationExportFormat', getFormat('duration', durationExportFormat));
      setValue('mileageExportFormat', getFormat('mileage', mileageExportFormat));
      setValue('volumeExportFormat', getFormat('volume', volumeExportFormat));
      setValue('weightExportFormat', getFormat('weight', weightExportFormat));
    }
  }, [query, selectedReport, settings]);

  const {
    dateExportFormat,
    dateFormat,
    distanceExportFormat,
    distanceFormat,
    durationExportFormat,
    durationFormat,
    email,
    endDate,
    mileageExportFormat,
    mileageFormat,
    startDate,
    volumeExportFormat,
    volumeFormat,
    weightExportFormat,
    weightFormat,
  } = watch();

  useEffect(() => {
    if (startDate?.isValid && endDate?.isValid) {
      if (startDate > endDate) {
        setError('startDate', { message: 'Start date must be before end date' });
      } else {
        clearErrors('startDate');
        clearErrors('endDate');
      }
    }
  }, [startDate, endDate]);

  // Settings useEffect
  useEffect(() => {
    setSettings({
      ...settings,
      ...(dateFormat && { dateFormat }),
      ...(distanceFormat && { distanceFormat }),
      ...(durationFormat && { durationFormat }),
      ...(mileageFormat && { mileageFormat }),
      ...(volumeFormat && { volumeFormat }),
      ...(weightFormat && { weightFormat }),
      ...(dateExportFormat && { dateExportFormat }),
      ...(distanceExportFormat && { distanceExportFormat }),
      ...(durationExportFormat && { durationExportFormat }),
      ...(mileageExportFormat && { mileageExportFormat }),
      ...(volumeExportFormat && { volumeExportFormat }),
      ...(weightExportFormat && { weightExportFormat }),
    });
  }, [
    dateFormat,
    distanceFormat,
    durationFormat,
    mileageFormat,
    volumeFormat,
    weightFormat,
    dateExportFormat,
    distanceExportFormat,
    durationExportFormat,
    mileageExportFormat,
    volumeExportFormat,
    weightExportFormat,
  ]);

  // Set child modal form values
  useEffect(() => {
    if (childModal.open) {
      setValue('name', childModal.content.name);
      setValue('description', childModal.content.description);
      setValue('isShared', !!childModal.content.ouKey);
    }
  }, [childModal]);

  const reportTooLarge = useMemo(() => {
    if (includes(dataTransferModes, type)) {
      return countOf(content) > 365 || countUniqueOfField(content, 'driver.key') > 10;
    }
    return false;
  }, [content, type]);

  const disableSubmit = useMemo(() => {
    if (includes(dataTransferModes, type)) {
      if (!isEmpty(formState.errors) || reportTooLarge || (type === 'canada-audit' && !email)) {
        return true;
      }
    }
    if (type === 'saveAs') {
      if (!isEmpty(formState.errors)) {
        return true;
      }
    }

    if (type === 'email-report') {
      if (!isEmpty(formState.errors)) {
        return true;
      }
    }
    return false;
  }, [content, type, formState, reportTooLarge, email]);

  const isSingleRow = includes(dataTransferModes, type) && content?.length === 1;

  const dataTransferFormProps = {
    clearErrors,
    content,
    control,
    currentSystemUser,
    formState,
    isSingleRow,
    methods,
    query,
    setError,
    setValue,
    type,
  };

  return (
    <Dialog
      open={open}
      onClose={(ev, reason) => reason !== 'backdropClick' && setModal(defaultModalState)}
      classes={{ paper: 'ReportModal' }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {type === 'open' && (
          <DataGrid
            theme="material"
            mode="clientSide"
            rowData={dataSource}
            columnDefs={reportOpenColumnDefs}
            context={{ currentSystemOus }}
            rowId="id"
            rowSelection="multiple"
            height={700}
            onRowClicked={({ data }) =>
              onRowClickedOpenReport(data, route, setModal, setSelectedReport)
            }
          />
        )}
        {includes(dataTransferModes, type) && <DataTransferForm {...dataTransferFormProps} />}
        {type === 'settings' && <ReportSettingsForm content={content} control={control} />}
        {type === 'saveAs' && (
          <ReportSaveAsForm
            control={control}
            formState={formState}
            reportData={reportData}
            trigger={methods.trigger}
          />
        )}
        {type === 'email-report' && (
          <EmailReportForm
            content={content}
            control={control}
            currentSystemUser={currentSystemUser}
            methods={methods}
          />
        )}
        {type === 'schedule-report' && (
          <ScheduleReportForm
            content={content}
            reportData={reportData}
            control={control}
            currentSystemUser={currentSystemUser}
            methods={methods}
          />
        )}
        {type === 'manage-reports' && (
          <>
            <DataGrid
              theme="material"
              mode="clientSide"
              rowData={sortBy(reportData, 'name')}
              columnDefs={reportManageColumnDefs}
              context={{ currentSystemOus, setChildModal }}
              rowId="id"
              rowSelection="multiple"
              height={700}
              suppressRowClickSelection
            />
            {childModal && (
              <Dialog open={childModal?.open} onClose={() => setChildModal(defaultModalState)}>
                <DialogTitle>{childModal?.title}</DialogTitle>
                <DialogContent>
                  {childModal?.type === 'edit-report' && (
                    <ReportEditForm
                      control={control}
                      formState={formState}
                      reportData={reportData.filter((r) => !r.defaultView)}
                      trigger={methods.trigger}
                      formData={childModal?.content}
                    />
                  )}
                  {childModal?.type === 'delete-report' && (
                    <p>{`Are you sure you want to delete ${childModal?.content?.name}`}?</p>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setChildModal(defaultModalState)}>Cancel</Button>
                  {childModal?.type === 'edit-report' && (
                    <Button
                      onClick={handleSubmit((data) => {
                        editReport({
                          api,
                          currentSystemOu,
                          data,
                          selectedReport: childModal?.content,
                          setLoading,
                          setReportData,
                          setSnackbar,
                          token,
                        });
                        setChildModal(defaultModalState);
                      })}
                      type="button"
                    >
                      Save
                    </Button>
                  )}
                  {childModal?.type === 'delete-report' && (
                    <Button
                      onClick={handleSubmit((data) => {
                        deleteReport({
                          api,
                          selectedReport: childModal?.content,
                          setLoading,
                          setReportData,
                          setSnackbar,
                          token,
                        });
                        setChildModal(defaultModalState);
                      })}
                      type="button"
                    >
                      Delete
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {disableSubmit && reportTooLarge && (
          <p style={{ color: 'red', width: '100%', padding: '0 0 0 16px' }}>
            You only can send up to 365 driver logs or data for 10 different drivers
          </p>
        )}
        <Button onClick={() => setModal(defaultModalState)}>Close</Button>
        {includes(dataTransferModes, type) && (
          <Button
            onClick={handleSubmit((data) => {
              if (type === 'general-purpose') {
                handleNonEldSubmit({ currentSystemUser, content, ...data }, setLoading);
              } else {
                handleEldSubmit({ isSingleRow, content, ...data }, setLoading);
              }
            })}
            type="button"
            disabled={disableSubmit || loading}
          >
            Submit
          </Button>
        )}
        {type === 'saveAs' && (
          <Button
            onClick={handleSubmit((data) =>
              saveAsReport({
                agGridApi,
                api,
                currentSystemOu,
                data,
                filters,
                query,
                route,
                selectedReport,
                setLoading,
                setModal,
                setReportData,
                setSnackbar,
                token,
              })
            )}
            type="button"
            disabled={disableSubmit || loading}
          >
            Save
          </Button>
        )}
        {type === 'email-report' && (
          <Button
            onClick={handleSubmit((data) =>
              emailReport({
                api,
                currentSystemOu,
                currentSystemUser,
                data,
                selectedReport,
                setLoading,
              })
            )}
            type="button"
            disabled={disableSubmit}
          >
            Submit
          </Button>
        )}
        {type === 'schedule-report' && (
          <Button
            onClick={handleSubmit((data) =>
              scheduleReport({
                agGridApi,
                api,
                currentSystemOu,
                currentSystemUser,
                data,
                filters,
                query,
                reportData,
                setLoading,
                setModal,
                setSnackbar,
                token,
              })
            )}
            type="button"
            disabled={disableSubmit}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ReportModal.propTypes = {
  config: PropTypes.object,
  dataSource: PropTypes.array,
  setModal: PropTypes.func,
  setSnackbar: PropTypes.func,
};
