import React, { useMemo, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import sortBy from 'lodash/sortBy';
import Skeleton from '@mui/material/Skeleton';

import isEmpty from 'lodash/isEmpty';

import { ReportContext } from '../../context/ReportContext';

import { debugMode } from '../../utils/debug';
import { agGridLicense } from '../../utils/constants';
import { getColumnTypes } from '../../utils/datagrid';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';

LicenseManager.setLicenseKey(agGridLicense);

const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left',
    },
  ],
};

export const ReportGrid = ({
  columnDefs,
  height,
  mode,
  recoverState = false,
  rowData,
  rowId = 'key',
  rowSelection = 'single',
  theme = 'balham',
  ...props
}) => {
  const gridRef = useRef();

  const defaultColDef = useMemo(
    () => ({
      minWidth: 100,
      sortable: true,
      filter: 'agTextColumnFilter',
      resizable: true,
    }),
    []
  );

  const dataSource = useMemo(() => sortBy(rowData, ['startDate', 'driver.name']), [rowData]);

  const { selectedReport, setAgGridApi, settings, currentSystemUser, ...context } =
    useContext(ReportContext);

  useEffect(() => {
    if (gridRef.current) {
      setAgGridApi(gridRef.current);
    }
  }, [gridRef.current]);

  const columnTypes = useMemo(
    () => getColumnTypes(settings, currentSystemUser),
    [settings, currentSystemUser]
  );

  if (!selectedReport || isEmpty(settings)) {
    return <Skeleton variant="rectangular" width="100%" height={height || '80%'} />;
  }

  return (
    <div className={`ag-theme-${theme}`} style={{ height: height || '80%', width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        context={context}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        getRowId={(params) => params?.data[rowId]}
        statusBar={debugMode ? statusBar : null}
        defaultColDef={defaultColDef}
        rowData={dataSource}
        rowSelection={rowSelection}
        onGridReady={(params) => {
          if (selectedReport) {
            const state = selectedReport.state.dataGrid.columnState.map(({ colId, ...rest }) => {
              const sortModel = selectedReport.state.dataGrid.sortModel.find(
                ({ colId: sortColId }) => sortColId === colId
              );
              return {
                ...rest,
                ...(sortModel ? { ...sortModel } : { colId }),
              };
            });

            params.columnApi.applyColumnState({
              state,
            });
            params.api.setFilterModel(selectedReport.state.dataGrid.filterModel);
          }
        }}
        {...props}
      />
    </div>
  );
};

ReportGrid.propTypes = {
  columnDefs: PropTypes.array,
  height: PropTypes.number,
  mode: PropTypes.string,
  recoverState: PropTypes.bool,
  rowData: PropTypes.array,
  rowId: PropTypes.string,
  rowSelection: PropTypes.oneOf(['single', 'multiple']),
  theme: PropTypes.oneOf(['balham', 'material', 'alpine']),
};
