// NotificationTypeState.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'stti-react-common';
import ToggleGrid from '../shared/ToggleGrid';
import ToggleRow from '../shared/ToggleRow';
import { RenderVehicles } from '../../NotificationRenders/RenderVehicles';
import { objectsToSelectOptions } from '../../../../../../../helpers/admin/adminUtils';

const NotificationTypeState = ({
  formControls,
  form,
  mode,
  vehicleOptions,
  countriesList,
  availableProvinces,
  Control,
}) => {
  const { country, provinceStateKeys, entered, exited } = formControls;
  const regionLabel = country === 'CANADA' ? 'Province' : 'State';

  // Validate toggles for entered/exited
  const stateToggleError = useMemo(() => {
    if (!entered && !exited) {
      return 'At least one of entered or exited must be selected.';
    }
    return null;
  }, [entered, exited]);

  return (
    <div className="notification-criteria-one-column">
      <h3 className="section-title">State/Province Notification</h3>
      <p className="section-description">
        Notify based on entering or exiting a state/province.
      </p>

      <div className="notification-criteria-fields">
        {/* Vehicles */}
        <RenderVehicles
          formControls={formControls}
          form={form}
          mode={mode}
          vehicleOptions={vehicleOptions}
          Control={Control}
        />

        {/* Country Selection */}
        <Control
          Component={SelectInput}
          name="country"
          label="Country"
          options={objectsToSelectOptions(countriesList, 'name', 'code')}
          readOnly={mode === 'view'}
          value={country}
          onChange={(value) => form.setControl('country', value)}
          error={({ value }) => (!value ? 'Country must be selected.' : null)}
        />

        {/* Region Selection (Province or State) */}
        <Control
          Component={SelectInput}
          name="provinceStateKeys"
          label={regionLabel}
          multiple
          options={objectsToSelectOptions(availableProvinces, 'name', 'code')}
          readOnly={mode === 'view'}
          value={provinceStateKeys || []}
          onChange={(value) => form.setControl('provinceStateKeys', value)}
          disabled={!country}
          error={({ value }) => {
            if (!value || value.length === 0) {
              return `At least one ${regionLabel.toLowerCase()} must be selected.`;
            }
            // Additional validation:
            // If multiple vehicles are selected, only one province/state is allowed.
            if (formControls.vehicles && formControls.vehicles.length > 1 && value.length !== 1) {
              return "If you select many vehicles, you can select one province/state";
            }
            return null;
          }}
        />
      </div>

      <div className="notification-criteria-config">
        <ToggleGrid>
          <ToggleRow
            label="Entered"
            name="entered"
            description={`Triggered when a ${regionLabel.toLowerCase()} is entered.`}
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={stateToggleError}
          />
          <ToggleRow
            label="Exited"
            name="exited"
            description={`Triggered when a ${regionLabel.toLowerCase()} is exited.`}
            formControls={formControls}
            form={form}
            mode={mode}
            Control={Control}
            errorMessage={stateToggleError}
          />
        </ToggleGrid>
      </div>
    </div>
  );
};

NotificationTypeState.propTypes = {
  formControls: PropTypes.shape({
    country: PropTypes.string,
    provinceStateKeys: PropTypes.array,
    entered: PropTypes.bool,
    exited: PropTypes.bool,
    vehicles: PropTypes.array,
  }).isRequired,
  form: PropTypes.shape({
    setControl: PropTypes.func.isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  vehicleOptions: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  availableProvinces: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationTypeState;
