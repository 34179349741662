import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, uniqBy, includes, capitalize, replace } from 'lodash';
import { Clickable } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../commons/ControlledDataGrid';
import {
  countOf,
  countUniqueOfArrayField,
  countUniqueOfField,
} from '../../../../helpers/reports/aggregation';
import { PrintLayout } from '../../../commons/PrintLayout';
import { customPropTypes } from '../../../../helpers/customPropTypes';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  SectionPanel,
  ControlGroupSummary,
  SectionPanelMenu,
  AggregatesCard,
  ReportControl,
  ControlsTablePrint,
  SectionPrint,
  ExceptionRules,
  SelectFilterInput,
  LaunchReport,
  DetailReportPrintingLinks,
  FetchReportData,
  SignalWebclientViewReady,
  DateRangeAnalytics,
  QueryPanel,
} from '../../../commons/ReportsCommon';

import './ExceptionsSummaryReport.scss';

import { columnDefs } from './columnDefs';

const { createState, asDataGridHeaderComponent } = ControlledDataGrid;

const makeExceptionDetailRouteParams = (exception) => ({
  exceptionType: exception.exceptionRule.type,
  tripId: exception.tripId,
  exceptionId: exception.exceptionId,
  description: exception.exceptionRule.description,
  reportViewId: 'default',
});

const defaultState = () => ({
  controls: { exceptionRules: [] },
  dataGrid: createState({ columnDefs }),
});

const requestServices = ['trips', 'exceptions', 'annotations'];

const convertProviderName = (provider) => {
  if (provider === 'surfsight') return 'Navistream';
  return capitalize(provider);
};

export const ExceptionsSummaryReport = ({
  exceptionDetailReportRoute,
  exceptions,
  fetchAnnotations,
  fetchExceptions,
  fetchTrips,
  limits,
  reportViewId,
  route,
  tenant,
}) => {
  // INITIALIZE REPORT

  const report = useReportController({
    reportType: 'exceptionsSummary',
    reportTypeName: 'Exception Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: exceptions,
      chain: ['query', 'exceptions', 'filters'],
    },
  });

  const { debouncedControls, setControl, filteredRows, dataGridController, reportType } = report;

  // GRID CELL COMPONENTS

  const dataGridComponents = ControlledDataGrid.useDataGridComponents({
    /* eslint-disable react/prop-types */
    ExceptionDescription: ({ data, value }) => {
      if (data.exceptionType === 'eventVideoEvent') {
        return (
          <Clickable onClick={() => setControl('exceptionDescription', [data.exceptionRule.id])}>
            {value} - {convertProviderName(data.exceptionRule.provider)}
          </Clickable>
        );
      }
      return (
        <Clickable onClick={() => setControl('exceptionDescription', [data.exceptionRule.id])}>
          {value}
        </Clickable>
      );
    },
    OpenDetail: ({ data }) => (
      <LaunchReport
        onClick={() => exceptionDetailReportRoute.open(makeExceptionDetailRouteParams(data))}
      />
    ),
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    Vehicle: ({ data, value }) => (
      <Clickable onClick={() => setControl('vehicleName', [data.vehicle.key])}>{value}</Clickable>
    ),
    Drivers: ({ data: { drivers } }) =>
      drivers.map((driver, index) => (
        <span key={`${driver.name} ${driver.order}`}>
          {!!index && ', '}
          <Clickable onClick={() => setControl('driversNames', [driver.key])}>
            {driver.name}
          </Clickable>
        </span>
      )),
  });

  // FETCH SUPPORT

  const { exceptionRules } = debouncedControls;
  const isTripExceptionRules = exceptionRules.some(({ type }) => type.match(/^trip/));
  const isEventExceptionRules = exceptionRules.some(({ type }) => type.match(/^event/));
  const isAnyExceptionRules = isTripExceptionRules || isEventExceptionRules;

  let exceptionEventTypes;

  if (isEventExceptionRules) {
    exceptionEventTypes = exceptionRules
      .filter(({ type }) => !!type.match(/^event/))
      .map(({ type }) => replace(type, 'event', ''));
  }

  // RENDER

  const getOptions = useMemo(
    () => ({
      exceptionDescription: () =>
        sortBy(
          uniqBy(
            filteredRows.exceptions.map(({ exceptionRule: { id, description } }) => ({
              label: description,
              value: id,
            })),
            'value'
          ),
          'label'
        ),
    }),
    [filteredRows.exceptions]
  );

  const features = useMemo(
    () => ({
      lytx: includes(tenant.enabledVideoProviders, 'LYTX') || tenant.enableLytx,
      surfsight: includes(tenant.enabledVideoProviders, 'SURFSIGHT'),
      cti: tenant.enableCti,
    }),
    [tenant, tenant.enabledVideoProviders, tenant.enableLytx]
  );

  return (
    <ReportProvider value={report}>
      <ReportLoader reportViewId={reportViewId} />

      {isTripExceptionRules && (
        <FetchReportData fetchAction={fetchTrips} isOptimized limits={limits} />
      )}
      {isEventExceptionRules &&
        exceptionEventTypes.map((event) => (
          <FetchReportData
            fetchAction={fetchExceptions}
            args={{ exceptionType: event }}
            isOptimized
            limits={limits}
            key={event}
          />
        ))}
      {isAnyExceptionRules && (
        <FetchReportData fetchAction={fetchAnnotations} isOptimized limits={limits} />
      )}
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="ExceptionsSummaryReport">
        <ReportFragment.Header
          reportType="exceptionsSummary"
          route={route}
          services={requestServices}
          canIncludeDetailInSendReport
        />

        <QueryPanel queryFor="exception" group="query" minAllowedDate="2018-01-01" disableFuture />
        <SectionPanel
          name="exceptionsPanel"
          title="Exception Rules"
          renderSummaries={() => <ControlGroupSummary group="exceptions" />}
          summaryEnd={<SectionPanelMenu group="exceptions" />}
        >
          <ExceptionRules features={features} />
        </SectionPanel>
        <SectionPanel
          name="filtersPanel"
          title="Filters"
          renderSummaries={() => <ControlGroupSummary group="filters" />}
          summaryEnd={<SectionPanelMenu group="filters" />}
        >
          <SelectFilterInput
            name="exceptionDescription"
            field="exceptionRule.id"
            displayValue="exceptionRule.description"
            group="filters"
            filterGroup="filters"
            label="Rules"
            summaryPrefix="Rule: "
            itemPlural="rules"
            autoGridArea
            getOptions={getOptions.exceptionDescription}
            printConfig={() => ({
              value: null, // no output (handled in ExceptionRulesControl)
            })}
          />
          <ReportFragment.TripResourceFilters
            rows={filteredRows.exceptions}
            group="filters"
            filterGroup="filters"
            hideTrailers
          />
        </SectionPanel>
        <SectionPanel
          name="aggregatesPanel"
          title="Aggregates"
          renderSummaries={() => <ControlGroupSummary group="aggregates" />}
        >
          <ReportControl
            name="aggregatesCollapsedSummary"
            group="aggregates"
            renderSummary={(renderProps, { filteredRows: { dataGrid: dataGridFilteredRows } }) => (
              <div {...renderProps}>
                {countOf(dataGridFilteredRows)} exceptions,{' '}
                {countUniqueOfField(dataGridFilteredRows, 'vehicle.name')} vehicles,{' '}
                {countUniqueOfArrayField(dataGridFilteredRows, 'driversNames')} drivers
              </div>
            )}
          />
          <AggregatesCard
            name="Overview"
            title="Overview"
            fields={[
              {
                header: 'Exceptions',
                value: countOf(filteredRows.dataGrid),
              },
              {
                header: 'Vehicles',
                value: countUniqueOfField(filteredRows.dataGrid, 'vehicle.name'),
              },
              {
                header: 'Drivers',
                value: countUniqueOfArrayField(filteredRows.dataGrid, 'driversNames'),
              },
            ]}
          />
        </SectionPanel>
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} />
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesCard
            name="Overview"
            title="Overview"
            fields={[
              {
                header: 'Exceptions',
                value: countOf(filteredRows.dataGrid),
              },
              {
                header: 'Vehicles',
                value: countUniqueOfField(filteredRows.dataGrid, 'vehicle.name'),
              },
              {
                header: 'Drivers',
                value: countUniqueOfArrayField(filteredRows.dataGrid, 'driversNames'),
              },
            ]}
          />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
      <DetailReportPrintingLinks
        getDetailReportPath={(exception) =>
          exceptionDetailReportRoute.makeTargetPath(makeExceptionDetailRouteParams(exception))
        }
      />
    </ReportProvider>
  );
};

ExceptionsSummaryReport.propTypes = {
  exceptionDetailReportRoute: PropTypes.object.isRequired,
  exceptions: PropTypes.array.isRequired,
  fetchAnnotations: PropTypes.func.isRequired,
  fetchExceptions: PropTypes.func.isRequired,
  fetchTrips: PropTypes.func.isRequired,
  limits: PropTypes.object,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  tenant: customPropTypes.tenant,
};
