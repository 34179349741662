import React, { useContext } from 'react';

import { ReportGrid } from '../DataGrid';
import { ReportContext } from '../../context/ReportContext';

export const ReportData = () => {
  const { filteredDataSource, columnDefs } = useContext(ReportContext);

  return (
    <ReportGrid
      mode="clientSide"
      rowData={filteredDataSource}
      columnDefs={columnDefs}
      rowId="key"
      onRowClicked={({ data }) => console.log('ReportGrid:: onRowClicked', data)}
    />
  );
};
