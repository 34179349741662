import { dateWithoutTime } from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

import { transformPerformancesForStore, transformPerformanceDetailForStore } from './utils';

export const fetchPerformances = makeRestApiAction({
  service: 'performances',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    ouKey,
    start: dateWithoutTime(startDate),
    end: dateWithoutTime(endDate),
  }),
  transformOutput: transformPerformancesForStore,
  baseActionType: ACTION_TYPE.fetchPerformances,
  notificationsItemDescriptor: 'performance',
});

export const fetchPerformanceDetails = makeRestApiAction({
  service: 'performanceDetails',
  method: 'get',
  getId: (id) => id,
  transformInput: () => ({
    includeDeletedRules: false,
  }),
  transformOutput: transformPerformanceDetailForStore,
  baseActionType: ACTION_TYPE.fetchPerformanceDetails,
  notificationsItemDescriptor: 'performance',
});

export const fetchMyPerformances = makeRestApiAction({
  service: 'myPerformances',
  method: 'get',
  transformInput: ({ startDate, endDate, ouKey }) => ({
    ouKey,
    start: dateWithoutTime(startDate),
    end: dateWithoutTime(endDate),
  }),
  transformOutput: transformPerformancesForStore,
  baseActionType: ACTION_TYPE.fetchMyPerformances,
  notificationsItemDescriptor: 'performance',
});
