import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormControl, InputLabel } from '@mui/material';

import { FormContext, FormProvider } from '../context/FormContext';

const Control = ({ name, label, render, variant = 'standard', ...props }) => {
  const context = useContext(FormContext);

  const { control } = context;

  return (
    <FormControl variant={variant} {...props}>
      <InputLabel>{label}</InputLabel>
      <Controller name={name} control={control} render={render} />
    </FormControl>
  );
};

Control.propTypes = {
  control: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  render: PropTypes.func,
  variant: PropTypes.string,
};

const Form = ({ children, defaultValues = {}, mode = 'onBlur', onSubmit }) => (
  <FormProvider values={{ defaultValues, mode, onSubmit }}>{children}</FormProvider>
);

Form.propTypes = {
  children: PropTypes.node,
  defaultValues: PropTypes.object,
  mode: PropTypes.string,
  onSubmit: PropTypes.func,
};

export { Form, Control };
