import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TextField from '@mui/material/TextField';

import { checkPrimitiveArray } from '../utils/collection';

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutoComplete({ label, options, ...props }) {
  const {
    name,
    methods: { setValue, setError, clearErrors, formState } = {},
    rules,
    multiple,
    initialValue,
    ...rest
  } = props;

  const [value, setStateValue] = useState(multiple ? [initialValue] : initialValue);

  const filterOptions = (items, params) => {
    const filtered = filter(items, params);
    const { inputValue } = params;

    if (props.freeSolo) {
      // Suggest the creation of a new value
      const isExisting = items.some((option) => inputValue === option);
      if (inputValue !== '' && !isExisting) {
        filtered.push(`Add "${inputValue}"`);
      }
    }

    return filtered;
  };

  // This effect is used to validate the field if we integrate AutoComplete with react-hook-form
  useEffect(() => {
    if (rules && rules.required) {
      const hasValue = multiple ? value.length > 0 : value;

      if (!hasValue) {
        setValue && setValue(name, multiple ? [] : null);
        setError && setError(name, { message: rules.requiredMessage || 'This field is required' });
        return;
      }

      if (rules.pattern) {
        if (multiple) {
          let errorCount = 0;
          value.forEach((row) => {
            if (!row && !row.trim()) {
              errorCount += 1;
            } else if (!rules.pattern.test(row.toLowerCase())) {
              errorCount += 1;
            }
          });
          if (errorCount > 0) {
            setError && setError(name, { message: rules.patternMessage || 'Invalid pattern' });
          } else {
            clearErrors && clearErrors(name);
            setValue && setValue(name, value);
          }
          return;
        }

        if (!rules.pattern.test(value?.toLowerCase())) {
          setError && setError(name, { message: rules.patternMessage || 'Invalid pattern' });
        } else {
          clearErrors && clearErrors(name);
          setValue && setValue(name, value);
        }
      } else {
        // clear error if no pattern and has a required value
        clearErrors && clearErrors(name);
        setValue && setValue(name, value);
      }
    }
  }, [value]);

  if (!checkPrimitiveArray(options)) {
    throw new Error('AutoComplete component error: options must be an array of strings');
  }

  if ((rules && !props?.methods) || (!rules && props?.methods)) {
    throw new Error('AutoComplete component error: methods and rules must be provided together');
  }

  return (
    <Autocomplete
      multiple={multiple}
      {...rest}
      value={value}
      onChange={(event, newValue) => setStateValue(newValue)}
      options={options}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          error={!!formState?.errors[name]}
          helperText={formState?.errors[name]?.message}
          placeholder="Deliver to"
        />
      )}
      getOptionLabel={(option) => {
        const match = option.match(/^Add "(.*)"$/);
        return match ? match[1] : option;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(params, option, { selected }) => {
        const { key, ...optionProps } = params;
        return (
          <li key={key} {...optionProps}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
            {option}
          </li>
        );
      }}
    />
  );
}

AutoComplete.propTypes = {
  freeSolo: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  methods: PropTypes.object,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.array,
  rules: PropTypes.object,
};
