import React, { useEffect, useMemo, useState, memo } from 'react';
import { flatten, isEmpty, isNil, some } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Clickable, GoogleMap, Icon, useBooleanState } from 'stti-react-common';

import { LOCAL_STORAGE_KEY } from '../../data/system/constants';
import { customPropTypes } from '../../helpers/customPropTypes';
import { FleetMapTypeControl } from '../commons/FleetTrackingCommon/FleetMapTypeControl';
import { FleetTrackingDriverVehicleSettings } from '../commons/FleetTrackingCommon/FleetTrackingDriverVehicleSettings';
import { ToggleTransition } from '../commons/FleetTrackingCommon/ToggleTransition';
import { MapSetting } from '../commons/MapsCommon/MapSetting';
import { NaviMap } from '../commons/NaviMap';
import { ReportProvider, useReportController } from '../commons/ReportsCommon';
import { TileRenderer } from './tiles';

import { dateObject, getMinutes } from '../../helpers/moment';
import { TrackingSelectionDialogue } from '../commons/FleetTrackingCommon/TrackingSelectionDialogue';
import { useFormats } from '../commons/Formats';
import { Bookmark } from '../commons/MapsCommon/BookMark';
import { Messenger } from '../commons/Messenger';

import './FleetTracking.scss';

const { ControlsButton, Controls, SearchInput } = GoogleMap;

const filterLocations = (selectedLocationType, locations) => {
  if (!locations) return [];
  return locations.filter((location) => selectedLocationType.includes(location.type));
};

const getSelectedEntityName = (entity) => {
  if (!entity) return '';

  return entity.type === 'driver' ? entity?.driverName : entity?.vehicleId;
};

const BottomPanelHeader = (selectedEntity, isHeader) => (
  <>
    {!isHeader && <Icon icon="keyboard_arrow_up" />}
    <Icon icon={selectedEntity?.type === 'driver' ? 'person' : 'local_shipping'} />
    <h4 className="bookmark-cnt">{getSelectedEntityName(selectedEntity)}</h4>
  </>
);

export const FleetTracking = memo(
  ({
    bookmarks = [],
    currentSystemOu,
    currentSystemUser,
    deleteFleetTrackingBookmark,
    driverLogs,
    fetchCameras,
    fetchDriverLog,
    fetchExceptions,
    fetchFleetTrackData,
    fetchFleetTrackingBookmark,
    fetchFleetTrackingShiftDetails,
    fetchInspectorsLatestInspections,
    fetchLiveSnapshotDataByCameraId,
    fetchLiveStreamDataByCameraId,
    fetchLocations,
    fetchLocationTypes,
    fetchVehiclesLatestInspections,
    fetchVehicleTypes,
    fleetTrackCurrentShiftDetails,
    fleetTrackData,
    fetchUsers,
    fetchVideoEventFileLocation,
    inspections,
    locations = [],
    locationTypes = [],
    messages,
    ous,
    participants,
    postMessageEvent,
    postFleetTrackingBookmark,
    route,
    updateFleetTrackingBookmark,
    users,
    vehicleTypes,
  }) => {
    let INITIAL_API_CALL_LIMIT = 0;
    const reports = useReportController();
    const [focus, setFocus] = useState([]);
    const [selectedFocus, setSelectedFocus] = useState([]);
    const [since, setSince] = useState(null);
    const [settingList, setSettingList] = useState([]);
    const [checkedLocationSettingList, setCheckedLocationSettingList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [option, setOption] = useState('summary');
    const [selectedData, setSelectedData] = useState({});
    const [loading, setLoading] = useState(false);

    const [isMapSetting, openMapSetting, closeMapSetting] = useBooleanState();
    const [mapItem, setMapItem] = useState({});
    const [isTrackingSelection, openTrackingSelection, closeTrackingSelection] = useBooleanState();
    const { formatUnit } = useFormats();

    const isGuest = some(currentSystemUser?.memberships, (membership) =>
      membership?.role?.includes('S-guest')
    );

    const selectedTrackingList = useMemo(() => {
      const trackingSelectionString = localStorage.getItem('fleetTrackingSelection');

      if (isNil(trackingSelectionString) || trackingSelectionString === '') {
        const newList = vehicleTypes.map((row) => row.key);
        localStorage.setItem('fleetTrackingSelection', newList);
        return newList;
      }

      return trackingSelectionString?.split(',').filter((row) => !isEmpty(row));
    }, [vehicleTypes, isTrackingSelection]);

    const BOOK_MARK_COUNTS = bookmarks.length <= 0 ? '' : bookmarks.length;

    const shouldCallApi = useMemo(() => !isEmpty(currentSystemUser), [currentSystemUser]);

    useEffect(() => {
      if (shouldCallApi && INITIAL_API_CALL_LIMIT === 0) {
        INITIAL_API_CALL_LIMIT += 1;

        if (!isGuest) fetchUsers();
        fetchFleetTrackData({ since: dateObject(dateObject().subtract(52, 'week')).toISOString() });
        fetchLocations();
        fetchLocationTypes();
        fetchVehicleTypes();
        fetchFleetTrackingBookmark();

        const settingListOptions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.mapSetting));

        // IF we don't have any settings we will set default on load fleet tracking for the 1st renderer
        // this is different from have a empty array set in local storage
        if (isNil(settingListOptions)) {
          localStorage.setItem(
            LOCAL_STORAGE_KEY.mapSetting,
            JSON.stringify(['automaticallyUpdate'])
          );
        }
      }
    }, [shouldCallApi, currentSystemUser]);

    const userList = useMemo(
      () =>
        users.map(({ key, ...rest }) => ({
          key,
          ...rest,
          conversations: participants.find((row) => row.reference === key).conversations,
          isActive: getMinutes(rest.lastSignIn) < 1440,
        })),
      [users, participants]
    );

    // After update settings we will set focus based in the logic below
    useEffect(() => {
      const allLocations = flatten(locations.map(({ locations: location }) => location));

      const filteredLocations = flatten(
        filterLocations(settingList, locations, false).map(({ locations: location }) => location)
      );

      const locationsToFocus = filteredLocations.length ? filteredLocations : allLocations;
      if (isEmpty(selectedFocus)) {
        setFocus(locationsToFocus);
      }
    }, [locations, settingList]);

    useEffect(() => {
      const storedTodo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.mapSetting));
      if (storedTodo) setSettingList(storedTodo);
    }, [isMapSetting]);

    // settingList to local storage on change
    useEffect(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY.mapSetting, JSON.stringify(settingList));
      setCheckedLocationSettingList([...settingList]);
    }, [settingList]);

    useEffect(() => {
      const fetchData = () => {
        const params = {
          since: since || new Date(),
        };
        fetchFleetTrackData(params).then(() => setSince(new Date()));
      };

      if (checkedLocationSettingList.includes('automaticallyUpdate')) {
        const intervalId = setInterval(fetchData, 30000); // 30 seconds interval to fetch data if automaticallyUpdate is on
        return () => {
          clearInterval(intervalId);
        };
      }
      return () => {};
    }, [checkedLocationSettingList, since]);

    useEffect(() => {
      if (isNil(selectedEntity) || isEmpty(selectedEntity)) {
        setSelectedData({});
        setSelectedFocus([]);
        setLoading(false);
        return;
      }

      setSelectedFocus(
        selectedEntity.location ? [selectedEntity.location] : [{ latitude: 0, longitude: 0 }]
      );

      if (isGuest) return; // we don't need to fetch data if it is a guest

      setLoading(true);

      if (selectedEntity.type === 'driver') {
        const today = dateObject().format('YYYY-MM-DD');
        const tomorrow = dateObject().add(1, 'days').format('YYYY-MM-DD');
        const driver = selectedEntity.key;

        const promises = [
          fetchDriverLog({ driverKey: driver, date: today }),
          fetchInspectorsLatestInspections({ ouKey: currentSystemOu.key }),
          fetchFleetTrackingShiftDetails({ date: today, driver, type: 'activities.ShiftDocument' }),
          fetchExceptions({ ouKey: currentSystemOu.key, startDate: today, endDate: tomorrow }),
        ];

        if (selectedEntity.vehicle && selectedEntity.camera) {
          promises.push(fetchCameras({ vehicleKey: selectedEntity.vehicle }));
        }

        Promise.all(promises).then((results) => {
          const [log, resultInspections, shiftDetails, exceptions, cameras = []] = results;

          const selectLog =
            log || driverLogs?.find((row) => row?.driver?.key === selectedEntity?.key);
          const selectInspection =
            resultInspections?.find((row) => row?.inspectorKey === selectedEntity?.key) ||
            inspections?.find((row) => row?.inspectorKey === selectedEntity?.key);
          const selectActivities =
            fleetTrackCurrentShiftDetails?.find((row) => row?.driver === selectedEntity?.key) ||
            shiftDetails?.find((row) => row?.driver === selectedEntity?.key);

          const selectExceptions = exceptions?.filter(
            (row) => row?.vehicle?.key === selectedEntity?.vehicle
          );

          setLoading(false);

          return setSelectedData({
            ...selectLog,
            cameras,
            inspection: selectInspection,
            shift: selectActivities,
            exceptions: selectExceptions,
          });
        });
      }

      if (selectedEntity.type === 'vehicle') {
        const today = dateObject().format('YYYY-MM-DD');
        const tomorrow = dateObject().add(1, 'days').format('YYYY-MM-DD');

        const promises = [
          fetchVehiclesLatestInspections({ ouKey: currentSystemOu.key }),
          fetchExceptions({ ouKey: currentSystemOu.key, startDate: today, endDate: tomorrow }),
        ];

        if (selectedEntity.camera) {
          promises.push(fetchCameras({ vehicleKey: selectedEntity.vehicle }));
        }

        Promise.all(promises).then((results) => {
          const [resultInspections, exceptions, cameras] = results;

          const selectInspection =
            resultInspections?.find((row) => row.assetKey === selectedEntity.vehicle) ||
            inspections?.find((row) => row.assetKey === selectedEntity.vehicle);

          const selectExceptions = exceptions?.filter(
            (row) => row.vehicle.key === selectedEntity.vehicle
          );

          try {
            return fetchFleetTrackingShiftDetails({
              date: today,
              driver: selectInspection.inspectorKey,
              type: 'activities.ShiftDocument',
            })
              .then((result) => {
                const selectActivities =
                  fleetTrackCurrentShiftDetails?.find(
                    (row) => row.driver === selectInspection.inspectorKey
                  ) || result?.find((row) => row.driver === selectInspection.inspectorKey);

                setLoading(false);

                return setSelectedData({
                  inspection: selectInspection,
                  shift: selectActivities || {},
                  cameras: cameras || [],
                  currentSystemOu,
                  exceptions: selectExceptions,
                });
              })
              .catch(() => {
                setLoading(false);
                setSelectedData({
                  inspection: selectInspection,
                  shift: {},
                  cameras: cameras || [],
                  exceptions: selectExceptions,
                });
              });
          } catch (error) {
            setLoading(false);

            return setSelectedData({
              inspection: selectInspection,
              shift: {},
              cameras: cameras || [],
              exceptions: selectExceptions,
            });
          }
        });
      }
    }, [selectedEntity]);

    const addMapSettingOption = (id) => {
      if (checkedLocationSettingList.includes(id)) {
        setCheckedLocationSettingList(checkedLocationSettingList.filter((key) => key !== id));
      } else {
        setCheckedLocationSettingList((prevItem) => [...prevItem, id]);
      }
    };

    const appSetting = () => {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.mapSetting,
        JSON.stringify(checkedLocationSettingList)
      );
      closeMapSetting();
    };

    const trackingSelectionKeys = (listKey) => {
      localStorage.setItem('fleetTrackingSelection', listKey);
      closeTrackingSelection();
    };

    const handleMapSelect = (target) => {
      setMapItem(target);
      if (target && !target.geozoneShape) {
        setSelectedEntity(target);
      }
    };

    const selectEntityProcessor = (entity) => {
      setSelectedEntity(entity);

      if (entity) {
        if (entity?.hasMessages) {
          const singleTarget = userList.find((row) => row.key === entity.driver);

          if (
            !singleTarget ||
            !singleTarget.conversations ||
            !singleTarget.conversations.messages
          ) {
            return;
          }

          const event = {
            participant: entity.driver, // it is always the current user
            date: dateObject().toISOString(),
            event: 'Read',
            type: 'messaging.MessageEvent',
            messageKey: singleTarget.conversations.messages[0].key,
          };

          postMessageEvent(event).then(() =>
            setSelectedFocus(entity.location ? [entity.location] : [{ latitude: 0, longitude: 0 }])
          );
        }
      }
    };

    return (
      <div className="fleetTracking">
        <ReportProvider value={reports}>
          <NaviMap
            focus={isEmpty(selectedFocus) ? focus : selectedFocus}
            disableNaviMapTypeButton
            onSelect={handleMapSelect}
            onReset={() => setMapItem(null)}
            selectedGeozone={mapItem}
            disableAnimation
            hideGeozoneInfo={!checkedLocationSettingList?.includes('showLocationLabels')}
            hideGeozoneTypes={locationTypes
              .map(({ key }) => key)
              .filter((key) => !checkedLocationSettingList?.includes(key))}
          >
            <Controls className="control-map-panel-right" controlPosition="leftTop">
              <ToggleTransition
                Header={<h4>Drivers and Vehicles</h4>}
                arrowPosition="right"
                mapPanelOpen
                ButtonContent={
                  <>
                    <Icon icon="local_shipping" />
                    <Icon icon="chevron_right" />
                  </>
                }
              >
                <FleetTrackingDriverVehicleSettings
                  organizationList={ous}
                  fleetTrackData={fleetTrackData}
                  filteredVehicleTypes={selectedTrackingList}
                  openTrackingSelection={openTrackingSelection}
                  mapInstance={GoogleMap}
                  onFocusReturn={(result) => setTimeout(() => setFocus(result), 100)}
                  currentOu={currentSystemOu}
                  currentUser={currentSystemUser}
                  messages={messages}
                  clusterNearbyVehicles={checkedLocationSettingList?.includes(
                    'clusterNearbyVehicles'
                  )}
                  selectedEntity={selectedEntity}
                  onSelectedEntity={selectEntityProcessor}
                  showTrafficOverlay={checkedLocationSettingList?.includes('showTrafficOverlay')}
                  showVehicleLabels={checkedLocationSettingList?.includes('showVehicleLabels')}
                />
              </ToggleTransition>
            </Controls>
            <Controls controlPosition="topCenter" className="search-input-container">
              {checkedLocationSettingList?.includes('showSearchBar') && <SearchInput />}
            </Controls>
            <Controls controlPosition="topCenter">
              <FleetMapTypeControl />
            </Controls>
            <Controls controlPosition="topCenter">
              <Button
                icon="add"
                variant="contained"
                label="New Geozone"
                onClick={() => setMapItem({ mode: 'drawShape' })}
              />
            </Controls>
            <Controls className="control-map-panel-left" controlPosition="rightTop">
              <ToggleTransition
                Header={<h4>Bookmarks</h4>}
                arrowPosition="left"
                ButtonContent={
                  <>
                    <Icon icon="bookmark" />
                    <h4 className="bookmark-cnt">{`${BOOK_MARK_COUNTS} Bookmark`}</h4>
                    <Icon icon="chevron_left" />
                  </>
                }
              >
                <Bookmark
                  bookmarks={bookmarks}
                  className="bookmarks-box"
                  deleteFleetTrackingBookmark={deleteFleetTrackingBookmark}
                  fetchFleetTrackingBookmark={fetchFleetTrackingBookmark}
                  onSetFocus={(result) => setFocus(result)}
                  postFleetTrackingBookmark={postFleetTrackingBookmark}
                  updateFleetTrackingBookmark={updateFleetTrackingBookmark}
                  mapInstance={GoogleMap}
                />
              </ToggleTransition>
            </Controls>
            <Controls controlPosition="rightBottom">
              <ControlsButton icon="settings" onClick={() => openMapSetting()} />
            </Controls>
            <Controls controlPosition="rightBottom">
              <ControlsButton icon="logout" onClick={() => route.logout()} />
            </Controls>
            {!isGuest && !isEmpty(selectedEntity) && (
              <Controls className="control-map-panel-bottom" controlPosition="bottomLeft">
                <ToggleTransition
                  arrowPosition="right"
                  arrowIcon="keyboard_arrow_down"
                  Header={BottomPanelHeader(selectedEntity, true)}
                  mapPanelOpen={!isEmpty(selectedEntity)}
                  ButtonContent={BottomPanelHeader(selectedEntity)}
                  className="bottomTransition"
                >
                  <div className={`bottom-panel ${selectedEntity.type}`}>
                    {selectedEntity.type === 'driver' && (
                      <Messenger user={currentSystemUser} targetParticipant={selectedEntity} />
                    )}
                    <div className="bottom-panel__tabContent">
                      <div className="bottom-panel__tabContent__tab">
                        <Clickable
                          className={option === 'summary' ? 'active' : ''}
                          onClick={() => setOption('summary')}
                        >
                          Summary
                        </Clickable>
                        {selectedEntity.type === 'driver' && (
                          <Clickable
                            className={option === 'driverLogs' ? 'active' : ''}
                            onClick={() => setOption('driverLogs')}
                          >
                            Driver Logs
                          </Clickable>
                        )}
                        <Clickable
                          className={option === 'inspections' ? 'active' : ''}
                          onClick={() => setOption('inspections')}
                        >
                          Inspections
                        </Clickable>
                        <Clickable
                          className={option === 'currentShift' ? 'active' : ''}
                          onClick={() => setOption('currentShift')}
                        >
                          Current Shift
                        </Clickable>
                        <Clickable
                          className={option === 'camera' ? 'active' : ''}
                          disabled={!selectedEntity.camera}
                          onClick={() => setOption('camera')}
                        >
                          Camera
                        </Clickable>
                      </div>
                      <div className="bottom-panel__tabContent__content">
                        <TileRenderer
                          option={option}
                          selectedEntity={selectedEntity}
                          selectedData={selectedData}
                          formatUnit={formatUnit}
                          fetchCameras={fetchCameras}
                          fetchLiveStreamDataByCameraId={fetchLiveStreamDataByCameraId}
                          fetchLiveSnapshotDataByCameraId={fetchLiveSnapshotDataByCameraId}
                          fetchVideoEventFileLocation={fetchVideoEventFileLocation}
                          loading={loading}
                          setOption={setOption}
                        />
                      </div>
                    </div>
                  </div>
                </ToggleTransition>
              </Controls>
            )}

            <MapSetting
              locationTypes={locationTypes}
              isModal
              isOpen={isMapSetting}
              onClose={() => closeMapSetting()}
              addMapSettingOption={addMapSettingOption}
              checkedLocationSettingList={checkedLocationSettingList}
              setCheckedLocationSettingList={setCheckedLocationSettingList}
              appSetting={appSetting}
            />
            <TrackingSelectionDialogue
              isModal
              isOpen={isTrackingSelection}
              onClose={() => closeTrackingSelection()}
              selectedTrackingList={selectedTrackingList}
              trackingSelectionKeys={trackingSelectionKeys}
              trackingSelectionList={vehicleTypes}
              vehicleTypes={vehicleTypes}
            />
          </NaviMap>
        </ReportProvider>
      </div>
    );
  }
);

FleetTracking.propTypes = {
  bookmarks: PropTypes.arrayOf(customPropTypes.location),
  currentSystemOu: customPropTypes.organization.isRequired,
  currentSystemUser: customPropTypes.user.isRequired,
  deleteFleetTrackingBookmark: PropTypes.func.isRequired,
  driverLogs: customPropTypes.driverLogs,
  fetchCameras: PropTypes.func.isRequired,
  fetchDriverLog: PropTypes.func.isRequired,
  fetchExceptions: PropTypes.func.isRequired,
  fetchFleetTrackData: PropTypes.func.isRequired,
  fetchFleetTrackingBookmark: PropTypes.func.isRequired,
  fetchFleetTrackingShiftDetails: PropTypes.func,
  fetchInspectorsLatestInspections: PropTypes.func,
  fetchLiveStreamDataByCameraId: PropTypes.func,
  fetchLiveSnapshotDataByCameraId: PropTypes.func,
  fetchLocations: PropTypes.func.isRequired,
  fetchLocationTypes: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchVehiclesLatestInspections: PropTypes.func,
  fetchVehicleTypes: PropTypes.func.isRequired,
  fleetTrackCurrentShiftDetails: PropTypes.any,
  fleetTrackData: PropTypes.any.isRequired,
  fetchVideoEventFileLocation: PropTypes.func,
  inspections: customPropTypes.inspections,
  locations: PropTypes.arrayOf(customPropTypes.location).isRequired,
  locationTypes: PropTypes.any,
  messages: PropTypes.array.isRequired,
  ous: customPropTypes.organizations,
  participants: PropTypes.any,
  postMessageEvent: PropTypes.func.isRequired,
  postFleetTrackingBookmark: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  updateFleetTrackingBookmark: PropTypes.func.isRequired,
  users: customPropTypes.users.isRequired,
  vehicleTypes: customPropTypes.vehicleTypes.isRequired,
};
