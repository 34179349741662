import React from 'react';
import PropTypes from 'prop-types';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Accordion = ({ items, ...props }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {items.map(({ label, node }, index) => (
        <MuiAccordion
          key={`${label}-${index}`}
          {...props}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>{label}</MuiAccordionSummary>
          <MuiAccordionDetails>{node}</MuiAccordionDetails>
        </MuiAccordion>
      ))}
    </>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      node: PropTypes.node.isRequired,
    })
  ).isRequired,
};
