import { differenceWith, isEqual, isArray, includes, toLower } from 'lodash';

import { dateObject } from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

const buildNotesString = (defects, user, newNote) => {
  if (defects.length === 0 && !newNote) {
    return '';
  }
  // Guard against applying without doing anything

  let output = `[${user.fullName}] - ${dateObject().format('MMMM Do YYYY, h:mm a')}\n`;

  defects.forEach((defect) => {
    output += `${defect.isRepaired ? 'Repaired' : 'Not Repaired'}: ${defect.position} ; ${
      defect.taskName
    } - ${defect.typeName} \n`;
  });

  output += '\n';

  if (newNote) {
    let indentedNotes = '';
    newNote.split('\n').forEach((line) => {
      indentedNotes += `\t${line}\n`;
    });

    output += `${indentedNotes}\n`;
  }

  return `${output} - - - - - - - - - -\n\n`;
};

const transformInspectionOutput = ({
  date,
  inspectionType,
  inspector,
  asset,
  assetId,
  assetTypeName,
  key,
  notes,
  engineHours,
  ...rest
}) => ({
  startedAt: date,
  endedAt: date,
  hasNotes: notes.length > 0,
  notes,
  inspectorKey: inspector,
  assetKey: asset,
  assetName: assetId,
  id: key,
  inspectionType: inspectionType === 'PRE_TRIP' ? 'PRE' : 'POST',
  engineHours: includes(toLower(assetTypeName), 'trailer') ? null : engineHours,
  assetTypeName,
  ...rest,
});

export const fetchVehiclesLatestInspections = makeRestApiAction({
  service: 'vehicleInspectionHistory',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchVehiclesLatestInspections,
  notificationsItemDescriptor: 'latest vehicle inspections',
  transformInput: ({ ouKey }) => ({
    ouKey,
  }),
  transformOutput: (responseData, { ouKey }) =>
    responseData.map(({ inspectorSignature, ...rest }) => ({
      ouKey, // Append OuKey so it can be used for filtering
      isLatestVehicleInspection: true,
      ...transformInspectionOutput(rest),
    })),
});

export const fetchInspectorsLatestInspections = makeRestApiAction({
  service: 'inspectorInspectionHistory',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchInspectorsLatestInspections,
  notificationsItemDescriptor: 'latest inspector inspections',
  transformInput: ({ ouKey }) => ({
    ouKey,
  }),
  transformOutput: (responseData, { ouKey }) =>
    responseData.map(({ inspectorSignature, ...rest }) => ({
      ouKey, // Append OuKey so it can be used for filtering
      isLatestInspectorInspection: true,
      ...transformInspectionOutput(rest),
    })),
});

export const fetchInspections = makeRestApiAction({
  service: 'allInspections',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchInspections,
  notificationsItemDescriptor: 'inspections',
  transformInput: ({ startDate, endDate, timeZone, ouKey }) => ({
    ouKey,
    startDate,
    endDate,
    timeZone,
  }),
  transformOutput: (data, { timeZone }) => {
    if (!data || !isArray(data)) return [];
    return data.map(({ inspectorSignature, ...inspection }) => ({
      timeZone,
      ouKey: inspection.principalKey,
      ...transformInspectionOutput(inspection),
    }));
  },
  enableProgress: true,
});

export const fetchMyInspections = makeRestApiAction({
  service: 'myInspections',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchMyInspections,
  notificationsItemDescriptor: 'inspections',
  transformInput: () => '',
  transformOutput: (responseData, { timeZone }) =>
    responseData.map((inspection) => ({
      timeZone,
      ouKey: inspection.principalKey,
      ...transformInspectionOutput(inspection),
    })),
});

export const fetchInspection = makeRestApiAction({
  service: 'inspections',
  method: 'get',
  getId: (id) => id,
  baseActionType: ACTION_TYPE.fetchInspection,
  transformOutput: (data) => transformInspectionOutput(data),
  notificationsItemDescriptor: 'inspections',
});

export const updateInspectionDefectAndNote = makeRestApiAction({
  service: 'inspections',
  method: 'put',
  getId: ({ key }) => key,
  baseActionType: ACTION_TYPE.updateInspectionDefectAndNote,
  transformInput: ({ defectKey, user, inspection, newNote }) => {
    const { majorRepairs, minorRepairs, ...rest } = inspection;

    const changedDefects = inspection.defects.map(({ key, isRepaired, ...defect }) =>
      key === defectKey
        ? { isRepaired: !isRepaired, key, ...defect }
        : { key, isRepaired, ...defect }
    );
    return {
      ...rest,
      defects: changedDefects,
      notes: `${inspection.notes} ${buildNotesString(
        differenceWith(changedDefects, inspection.defects, isEqual),
        user,
        newNote
      )}`,
    };
  },
  transformOutput: (data) => transformInspectionOutput(data),
  notificationsItemDescriptor: 'inspection defect',
});

export const addInspectionNote = makeRestApiAction({
  service: 'inspections',
  method: 'put',
  getId: ({ id }) => id,
  baseActionType: ACTION_TYPE.updateInspection,
  transformInput: ({ newNote, user, inspection }) => ({
    ...inspection,
    notes: `${inspection.notes} ${buildNotesString([], user, newNote)}`,
  }),
  transformOutput: (responseData, actionParam) => transformInspectionOutput(responseData),
  notificationsItemDescriptor: 'inspection note',
});
