import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { find } from 'lodash';

import { inOuBasedTime } from '../../system';
import { organization } from '../../administration/configurations';

const extractId = (_, { id }) => id;

const getUsers = (state) => state.administration.resources.users.data;
const getTrips = (state) => state.reports.trips.trips;

/**
 * selectTrips(state) -> [trip]
 *
 * Selector for all trips
 */
export const selectTrips = createSelector(
  [getTrips, organization.selectors.selectOrganizations, getUsers],
  (trips, ous, users) => trips.map((trip) => inOuBasedTime(trip, ous, undefined, undefined, users))
);

/**
 * selectTrip(state, { id }) -> trip
 *
 * Selector for single trip by id
 */
export const selectTrip = createCachedSelector([selectTrips, extractId], (trips, id) =>
  find(trips, { id })
)(extractId);
