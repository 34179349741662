import { GoogleMap } from 'stti-react-common';

const { symbol } = GoogleMap;

const star = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.54 92.76">
              <polygon points="48.77 0 60.28 35.43 97.53 35.43 67.39 57.33 78.91 92.76 48.77 70.86 18.63 92.76 30.14 57.33 0 35.43 37.26 35.43 48.77 0"/>
              </svg>`;

/*
const arrow = `<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M14.13 9.11h-12l6-7 6 7z"/><path d="M6.12 8h4v6h-4z"/>
              </svg>`;
*/

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 115.4 122.88"><title>up-arrow</title><path d="M24.94,67.88A14.66,14.66,0,0,1,4.38,47L47.83,4.21a14.66,14.66,0,0,1,20.56,0L111,46.15A14.66,14.66,0,0,1,90.46,67.06l-18-17.69-.29,59.17c-.1,19.28-29.42,19-29.33-.25L43.14,50,24.94,67.88Z"/></svg>`;

export const symbols = {
  go: new symbol.Icon({
    variant: 'paddle',
    size: 45,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 0 75 86.6">
          <polygon points="0 0 75 43.3 0 86.6 0 0"/>
          </svg>`,
    color: '#56FC7A',
    svgScale: 0.75,
    // url: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
  }),
  stop: new symbol.Icon({
    variant: 'paddle',
    size: 45,
    color: '#FC6557',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
          <polygon points="0 0 10 0 10 10 0 10 0 0"/>
          </svg>`,
    svgScale: 0.55,
    // url: 'http://maps.google.com/mapfiles/kml/paddle/stop.png',
  }),
  pause: new symbol.Icon({
    variant: 'paddle',
    size: 45,
    color: '#FCF258',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.92 96">
          <rect width="35.33" height="96"/>
          <rect x="61.59" width="35.33" height="96"/>
          </svg>`,
    svgScale: 0.6,
    // url: 'http://maps.google.com/mapfiles/kml/paddle/pause.png',
  }),
  blueStar: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#8EFFEA',
    svg: star,
    svgScale: 0.9,
    // url: 'http://maps.google.com/mapfiles/kml/paddle/ltblu-stars.png',
  }),
  whiteStar: new symbol.Icon({
    variant: 'paddle',
    size: 45,
    color: '#FFFFFF',
    svg: star,
    svgScale: 0.9,
    // url: 'http://maps.google.com/mapfiles/kml/paddle/wht-stars.png',
  }),
  dot: new symbol.Icon({
    variant: 'dot',
  }), // TODO: deprecate in favour of dotIcon
  route: new symbol.Line({ color: '#669df6' }), // TODO: deprecate in favour of drivingLine
  violation: new symbol.Line({ color: '#ff0000' }), // TODO: deprecate in favour of speedViolationLine

  dotIcon: new symbol.Icon({
    variant: 'dot',
  }),

  loadingLine: new symbol.Line({ color: '#47ff3d' }),
  unloadingLine: new symbol.Line({ color: '#f49c00' }),
  unknownLine: new symbol.Line({ color: '#cccccc' }),
  inspectionLine: new symbol.Line({ color: '#24d3ff' }),
  delayLine: new symbol.Line({ color: '#f9ec00' }),
  fuellingLine: new symbol.Line({ color: '#888888' }),
  drivingLine: new symbol.Line({ color: '#669df6' }),
  speedViolationLine: new symbol.Line({ color: '#ff0000' }),
  clearLine: new symbol.Line({ color: '#000000', opacity: 0 }),

  lineHighlight: new symbol.Line({ color: '#ffff00', variant: 'highlight' }),
  markerHighlight: new symbol.Line({ color: '#ffff00', size: 40, variant: 'highlight' }),

  speedSampleIcon: new symbol.Icon({
    variant: 'plain',
    size: 13,
    svg: ` <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M10,0L3,17.17l0.67,0.67L10,15l6.38,2.82L17,17.17Z"
      style="fill: #fff;stroke: #333;stroke-width: 1"
    />
    </svg>`,
  }),

  loadingIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#47ff3d',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <polygon points="50.5 55.19 80.32 24.43 20.68 24.43 50.5 55.19"/>
    <rect x="20.68" y="62.63" width="59.64" height="7.5"/>
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#555555',
  }),

  unloadingIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#f49c00',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <polygon points="50.5 24.43 80.32 55.19 20.68 55.19 50.5 24.43"/>
    <rect x="20.68" y="62.63" width="59.64" height="7.5"/>
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#333333',
  }),

  unknownIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#cccccc',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M54.9,59.39H41.44V58a16.63,16.63,0,0,1,.78-5.58,12.36,12.36,0,0,1,2.32-3.91,71.38,71.38,0,0,1,6.92-6.23Q54.33,40,54.33,38a4,4,0,0,0-1.15-3,4.94,4.94,0,0,0-3.49-1.08,5.61,5.61,0,0,0-4.16,1.66q-1.65,1.67-2.11,5.81L29.68,39.7a18.71,18.71,0,0,1,5.51-12.2Q40,22.88,49.9,22.88q7.72,0,12.47,3.22,6.45,4.36,6.45,11.62a11.21,11.21,0,0,1-1.67,5.81q-1.67,2.8-6.8,6.84a18.22,18.22,0,0,0-4.52,4.55A9.31,9.31,0,0,0,54.9,59.39ZM41,63H55.39V75.69H41V63Z" transform="translate(0.5 0.5)"/>
    </svg>
    `,
    svgScale: 1.3,
    svgColor: '#222222',
  }),

  inspectionIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#24d3ff',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <circle cx="53.18" cy="36.61" r="18" transform="matrix(0.97, -0.26, 0.26, 0.97, -7.09, 15.32)" style="fill: none;stroke-miterlimit: 10;stroke-width: 8.5"/>
    <line x1="36.27" y1="85.9" x2="47.56" y2="54.26" style="fill: none;stroke-miterlimit: 10;stroke-width: 8.5"/>
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#222222',
  }),

  delayIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#f9ec00',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M33.56,79.12H30.3V68.74l0-.12c0.06-.39,1.54-9.45,14.64-19.3C41.67,47,30.1,38.31,30.1,29.73V20.91h3.26v8.82c0,3.23,2.41,7.24,7,11.6a57.44,57.44,0,0,0,7.25,5.83l0.74,0.48v3.25l-0.67.49C35.78,60,33.76,68,33.56,69V79.12Z" transform="translate(0.5 0.5)"/>
  <path d="M69.7,79.12H66.44V69c-0.2-.94-2.2-9-14.1-17.63l-0.67-.49V47.65l0.74-.48A57.66,57.66,0,0,0,59.7,41.3C64.24,37,66.64,33,66.64,29.73V20.91H69.9v8.82C69.9,38.31,58.33,47,55,49.32c13.1,9.85,14.59,18.91,14.64,19.3l0,0.12V79.12Z" transform="translate(0.5 0.5)"/>
  <rect x="28.5" y="15.24" width="44.01" height="6.18"/>
  <rect x="28.5" y="79.58" width="44.01" height="6.18"/>
  <path d="M34.23,79.42s4-22.69,15.73-22.69S65.87,79.51,65.87,79.51" transform="translate(0.5 0.5)"/>
  <path d="M60,39.92s-5.65,6.51-10,6.46S39.93,39.9,39.93,39.9" transform="translate(0.5 0.5)"/>
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#000000',
  }),

  fuellingIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#888888',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M50.3,15.67s-4.53,17.58-16.18,31c-8.45,9.7-7.27,30.71,15.68,30.71S74.9,58.29,65.35,46C55.47,33.27,50.3,15.67,50.3,15.67Z" transform="translate(0.5 0.5)"/>
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#000000',
  }),

  shiftStartedIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#ffffff',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <polygon points="32.66 21.07 83.63 50.5 32.66 79.93 32.66 21.07"/>
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#444444',
  }),

  loggedInIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#ffffff',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <polygon points="32.66 21.07 83.63 50.5 32.66 79.93 32.66 21.07" style="fill: none;stroke-width: 6" />
    </svg>
    `,
    svgScale: 1.2,
    svgColor: '#444444',
  }),

  shiftEndedIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#ffffff',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <rect x="25.75" y="25.75" width="50" height="50"/>
    </svg>
    `,
    svgScale: 1.1,
    svgColor: '#444444',
  }),

  loggedOutIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#ffffff',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <rect x="25.75" y="25.75" width="50" height="50" style="fill: none;stroke-width: 6" />
    </svg>
    `,
    svgScale: 1.1,
    svgColor: '#444444',
  }),

  videoIcon: new symbol.Icon({
    variant: 'paddle',
    size: 35,
    color: '#fc7eb2',
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"></path>
    </svg>
    `,
    svgScale: 1.1,
    svgColor: '#222222',
  }),
};

export const makeIcon = (iconColor, bgColor = 'white', svg, selected, isLoaded) =>
  new symbol.Icon({
    variant: 'circular',
    color: isLoaded ? iconColor : bgColor,
    svgColor: isLoaded ? bgColor : iconColor,
    svgScale: 0.8,
    svg: svg || arrow,
    size: selected ? 30 : 25,
  });
