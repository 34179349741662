// ChipList.jsx
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { BasicDialog } from 'stti-react-common';
import styles from './ChipList.module.scss';

export default function ChipList({
  type,
  label,
  items,
  onAddItem,
  onRemoveItem,
  placeholder,
  readOnly,
  validator,
}) {
  const [inputValue, setInputValue] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const inputRef = useRef(null);

  const handleAdd = () => {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    if (validator && !validator(trimmedValue)) {
      setDialogMessage(`Invalid entry: "${trimmedValue}"`);
      setDialogOpen(true);
      return;
    }

    // For phone numbers, store the formatted value if needed.
    onAddItem(trimmedValue);
    setInputValue('');
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    const parts = [
      cleaned.slice(0, 3),
      cleaned.slice(3, 6),
      cleaned.slice(6, 10),
    ];
    
    let formatted = parts[0];
    if (parts[1]) formatted += `-${parts[1]}`;
    if (parts[2]) formatted += `-${parts[2]}`;
    
    return formatted;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (type === 'phone') {
      setInputValue(formatPhoneNumber(value));
    } else {
      setInputValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAdd();
    }
  };

  // When the input loses focus, if it contains any number and the blur wasn’t caused by clicking the Add button,
  // auto-add the entry.
  const handleBlur = (e) => {
    // Check if the new focused element is the Add button.
    if (
      e.relatedTarget &&
      e.relatedTarget.dataset &&
      e.relatedTarget.dataset.role === 'chip-add-button'
    ) {
      return;
    }
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    // If the input contains a digit, auto-add.
    if (/\d/.test(trimmedValue)) {
      handleAdd();
    }
  };

  return (
    <div className={styles.chipList}>
      <label htmlFor="chip-list-input">{label}</label>
      {!readOnly && (
        <div 
          className={styles.chipListInputContainer}
          onClick={() => inputRef.current?.focus()}
        >
          {items.map((item, index) => (
            <div key={`${item}-${index}`} className={styles.chipListChip}>
              <span>{item}</span>
              <button
                type="button"
                className={styles.chipListRemoveBtn}
                onClick={() => onRemoveItem(index)}
                aria-label={`Remove ${item}`}
              >
                ×
              </button>
            </div>
          ))}
          <input
            ref={inputRef}
            id="chip-list-input"
            type="text"
            className={styles.chipListInput}
            value={inputValue}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}  // Added onBlur for auto-adding when focus is lost.
          />
          <button
            type="button"
            className={styles.chipListButton}
            onClick={handleAdd}
            aria-label="Add item"
            disabled={!inputValue.trim()}
            data-role="chip-add-button" // This data attribute helps identify the Add button in the blur event.
          >
            Add
          </button>
        </div>
      )}
      <BasicDialog
        title="Invalid Entry"
        className={styles.ChipList__InvalidEntryDialog}
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <p>{dialogMessage}</p>
      </BasicDialog>
    </div>
  );
}

ChipList.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  validator: PropTypes.func,
};
