import produce from 'immer';

import { actionTypeProgressOrSuccess, shallowMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchFuelConsumptions: 'reports/fuelConsumptions/fetchFuelConsumptions',
  fetchFuelConsumption: 'reports/fuelConsumptions/fetchFuelConsumption',
};

const initialState = {
  fuelConsumptions: [],
};

export const fuelConsumptionsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchFuelConsumptions,
        ACTION_TYPE.fetchFuelConsumption,
      ])
    ) {
      draft.fuelConsumptions = shallowMergeEntities(state.fuelConsumptions, payload, 'id');
    }
  });
