import { createSelector } from 'reselect';

import { sortBy, uniqBy } from 'lodash';

import { convertToParticipants } from './helpers';

/**
 * selectMessages(state) -> [messages]
 *
 * Selector for all messages
 */
export const selectMessages = (state) => sortBy(state.messenger.messages, 'date');

/**
 * selectOldestDate(state) -> date
 *
 * Selector for oldest date set in chat component
 */
export const selectOldestDate = (state) => state.messenger.oldestDate;

const getMessages = (state) => state.messenger.messages;
const getSystemActiveOu = (state) => state.system.activeOu;

export const selectUnreadMessages = createSelector(
  [getMessages, getSystemActiveOu],
  (messages, activeOu) => {
    const users = messages.map(({ author, authorName }) => ({
      key: author,
      fullName: authorName,
    }));

    const unreadMessages = uniqBy(convertToParticipants(users, activeOu, messages), 'key')?.filter(
      ({ conversations }) => conversations && !conversations.hasBeenRead
    );

    return unreadMessages || 0;
  }
);

export const selectUnreadCount = (state) => selectUnreadMessages(state).length;
