import React from 'react';

import { configureRoute } from '../../../config';
import { BasicRouteFrame } from './RouteFrame';

import { AppLogin } from '../AppLogin';

const loginRoute = configureRoute({
  name: 'Login',
  path: '/login',
  RouteFrame: BasicRouteFrame,
  render: (props) => <AppLogin {...props} />,
});

export const systemRoutes = [loginRoute];
