import { orderBy } from 'lodash';

/**
 * selectPerformances(state) -> [performances]
 *
 * Selector for all performances
 */
export const selectPerformances = (state) =>
  orderBy(state.reports.performances.data.summary, ['driverName', 'score'], ['asc', 'desc']);

/**
 * selectPerformanceDetails(state) -> performanceDetails
 *
 * Selector for all performanceDetails
 */
export const selectPerformanceDetails = (state) => state.reports.performances.data.detail;
