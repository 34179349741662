import _, {
  groupBy,
  forEach,
  orderBy,
  flatten,
  uniq,
  find,
  some,
  partialRight,
  isUndefined,
  sortBy,
  map,
  union,
  clone,
} from 'lodash';

import moment from 'moment';

import { addMessageNotification, enhanceMessages } from './notifications';

let appThreads = [];

function addThreadMessages(threads, messages) {
  const enhancedMsgs = enhanceMessages(messages);

  enhancedMsgs.forEach((message) => {
    const thread = threads.find((t) => t.threadId === message.threadId) || {
      threadId: message.threadId,
      messages: [],
      ous: [],
    };
    thread.messages = thread.messages.filter((m) => m.key !== message.key).concat(message);
    thread.ous.push(
      ...[
        message.viewRecipient.type === 'Organization' ? message.viewRecipient.reference : null,
        message.viewSender.type === 'Organization' ? message.viewSender.reference : null,
      ]
        .filter(Boolean)
        .filter((ou) => !thread.ous.includes(ou))
    );
    if (!threads.includes(thread)) threads.push(thread);
  });
  return threads;
}

function sortThreadMessages(thread) {
  const cloneThread = clone(thread);
  cloneThread.messages = sortBy(
    thread.messages,
    (message) => message && message.sent && message.sent.date
  )
    .valueOf()
    .reverse();

  return cloneThread;
}

function determineThreadRecipient(thread) {
  const cloneThread = clone(thread);
  const recipient = map(cloneThread.messages, 'viewRecipient');
  const sender = map(cloneThread.messages, 'viewSender');
  let participants = union(recipient, sender);
  participants = _(participants)
    .flatten()
    .reject(isUndefined)
    .uniq(false, 'key')
    .filter({ type: 'User' })
    .valueOf();

  [cloneThread.recipient] = participants;

  return cloneThread;
}

/**
 * Sets a title for the thread, based on the recipient.
 *
 * In the future, this will account for broadcasts.
 *
 * @param {Object} thread
 * @return {Object}
 */
function setThreadTitle(thread) {
  const cloneThread = clone(thread);
  cloneThread.title = !isUndefined(cloneThread.recipient)
    ? cloneThread.recipient.name
    : 'Unknown recipient';
  return cloneThread;
}

/**
 * Extracts the original message to a thread, for convenient access.
 *
 * @param {Object} thread
 * @return {Object}
 */
function setOriginal(thread) {
  const cloneThread = clone(thread);
  if (!isUndefined(cloneThread.original)) {
    return cloneThread;
  }

  cloneThread.original = find(thread.messages, { key: thread.threadId });
  return cloneThread;
}

/**
 * Get the Date object of the latest message in the thread
 *
 * @param  {Object} thread
 * @return {Date}
 */
function latestThreadMessage(thread) {
  if (thread.messages[0] && thread.messages[0].sent)
    return moment(thread.messages[0].sent.date).toDate();
  return false;
}

export const getThreads = (filter) => {
  if (isUndefined(filter)) return appThreads;
  return filter(appThreads, filter);
};

export const updateThreads = (messages) => {
  appThreads = _(appThreads)
    .tap(partialRight(addThreadMessages, messages)) // add messages
    .map(sortThreadMessages) // sort thread messages
    .map(determineThreadRecipient) // determine thread recipient
    .map(setThreadTitle) // set thread title
    .map(setOriginal) // shortcut original thread message
    .sortBy(latestThreadMessage) // sort threads
    .valueOf()
    .reverse(); // reverse sort (latest first)

  return appThreads;
};

export const groupThreadsByUser = (threads) => {
  const groups = groupBy(threads, 'recipient.reference');
  const threadArr = [];
  forEach(groups, (item) => {
    const messages = orderBy(flatten(item.map((trd) => trd.messages)), 'date', 'desc');
    const ous = uniq(flatten(item.map((trd) => trd.ous)));
    const thread = find(threads, (o) => o.threadId === messages[0].threadId);
    const hasBeenRead = some(messages[0].events, (o) => o.event === 'Read');

    if (thread.recipient) {
      const conversation = {
        messages,
        ous,
        threadId: messages[0].threadId,
        recipient: thread.recipient,
        title: thread.title,
        hasBeenRead,
        isMe: messages[0].viewSender.type !== 'User', // If we sent the message we don't want it show as a notification
      };

      if (!hasBeenRead && !conversation.isMe) {
        addMessageNotification(conversation);
      }

      threadArr.push(conversation);
    }
  });
  return threadArr;
};
