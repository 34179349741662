import { connect } from 'react-redux';

import { breadcrumbs, fuelConsumptions } from '../../../../data/reports';
import { FuelConsumptionLocationReport } from './FuelConsumptionLocationReport';

export const FuelConsumptionLocationReportContainer = connect(
  (state, { fuelConsumptionId }) => ({
    fuelConsumption: fuelConsumptions.selectors.selectFuelConsumptionsById(
      state,
      fuelConsumptionId
    ),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
  }),
  { fetchFuelConsumption: fuelConsumptions.actions.fetchFuelConsumption }
)(FuelConsumptionLocationReport);
