import React from 'react';
import PropTypes from 'prop-types';

import { ReportAggregate } from './Aggregate';
import { ReportData } from './Data';
import { ReportFilter } from './Filter';
import { ReportMenu } from './Menu';
import { ReportQuery } from './Query';

import { ReportProvider } from '../../context/ReportContext';

import '../../css/Report.css';

export const Report = ({ children, ...props }) => (
  <ReportProvider values={{ ...props }}>
    <ReportMenu code={props.report.code} />
    <ReportQuery />
    <ReportFilter />
    <ReportAggregate />
    {children}
    <ReportData />
  </ReportProvider>
);

Report.propTypes = {
  children: PropTypes.node,
  report: PropTypes.object.isRequired,
};
