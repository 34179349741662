import { makeRestApiAction } from '../../../apiActionSupport';

import { ACTION_TYPE } from './reducer';

export const fetchNotificationTriggers = makeRestApiAction({
  service: 'notificationTriggers',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchNotificationTriggers,
  notificationsItemDescriptor: 'triggers',
});

export const fetchNotificationTrigger = makeRestApiAction({
  service: 'notificationTriggers',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchNotificationTrigger,
  getId: ({ id }) => id,
  notificationsItemDescriptor: 'triggers',
});

export const createNotificationTrigger = makeRestApiAction({
  service: 'notificationTriggers',
  method: 'post',
  baseActionType: ACTION_TYPE.createNotificationTrigger,
  notificationsItemDescriptor: 'notification',

  throwApiErrors: true,
});

export const updateNotificationTrigger = makeRestApiAction({
  service: 'notificationTriggers',
  method: 'put',
  baseActionType: ACTION_TYPE.updateNotificationTrigger,
  getId: ({ id }) => id,
  transformInput: ({ body }) => body,
  notificationsItemDescriptor: 'notification',
  throwApiErrors: true,
});

export const deleteNotificationTrigger = makeRestApiAction({
  service: 'notificationTriggers',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteNotificationTrigger,
  getId: ({ id }) => id,
  notificationsItemDescriptor: 'notification',
  transformOutput: (actionParams) => ({ id: actionParams.id }),
});

export const fetchNotificationHistories = makeRestApiAction({
  service: 'notificationHistories',
  method: 'get',
  transformInput: ({ startDate, endDate }) => ({
    startDate,
    endDate,
  }),
  transformOutput: ({ data, paging }) => data,
  baseActionType: ACTION_TYPE.fetchNotificationHistories,
});

export const updateNotificationHistories = makeRestApiAction({
  service: 'notificationHistories',
  method: 'post',
  baseActionType: ACTION_TYPE.updateNotificationHistories,
  transformInput: ({ ids, status }) => ({ ids, status }),
  transformOutput: (responseData, actionParam) => responseData,
  throwApiErrors: true,
});
