import { connect } from 'react-redux';

import {
  selectAccessToken,
  selectApi,
  selectCurrentOus,
  selectSystemActiveOu,
  selectSystemUser,
  selectSystemUserRole,
} from '../../data/system';

import { FleetTrackWrapper as FleetTrack } from './FleetTrack';

export const FleetTrackContainer = connect(
  (state) => ({
    api: selectApi(state),
    currentSystemOu: selectSystemActiveOu(state),
    currentSystemOus: selectCurrentOus(state),
    currentSystemUser: selectSystemUser(state),
    currentUserSystemRoles: selectSystemUserRole(state),
    token: selectAccessToken(state),
  }),
  {}
)(FleetTrack);
