import PropTypes from 'prop-types';
import BetterPropTypes from 'better-prop-types';

export const tenant = PropTypes.shape({
  brandPrimary: PropTypes.string,
  deviceCount: PropTypes.number,
  licenseCount: PropTypes.number,
  ecmState: PropTypes.string,
  enableCti: PropTypes.bool,
  enableCumminsEngineDiagnostics: PropTypes.bool,
  enableDispatch: PropTypes.bool,
  enabledVideoProviders: PropTypes.arrayOf(PropTypes.string),
  isSystem: PropTypes.bool,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  logo: PropTypes.string,
  name: BetterPropTypes.string.isOptionalButNotNull,
  personalUseDefault: PropTypes.bool,
  tenantAddress: BetterPropTypes.string.isOptionalButNotNull,
  tenantAdmin: BetterPropTypes.string.isOptionalButNotNull,
  active: PropTypes.bool,
  yardMovesDefault: PropTypes.bool,
  minimumInspectionDurationInMinutes: PropTypes.number,
});

export const tenants = PropTypes.arrayOf(tenant);
