import { connect } from 'react-redux';
import { InspectionDetailReport } from './InspectionDetailReport';

import { inspections } from '../../../../data/reports';

import { selectSystemUser } from '../../../../data/system';

export const InspectionDetailReportContainer = connect(
  (state, { inspectionId }) => ({
    inspection: inspections.selectors.selectInspectionById(state, inspectionId),
    user: selectSystemUser(state),
  }),
  {
    fetchInspection: inspections.actions.fetchInspection,
    updateInspectionDefectAndNote: inspections.actions.updateInspectionDefectAndNote,
    addInspectionNote: inspections.actions.addInspectionNote,
  }
)(InspectionDetailReport);
