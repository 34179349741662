import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import {
  Alert,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PrintIcon from '@mui/icons-material/Print';
import SettingsIcon from '@mui/icons-material/Settings';

import { printToPDF, exportToCsv, exportToExcel, exportToPDF } from '../../utils/datagrid';
import {
  defaultModalState,
  modalConfigProcessor,
  reportFormatOptions,
  saveAsDefaultReport,
  saveCurrentReport,
} from '../../utils/report';
import { NEW_REPORT_ID } from '../../utils/constants';
import { ReportContext } from '../../context/ReportContext';

import { ReportModal } from './Modal';

export const ReportMenu = ({ code }) => {
  const {
    agGridApi,
    api,
    currentSystemUsers,
    filteredDataSource,
    filters,
    loading,
    newReport,
    query,
    report,
    reportData,
    resetReport,
    revertReport,
    route,
    selectedReport,
    setLoading,
    setReportData,
    token,
  } = useContext(ReportContext);

  const [modal, setModal] = useState(defaultModalState);
  const [menu, setMenu] = useState({ id: null, el: null, open: false });
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const handleMenuOpen = (event, id) => {
    setMenu({ id, el: event.currentTarget, open: true });
  };

  const handleMenuClose = () => {
    setMenu({ id: null, el: null, open: false });
  };

  const otherReports = useMemo(() => {
    const reports = reportData?.filter((row) => !row.defaultView);
    return orderBy(reports, ['name'], ['asc']);
  }, [reportData]);

  const reportFormatSettings = useMemo(
    () => ({
      display: {
        dateFormat: reportFormatOptions('date', 'display'),
        distanceFormat: reportFormatOptions('distance', 'display'),
        durationFormat: reportFormatOptions('duration', 'display'),
        mileageFormat: reportFormatOptions('mileage', 'display'),
        volumeFormat: reportFormatOptions('volume', 'display'),
        weightFormat: reportFormatOptions('weight', 'display'),
      },
      export: {
        dateExportFormat: reportFormatOptions('date', 'export'),
        distanceExportFormat: reportFormatOptions('distance', 'export'),
        durationExportFormat: reportFormatOptions('duration', 'export'),
        mileageExportFormat: reportFormatOptions('mileage', 'export'),
        volumeExportFormat: reportFormatOptions('volume', 'export'),
        weightExportFormat: reportFormatOptions('weight', 'export'),
      },
    }),
    []
  );

  const enableScheduleReport = useMemo(() => {
    if (
      query.modeSelector &&
      includes(['latestInspectionsByVehicle', 'latestInspectionsByInspector'], query.modeSelector)
    ) {
      return true;
    }
    return (
      !!(query.dateRangeMode && query.dateRangeMode !== 'custom') || !!route.isAllowedToSchedule
    );
  }, [query, route]);

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f2f2f2',
        minHeight: '45px',
        padding: '0 0 0 10px',
      }}
    >
      <Grid size={6}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{ minWidth: 35, padding: '0 0 0 15px', margin: '10px 0', color: 'black' }}
            onClick={() => route.close()}
          />
          <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '10px 0' }}>
            {report?.title}
          </Typography>
          <Typography variant="h5" sx={{ margin: '10px 0' }}>
            {!selectedReport?.defaultView ? selectedReport?.name : ''}
          </Typography>
        </Stack>
      </Grid>
      <Grid size={6}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }} padding="0 20px 0 0">
          {code === 'DRIVER_LOGS_SUMMARY' && (
            <div>
              <Button
                variant="text"
                startIcon={<CloudUploadIcon />}
                sx={{ color: 'black' }}
                disabled={filteredDataSource.length === 0}
                onClick={(e) => handleMenuOpen(e, 'dataTransfer')}
              >
                Data Transfer
              </Button>
              <Menu
                anchorEl={menu?.el}
                open={menu.id === 'dataTransfer' && menu.open}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    modalConfigProcessor({
                      config: { type: 'general-purpose', content: filteredDataSource, route },
                      setModal,
                    });
                    handleMenuClose();
                  }}
                >
                  General Purpose
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    modalConfigProcessor({
                      config: { type: 'us-audit', content: filteredDataSource, route },
                      setModal,
                    });
                    handleMenuClose();
                  }}
                >
                  US Audit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    modalConfigProcessor({
                      config: { type: 'canada-audit', content: filteredDataSource, route },
                      setModal,
                    });
                    handleMenuClose();
                  }}
                >
                  Canada Audit
                </MenuItem>
              </Menu>
            </div>
          )}
          <Button
            variant="text"
            startIcon={<PrintIcon />}
            sx={{ color: 'black' }}
            disabled={reportData.length === 0}
            onClick={() => {
              if (!agGridApi) return;
              printToPDF(agGridApi);
            }}
          >
            Print
          </Button>
          <Button
            variant="text"
            startIcon={<CloudDownloadIcon />}
            sx={{ color: 'black' }}
            disabled={reportData.length === 0}
            onClick={(e) => handleMenuOpen(e, 'export')}
          >
            Export
          </Button>
          <Menu
            anchorEl={menu?.el}
            open={menu.id === 'export' && menu.open}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => exportToExcel(agGridApi, code)}>Export Excel</MenuItem>
            <MenuItem onClick={() => exportToCsv(agGridApi, code)}>Export CSV</MenuItem>
            <MenuItem onClick={() => exportToPDF(agGridApi, code)}>Export PDF</MenuItem>
          </Menu>
          <Button
            variant="text"
            startIcon={<SettingsIcon />}
            sx={{ color: 'black' }}
            disabled={reportData.length === 0}
            onClick={() => {
              modalConfigProcessor({
                config: { type: 'settings', content: reportFormatSettings, route },
                setModal,
              });
              handleMenuClose();
            }}
          >
            Settings
          </Button>
          <div>
            <Button
              variant="text"
              startIcon={<FolderOpenIcon />}
              sx={{ color: 'black' }}
              disabled={reportData.length === 0}
              onClick={(e) => handleMenuOpen(e, 'report')}
            >
              Report
            </Button>
            <Menu
              anchorEl={menu?.el}
              open={menu.id === 'report' && menu.open}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  route.open({ ...route.matched.values, reportViewId: 'default' });
                  newReport();
                }}
              >
                New
              </MenuItem>
              <MenuItem
                onClick={() => {
                  modalConfigProcessor({
                    config: { type: 'open', content: otherReports, route },
                    setModal,
                  });
                  handleMenuClose();
                }}
              >
                Open...
              </MenuItem>
              <MenuItem
                variant="contained"
                onClick={() => {
                  saveCurrentReport({
                    agGridApi,
                    api,
                    filters,
                    query,
                    selectedReport,
                    setLoading,
                    setModal,
                    setSnackbar,
                    token,
                  });
                  handleMenuClose();
                }}
                disabled={
                  selectedReport?.defaultView || selectedReport?.id === NEW_REPORT_ID || loading
                }
              >
                Save Current Report
              </MenuItem>
              <MenuItem
                onClick={() => {
                  modalConfigProcessor({
                    config: { type: 'saveAs', content: selectedReport, route },
                    setModal,
                  });
                  handleMenuClose();
                }}
              >
                Save As
              </MenuItem>
              <MenuItem
                onClick={() => {
                  saveAsDefaultReport({
                    agGridApi,
                    api,
                    filters,
                    query,
                    route,
                    selectedReport,
                    setReportData,
                    setSnackbar,
                    token,
                  });
                  handleMenuClose();
                }}
              >
                Save As Default
              </MenuItem>
              <MenuItem
                onClick={() => {
                  revertReport({ selectedReport });
                  handleMenuClose();
                }}
              >
                Revert Report to {selectedReport?.defaultView ? 'Default' : 'Saved'}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  resetReport({ selectedReport });
                  handleMenuClose();
                }}
              >
                Reset Report
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  modalConfigProcessor({
                    config: { type: 'email-report', content: currentSystemUsers, route },
                    setModal,
                  });
                  handleMenuClose();
                }}
              >
                Email Report...
              </MenuItem>
              <MenuItem
                onClick={() => {
                  modalConfigProcessor({
                    config: { type: 'schedule-report', content: currentSystemUsers, route },
                    setModal,
                  });
                  handleMenuClose();
                }}
                disabled={!enableScheduleReport}
              >
                Schedule Report...
              </MenuItem>
              <MenuItem
                onClick={() => {
                  modalConfigProcessor({
                    config: {
                      type: 'manage-reports',
                      content: reportData.filter((r) => !r.defaultView),
                      route,
                    },
                    setModal,
                  });
                  handleMenuClose();
                }}
              >
                Manage Reports...
              </MenuItem>
            </Menu>
          </div>
        </Stack>
      </Grid>
      {modal?.open && (
        <ReportModal
          dataSource={otherReports}
          config={modal}
          setModal={setModal}
          setSnackbar={setSnackbar}
        />
      )}
      {snackbar.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ open: false, message: '', severity: 'info' })}
        >
          <Alert severity={snackbar.severity} variant="filled" sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
};

ReportMenu.propTypes = {
  code: PropTypes.string.isRequired,
};
