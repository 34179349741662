import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { Controller } from 'react-hook-form';

import { ReportContext } from '../../../context/ReportContext';

const hasReportName = (reports, name) => reports.find((r) => r.name === name);

export const ReportEditForm = ({ control, formState, trigger, formData }) => {
  const { currentSystemOu, reportData } = useContext(ReportContext);

  useEffect(() => {
    trigger('name');
  }, []);

  return (
    <form>
      <Controller
        name="name"
        control={control}
        rules={{
          required: true,
          validate: (value) => {
            if (value === formData.name) return true;
            if (hasReportName(reportData, value)) {
              return 'This report name already exists.';
            }
            return true;
          },
        }}
        render={({ field }) => (
          <FormControl variant="standard" fullWidth>
            <TextField
              {...field}
              sx={{ mb: 1 }}
              label="Name"
              variant="standard"
              error={!!formState?.errors?.name}
              helperText={formState?.errors?.name?.message}
            />
          </FormControl>
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <FormControl variant="standard" fullWidth>
            <TextField {...field} sx={{ mb: 1 }} label="Description" variant="standard" />
          </FormControl>
        )}
      />
      <Controller
        name="isShared"
        control={control}
        render={({ field }) => (
          <FormGroup>
            <FormControlLabel
              control={<Switch {...field} sx={{ mb: 1 }} checked={field.value} />}
              label={`Shared with ${currentSystemOu.name}`}
            />
          </FormGroup>
        )}
      />
    </form>
  );
};

ReportEditForm.propTypes = {
  control: PropTypes.object,
  formData: PropTypes.object,
  formState: PropTypes.object,
  reportData: PropTypes.array,
  trigger: PropTypes.func,
};
