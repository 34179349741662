import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

import { DATA_TEST } from '../utils/constants';

export const DatePicker = ({ disabled, field, label, variant = 'standard' }) => {
  const inputRef = (element) => {
    if (element) {
      element.setAttribute(DATA_TEST, field.name);
    }
  };
  return (
    <MuiDatePicker
      label={label}
      slotProps={{ textField: { variant } }}
      disabled={disabled}
      inputRef={inputRef}
      {...field}
    />
  );
};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
};
