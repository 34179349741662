import { connect } from 'react-redux';

import { GeozonesSummaryReport } from './GeozonesSummaryReport';
import { geozones } from '../../../../data/reports';

import { organization } from '../../../../data/administration/configurations';

export const GeozonesSummaryReportContainer = connect(
  (state) => ({
    geozones: geozones.selectors.selectGeozones(state),
  }),
  {
    fetchOrganizations: organization.actions.fetchOrganizationsOptimized,
    fetchGeozones: geozones.actions.fetchGeozones,
  }
)(GeozonesSummaryReport);
