import { omit, isNil, filter } from 'lodash';

import { makeRestApiAction, makeOneTimeOptimizedAction } from '../../../apiActionSupport';
import { ACTION_TYPE } from './reducer';
import { selectUsersFetchRequests } from './selectors';
import { removeDeleteParams } from '../../../../helpers/admin/adminActionSupport';

const fetchUsers = makeRestApiAction({
  service: 'users',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchUsers,
  transformInput: () => ({
    include: `disabled,deleted,memberships`,
  }),
  transformOutput: (responseData) =>
    responseData.map(({ memberships, ...rest }) => ({
      memberships: memberships && memberships.map(({ ou, roles }) => ({ ou, roles })),
      ...rest,
    })),
  notificationsItemDescriptor: 'user',
});

export const fetchUsersOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectUsersFetchRequests,
  filterFetches: (fetches, actionParam) => filter(fetches, { actionParam }),
  fetchAction: fetchUsers,
  baseActionType: ACTION_TYPE.fetchUsers,
});

const fetchUser = makeRestApiAction({
  service: 'users',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchUser,
  getId: (key) => key,
  transformInput: () => ({
    fetch: `homeTerminal,memberships.ou,memberships.role`,
  }),
  transformOutput: ({ memberships, ...rest }) => ({
    memberships: memberships && memberships.map(({ ou, roles }) => ({ ou, roles })),
    ...rest,
  }),
  notificationsItemDescriptor: 'user',
});

export const fetchUserOptimized = makeOneTimeOptimizedAction({
  selectFetches: selectUsersFetchRequests,
  filterFetches: (fetches, id) => filter(fetches, { actionParam: id }),
  fetchAction: fetchUser,
  baseActionType: ACTION_TYPE.fetchUser,
});

export const createUser = makeRestApiAction({
  service: 'users',
  method: 'post',
  baseActionType: ACTION_TYPE.createUser,
  transformInput: (data) => omit(data, isNil),
  notificationsItemDescriptor: 'user',
  throwApiErrors: true,
});

export const updateUser = makeRestApiAction({
  service: 'users',
  method: 'put',
  baseActionType: ACTION_TYPE.updateUser,
  getId: ({ key }) => key,
  transformInput: (data) => omit(data, isNil),
  notificationsItemDescriptor: 'user',
  throwApiErrors: true,
});

export const deleteUser = makeRestApiAction({
  service: 'users',
  method: 'delete',
  baseActionType: ACTION_TYPE.deleteUser,
  getId: ({ key }) => key,
  transformInput: removeDeleteParams,
  transformOutput: (responseData, actionParam) => actionParam,
  notificationsItemDescriptor: 'user',
});

export const restoreUser = makeRestApiAction({
  service: 'users',
  method: 'post',
  baseActionType: ACTION_TYPE.restoreUser,
  getId: ({ key }) => `${key}/restore`,
  transformOutput: (responseData, actionParam) => actionParam,
  notifications: {
    success: 'User restored.',
    failure: 'User could not be restored.',
  },
});
