import React, { createContext, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { apiGet } from '../utils/api';
import { createDateTime } from '../utils/date';
import { filterFn, getBooleanOptions } from '../utils/filters';
import { formatSettings } from '../utils/report';
import { LATEST_REPORT_VERSION, NEW_REPORT_ID } from '../utils/constants';
import { queryOus } from '../utils/query';

const ReportContext = createContext();

const ReportProvider = ({ children, values }) => {
  const {
    api,
    columnDefs,
    currentSystemOu,
    currentSystemOus,
    currentSystemUser,
    limits,
    report,
    route,
    title,
    token,
  } = values;

  const filterState = report?.filter?.controllers.reduce((acc, controller) => {
    const newAcc = { ...acc };
    if (controller.isBoolean) {
      newAcc[controller.id] = false;
    } else {
      newAcc[controller.id] = [];
    }
    return newAcc;
  }, {});

  const [agGridApi, setAgGridApi] = useState({});
  const [currentSystemUsers, setCurrentSystemUsers] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [filters, setFilters] = useState(filterState);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({});
  const [reportData, setReportData] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [settings, setSettings] = useState({});

  // Get report data
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!isMounted) return [];

      const reportViewUrl = `${api.baseUrl}reportViews?reportCode=${report.code}`;
      const reportResponse = await apiGet({ url: reportViewUrl, token });

      const usersViewUrl = `${api.originUrl}/api/admin/users/?include=disabled,deleted,memberships`;
      const usersResponse = await apiGet({ url: usersViewUrl, token });

      return [reportResponse?.data, usersResponse];
    };

    fetchData().then((response) => {
      if (isMounted) {
        setReportData(response[0]);
        setCurrentSystemUsers(response[1]);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const controllers = useMemo(() => {
    if (
      !report.filter ||
      isEmpty(report.filter) ||
      isEmpty(report.filter.controllers || isEmpty(dataSource) || dataSource.length === 0)
    ) {
      return null;
    }

    const controls = {};

    report.filter.controllers.forEach((controller) => {
      controls[controller.id] = {
        label: controller.label,
        uniq: controller.uniq,
        col: sortBy(
          dataSource
            .map((item) => (item ? item[controller.id] : null))
            .filter((item) => item !== null),
          controller.sort
        ),
      };

      if (controls[controller.id].col.some((item) => Array.isArray(item))) {
        controls[controller.id] = {
          label: controller.label,
          uniq: controller.uniq,
          col: sortBy(flatten(controls[controller.id].col), controller.sort),
        };
      }
    });

    Object.keys(controls).forEach((control) => {
      const controller = report.filter.controllers?.find(({ id }) => id === control);
      controls[control] = {
        col: controller.options
          ? getBooleanOptions(controller.options)
          : uniqBy(controls[control].col, controller.uniq),
        disabled: !!controller.disabled,
        isBoolean: !!controller.options,
        filter: controller.filter,
        label: controller.label,
        lookup: controller.sort, // this will result into fromTo method on Filter.js from key to value [usually name property]
      };
    });

    return controls;
  }, [report, dataSource]);

  // Filter report data based on query panel values and filter panel values
  useEffect(() => {
    const orgs = queryOus(query.ouMode, currentSystemOu, currentSystemOus, query.ousSelected);
    const criteria = { startDate: query.startDate, endDate: query.endDate, ous: orgs };

    const ouFilter = (item) => {
      if (query.ouMode === 'all') {
        return true;
      }
      return criteria.ous.length === 0
        ? false
        : criteria.ous.some((ou) => item.ous?.some((itemOu) => itemOu.key.toString() === ou));
    };

    if (isEqual(filters, filterState)) {
      return setFilteredDataSource(
        dataSource.filter(
          (item) =>
            item.startDate >= criteria.startDate &&
            item.endDate <= criteria.endDate &&
            ouFilter(item)
        )
      );
    }

    return setFilteredDataSource(
      dataSource
        .filter((item) =>
          report.filter.controllers.every(
            (controller) =>
              filters[controller.id]?.length === 0 ||
              (Array.isArray(item[controller.id])
                ? item[controller.id]?.some((subItem) =>
                    filters[controller.id]?.includes(subItem?.key)
                  )
                : filterFn(filters[controller.id], item[controller.id]))
          )
        )
        .filter(
          (item) =>
            item.startDate >= criteria.startDate &&
            item.endDate <= criteria.endDate &&
            ouFilter(item)
        )
    );
  }, [filters, dataSource, query]);

  useEffect(() => {
    if (!reportData) return;

    const { reportViewId } = route.matched.values;
    const reportFound = reportData?.find((row) => row.id === reportViewId);
    const defaultReport = reportData?.find((row) => row.defaultView);
    const reportSelected = reportFound || defaultReport || reportData[0];

    const filtersFound = report.filter.controllers.map(({ id }) => id);

    const restoredFilters = Object.keys(reportSelected?.state?.controls || {})
      .filter((key) => filtersFound.includes(key))
      .reduce((obj, key) => {
        const newObj = { ...obj };
        newObj[key] = reportSelected.state.controls[key];
        return newObj;
      }, {});

    setSelectedReport(reportSelected);
    setFilters((prev) => ({ ...prev, ...restoredFilters }));
  }, [reportData, route]);

  // Initialize report format settings
  useEffect(() => {
    if (selectedReport?.state?.controls) {
      const { controls } = selectedReport.state;

      const reportSettings = {};
      Object.keys(controls).forEach((control) => {
        if (includes(formatSettings, control)) {
          reportSettings[control] = controls[control];
        }
      });
      setSettings({ ...reportSettings });
    }
  }, [selectedReport]);

  const newReport = () => {
    const defaultReport = reportData?.find((row) => row.defaultView);

    const { id, state, ...rest } = defaultReport;
    const reportToBeSetAsNew = {
      ...rest,
      defaultView: false,
      name: 'New report',
      description: '',
      id: NEW_REPORT_ID,
      state: {
        controls: {
          dateExportFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
          dateFormat: 'default',
          dateRangeFor: 'startedAt',
          dateRangeMode: 'today',
          endDate: createDateTime().toFormat('YYYY-MM-DD'),
          ouMode: 'default',
          ousSelected: [],
          startDate: createDateTime().toFormat('YYYY-MM-DD'),
        },
        dataGrid: {
          sortModel: [],
          filterModel: {},
          columnState: [],
        },
        version: LATEST_REPORT_VERSION,
      },
    };

    // this is necessary to avoid problematic state vs rendering issues with Controller component
    setSelectedReport(null);

    setTimeout(() => {
      setSelectedReport(reportToBeSetAsNew);
      setFilters(filterState);
    }, 100);
  };

  const revertReport = ({ selectedReport: reportToBeReverted }) => {
    setSelectedReport(null);

    const filtersFound = report.filter.controllers.map(({ id }) => id);

    const restoredFilters = Object.keys(reportToBeReverted?.state?.controls || {})
      .filter((key) => filtersFound.includes(key))
      .reduce((obj, key) => {
        const newObj = { ...obj };
        newObj[key] = reportToBeReverted.state.controls[key];
        return newObj;
      }, {});

    setTimeout(() => {
      setSelectedReport(reportToBeReverted);
      setFilters((prev) => ({ ...prev, ...restoredFilters }));
    }, 100);
  };

  const resetReport = ({ selectedReport: reportUsed }) => {
    setSelectedReport(null);

    const reportToBeReset = {
      ...reportUsed,
      state: {
        controls: {
          dateExportFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
          dateFormat: 'default',
          dateRangeFor: 'startedAt',
          dateRangeMode: 'today',
          endDate: createDateTime().toFormat('YYYY-MM-DD'),
          ouMode: 'default',
          ousSelected: [],
          startDate: createDateTime().toFormat('YYYY-MM-DD'),
        },
        dataGrid: {
          sortModel: [],
          filterModel: {},
          columnState: [],
        },
        version: LATEST_REPORT_VERSION,
      },
    };
    setTimeout(() => {
      setSelectedReport(reportToBeReset);
      setFilters(filterState);
    }, 100);
  };

  const value = useMemo(
    () => ({
      agGridApi,
      api,
      columnDefs,
      controllers,
      currentSystemOu,
      currentSystemOus,
      currentSystemUser,
      currentSystemUsers,
      dataSource,
      extraData,
      filteredDataSource,
      filters,
      limits,
      loading,
      newReport,
      query,
      report,
      reportData,
      resetReport,
      revertReport,
      route,
      selectedReport,
      setAgGridApi,
      setDataSource,
      setExtraData,
      setFilteredDataSource,
      setFilters,
      setLoading,
      setQuery,
      setReportData,
      setSelectedReport,
      setSettings,
      settings,
      title,
      token,
    }),
    [
      agGridApi,
      api,
      columnDefs,
      controllers,
      currentSystemOu,
      currentSystemOus,
      currentSystemUser,
      currentSystemUsers,
      dataSource,
      extraData,
      filteredDataSource,
      filters,
      limits,
      loading,
      query,
      report,
      reportData,
      route,
      selectedReport,
      settings,
      title,
      token,
    ]
  );

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
};

ReportProvider.propTypes = {
  children: PropTypes.node,
  values: PropTypes.object,
};

export { ReportContext, ReportProvider };
