import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NotificationTypeFields } from '../NotificationTypes/NotificationTypeFields/NotificationTypeFields';

export function NotificationCriteria({
  form,
  mode,
  geozones,
  vehicles,
  drivers,
  countriesList,
  availableProvinces,
  Control,
}) {
  // Prepare options for the various selects
  const geozoneOptions = useMemo(
    () => geozones.map((g) => ({ value: g.key, label: g.name })),
    [geozones]
  );

  const vehicleOptions = useMemo(
    () =>
      vehicles.map((v) => ({
        value: v.key,
        label: v.assetId || v.vin || 'Unnamed Vehicle',
      })),
    [vehicles]
  );

  const driverOptions = useMemo(
    () =>
      drivers.map((d) => ({
        value: d.key,
        label: d.fullName || `${d.firstName} ${d.lastName}`,
      })),
    [drivers]
  );

  return (
    <NotificationTypeFields
      formControls={form.controls}
      form={form}
      mode={mode}
      geozoneOptions={geozoneOptions}
      vehicleOptions={vehicleOptions}
      driverOptions={driverOptions}
      countriesList={countriesList}
      availableProvinces={availableProvinces}
      Control={Control}
    />
  );
}

NotificationCriteria.propTypes = {
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  geozones: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  availableProvinces: PropTypes.array.isRequired,
  Control: PropTypes.elementType.isRequired,
};

export default NotificationCriteria;
