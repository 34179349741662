import React from 'react';

import { NotificationSummaryContainer } from './Summary/connect';
import { NotificationDetailContainer } from './Detail/connect';

import { configureRoute } from '../../../../config/configureRoute';
import { BasicRouteFrame } from '../../../commons/AppRouter/RouteFrame';
import { SUPER_ADMIN_ROLE_KEY } from '../../../../data/system/constants';
import { ADMIN_ROLE_KEY, administrationGroups } from '../../../../config';
import { FEATURES } from '../../../../features';

export const notificationSummary = configureRoute({
  path: '/notifications',
  name: 'Notifications',
  RouteFrame: BasicRouteFrame,
  permissions: [SUPER_ADMIN_ROLE_KEY, ADMIN_ROLE_KEY],
  group: administrationGroups.configurations,
  order: 1,
  isDisabled: FEATURES.admin.hideNotifications,
  render: (props) => (
    <NotificationSummaryContainer
      {...props}
      openNotificationCreate={notificationCreate.open}
      openNotificationDetail={notificationDetail.open}
    />
  ),
});

export const notificationCreate = configureRoute({
  path: '/notification/new',
  switchKey: 'notification',
  RouteFrame: BasicRouteFrame,
  render: (props) => <NotificationDetailContainer {...props} mode="create" />,
});

export const notificationDetail = configureRoute({
  path: '/notification/:notificationId',
  switchKey: 'notification',
  RouteFrame: BasicRouteFrame,
  render: (props) => (
    <NotificationDetailContainer {...props} openNotificationDetail={notificationDetail.open} />
  ),
});
