import { convertStringToBoolean } from './formats';

export const filterFn = (filterControl, itemControl) => {
  if (typeof filterControl?.includes === 'function' && itemControl?.key) {
    return filterControl?.includes(itemControl.key);
  }

  return filterControl ? convertStringToBoolean(filterControl) === itemControl : true;
};

// This will generate the dynamic options for a boolean filter, key needs to be true, false or empty as string
export const getBooleanOptions = (options) => [
  { key: 'true', name: options?.find(({ key }) => key === true)?.label },
  { key: 'false', name: options?.find(({ key }) => key === false)?.label },
  { key: '', name: '(blank)' },
];
