// SectionCard.jsx
import React from 'react';
import PropTypes from 'prop-types';

export const SectionCard = ({ title, description, children }) => (
  <div className="section-card">
    <div className="section-card__header">
      <h2 className="section-card__header__title">{title}</h2>
      {description && <p className="section-card__header__description">{description}</p>}
    </div>
    <div className="section-card__body">{children}</div>
  </div>
);

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};
