import { connect } from 'react-redux';

import { PerformancesSummaryReport } from './PerformancesSummaryReport';
import { performances } from '../../../../data/reports';
import { selectSystemUser, selectSystemOus } from '../../../../data/system';

import { user } from '../../../../data/administration/resources';

export const PerformancesSummaryReportContainer = connect(
  (state) => ({
    performances: performances.selectors.selectPerformances(state),
    limits: { forAllOus: 6, forOu: 30 },
    user: selectSystemUser(state),
    users: user.selectors.selectUsers(state),
    ous: selectSystemOus(state),
  }),
  {
    fetchPerformances: performances.actions.fetchPerformances,
    fetchMyPerformances: performances.actions.fetchMyPerformances,
    fetchUsers: user.actions.fetchUsersOptimized,
  }
)(PerformancesSummaryReport);
