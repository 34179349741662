import { startDateWithTimeZoneToIso, endDateWithTimeZoneToIso } from '../../../helpers/moment';
import { makeRestApiAction } from '../../apiActionSupport';
import { ACTION_TYPE } from './reducer';

const transformForStore = ({ sinceFuelledDistance: distance, ...fuelling }) => {
  const transformedFuelling = {
    distance,
    ouKey: fuelling.ou.key,
    ...fuelling,
  };

  const { fuelType, addedFuel } = fuelling;

  switch (fuelType) {
    case 'Diesel':
      transformedFuelling.addedDiesel = addedFuel;
      transformedFuelling.dieselDistance = distance;
      break;
    case 'Gas':
      transformedFuelling.addedGas = addedFuel;
      transformedFuelling.gasDistance = distance;
      break;
    case 'LNG':
      transformedFuelling.addedLng = addedFuel;
      transformedFuelling.lngDistance = distance;
      break;
    default:
  }

  return transformedFuelling;
};

// fetchFuellings({ startDate, endDate, ouKey, timeZone })
export const fetchFuellings = makeRestApiAction({
  service: 'fuellings',
  method: 'get',
  baseActionType: ACTION_TYPE.fetchFuellings,
  transformInput: ({ startDate, endDate, ouKey, timeZone }) => ({
    ouKey,
    startDate: startDateWithTimeZoneToIso(startDate, timeZone),
    endDate: endDateWithTimeZoneToIso(endDate, timeZone),
  }),
  transformOutput: (data) => data.map(transformForStore),
  notifications: {
    failure: (actionParam, apiError) => `Couldn't retrieve data: ${apiError.message}`,
  },
  enableProgress: true,
});
