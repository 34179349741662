import React from 'react';

import { configureRoute } from '../../../config';
import { BasicRouteFrame } from './RouteFrame';

import { AppLogin } from '../AppLogin';
import { AppLoginProvider } from '../AppLoginProvider';

const loginRoute = configureRoute({
  name: 'Login',
  path: '/login',
  RouteFrame: BasicRouteFrame,
  render: (props) => <AppLogin {...props} />,
});

const loginProviderRoute = configureRoute({
  name: 'Login',
  path: '/login/provider/callback',
  RouteFrame: BasicRouteFrame,
  render: (props) => <AppLoginProvider {...props} />,
});

export const systemRoutes = [loginRoute, loginProviderRoute];
