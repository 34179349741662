import React from 'react';
import { Button, Clickable } from 'stti-react-common';
import { uniqueId } from 'lodash';

import { ToggleChip } from '../../../../commons/ToggleChip';

export const dataGridDefs = ({ openUserDetail, setTargetRow, openDeleteDialog }) => ({
  Actions: ({ data }) =>
    !data.deleted && (
      <>
        <Button
          onClick={() => {
            openUserDetail({ userId: data.key, mode: 'edit' });
          }}
          icon="edit"
        />
        <Button
          onClick={() => {
            setTargetRow(data);
            openDeleteDialog();
          }}
          icon="delete"
        />
      </>
    ),
  Email: ({ data, value }) => (
    <Clickable
      className="UserAdmin__itemAction--view"
      onClick={() => {
        if (!data.deleted) {
          openUserDetail({ userId: data.key, mode: 'view' });
        } else {
          openUserDetail({ userId: data.key, mode: 'restore' });
        }
      }}
    >
      {value}
    </Clickable>
  ),
  ToggleChip: ({ value }) => <ToggleChip value={value} />,
  Organizations: ({ value }) =>
    value && value.length > 0 ? (
      <ul>
        {value.map((row) => (
          <li key={Math.random()}>{row}</li>
        ))}
      </ul>
    ) : (
      '—'
    ),
  Roles: ({ value }) =>
    value && value.length > 0 ? (
      <ul>
        {value.map((row) => (
          // Using uniqueID here instead of a composite key because if I do this I will lose
          // the ability to print the correct values.
          <li key={uniqueId()}>{row}</li>
        ))}
      </ul>
    ) : (
      '—'
    ),
});
