import { connect } from 'react-redux';
import { sortBy, filter } from 'lodash';

import { FleetTracking } from './FleetTracking';

import { location, locationType, vehicleType, user } from '../../data/administration/resources';
import { fleetTracking } from '../../data/tracking';
import { selectSystemUser, selectSystemActiveOu, selectSystemOus } from '../../data/system';
import { messenger } from '../../data/messenger';
import { camera } from '../../data/administration/devices';
import {
  fetchDriverLogOptimized,
  fetchExceptions,
  fetchInspectorsLatestInspectionsOptimized,
  fetchVehiclesLatestInspectionsOptimized,
  selectDriverLogs,
  selectInspections,
  fetchVideoEventFileLocation,
} from '../../data/reports';

import { convertToParticipants } from '../../data/messenger/helpers';

export const FleetTrackingContainer = connect(
  (state) => ({
    bookmarks: fleetTracking.selectors.selectFleetTrackingBookmarks(state),
    currentSystemOu: selectSystemActiveOu(state),
    currentSystemUser: selectSystemUser(state),
    driverLogs: selectDriverLogs(state),
    fleetTrackCurrentShiftDetails: fleetTracking.selectors.selectFleetTrackingShiftDetail(state),
    fleetTrackData: fleetTracking.selectors.selectFleetTrackedData(state),
    inspections: selectInspections(state),
    locations: location.selectors.selectLocations(state),
    locationTypes: locationType.selectors.selectLocationTypes(state),
    messages: messenger.selectors.selectMessages(state),
    ous: selectSystemOus(state),
    participants: convertToParticipants(
      user.selectors.selectUsers(state),
      selectSystemActiveOu(state),
      messenger.selectors.selectMessages(state)
    ),
    users: sortBy(
      filter(user.selectors.selectUsers(state), { isDriver: true, _deleted: false }),
      (row) => row.fullName.toLowerCase()
    ),
    vehicleTypes: vehicleType.selectors.selectVehicleTypes(state),
  }),
  {
    deleteFleetTrackingBookmark: fleetTracking.actions.deleteFleetTrackingBookmark,
    fetchCameras: camera.actions.fetchCameras,
    fetchLiveStreamDataByCameraId: camera.actions.fetchLiveStreamDataByCameraId,
    fetchLiveSnapshotDataByCameraId: camera.actions.fetchLiveSnapshotDataByCameraId,
    fetchDriverLog: fetchDriverLogOptimized,
    fetchExceptions,
    fetchFleetTrackData: fleetTracking.actions.fetchFleetTrackedData,
    fetchFleetTrackingBookmark: fleetTracking.actions.fetchFleetTrackingBookmark,
    fetchFleetTrackingShiftDetails: fleetTracking.actions.fetchFleetTrackingShiftDetailOptimized,
    fetchInspectorsLatestInspections: fetchInspectorsLatestInspectionsOptimized,
    fetchLocations: location.actions.fetchLocationsOptimized,
    fetchLocationTypes: locationType.actions.fetchLocationTypesOptimized,
    fetchMessages: messenger.actions.fetchMessages,
    fetchUsers: user.actions.fetchUsersOptimized,
    fetchVehiclesLatestInspections: fetchVehiclesLatestInspectionsOptimized,
    fetchVehicleTypes: vehicleType.actions.fetchVehicleTypesOptimized,
    fetchVideoEventFileLocation,
    postFleetTrackingBookmark: fleetTracking.actions.postFleetTrackingBookmark,
    postMessageEvent: messenger.actions.postMessageEvent,
    updateFleetTrackingBookmark: fleetTracking.actions.updateFleetTrackingBookmark,
  }
)(FleetTracking);
