// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fwnEtlg5jF1q8eLJuIrEMA\\=\\={border:1px solid #ccc;border-radius:4px;overflow:hidden;background-color:#fff}.Vpy0VBddbBFySZBxEeXSrA\\=\\={display:flex;background-color:#f2f2f2;padding:8px 16px;border-bottom:1px solid #ddd}.xOv3q6t5t07IJh7YK-0DjA\\=\\={padding:4px 8px;font-size:14px;font-weight:bold;color:#444}.xOv3q6t5t07IJh7YK-0DjA\\=\\=:first-child{flex:0 0 25%}.xOv3q6t5t07IJh7YK-0DjA\\=\\=:nth-child(2){flex:0 0 25%;text-align:center}.xOv3q6t5t07IJh7YK-0DjA\\=\\=:nth-child(3){flex:0 0 50%}", "",{"version":3,"sources":["webpack://./src/components/administration/configurations/Notifications/Detail/NotificationTypes/shared/ToggleGrid.module.scss"],"names":[],"mappings":"AAEA,4BACI,qBAAA,CACA,iBAAA,CACA,eAAA,CACA,qBAAA,CAGF,4BACE,YAAA,CACA,wBAAA,CACA,gBAAA,CACA,4BAAA,CAGF,4BACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CAIF,wCACE,YAAA,CAIF,yCACE,YAAA,CACA,iBAAA,CAIF,yCACE,YAAA","sourcesContent":["/* ToggleGrid.module.scss */\n\n.toggle-grid {\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    overflow: hidden;\n    background-color: #fff;\n  }\n  \n  .toggle-grid-header {\n    display: flex;\n    background-color: #f2f2f2;\n    padding: 8px 16px;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .toggle-grid-cell {\n    padding: 4px 8px;\n    font-size: 14px;\n    font-weight: bold;\n    color: #444;\n  }\n  \n  /* First header cell (Type) */\n  .toggle-grid-cell:first-child {\n    flex: 0 0 25%;\n  }\n  \n  /* Second header cell (Toggle) */\n  .toggle-grid-cell:nth-child(2) {\n    flex: 0 0 25%;\n    text-align: center;\n  }\n  \n  /* Third header cell (Description) */\n  .toggle-grid-cell:nth-child(3) {\n    flex: 0 0 50%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-grid": "fwnEtlg5jF1q8eLJuIrEMA==",
	"toggle-grid-header": "Vpy0VBddbBFySZBxEeXSrA==",
	"toggle-grid-cell": "xOv3q6t5t07IJh7YK-0DjA=="
};
export default ___CSS_LOADER_EXPORT___;
