import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { toLower, uniq, isNil, isEmpty, find } from 'lodash';
import moment from 'moment';
import {
  BasicDialog,
  Button,
  Clickable,
  Form,
  Icon,
  NumberInput,
  ProgressOverlay,
} from 'stti-react-common';

import { NA_VALUE } from '../../../../../../helpers/formats';
import { ControlledDataGrid, ControlledDataTable } from '../../../../../commons/ControlledDataGrid';
import { PrintLayout } from '../../../../../commons/PrintLayout';

import { FiltersPanelDetail } from '../../common/filters';
import { AggregatesPanel } from '../../common/aggregators';
import { useFormats } from '../../../../../commons/Formats';

import {
  ControlsTablePrint,
  DateRangeAnalytics,
  FetchReportDetail,
  QueryPanel,
  ReportFragment,
  MapIcon,
  ReportLoader,
  ReportProvider,
  SectionPrint,
  SignalWebclientViewReady,
  useReportController,
} from '../../../../../commons/ReportsCommon';

import { columnDefs } from './columnDefs';

import './detail.scss';

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: { dateRangeMode: '1q', selectedYear: moment().format('YYYY') },
  dataGrid: createState({ columnDefs }),
});

const { useFormController, Control } = Form;

const requestServices = ['fuelTaxes'];

export const FuelTaxDetailReport = ({
  activeOu,
  breadcrumbsByVehicleKey,
  fetchFuelTax,
  fetchFuelTaxExtraSegments,
  fuelTaxDetails = [],
  fuelTaxId,
  openFuelTaxMapReportRoute,
  postFuelTaxEdits,
  quarter,
  reportViewId,
  route,
  shiftDetailReportRoute,
  taxRates = [],
  vehicleKey,
  jurisdictionState,
}) => {
  const { formatUnit, convertUnit, unconvertUnit } = useFormats();

  const [modalConfig, setModalConfig] = useState({ show: false, id: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [currentVal, setCurrentVal] = useState(null);

  const editForm = useFormController({
    controls: {},
  });

  const { unitSystem } = activeOu;

  // INITIALIZE REPORT -----------------------------------------------------

  const REPORT_TYPE = 'fuelTaxDetail';

  const report = useReportController({
    reportType: REPORT_TYPE,
    reportTypeName: 'Fuel Tax Detail Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: fuelTaxDetails,
      supplements: { breadcrumbsByVehicleKey },
      chain: ['query', 'resource', 'map'],
    },
  });

  const { controls, filteredRows, dataGridController, reportType, setControl } = report;
  const { controls: formControls, hasErrors: hasFormErrors, resetControls } = editForm;

  const editReport = () => {
    const payload = {
      reportCode: 'FUEL_TAX_DETAIL',
      recordId: currentVal.id,
      propertyName: currentVal.key,
      propertyValue:
        currentVal.key === 'addedFuel'
          ? formControls[currentVal.key]
          : unconvertUnit('distance', formControls[currentVal.key]),
    };

    setIsLoading(true);
    setCurrentVal(null);
    postFuelTaxEdits(payload).then(() => {
      resetControls();
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const ouKeys = fuelTaxDetails.map(({ ouKey }) => ouKey); // we will get the 1st row to set some basic control values
    if (fuelTaxDetails.length > 0 && quarter) {
      setControl('ouMode', 'custom');
      setControl('dateRangeMode', toLower(quarter.slice(0, 2)));
      setControl('selectedYear', quarter.slice(2));
      setControl('ouKeys', ouKeys);
      setControl('ousSelected', ouKeys);
      setControl('jurisdictionState', [jurisdictionState]);
      setIsLoading(false);
    }
  }, [fuelTaxDetails, breadcrumbsByVehicleKey]);

  // INITIALIZE DATAGRID START -----------------------------------------------------
  const dataGridComponents = useDataGridComponents({
    DriverNameToShiftReport: ({ data: { shiftId, driver } }) =>
      shiftId ? (
        <div className="LaunchDriverShift">
          <Clickable
            onClick={() => shiftDetailReportRoute.open({ shiftId, reportViewId: 'default' })}
          >
            <Icon icon="person_pin" />
          </Clickable>
          <span className="DriverName">{driver && driver.name ? driver.name : '-'}</span>
        </div>
      ) : (
        <div className="LaunchDriverShift">
          <Icon className="disabled" icon="person_pin" />
          <span className="DriverName">{driver && driver.name ? driver.name : '-'}</span>
        </div>
      ),
    OpenMap: ({ data: { fuelTaxId: fuelId, id } }) => (
      <Clickable
        onClick={() =>
          openFuelTaxMapReportRoute.open({
            fuelTaxId: fuelId,
            fuelTaxDetailId: id,
            reportViewId: 'default',
          })
        }
      >
        <MapIcon />
      </Clickable>
    ),
    OpenMapHeader: asDataGridHeaderComponent(() => <MapIcon />),
    JsonEdits: ({
      data: {
        json: { edits },
      },
    }) => (
      <ul className="edits">
        {edits.map(({ property, newValue, userName }, index) => (
          <li key={index}>
            {property === 'addedFuel'
              ? `${property} = ${formatUnit('volume', newValue)} - ${userName}`
              : `${property} = ${formatUnit('distance', newValue)} - ${userName}`}
          </li>
        ))}
      </ul>
    ),
    OpenEditHeader: asDataGridHeaderComponent(() => <Icon icon="edit" />),
    OpenEdit: ({ data: { id } }) => (
      <Clickable onClick={() => setModalConfig({ show: true, id })}>
        <Icon icon="edit" />
      </Clickable>
    ),
  });

  const fuelTypes = useMemo(() => uniq(taxRates.map(({ fuelType }) => fuelType)), [taxRates]);

  const vehicle = useMemo(
    () =>
      (
        find(fuelTaxDetails, ({ vehicle: { key } }) => key === vehicleKey) || {
          vehicle: { name: NA_VALUE },
        }
      ).vehicle,
    [fuelTaxDetails]
  );

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportDetail action={fetchFuelTax} args={{ fuelTaxId }} />
      <FetchReportDetail
        action={fetchFuelTaxExtraSegments}
        args={{ fuelTaxId, vehicleKey, quarter }}
      />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <ProgressOverlay isOpen={isLoading} />
      <div className="FuelTaxDetailReport">
        <ReportFragment.Header reportType={reportType} route={route} services={requestServices}>
          <Button
            icon="directions_car"
            label={vehicle && vehicle.name}
            tooltip={vehicle && vehicle.typeName}
            onClick={() => {}}
          />
        </ReportFragment.Header>
        <QueryPanel
          queryFor="segment"
          group="query"
          defaultDateRangeFor="startedAt"
          selectedDateRange={controls.dateRangeMode}
          disableQueryPanel
          disableNonAccessibleMessage
          filterDateRange={['1q', '2q', '3q', '4q']}
          isUtc
        />
        <FiltersPanelDetail
          rows={filteredRows.query}
          quarter={quarter}
          group="filters"
          filterGroup="resource"
          fuelTaxId={fuelTaxId}
        />
        <AggregatesPanel rows={filteredRows.dataGrid} fuelTypes={fuelTypes} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} autoHeightColumns />
      </div>
      {modalConfig && modalConfig.show && (
        <BasicDialog
          title="Choose Property to Edit"
          maxWidth="lg"
          isOpen={modalConfig.show}
          onClose={() => setModalConfig({ show: false, id: '' })}
          buttons={[{ label: 'Close', onClick: () => setModalConfig({ show: false, id: '' }) }]}
        >
          <Button
            icon="local_gas_station"
            variant="contained"
            label="Taxed Qty Purchased"
            onClick={() => {
              setCurrentVal({
                key: 'addedFuel',
                label: 'Taxed Qty Purchased',
                format: 'Liters',
                id: modalConfig.id,
                value: parseFloat(
                  convertUnit(
                    'volume',
                    (
                      filteredRows.dataGrid.find(({ id }) => modalConfig.id === id) || {
                        addedFuel: 0,
                      }
                    ).addedFuel
                  ).toFixed(0)
                ),
              });
              setModalConfig({ show: false, id: '' });
            }}
          />
          <Button
            icon="payments"
            variant="contained"
            label="Taxable Dist (On Hwy)"
            onClick={() => {
              setCurrentVal({
                key: 'onHighwayDistance',
                label: 'Taxable Dist (On Hwy)',
                format: unitSystem === 'Metric' ? 'Kilometers' : 'Miles',
                id: modalConfig.id,
                value: parseFloat(
                  convertUnit(
                    'distance',
                    (
                      filteredRows.dataGrid.find(({ id }) => modalConfig.id === id) || {
                        onHighwayDistance: 0,
                      }
                    ).onHighwayDistance
                  ).toFixed(0)
                ),
              });
              setModalConfig({ show: false, id: '' });
            }}
          />
          <Button
            icon="money_off"
            variant="contained"
            label="Non-Taxable Dist (Off Hwy)"
            onClick={() => {
              setCurrentVal({
                key: 'offHighwayDistance',
                label: 'Non-Taxable Dist (Off Hwy)',
                format: unitSystem === 'Metric' ? 'Kilometers' : 'Miles',
                id: modalConfig.id,
                value: parseFloat(
                  convertUnit(
                    'distance',
                    (
                      filteredRows.dataGrid.find(({ id }) => modalConfig.id === id) || {
                        offHighwayDistance: 0,
                      }
                    ).offHighwayDistance
                  ).toFixed(0)
                ),
              });
              setModalConfig({ show: false, id: '' });
            }}
          />
        </BasicDialog>
      )}

      {!isEmpty(currentVal) && (
        <BasicDialog
          title="Edit Report"
          maxWidth="lg"
          isOpen={!isEmpty(currentVal)}
          onClose={() => setCurrentVal(false)}
          buttons={[
            { label: 'Close', onClick: () => setCurrentVal(false) },
            {
              label: 'Edit Report',
              onClick: () => editReport(),
              disabled: hasFormErrors,
            },
          ]}
        >
          <div className="EditFormContainer">
            <Form form={editForm}>
              {formControls[currentVal.key] !== currentVal.value && (
                <p>
                  Original Value:{' '}
                  <strong>
                    {currentVal && currentVal.value} {currentVal && currentVal.format}
                  </strong>
                </p>
              )}
              <p>
                Enter the new value in <strong>{currentVal && currentVal.format}</strong>
              </p>
              <Control
                Component={NumberInput}
                name={currentVal && currentVal.key}
                label={currentVal && currentVal.label}
                defaultValue={currentVal.value}
                error={({ value }) => {
                  if (isNil(value)) {
                    return true;
                  }
                  if (value === currentVal.value) {
                    return 'New value must be different from current value';
                  }
                  return false;
                }}
              />
            </Form>
          </div>
        </BasicDialog>
      )}
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesPanel rows={filteredRows.query} fuelTypes={fuelTypes} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.dataGrid} />
      </PrintLayout>
    </ReportProvider>
  );
};

FuelTaxDetailReport.propTypes = {
  activeOu: PropTypes.object,
  breadcrumbsByVehicleKey: PropTypes.object,
  fetchFuelTax: PropTypes.func.isRequired,
  fuelTaxDetails: PropTypes.array,
  fuelTaxId: PropTypes.string.isRequired,
  openFuelTaxMapReportRoute: PropTypes.object.isRequired,
  postFuelTaxEdits: PropTypes.func.isRequired,
  quarter: PropTypes.string.isRequired,
  reportViewId: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  shiftDetailReportRoute: PropTypes.object.isRequired,
  taxRates: PropTypes.array,
  vehicleKey: PropTypes.string.isRequired,
  fetchFuelTaxExtraSegments: PropTypes.func.isRequired,
  jurisdictionState: PropTypes.string.isRequired,
};
