import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';
import { Button, SplitPane, ToggleInput } from 'stti-react-common';
import { useHistory } from 'react-router-dom';

import {
  adminGridOptions as gridOptions,
  ControlledDataGrid,
} from '../../../../commons/ControlledDataGrid';
import { makeColumnDefs } from './columnDefs';
import { useDebounce } from '../../../../../helpers/hooks';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { Feature } from '../../../../commons/Feature';
import { AdminSearchPanel, AdminColorDisplay } from '../../../../commons/AdminCommon';
import { NaviMap } from '../../../../commons/NaviMap';
import { useReportController, ReportProvider } from '../../../../commons/ReportsCommon';
import { useFormats } from '../../../../commons/Formats';

import { FEATURES } from '../../../../../features';

import './summary.scss';

const { useDataGridComponents, useDataGridController, createState } = ControlledDataGrid;

const filterLocations = (locations, showSystem) => {
  if (!locations) return [];
  return locations.filter((location) => (!showSystem ? location.systemZone === false : true));
};

export const LocationSummary = ({
  tenant,
  locations,
  fetchLocations,
  isUserSuperAdmin,
  isUserAdmin,
  fetchLocationTypes,
  openLocationTypes,
  route,
}) => {
  const [currentDialog, setCurrentDialog] = useState(null);
  const [targetRow, setTargetRow] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [showSystem, setShowSystem] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [focus, setFocus] = useState(null);
  const [splitPaneValue, setSplitPaneValue] = useState(60);

  const debouncedSearchText = useDebounce(searchText, 500, '');
  const { formatUnit } = useFormats();
  const history = useHistory();

  useEffect(() => {
    Promise.all([fetchLocations(), fetchLocationTypes()]).finally((responses) => {
      if (responses && responses[0].length > 0) {
        setFilteredLocations(filterLocations(responses[0], showSystem));
      }
    });
  }, []);

  useEffect(() => {
    setFocus(flatten(filterLocations(locations, showSystem).map((location) => location.locations)));
  }, [filteredLocations]);

  useEffect(() => {
    setFilteredLocations(filterLocations(locations, showSystem));
  }, [showSystem, locations]);

  useEffect(() => {
    dataGridController.setState(
      createState({ columnDefs: makeColumnDefs(tenant, isUserSuperAdmin) })
    );
    setFilteredLocations(filterLocations(locations, showSystem));
  }, [tenant, isUserSuperAdmin]);

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs: makeColumnDefs(tenant, isUserSuperAdmin),
  });

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) =>
      !data.deleted && (
        <>
          <Button
            onClick={() => {
              setTargetRow(data);
              setCurrentDialog('editProperties');
            }}
            icon="edit"
          />
          {!data.systemZone && (
            <Button
              onClick={() => {
                setTargetRow(data);
                setCurrentDialog('delete');
              }}
              icon="delete"
            />
          )}
        </>
      ),
    Color: ({ value }) => <AdminColorDisplay color={value} />,
    Limits: ({ value }) =>
      value && value.length > 0 ? (
        <ul className="LocationsAdmin__tirePressuresList">
          {value.map((limit) => (
            <li key={limit.groupId + limit.loaded}>
              {limit.groupId} : {formatUnit('pressure', limit.maximumLimit)}{' '}
              {limit.loaded ? ' (L)' : ' (U)'}
            </li>
          ))}
        </ul>
      ) : (
        '—'
      ),
  });

  const report = useReportController();

  const onGeozoneClicked = (geozone) => {
    if (!geozone) return;
    dataGridController.methods.scrollToRow((row) => row.id === geozone.key);
    dataGridController.setState({ selectedRowIds: [geozone.key] });
  };

  return (
    <Feature.Wrapper className="LocationSummary">
      <Feature.Header onClose={route.close} title="Geozones / Locations" service="locations">
        {FEATURES.admin.hideVehicleTypesFromMainMenu && (isUserSuperAdmin || isUserAdmin) && (
          <Button
            icon="share_location"
            variant="contained"
            label="Location Types"
            onClick={() =>
              openLocationTypes({ ...route.matched.values }, { history, root: 'administration' })
            }
          />
        )}
      </Feature.Header>
      <SplitPane
        split="horizontal"
        value={splitPaneValue}
        onChange={(value) => setSplitPaneValue(value)}
      >
        <ReportProvider value={report}>
          <NaviMap
            focus={focus}
            onSelect={onGeozoneClicked}
            selectedGeozone={{ geozone: targetRow, mode: currentDialog }}
            onReset={() => setCurrentDialog('')}
          />
        </ReportProvider>
        {/* This react fragment below is the SECOND container for split pane */}
        <>
          <AdminSearchPanel
            searchPlaceHolder="Search Locations"
            searchText={searchText}
            onSearchTextChanged={setSearchText}
            renderPanelActions={
              <ToggleInput
                label="Show System Locations"
                value={showSystem}
                onChange={() => {
                  setShowSystem(!showSystem);
                }}
              />
            }
          />
          <ControlledDataGrid
            theme="balham"
            controller={dataGridController}
            components={dataGridComponents}
            rowIdProperty="key"
            rows={filteredLocations}
            searchText={debouncedSearchText}
            onRowClick={(row) => {
              setFocus(row.locations);
              dataGridController.setState({ selectedRowIds: [row.key] });
            }}
            autoHeightColumns
          />
        </>
      </SplitPane>
    </Feature.Wrapper>
  );
};

LocationSummary.propTypes = {
  tenant: customPropTypes.tenant,
  isUserSuperAdmin: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  locations: PropTypes.arrayOf(customPropTypes.location).isRequired,
  fetchLocations: PropTypes.func.isRequired,
  fetchLocationTypes: PropTypes.func.isRequired,
  openLocationTypes: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
};
