import { createSelector } from 'reselect';

import { inOuBasedTime } from '../../system';
import { organization } from '../../administration/configurations';

const getUsers = (state) => state.administration.resources.users.data;
const getFuellings = (state) => state.reports.fuellings.fuellings;

/**
 * selectFuellings(state) -> [fuellingEvent]
 *
 * Selector for all fuelling events
 */
export const selectFuellings = createSelector(
  [getFuellings, organization.selectors.selectOrganizations, getUsers],
  (fuellings, ous, users) =>
    fuellings.map((fuelling) => inOuBasedTime(fuelling, ous, undefined, undefined, users))
);
