import { connect } from 'react-redux';

import { signIn, selectSystemUser, selectSystemOus, setMenu } from '../../../data/system';

import { AppLoginProvider } from './page';

export const AppLoginProviderContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    ous: selectSystemOus(state),
  }),
  { signIn, setMenu }
)(AppLoginProvider);
