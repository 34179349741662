import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { find, orderBy } from 'lodash';

import { guessTimezone } from '../../../helpers/moment';

const extractKey = (_, key) => key;
const getOrganizations = (state) => state.system.ous.data;
const getSpeedViolations = (state) => state.reports.speedViolations.speedViolations;

/**
 * selectSpeedViolations(state) -> [speedViolations]
 *
 * Selector for all speed violation events
 */

export const selectSpeedViolations = createSelector(
  [getSpeedViolations, getOrganizations],
  (violations, ous) => {
    const speedViolations = violations.map((violation) => {
      const organization = ous.find((ou) => ou.key === violation.ouKeys[0]);

      return {
        ...violation,
        ouKey: violation.ouKey || (organization && organization.key),
        timeZone: organization ? organization.timeZone : guessTimezone(),
      };
    });

    return orderBy(speedViolations, ['driverName'], ['asc']);
  }
);

/**
 * selectSpeedViolationsByKey(state, { key }) -> violation
 *
 * Selector for single violation by key
 */
export const selectSpeedViolationsByKey = createCachedSelector(
  [selectSpeedViolations, extractKey],
  (violations, key) => find(violations, { key })
)(extractKey);
