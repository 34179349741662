import React from 'react';
import PropTypes from 'prop-types';

import { AggregatesCard } from '../../../commons/ReportsCommon';
import {
  countOf,
  countUniqueOfField,
  countTruthyOfField,
  countValueOfField,
} from '../../../../helpers/reports/aggregation';
import { driverLogs } from '../../../../data/reports';

export const AggregatesContent = ({ rows }) => (
  <>
    <AggregatesCard
      title="Overview"
      name="overview"
      fields={[
        {
          header: 'Logs',
          value: countOf(rows),
        },
        {
          header: 'With Activity',
          value: countTruthyOfField(rows, 'hasActivity'),
        },
        {
          header: 'Drivers',
          value: countUniqueOfField(rows, 'driver.key'),
        },
      ]}
    />
    <AggregatesCard
      title="Violations"
      name="violations"
      fields={[
        {
          header: 'Unreviewed',
          value: countValueOfField(
            rows,
            'violationStatus',
            driverLogs.constants.UN_REVIEWED_VIOLATION_STATUS
          ),
        },
        {
          header: 'Reviewed',
          value: countValueOfField(
            rows,
            'violationStatus',
            driverLogs.constants.REVIEWED_VIOLATION_STATUS
          ),
        },
        {
          header: 'None',
          value: countValueOfField(
            rows,
            'violationStatus',
            driverLogs.constants.NO_VIOLATION_STATUS
          ),
        },
      ]}
    />
    <AggregatesCard
      title="ELD State"
      name="violations"
      fields={[
        {
          header: 'With Diagnostic',
          value: countTruthyOfField(rows, 'diagnosticIndication'),
        },
        {
          header: 'With Malfunction',
          value: countTruthyOfField(rows, 'malfunctionIndication'),
        },
      ]}
    />
  </>
);

AggregatesContent.propTypes = {
  rows: PropTypes.array,
};
