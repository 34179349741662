import { connect } from 'react-redux';

import { CyclesSummaryReport } from './CyclesSummaryReport';
import { trips, reportViews } from '../../../../data/reports';
import { selectSearchLimits } from '../../../../data/system';

export const CyclesSummaryReportContainer = connect(
  (state) => ({
    trips: trips.selectors.selectTrips(state),
    limits: selectSearchLimits(state, 'cycles'),
  }),
  {
    fetchTrips: trips.actions.fetchTrips,
    createLocalReportView: reportViews.actions.createLocalReportView,
  }
)(CyclesSummaryReport);
