import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card, Button } from 'stti-react-common';

import { HidePrint } from '../../../../commons/ReportsCommon';
import {
  ControlledDataGrid,
  ControlledDataTable,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import { adminStatusChangesColumnDefs } from './columnDefs';
import { PrintLayout } from '../../../../commons/PrintLayout';

const { useDataGridComponents, useDataGridController } = ControlledDataGrid;

const formatStatus = (dutyStatus) => {
  switch (dutyStatus) {
    case 'OFF_DUTY':
      return 'Off Duty';
    case 'ON_DUTY':
      return 'On Duty';
    case 'SLEEPER_BERTH':
      return 'Sleeper Berth';
    case 'DRIVING':
      return 'Driving';
    default:
      return dutyStatus;
  }
};

export const RequestedChangesCard = ({
  log,
  submitCreateLogDutyStatusEvent,
  printLayoutMode,
  hidePrint,
}) => {
  const { adminStatusChanges = [] } = log || {};
  const changesViewRef = useRef(null);

  const adminStatusChangesDataGridController = useDataGridController({
    columnDefs: adminStatusChangesColumnDefs,
    gridOptions,
  });

  useEffect(() => {
    adminStatusChangesDataGridController.methods.sizeColumnsToFit();
  }, [adminStatusChanges]);

  const adminStatusChangesDataGridComponents = useDataGridComponents({
    // eslint-disable-next-line react/prop-types
    Actions: ({ data }) => {
      const {
        date,
        dutyStatus,
        remarks,
        status,
        driver: dataDriver,
        timeZone: dataTimeZone,
        ...rest
      } = data;

      if (status !== 'INACTIVE_CHANGE_REJECTED') return '';
      return (
        <Button
          className="DriverLogDetailReport__EventsCard__actionButton"
          onClick={() => {
            submitCreateLogDutyStatusEvent({
              driver: dataDriver,
              dutyStatus: formatStatus(dutyStatus),
              key: `${moment(date).format('YYYY-MM-DD')}-${dataDriver}`,
              reason: remarks.split(' [')[0],
              remarks,
              start: date,
              timeZone: dataTimeZone,
              ...rest,
            });
          }}
          tooltip="Resend Change"
          icon="change_circle"
        />
      );
    },
  });

  return (
    <HidePrint hidePrint={hidePrint}>
      <Card className="DriverLogDetailReport__RequestedChangesCard" fullWidth>
        <div ref={changesViewRef}>
          <div className="DriverLogDetailReport__RequestedChangesCard__eventsHeader">
            <h4>Requested Changes</h4>
          </div>
          {adminStatusChanges && (
            <>
              <PrintLayout mode={printLayoutMode} inline>
                <ControlledDataTable
                  controller={adminStatusChangesDataGridController}
                  rows={adminStatusChanges}
                  rowIdProperty="key"
                />
              </PrintLayout>
              <ControlledDataTable
                controller={adminStatusChangesDataGridController}
                components={adminStatusChangesDataGridComponents}
                rows={adminStatusChanges}
                rowIdProperty="key"
                className="ControlledDataTable--webOnly"
              />
            </>
          )}
        </div>
      </Card>
    </HidePrint>
  );
};

RequestedChangesCard.propTypes = {
  log: customPropTypes.detailedDriverLog,
  submitCreateLogDutyStatusEvent: PropTypes.func.isRequired,
  printLayoutMode: PropTypes.string,
  hidePrint: PropTypes.bool,
};
