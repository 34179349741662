import { connect } from 'react-redux';

import { SupportInvestigationReport } from './SupportInvestigationReport';
import { selectIsUserSuperAdmin } from '../../../data/system';
import { breadcrumbs } from '../../../data/reports';
import { vehicle } from '../../../data/administration/resources';

export const SupportInvestigationReportContainer = connect(
  (state) => ({
    vehicles: vehicle.selectors.selectAllVehicles(state),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
    isFeaturePermitted: selectIsUserSuperAdmin(state),
  }),
  {
    fetchVehicles: vehicle.actions.fetchVehiclesOptimized,
    fetchBreadcrumbs: breadcrumbs.actions.fetchBreadcrumbsOptimized,
    fetchRefinedBreadcrumbs: breadcrumbs.actions.fetchRefinedBreadcrumbsOptimized,
  }
)(SupportInvestigationReport);
