import { find } from 'lodash';
import { createSelector } from 'reselect';

import { inOuBasedTime } from '../../system';
import { organization } from '../../administration/configurations';

const getUsers = (state) => state.administration.resources.users.data;
const extractVehicleId = (_, { vehicleId }) => vehicleId;
const extractInspectionId = (_, inspectionId) => inspectionId;

const getInspections = (state) => state.reports.inspections.data;

export const selectInspections = createSelector(
  [getInspections, organization.selectors.selectOrganizations, getUsers],
  (inspections, ous, users) =>
    inspections.map((inspection) => ({
      majorRepairs: inspection.defects && inspection.defects.some((defect) => defect.isMajor),
      minorRepairs: inspection.defects && inspection.defects.some((defect) => !defect.isMajor),
      ...inOuBasedTime(inspection, ous, inspection.principalKey, undefined, users),
    }))
);

/**
 * selectVehiclesLatestInspection(state) -> [inspections]
 *
 * Selector for latest inspection for each vehicle
 */
export const selectVehiclesLatestInspection = createSelector([selectInspections], (inspections) =>
  inspections.data.filter((inspection) => inspection.isLatest)
);

/**
 * selectInspectionsByVehicleId(state, { vehicleId }) -> inspection
 *
 * Selector for inspection by vehicleId
 */
export const selectInspectionsByVehicleId = createSelector(
  [selectInspections, extractVehicleId],
  (inspections, id) => find(inspections, { id })
);

/**
 * selectInspectionById(state, { inspectionId }) -> inspection
 *
 * Selector for inspection by inspectionId
 */
export const selectInspectionById = createSelector(
  [selectInspections, extractInspectionId],
  (inspections, id) => find(inspections, { id })
);
