import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Details',
    children: [
      columnDef.iconColumn({
        field: 'geozone',
        headerName: 'Open Detail',
        cellRenderer: 'OpenDetail',
        headerComponent: 'OpenDetailHeader',
        width: 65,
      }),
      columnDef.textColumn({
        field: 'monthYear',
        headerName: 'Period',
        width: 140,
        enableRowGroup: true,
        rowGroupIndex: 0,
      }),
      columnDef.textColumn({
        field: 'geozoneName',
        headerName: 'Geozone',
      }),
      columnDef.textColumn({
        field: 'geozoneTypeName',
        headerName: 'Geozone Type',
      }),
      columnDef.textColumn({
        field: 'ouName',
        headerName: 'Organization',
      }),
      columnDef.fuelColumn({
        field: 'fuelConsumed',
        headerName: 'Fuel Consumed',
        width: 130,
        aggFunc: 'sum',
      }),
      columnDef.distanceColumn({
        field: 'distanceDriven',
        headerName: 'Distance',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'recordCount',
        headerName: 'Traversals',
        aggFunc: 'sum',
      }),
      columnDef.numberColumn({
        field: 'speedViolationCount',
        headerName: 'Speed Violations',
        aggFunc: 'sum',
      }),
      columnDef.speedColumn({
        field: 'averageSpeed',
        headerName: 'Average Speed',
      }),
      columnDef.speedColumn({
        field: 'maximumSpeed',
        headerName: 'Maximum Speed',
      }),
    ],
  },
  {
    headerName: 'Time',
    children: [
      columnDef.durationColumn({
        field: 'driveDuration',
        headerName: 'Driving',
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'stopDuration',
        headerName: 'Stopped',
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'idleDuration',
        headerName: 'Idling',
        aggFunc: 'sum',
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Total',
        aggFunc: 'sum',
      }),
    ],
  },
];
