export const validateName = (target, value) => {
  if (!value || !value.trim()) {
    return true;
  }
  if (value.length < 2) {
    return `${target} name must have at least 2 characters`;
  }
  if (/\s/.test(value)) {
    return `${target} name cannot contain spaces`;
  }
  if (/[^a-zA-Z0-9]/.test(value)) {
    return `${target} name cannot contain special characters`;
  }
  if (value && value.length > 30) {
    return `${target} name cannot be over 30 characters`;
  }
  return false;
};

export const validateEmail = (value) => {
  const validEmailPattern = /\S+@\S+\.\S+/;
  if (!value || !value.trim()) {
    return true;
  }
  if (!validEmailPattern.test(value.toLowerCase())) {
    return 'Please enter a valid email';
  }
  return false;
};

export const validateNotificationEmail = (value) => {
  const validEmailPattern = /\S+@\S+\.\S+/;
  if (!value || !value.trim()) {
    return true;
  }
  if (!validEmailPattern.test(value.toLowerCase())) {
    return false;
  }
  return true;
};

export function validateNotificationName(name, MAX_NAME_LENGTH = 60) {
  if (!name || name.trim() === '') {
    return 'Name is required.';
  }
  if (name.length > MAX_NAME_LENGTH) {
    return `Name must be at most ${MAX_NAME_LENGTH} characters long.`;
  }
  return null;
}

export function validatePhoneNumber(phoneNumber) {
  const phoneRegex = /^[+\d]?(?:[\d-.\s()]*)$/;
  return phoneRegex.test(phoneNumber);
}

export const validate24HrTime = (value) =>
  !/^(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):[0-5][0-9]:[0-5][0-9]$/.test(
    value
  ) && 'HH:mm:ss';

export function validateNotificationDestinations({ emails, phoneNumbers, userKeys }) {
  const noDestinations =
    (!emails || emails.length === 0) &&
    (!phoneNumbers || phoneNumbers.length === 0) &&
    (!userKeys || userKeys.length === 0);

  if (noDestinations) {
    return 'You must provide at least one destination (Email, Phone Number, or User).';
  }

  if (emails && emails.length > 0) {
    for (let i = 0; i < emails.length; i += 1) {
      if (!validateNotificationEmail(emails[i])) {
        return `Invalid email address at position ${i + 1}: ${emails[i]}`;
      }
    }
  }

  if (phoneNumbers && phoneNumbers.length > 0) {
    for (let i = 0; i < phoneNumbers.length; i += 1) {
      if (!validatePhoneNumber(phoneNumbers[i])) {
        return `Invalid phone number at position ${i + 1}: ${phoneNumbers[i]}`;
      }
    }
  }

  return null;
}
