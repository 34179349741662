// For local environment customization: add a variable to local storage with the name 'Navistream.Custom.Env'
import { ALPHA } from './alpha';
import { BETA } from './beta';
import { DELTA } from './delta';
import { DEVELOPMENT } from './development';
import { MASTER } from './master';
import { OMEGA } from './omega';
import { PRODUCTION } from './production';
import { SANDBOX } from './sandbox';
import { STAGING } from './staging';

function getEnvByLocalStorage() {
  const customEnv = localStorage.getItem('Navistream.Custom.Env');

  if (customEnv === null) {
    return MASTER;
  }

  const upperCaseCustomEnv = customEnv.toUpperCase();

  switch (upperCaseCustomEnv) {
    case 'ALPHA':
      return ALPHA;
    case 'BETA':
      return BETA;
    case 'DELTA':
      return DELTA;
    case 'DEVELOPMENT':
      return DEVELOPMENT;
    case 'LOCAL':
    case 'MASTER':
      return MASTER;
    case 'OMEGA':
      return OMEGA;
    case 'PRODUCTION':
      return PRODUCTION;
    case 'SANDBOX':
      return SANDBOX;
    case 'STAGING':
      return STAGING;
    default:
      return MASTER;
  }
}

export const LOCAL = { ...getEnvByLocalStorage(), isLocal: true };
