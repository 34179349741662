import React, { useMemo } from 'react';
import { Clickable, Icon, Card } from 'stti-react-common';
import PropTypes from 'prop-types';
import { groupBy, sortBy } from 'lodash';

import './Navigation.scss';

export const Navigation = ({ route, menuCategory }) => {
  const { availableRoutes = [] } = route;
  const navigationItems = useMemo(
    () =>
      Object.entries(
        groupBy(
          sortBy(
            availableRoutes.filter(
              ({ group, hidden, category }) => group && !hidden && category === menuCategory
            ),
            'group.order'
          ).map(({ group, ...restProps }) => ({
            groupIcon: group.icon,
            group,
            ...restProps,
          })),
          'group.name'
        )
      ),
    [availableRoutes, route]
  );

  if (!navigationItems || navigationItems.length < 1) {
    return (
      <div className="Navigation">
        <Card className="Navigation__Card">
          <div className="Navigation__Card__header">
            <Icon icon="info" />
            <span>Information</span>
          </div>
          <ul>
            <li>There is no available links to be shown here for this user.</li>
          </ul>
        </Card>
      </div>
    );
  }

  return (
    <div className="Navigation">
      {navigationItems.map((item) => {
        const groupName = item[0];
        const children = item[1];
        return (
          <div key={groupName}>
            <Card className="Navigation__Card">
              <div className="Navigation__Card__header">
                <Icon icon={children[0].groupIcon} />
                <span>{groupName}</span>
              </div>
              <ul>
                {children &&
                  sortBy(children, 'order').map(({ name, open }) => (
                    <li key={name}>
                      <Clickable
                        onClick={() =>
                          open(
                            { reportViewId: 'default' },
                            { ignoreUrlByMenuCategory: menuCategory }
                          )
                        }
                      >
                        {name}
                      </Clickable>
                    </li>
                  ))}
              </ul>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

Navigation.propTypes = {
  menuCategory: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};
