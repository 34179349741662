import { reject } from 'lodash';
import {
  actionTypeProgressOrSuccess,
  shallowMergeEntities,
  removeEntity,
} from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchReportViews: 'reports/reportViews/fetchReportViews',
  fetchReportView: 'reports/reportViews/fetchReportView',
  createReportView: 'reports/reportViews/createReportView',
  createDefaultReportView: 'reports/reportViews/createDefaultReportView',
  createTemporaryDefaultReportView: 'reports/reportViews/createTemporaryDefaultReportView',
  updateReportView: 'reports/reportViews/updateReportView',
  updateDefaultReportView: 'reports/reportViews/updateDefaultReportView',
  deleteReportView: 'reports/reportViews/deleteReportView',
};

const initialState = [];

export const reportViewsReducer = (state = initialState, { type, payload }) => {
  if (
    actionTypeProgressOrSuccess(type, [
      ACTION_TYPE.fetchReportViews,
      ACTION_TYPE.fetchReportView,
      ACTION_TYPE.createReportView,
      ACTION_TYPE.updateReportView,
    ]) ||
    type === ACTION_TYPE.createTemporaryDefaultReportView
  ) {
    return shallowMergeEntities(state, payload);
  }

  if (
    actionTypeProgressOrSuccess(type, [
      ACTION_TYPE.createDefaultReportView,
      ACTION_TYPE.updateDefaultReportView,
    ])
  ) {
    const keepReportViews = reject(state, { __local: true }); // drop the temporary local default reportView, if exists
    return shallowMergeEntities(keepReportViews, payload);
  }

  if (actionTypeProgressOrSuccess(type, ACTION_TYPE.deleteReportView))
    return removeEntity(state, payload, 'id');

  return state;
};
