import { connect } from 'react-redux';

import { breadcrumbs, unidentifiedLogs } from '../../../../data/reports';
import { selectSystemUser } from '../../../../data/system';
import { user } from '../../../../data/administration/resources';
import { UnidentifiedLogDetailReport } from './UnidentifiedLogDetailReport';

export const UnidentifiedLogDetailReportContainer = connect(
  (state, { vehicleKey, date }) => ({
    unidentifiedLog: unidentifiedLogs.selectors.selectUnidentifiedLog(state, { vehicleKey, date }),
    driverUsers: user.selectors.selectDriverUsers(state),
    breadcrumbsByVehicleKey: breadcrumbs.selectors.selectVehiclesBreadcrumbs(state),
    user: selectSystemUser(state),
  }),
  {
    submitAssignLogDutyStatusEvents: unidentifiedLogs.actions.submitAssignLogDutyStatusEvents,
    fetchUnidentifiedLog: unidentifiedLogs.actions.fetchUnidentifiedLog,
  }
)(UnidentifiedLogDetailReport);
