/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { isNil, isEmpty } from 'lodash';

import {
  Button,
  Form,
  GoogleMap,
  ProgressOverlay,
  SelectInput,
  TextInput,
} from 'stti-react-common';

import { CrudAdminItem } from '../../../../commons/AdminCommon';
import { ErrorSummary } from '../../../../commons/ErrorSummary';
import { useApiError } from '../../../../../helpers/hooks';
import { objectsToSelectOptions } from '../../../../../helpers/admin/adminUtils';
import { symbols, EventInfo } from '../../../../commons/MapsCommon';
import { useFormats } from '../../../../commons/Formats';
import { toMoment } from '../../../../../helpers/moment';
import { getMembership } from '../utils';

import { ADMIN_ROLE_KEY, SUPER_ADMIN_ROLE_KEY } from '../../../../../data/system/constants';

import './detail.scss';

export const CameraDetail = ({
  cameraDevice,
  cameraId,
  fetchCameraDevice,
  fetchCameraWakeUp,
  fetchSystemCameraDevice,
  fetchVehicles,
  fetchTenants,
  mode: initialMode = 'view',
  openCameraRecords,
  organizations,
  postCameraDeviceReboot,
  postSystemCameraTenantAssignment,
  putCameraDevice,
  route,
  systemTenant,
  systemUser,
  tenants,
  vehicles,
}) => {
  const [loading, setLoading] = useState(true);
  const [wakeUpLoading, setWakeUpLoading] = useState(false);
  const [rebootLoading, setRebootLoading] = useState(false);
  const [mode, setMode] = useState(initialMode);
  const [cameraStatus, setCameraStatus] = useState(null);
  const { apiError, setApiError } = useApiError();
  const { formatUnit } = useFormats();

  const { Marker } = GoogleMap;

  useEffect(() => {
    fetchTenants();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (initialMode !== 'create') {
      const fetchCameraData = (id) =>
        systemTenant?.isSystem ? fetchSystemCameraDevice(id) : fetchCameraDevice(id);

      Promise.all([fetchCameraData(cameraId), fetchVehicles()])
        .then(resetControls)
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initialMode, cameraId, systemTenant]);

  const { useFormController, Control } = Form;

  const form = useFormController({
    controls: {
      ...cameraDevice,
      status: cameraStatus || cameraDevice?.status,
      vehicleKey: cameraDevice?.vehicleKey || 'not-assigned',
      tenantKey: cameraDevice?.tenantKey || 'not-assigned',
    },
  });

  const { controls: formControls, resetControls, setControl } = form;

  const topOu = useMemo(() => organizations.find((org) => org.parentKey === null), [organizations]);

  const isSuperAdminFromTopOu = useMemo(
    () =>
      !!systemUser?.memberships?.find(
        ({ ou, role }) => role.includes(SUPER_ADMIN_ROLE_KEY) && ou === topOu?.key?.toString()
      ),

    [systemUser, topOu]
  );

  const isAdminFromTopOu = useMemo(
    () =>
      !!systemUser?.memberships?.find(
        ({ ou, role }) => role.includes(ADMIN_ROLE_KEY) && ou === topOu?.key?.toString()
      ),
    [systemUser, topOu]
  );

  const filteredVehicles = useMemo(() => {
    const memberships = systemUser?.memberships?.map(({ ou }) => ou);
    const newArray = vehicles
      .filter(
        ({ key, assetType, active, managedBy, cameraProvider }) =>
          (assetType?.typeClass !== 'Trailer' &&
            active === true &&
            getMembership(memberships, managedBy.key, isSuperAdminFromTopOu, isAdminFromTopOu) &&
            cameraProvider === null) ||
          key === cameraDevice?.vehicleKey
      )
      .map((item) => ({ assetId: item?.assetId, key: item?.key }));
    newArray.unshift({ assetId: 'Not Assigned', key: 'not-assigned' });
    setCameraStatus(cameraDevice?.status || null);

    return newArray;
  }, [vehicles, systemUser, cameraDevice]);

  const disableAssetTag = useMemo(
    () => !systemUser?.username?.includes('streamline@'),
    [systemUser]
  );

  const isSystemTenant = useMemo(() => systemTenant?.isSystem, [systemTenant]);

  const tenantList = useMemo(() => {
    const list = [{ key: 'not-assigned', name: 'Not Assigned' }].concat(
      tenants.filter(({ enabledVideoProviders }) => !isEmpty(enabledVideoProviders))
    );

    return list;
  }, [tenants]);

  return (
    <div className="CameraDetail">
      <ProgressOverlay isOpen={loading} />
      {!loading && (
        <CrudAdminItem
          title="Camera"
          form={form}
          itemTitle={cameraDevice?.assetTag}
          hasError={form.hasErrors}
          isDirty={form.isDirty}
          mode={mode}
          onApiError={setApiError}
          setMode={setMode}
          setLoading={setLoading}
          data={
            isSystemTenant
              ? { id: cameraDevice?.id, tenantKey: formControls?.tenantKey }
              : formControls
          }
          onClose={route.close}
          updateItem={isSystemTenant ? postSystemCameraTenantAssignment : putCameraDevice}
          resetControls={resetControls}
        >
          <ErrorSummary errorMsg={apiError} />
          <div className="CameraDetail__controlsContainer">
            <Control
              Component={TextInput}
              name="assetTag"
              label="Asset Tag"
              error={({ value }) => !value}
              disabled={disableAssetTag}
            />
            {!isSystemTenant ? (
              <Control
                Component={SelectInput}
                name="vehicleKey"
                label="Vehicle Assignment"
                error={({ value }) => isNil(value)}
                options={objectsToSelectOptions(filteredVehicles, 'assetId', 'key', false)}
                /*
              onSet={({ draft, value }) => {
                draft.assetTag = `${
                  filteredVehicles.find(({ key }) => key === value)?.assetId
                }_${value}`;
                if (value === 'not-assigned') {
                  draft.assetTag = '-';
                }
            }}
            */
              />
            ) : (
              <Control
                Component={SelectInput}
                name="tenantKey"
                label="Tenant Assignment"
                error={({ value }) => isNil(value)}
                options={objectsToSelectOptions(tenantList, 'name', 'key')}
              />
            )}
            <Control Component={TextInput} name="deviceModel" label="Device Model" disabled />
            <Control
              Component={TextInput}
              name="firmwareVersion"
              label="Firmware Version"
              disabled
            />
            <Control Component={TextInput} name="iccid" label="ICCID" disabled />
            <Control Component={TextInput} name="imei" label="IMEI" disabled />
            <Control Component={TextInput} name="status" label="Status" disabled />
            <Control
              Component={TextInput}
              name="lastSeenOnline"
              label="lastSeenOnline"
              value={formatUnit('date', toMoment(cameraDevice?.lastSeenOnline))}
              disabled
            />
          </div>
          {!isSystemTenant && (
            <div className="CameraDetail__buttonContainer">
              <h4>Camera Actions</h4>
              <Button
                onClick={() => {
                  setWakeUpLoading(true);
                  fetchCameraWakeUp({ vehicleKey: cameraDevice.vehicleKey })
                    .then((response) => {
                      const nStatus =
                        response?.message === 'Success' ? 'online' : cameraDevice?.status;
                      setWakeUpLoading(false);
                      setCameraStatus(nStatus);
                      setControl('status', nStatus);

                      if (response?.message !== 'Success') {
                        setMode('view');
                      }

                      return true;
                    })
                    .catch((error) => {
                      setWakeUpLoading(false);
                      setApiError(error?.message);
                    });
                }}
                label={wakeUpLoading ? 'Loading...' : 'Wake Up Camera'}
                variant="outlined"
                disabled={
                  wakeUpLoading || cameraStatus === 'online' || cameraDevice?.status === 'online'
                }
              />
              <Button
                onClick={() => {
                  setRebootLoading(true);
                  postCameraDeviceReboot(cameraDevice?.id).then(() => {
                    setRebootLoading(false);
                  });
                }}
                label={rebootLoading ? 'Loading...' : 'Reboot Camera'}
                variant="outlined"
                disabled={
                  rebootLoading || cameraStatus === 'offline' || cameraDevice?.status === 'offline'
                }
              />
              <Button
                onClick={() =>
                  openCameraRecords({
                    ouId: cameraDevice?.oukey,
                    vehicleId: cameraDevice?.vehicleKey,
                  })
                }
                label="Camera Recordings"
                variant="outlined"
              />
            </div>
          )}
          {cameraDevice?.location !== null && (
            <div className="CameraDetail__mapContainer">
              <h4>Map</h4>
              <Button
                onClick={() => {
                  setLoading(true);
                  const fetchCameraData = (id) =>
                    systemTenant?.isSystem ? fetchSystemCameraDevice(id) : fetchCameraDevice(id);
                  Promise.all([fetchCameraData(cameraId), fetchVehicles()])
                    .then(resetControls)
                    .finally(() => {
                      setLoading(false);
                    });
                }}
                label="Refresh Location"
                variant="outlined"
              />
              <GoogleMap focus={cameraDevice?.location} focusZoom={12}>
                <Marker position={cameraDevice?.location} icon={symbols.videoIcon}>
                  <EventInfo
                    event={{
                      eventType: 'LastKnownLocation',
                      eventAt: cameraDevice?.lastSeenOnline,
                      timeZone: cameraDevice?.ouTimeZone || moment.tz.guess(),
                    }}
                    trip={{ ...cameraDevice, startedAt: cameraDevice?.lastSeenOnline || '' }}
                    extraInfo={
                      <ul>
                        <li>Altitude: {formatUnit('number', cameraDevice?.location?.altitude)}m</li>
                        <li>
                          Speed:{' '}
                          {cameraDevice?.speed ? formatUnit('speed', cameraDevice?.speed) : '-'}
                        </li>
                      </ul>
                    }
                    forceDateFormat="dddd, MMMM Do YYYY, HH:mm"
                  />
                </Marker>
              </GoogleMap>
            </div>
          )}
        </CrudAdminItem>
      )}
    </div>
  );
};

CameraDetail.propTypes = {
  cameraDevice: PropTypes.object,
  cameraId: PropTypes.string,
  fetchCameraDevice: PropTypes.func.isRequired,
  fetchCameraWakeUp: PropTypes.func.isRequired,
  fetchSystemCameraDevice: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  fetchTenants: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['view', 'edit', 'create']),
  openCameraRecords: PropTypes.func.isRequired,
  organizations: PropTypes.array,
  postCameraDeviceReboot: PropTypes.func.isRequired,
  postSystemCameraTenantAssignment: PropTypes.func.isRequired,
  putCameraDevice: PropTypes.func.isRequired,
  route: PropTypes.object.isRequired,
  systemTenant: PropTypes.object.isRequired,
  systemUser: PropTypes.object.isRequired,
  tenants: PropTypes.array,
  vehicles: PropTypes.array,
};
