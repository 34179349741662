import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Chip, Button, useBooleanState } from 'stti-react-common';

import {
  useReportController,
  ReportProvider,
  ReportLoader,
  ReportFragment,
  SignalWebclientViewReady,
  FetchReportDetail,
  DateStepperInput,
  DriverLogSignDialog,
  DriverLogPrintCardDialog,
} from '../../../commons/ReportsCommon';
import { DriverCard } from './Cards/DriverCard';
import { EventsCard } from './Cards/EventsCard';
import { EldCard } from './Cards/EldCard';
import { CarrierCard } from './Cards/CarrierCard';
import { VehicleCard } from './Cards/VehicleCard';
import { EffectiveRegulationsCard } from './Cards/EffectiveRegulationsCard';
import { ViolationsCard } from './Cards/ViolationsCard';
import { OnDutySummaryCard } from './Cards/OnDutySummaryCard';
import { DriverLogPrintLayoutHeader } from '../../../commons/DriverLogPrintLayoutHeader';
import { RequestedChangesCard } from './Cards/RequestedChangesCard';
import { CommentCard } from './Cards/CommentCard';

import { findCardToPrint, driverLogCards } from '../../../../helpers/reports/printUtils';
import { customPropTypes } from '../../../../helpers/customPropTypes';
import { PrintLayout } from '../../../commons/PrintLayout';

import { UNREVIEWED_VIOLATION_STATUS } from '../../../../data/reports';

import './DriverLogDetailReport.scss';

const requestServices = ['driverLogDetail'];

const NORMAL_DATE_FORMAT = 'YYYY-MM-DD';

export const DriverLogDetailReport = ({
  driverLog: connectDriverLog,
  isDriver,
  driverKey,
  date,
  reportViewId,
  submitCreateLogDutyStatusEvent,
  submitUpdateLogDutyStatusEvent,
  submitReassignLogDutyStatusEvent,
  reviewViolation,
  user,
  route,
  addDriverLogComment,
  deleteDriverLogComment,
  fetchDriverLog,
  fetchDriverLogViolation,
}) => {
  const [dateInputValue, setDateInputValue] = useState(null);
  const [cardsToPrint, setCardsToPrint] = useState(driverLogCards.map(({ key }) => key));
  const [isDriverLogSignDialogOpen, openDriverLogSignDialog, closeDriverLogSignDialog] =
    useBooleanState();

  const [
    isDriverLogPrintCardDialogOpen,
    openDriverLogPrintCardDialog,
    closeDriverLogPrintCardDialog,
  ] = useBooleanState();
  const driverLog = useMemo(() => connectDriverLog, [connectDriverLog, isDriverLogSignDialogOpen]);

  const { signature } = user;
  const isNotReviewed = driverLog && driverLog.violationStatus === UNREVIEWED_VIOLATION_STATUS;
  const isUnsigned =
    driverLog && driverLog.certificationStatus && !driverLog.certificationStatus.certified;

  const todayDate = moment().format(NORMAL_DATE_FORMAT);
  const minDate = moment('2019-01-01').format(NORMAL_DATE_FORMAT);
  const isDateValid = moment(date, NORMAL_DATE_FORMAT).format(NORMAL_DATE_FORMAT) === date;
  const isDateInFuture = isDateValid && date > todayDate;
  const isDateTooOld = isDateValid && date < minDate;
  const isDateAcceptable = isDateValid && !isDateInFuture && !isDateTooOld;

  const report = useReportController({
    reportType: 'driverLogDetail',
    reportTypeName:
      driverLog && driverLog.driver
        ? `Driver's Daily Log: ${driverLog.driver.name}`
        : `Driver's Daily Log`,
    reportViewId,
    route,
    reportActions: [fetchDriverLog, fetchDriverLogViolation],
  });

  const { reportType } = report;

  const isPartial = driverLog && !driverLog.endedAt;

  const logSummaryPrintDetails = useMemo(() => {
    const details = [];

    if (isNotReviewed) {
      details.push('Unreviewed Violations');
    }

    if (isUnsigned) {
      details.push('Unsigned Log');
    }

    if (isPartial) {
      details.push('Partial Log');
    }
    return details;
  }, [isPartial, isUnsigned, isNotReviewed]);

  useEffect(() => {
    if (!isDateValid) return;
    setDateInputValue(date);
  }, [isDateValid, date]);

  useEffect(() => {
    if (!dateInputValue || dateInputValue === date) return;
    route.open({
      driverKey,
      date: dateInputValue,
      reportViewId,
    });
  }, [dateInputValue]);

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <SignalWebclientViewReady />
      {isDateAcceptable && <FetchReportDetail action={fetchDriverLog} args={{ driverKey, date }} />}
      {driverLog && driverLog.reviewDetailsKey && (
        <FetchReportDetail action={fetchDriverLogViolation} args={driverLog.reviewDetailsKey} />
      )}
      <div className="DriverLogDetailReport">
        <ReportFragment.Header
          reportType="driverLog"
          route={route}
          services={requestServices}
          hideExport
          hidePrint
        >
          {isDriver && !isPartial && (
            <Button
              icon="done"
              label={!isUnsigned ? 'Update Signature' : 'Sign log'}
              onClick={() => openDriverLogSignDialog()}
            />
          )}
          <Button
            className="PrintButton"
            icon="print"
            label="Print"
            tooltip="Print"
            onClick={() => openDriverLogPrintCardDialog()}
          />
          <DriverLogSignDialog
            signature={signature}
            driverLog={driverLog}
            isOpen={isDriverLogSignDialogOpen}
            onClose={closeDriverLogSignDialog}
          />
          {isDriverLogPrintCardDialogOpen && (
            <DriverLogPrintCardDialog
              driverLog={driverLog}
              mode={reportType}
              isOpen={isDriverLogPrintCardDialogOpen}
              onClose={closeDriverLogPrintCardDialog}
              onCardSelect={(cards) => setCardsToPrint(cards)}
            />
          )}
        </ReportFragment.Header>

        <div className="DriverLogDetailReport__wrapper">
          <div className="DriverLogDetailReport__subHeader">
            <div className="DriverLogDetailReport__subHeader__controls">
              <DateStepperInput
                value={dateInputValue}
                onChange={setDateInputValue}
                min={minDate}
                max={todayDate}
                error={
                  (isDateInFuture && 'Cannot view log for future date') ||
                  (isDateTooOld && 'Logbook data older than Jan 1, 2019 is unavailable.') ||
                  !dateInputValue
                }
              />
            </div>
          </div>
          {driverLog && (isNotReviewed || isUnsigned || isPartial) && (
            <div className="DriverLogDetailReport__chips">
              {isNotReviewed && <Chip color="secondary" label="Unreviewed Violations" />}
              {isUnsigned && !isDateInFuture && <Chip color="primary" label="Unsigned Log" />}
              {isPartial && <Chip label="Partial Log" />}
            </div>
          )}
          <PrintLayout mode={reportType} inline>
            <DriverLogPrintLayoutHeader
              driverLog={driverLog}
              logSummaryPrintDetails={logSummaryPrintDetails}
              date={date}
            />
          </PrintLayout>
          <DriverCard log={driverLog} hidePrint={!findCardToPrint('DriverCard', cardsToPrint)} />
          <EventsCard
            log={driverLog}
            submitCreateLogDutyStatusEvent={submitCreateLogDutyStatusEvent}
            submitUpdateLogDutyStatusEvent={submitUpdateLogDutyStatusEvent}
            submitReassignLogDutyStatusEvent={submitReassignLogDutyStatusEvent}
            user={user}
            hidePrint={!findCardToPrint('EventsCard', cardsToPrint)}
          />
          <RequestedChangesCard
            log={driverLog}
            submitCreateLogDutyStatusEvent={submitCreateLogDutyStatusEvent}
            printLayoutMode={reportType}
            hidePrint={!findCardToPrint('RequestedChangesCard', cardsToPrint)}
          />
          <EldCard log={driverLog} hidePrint={!findCardToPrint('EldCard', cardsToPrint)} />
          <CarrierCard log={driverLog} hidePrint={!findCardToPrint('CarrierCard', cardsToPrint)} />
          {driverLog && driverLog.vehicleDetails && driverLog.vehicleDetails.length > 0 && (
            <VehicleCard
              log={driverLog}
              hidePrint={!findCardToPrint('VehicleCard', cardsToPrint)}
            />
          )}
          <EffectiveRegulationsCard
            log={driverLog}
            hidePrint={!findCardToPrint('EffectiveRegulationsCard', cardsToPrint)}
          />
          <ViolationsCard
            log={driverLog}
            user={user}
            reviewViolation={reviewViolation}
            hidePrint={!findCardToPrint('ViolationsCard', cardsToPrint)}
          />
          <OnDutySummaryCard
            log={driverLog}
            hidePrint={!findCardToPrint('OnDutySummaryCard', cardsToPrint)}
          />
          <CommentCard
            log={driverLog}
            hidePrint={!findCardToPrint('CommentCard', cardsToPrint)}
            commentMethods={{
              addDriverLogComment,
              deleteDriverLogComment,
              fetchDriverLog,
            }}
          />
        </div>
      </div>
    </ReportProvider>
  );
};

DriverLogDetailReport.propTypes = {
  driverLog: customPropTypes.detailedDriverLog,
  isDriver: PropTypes.bool,
  driverKey: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  reportViewId: PropTypes.string.isRequired,
  submitCreateLogDutyStatusEvent: PropTypes.func.isRequired,
  submitUpdateLogDutyStatusEvent: PropTypes.func.isRequired,
  submitReassignLogDutyStatusEvent: PropTypes.func.isRequired,
  reviewViolation: PropTypes.func.isRequired,
  user: PropTypes.object,
  route: PropTypes.object.isRequired,
  addDriverLogComment: PropTypes.func.isRequired,
  deleteDriverLogComment: PropTypes.func.isRequired,
  fetchDriverLog: PropTypes.func.isRequired,
  fetchDriverLogViolation: PropTypes.func.isRequired,
};
