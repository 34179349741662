import filter from 'lodash/filter';

import { apiDelete, apiPatch, apiPost } from './api';
import { createDateTime } from './date';
import { getFormatDefinitions } from './formats';
import { LATEST_REPORT_VERSION } from './constants';

export const defaultModalState = { content: null, open: false, title: '', type: '' };

export const dataTransferModes = ['general-purpose', 'us-audit', 'canada-audit'];

export const defaultValuesProcessor = ({ selectedReport, type }) => {
  if (type === 'saveAs') {
    return {
      name: selectedReport?.name,
    };
  }

  if (type === 'email-report') {
    return {
      toEmails: [],
      orientation: 'landscape',
      attach: 'pdf',
    };
  }

  if (type === 'schedule-report') {
    return {
      toEmails: [],
      orientation: 'landscape',
      attach: 'pdf',
      frequency: 'Day',
      hourAt: createDateTime().startOf('day'),
      endAt: createDateTime().plus({ years: 2 }).endOf('day'),
      report: selectedReport?.id,
    };
  }

  return {
    toEmails: [],
    records: [],
    orientation: 'landscape',
    startDate: createDateTime().startOf('day'),
    endDate: createDateTime().endOf('day'),
    agency: type === 'us-audit' ? 'FMCSA' : 'CVSE',
    mechanism: 'SOAP',
    email: '',
  };
};

export const formatSettings = [
  'dateFormat',
  'distanceFormat',
  'durationFormat',
  'mileageFormat',
  'volumeFormat',
  'weightFormat',
  'dateExportFormat',
  'distanceExportFormat',
  'durationExportFormat',
  'mileageExportFormat',
  'volumeExportFormat',
  'weightExportFormat',
];

export const mechanismOptions = [
  { label: 'Online (Recommended)', value: 'SOAP' },
  { label: 'Email  (Use only when online method has downtime)', value: 'EMAIL' },
];

export const reportFormatOptions = (formatType, displayOrExport, withDefault = true) => {
  const options = filter(getFormatDefinitions(formatType), displayOrExport).map(
    ({ key, description }) => ({
      value: key,
      label: description,
    })
  );
  if (withDefault)
    options.unshift({
      label: displayOrExport === 'display' ? 'User default' : 'Same as display',
      value: 'default',
    });
  return options;
};

export const modalConfigProcessor = ({ config, setModal }) => {
  let result = defaultModalState;

  switch (config.type) {
    case 'open': {
      result = {
        content: config.content,
        open: true,
        title: 'Open Report',
        type: config.type,
        route: config.route,
      };
      break;
    }
    case 'general-purpose':
    case 'us-audit':
    case 'canada-audit': {
      result = {
        content: config.content,
        open: true,
        title:
          config.content.length === 1
            ? `Data Transfer for ${config.content[0].driver.name}`
            : `Data Transfer (Visible Rows)`,
        type: config.type,
        route: config.route,
      };
      break;
    }
    case 'settings': {
      result = {
        content: config.content,
        open: true,
        title: 'Report Settings',
        type: config.type,
        route: config.route,
      };
      break;
    }
    case 'saveAs': {
      result = {
        content: config.content,
        open: true,
        title: 'Save New Report',
        type: config.type,
        route: config.route,
      };
      break;
    }
    case 'email-report': {
      result = {
        content: config.content,
        open: true,
        title: 'Email Report',
        type: config.type,
        route: config.route,
      };
      break;
    }
    case 'schedule-report': {
      result = {
        content: config.content,
        open: true,
        title: 'Create New Report Schedule',
        type: config.type,
        route: config.route,
      };
      break;
    }
    case 'manage-reports': {
      result = {
        content: config.content,
        open: true,
        title: 'Manage Reports',
        type: config.type,
        route: config.route,
      };
      break;
    }
    default:
      break;
  }

  return setModal(result);
};

export const onRowClickedOpenReport = (data, route, setModal, setSelectedReport) => {
  setSelectedReport(null);
  setTimeout(() => {
    route.open({ ...route.matched.values, reportViewId: data.id }, { replace: 'defaultView' });
    setModal(defaultModalState);
  }, 100);
};

export const saveCurrentReport = ({
  agGridApi,
  api,
  filters,
  query,
  selectedReport,
  setLoading,
  setSnackbar,
  token,
}) => {
  const columnState = agGridApi.columnApi.getColumnState();
  const filterModel = agGridApi.api.getFilterModel();
  const sortObject = columnState.find((s) => s.sort != null);
  const sortModel = sortObject ? [{ colId: sortObject.colId, sort: sortObject.sort }] : [];

  const payload = {
    ...selectedReport,
    state: {
      ...selectedReport.state,
      controls: {
        ...selectedReport.state.controls,
        ...query,
        ...filters,
        startDate: query.startDate.toFormat('yyyy-MM-dd'),
        endDate: query.endDate.toFormat('yyyy-MM-dd'),
      },
      dataGrid: {
        ...selectedReport.state.dataGrid,
        columnState,
        filterModel,
        sortModel,
      },
      version: LATEST_REPORT_VERSION,
    },
  };

  const url = `${api.baseUrl}reportViews/${selectedReport.id}`;

  setLoading(true);
  apiPatch({ url, token, body: payload }).then((resp) => {
    try {
      setSnackbar({
        open: true,
        message: resp ? 'Report saved' : 'Error saving report',
        severity: resp ? 'success' : 'error',
      });
      setLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error saving report',
        severity: 'error',
      });
      setLoading(false);
    }
  });
};

export const saveAsReport = ({
  agGridApi,
  api,
  currentSystemOu,
  data,
  filters,
  query,
  route,
  selectedReport,
  setLoading,
  setModal,
  setReportData,
  setSnackbar,
  token,
}) => {
  const { description, name, isShared } = data;
  const columnState = agGridApi.columnApi.getColumnState();
  const filterModel = agGridApi.api.getFilterModel();
  const sortObject = columnState.find((s) => s.sort != null);
  const sortModel = sortObject ? [{ colId: sortObject.colId, sort: sortObject.sort }] : [];

  const payload = {
    defaultView: false,
    description: description || '',
    hidden: false,
    name,
    ...(isShared && { ouKey: currentSystemOu.key }),
    reportCode: selectedReport.reportCode,
    state: {
      controls: {
        ...selectedReport.state.controls,
        ...query,
        ...filters,
        startDate: query.startDate.toFormat('yyyy-MM-dd'),
        endDate: query.endDate.toFormat('yyyy-MM-dd'),
      },
      dataGrid: {
        ...selectedReport.state.dataGrid,
        columnState,
        filterModel,
        sortModel,
      },
      version: LATEST_REPORT_VERSION,
    },
  };

  const url = `${api.baseUrl}reportViews`;

  setLoading(true);
  apiPost({ url, token, body: payload }).then((resp) => {
    try {
      setSnackbar({
        open: true,
        message: resp ? 'Report saved' : 'Error saving report',
        severity: resp ? 'success' : 'error',
      });
      setReportData((prev) => [...prev, resp]);
      setModal(defaultModalState);
      setLoading(false);
      route.open({ ...route.matched.values, reportViewId: resp.id }, { replace: 'defaultView' });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error saving report',
        severity: 'error',
      });
      setModal(defaultModalState);
      setLoading(false);
    }
  });
};

export const saveAsDefaultReport = ({
  agGridApi,
  api,
  filters,
  query,
  route,
  selectedReport,
  setReportData,
  setSnackbar,
  token,
}) => {
  const columnState = agGridApi.columnApi.getColumnState();
  const filterModel = agGridApi.api.getFilterModel();
  const sortObject = columnState.find((s) => s.sort != null);
  const sortModel = sortObject ? [{ colId: sortObject.colId, sort: sortObject.sort }] : [];

  const payload = {
    ...selectedReport,
    defaultView: true,
    state: {
      controls: {
        ...selectedReport.state.controls,
        ...query,
        ...filters,
        startDate: query.startDate.toFormat('yyyy-MM-dd'),
        endDate: query.endDate.toFormat('yyyy-MM-dd'),
      },
      dataGrid: {
        ...selectedReport.state.dataGrid,
        columnState,
        filterModel,
        sortModel,
      },
      version: LATEST_REPORT_VERSION,
    },
  };

  const url = `${api.baseUrl}reportViews/${selectedReport.id}`;

  apiPatch({ url, token, body: payload }).then((resp) => {
    try {
      setSnackbar({
        open: true,
        message: resp ? 'Report saved' : 'Error saving report',
        severity: resp ? 'success' : 'error',
      });

      setReportData((prev) => {
        const prevMap = prev.map((row) => {
          if (row.defaultView) {
            return { ...row, defaultView: false };
          }
          return row;
        });
        return [...prevMap, resp];
      });
      route.open({ ...route.matched.values, reportViewId: 'default' });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error saving report',
        severity: 'error',
      });
    }
  });
};

export const emailReport = ({
  api,
  currentSystemUser,
  currentSystemOu,
  data: { toEmails, orientation, attach, includeDetail },
  selectedReport,
  setLoading,
}) => {
  const payload = {
    toEmails: toEmails.map((email) => email.split(' - ')[1]),
    config: {
      landscape: orientation === 'landscape',
    },
    attach,
    includeDetail: !!includeDetail,
    userKey: currentSystemUser.key,
    timeZone: currentSystemOu.timeZone,
    report: selectedReport,
    urls: [], // todo: maybe we will keep using urls param, to be determined
  };

  setLoading(true);

  console.log('debug:: emailReport', payload);

  setLoading(false); // once we have the async method here
};

export const scheduleReport = ({
  agGridApi,
  api,
  currentSystemOu,
  currentSystemUser,
  data: { toEmails, orientation, includeDetail, hourAt, endAt, report },
  filters,
  query,
  reportData,
  setLoading,
  setModal,
  setSnackbar,
  token,
}) => {
  const selectedReport = reportData.find((r) => r.id === report);

  const utcDt = endAt?.toUTC();

  const payload = {
    config: {
      landscape: orientation === 'landscape',
    },
    delivery: hourAt?.hour || 0,
    endAt: utcDt?.toISO(),
    frequency: 'daily',
    includeDetail: !!includeDetail,
    intervalStart: hourAt?.minutes || 0, // todo: determine with BE team if interval reflects the minutes or not - original code is set as 0.
    reportName: selectedReport.name,
    tenantKey: currentSystemOu.key,
    timeZone: currentSystemOu.timeZone,
    userKey: currentSystemUser.key,
    toEmails: toEmails.map((email) => email.split(' - ')[1]),
    urls: [], // todo: maybe we will keep using urls param, to be determined
  };

  saveCurrentReport({
    agGridApi,
    api,
    filters,
    query,
    selectedReport,
    setSnackbar,
    setLoading,
    token,
  });

  setModal(defaultModalState);

  console.log('debug:: scheduleReport', payload);
};

export const deleteReport = ({
  api,
  selectedReport,
  setLoading,
  setReportData,
  setSnackbar,
  token,
}) => {
  const url = `${api.baseUrl}reportViews/${selectedReport.id}`;

  setLoading(true);
  apiDelete({ url, token }).then(() => {
    try {
      setSnackbar({
        open: true,
        message: 'Report deleted',
        severity: 'success',
      });
      setReportData((prev) => [...prev.filter((row) => row.id !== selectedReport.id)]);
      setLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error deleting report',
        severity: 'error',
      });
      setLoading(false);
    }
  });
};

export const editReport = ({
  api,
  currentSystemOu,
  data,
  selectedReport,
  setLoading,
  setReportData,
  setSnackbar,
  token,
}) => {
  const url = `${api.baseUrl}reportViews/${selectedReport.id}`;

  const payload = {
    ...selectedReport,
    name: data.name,
    description: data.description,
    ...(selectedReport.isShared && { ouKey: currentSystemOu.key }),
    state: {
      ...selectedReport.state,
      controls: {
        ...selectedReport.state.controls,
        ...data,
      },
    },
  };

  setLoading(true);
  apiPatch({ url, token, body: payload }).then((resp) => {
    try {
      setSnackbar({
        open: true,
        message: resp ? 'Report saved' : 'Error saving report',
        severity: resp ? 'success' : 'error',
      });
      setReportData((prev) => [...prev.filter((row) => row.id !== selectedReport.id), payload]);
      setLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error saving report',
        severity: 'error',
      });
      setLoading(false);
    }
  });
};
