import React from 'react';
import PropTypes from 'prop-types';
import { ToggleInput } from 'stti-react-common';
import styles from './ToggleRow.module.scss';

function ToggleRow({ label, name, description, formControls, form, mode, Control, errorMessage }) {
  return (
    <div className={styles['toggle-grid-row']}>
      <div className={styles['toggle-grid-cell']}>{label}</div>
      <div className={styles['toggle-grid-cell']}>
        <Control
          Component={ToggleInput}
          name={name}
          checked={formControls[name] || false}
          onChange={(value) => form.setControl(name, value)}
          readOnly={mode === 'view'}
          error={errorMessage}
        />
      </div>
      <div className={styles['toggle-grid-cell']}>{description}</div>
    </div>
  );
}

ToggleRow.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  formControls: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  Control: PropTypes.elementType.isRequired,
  errorMessage: PropTypes.string,
};

ToggleRow.defaultProps = {
  description: '',
  errorMessage: null,
};

export default ToggleRow;
