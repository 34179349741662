import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { trim, toUpper } from 'lodash';
import moment from 'moment';
import { Clickable, ToggleInput, Button, SelectInput } from 'stti-react-common';

import { ControlledDataGrid, ControlledDataTable } from '../../../../../commons/ControlledDataGrid';
import { useFormats } from '../../../../../commons/Formats';
import { PrintLayout } from '../../../../../commons/PrintLayout';

import { FiltersPanel } from '../../common/filters';
import { AggregatesPanel, AggregatesContent } from '../../common/aggregators';
import { formatFuelType } from '../../utils';
import { monthNameToNumber } from '../../../../../../helpers/moment';

import {
  ControlsTablePrint,
  DateRangeAnalytics,
  FetchReportData,
  LaunchReport,
  QueryPanel,
  ReportFragment,
  ReportLoader,
  ReportProvider,
  SectionPrint,
  SignalWebclientViewReady,
  useReportController,
} from '../../../../../commons/ReportsCommon';

import { fuelConsumptionRates } from '../../../../../../data/reports/fuelTax/utils';

import { FEATURES } from '../../../../../../features';
import { makeColumnDefs } from './columnDefs';

import './summary.scss';

const dateRangeModes = [
  'thisMonth',
  'lastMonth',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  '1q',
  '2q',
  '3q',
  '4q',
];

const today = moment();
const currentQuarter = `${today.quarter()}q`;

const { createState, useDataGridComponents, asDataGridHeaderComponent } = ControlledDataGrid;

const defaultState = () => ({
  controls: { dateRangeMode: currentQuarter },
  dataGrid: createState({ columnDefs: makeColumnDefs() }),
});

const requestServices = ['fuelTaxes'];

const calculateYearFromDateRange = (dateRange, controlledYear) => {
  if (dateRange === 'lastMonth') {
    return Number(moment().subtract(1, 'month').format('YYYY'));
  }
  return controlledYear;
};

export const FuelTaxSummaryReport = ({
  fetchFuelTaxes,
  fuelTaxes = [],
  taxRates,
  reportViewId,
  route,
  openFuelTaxDetailReportRoute,
}) => {
  const [segmentFieldCalc, setSegmentFieldCalc] = useState(false);
  const [taxRateCountry, setTaxRateCountry] = useState('CA');

  const { formatUnit } = useFormats();
  // INITIALIZE REPORT START -----------------------------------------------------

  const REPORT_TYPE = 'fuelTaxSummary';

  const report = useReportController({
    reportType: REPORT_TYPE,
    reportTypeName: 'Fuel Tax Summary Report',
    reportViewId,
    route,
    defaultState,
    filtersConfig: {
      input: fuelTaxes,
      chain: ['query', 'resource'],
    },
  });

  const { controls, filteredRows, reportType, setControl, dataGridController } = report;

  // INITIALIZE DATAGRID START -----------------------------------------------------
  const dataGridComponents = useDataGridComponents({
    OpenDetail: ({ data: { id, quarter, endedAt, vehicle, jurisdictionState } }) =>
      endedAt ? (
        <LaunchReport
          onClick={() =>
            openFuelTaxDetailReportRoute.open({
              fuelTaxId: id,
              quarter,
              vehicleKey: vehicle && vehicle.key,
              jurisdictionState,
              reportViewId: 'default',
            })
          }
        />
      ) : null,
    OpenDetailHeader: asDataGridHeaderComponent(() => <LaunchReport />),
    Vehicle: ({ value }) => (
      <Clickable onClick={() => setControl('vehicleName', [value])}>{value}</Clickable>
    ),
    FuelType: ({ value }) => (
      <Clickable onClick={() => setControl('fuelType', [value])}>{value}</Clickable>
    ),
    Drivers: ({ value }) =>
      value && value.length > 0 ? (
        <ul>
          {value.map((row) => (
            <li key={row}>{row}</li>
          ))}
        </ul>
      ) : (
        '—'
      ),
    JsonEdits: ({
      data: {
        json: { edits },
      },
    }) => (
      <ul className="edits">
        {edits.map((edit, index) => (
          <li key={index}>
            {edit.property} = {formatUnit('distance', edit.newValue)} - {edit.userName}
          </li>
        ))}
      </ul>
    ),
  });

  const { dateRangeMode, selectedYear, ouMode, ouKeys } = controls;

  const quarter = useMemo(() => {
    if (!dateRangeMode) return `Q1${moment().format('YYYY')}`;

    if (['1q', '2q', '3q', '4q'].includes(dateRangeMode)) {
      return trim(`${toUpper(dateRangeMode)}${selectedYear}`);
    }
    return null;
  }, [controls.ouMode, controls.dateRangeMode, controls.selectedYear]);

  const month = useMemo(() => {
    if (['1q', '2q', '3q', '4q'].includes(dateRangeMode)) {
      return null;
    }
    if (dateRangeMode === 'lastMonth') {
      return Number(moment().subtract(1, 'month').format('M'));
    }
    if (dateRangeMode === 'thisMonth') {
      return Number(moment().format('M'));
    }
    return monthNameToNumber(dateRangeMode);
  }, [controls.ouMode, controls.dateRangeMode, controls.selectedYear]);

  const filteredDataRange = useMemo(() => {
    if (!filteredRows || !filteredRows.output) return 0;

    return filteredRows.output.map((row) => {
      const { addedFuel, onHighwayDistance, offHighwayDistance } = row;
      let taxRate = 0;

      const { kpl } = fuelConsumptionRates(filteredRows.output)[row.fuelType];

      // if we have json edits, we need to use it
      const totalDistance = onHighwayDistance + offHighwayDistance;
      const taxQtConsumed = onHighwayDistance / kpl; // as stated by MBL-3385
      const netTaxableQty = taxQtConsumed - addedFuel;

      const remappedTaxRates = taxRates.map(({ fuelType, ...rest }) => ({
        ...rest,
        fuelType: formatFuelType(fuelType),
      }));

      const taxRateFound =
        remappedTaxRates &&
        remappedTaxRates.find(
          ({
            fuelType,
            quarter: taxRateQuarter,
            jurisdictionState: state,
            jurisdictionCountry: country,
          }) =>
            row.fuelType === fuelType &&
            row.quarter === taxRateQuarter &&
            row.jurisdictionState === state &&
            row.jurisdictionCountry === country
        );

      if (taxRateFound) {
        if (taxRateCountry === 'CA') {
          taxRate = taxRateFound.caRate;
        } else {
          taxRate = taxRateFound.usRate;
        }
      }

      const taxDue = netTaxableQty * taxRate;

      return {
        ...row,
        taxQtConsumed,
        netTaxableQty,
        taxDue,
        taxRate,
        totalDistance,
      };
    });
  }, [filteredRows, fuelTaxes, segmentFieldCalc, taxRateCountry]);

  const filteredDateRangeModes = useMemo(() => {
    const currentDay = new Date();
    const modes = dateRangeModes.filter((mode) => {
      if (mode === 'thisMonth' || mode === 'lastMonth') {
        return true;
      }

      if (mode.endsWith('q')) {
        if (selectedYear < Number(moment().format('YYYY'))) return true;

        const currentQuarterMode = Math.ceil((currentDay.getMonth() + 1) / 3);
        const quarters = [];
        for (let i = 1; i <= currentQuarterMode; i += 1) {
          quarters.push(`${i}q`);
        }
        return quarters.includes(mode);
      }

      const monthNumber = monthNameToNumber(mode);
      const stringDate = `${selectedYear}-${monthNumber}-01`;

      const monthDate = moment(stringDate);

      return monthDate <= currentDay;
    });

    if (!modes.includes(controls.dateRangeMode)) {
      setControl('dateRangeMode', modes[0]);
    }

    return modes;
  }, [selectedYear]);

  useEffect(() => {
    dataGridController.setState(
      createState({
        columnDefs: makeColumnDefs(filteredDataRange, segmentFieldCalc),
      })
    );
  }, [filteredDataRange]);

  return (
    <ReportProvider value={report}>
      <ReportLoader />
      <FetchReportData
        isQuarterlyOrMonthly
        fetchAction={fetchFuelTaxes}
        args={{
          ...(quarter && { quarter }),
          ...(month && { month, year: calculateYearFromDateRange(dateRangeMode, selectedYear) }),
          ...(ouMode === 'default' && { ouKey: ouKeys[0] }),
        }}
      />
      <DateRangeAnalytics />
      <SignalWebclientViewReady />
      <div className="FuelTaxSummaryReport">
        <ReportFragment.Header reportType={reportType} route={route} services={requestServices} />
        <QueryPanel
          queryFor="segment"
          group="query"
          defaultDateRangeFor="startedAt"
          canSelectDateRangeFor
          selectedDateRange={controls.dateRangeMode}
          filterDateRange={filteredDateRangeModes}
          minAllowedDate="2023-01-01"
          disableStartDate
          disableEndDate
          isUtc
        />
        <FiltersPanel rows={filteredRows.query} group="filters" filterGroup="resource" />
        <AggregatesPanel rows={filteredRows.dataGrid} reportType={reportType} />
        <ReportFragment.DataGridPanel dataGridComponents={dataGridComponents} autoHeightColumns>
          {FEATURES.reports.useDynamicTaxReportCalculation && (
            <ToggleInput
              label="Use selected Segment Filters to calculate Data Table values"
              value={segmentFieldCalc}
              onChange={() => setSegmentFieldCalc(!segmentFieldCalc)}
            />
          )}
          {FEATURES.reports.useJurisdictionTaxRates && (
            <SelectInput
              label="Use jurisdiction Tax Rates for"
              className="AdminSearchPanel__SelectInput--aligned"
              value={taxRateCountry}
              options={[
                { value: 'CA', label: 'CAN' },
                { value: 'US', label: 'USA' },
              ]}
              onChange={(value) => setTaxRateCountry(value)}
            />
          )}
        </ReportFragment.DataGridPanel>
      </div>
      <PrintLayout mode={reportType}>
        <ReportFragment.Header />
        <ControlsTablePrint />
        <SectionPrint flexRow>
          <AggregatesContent rows={filteredRows.output} />
        </SectionPrint>
        <ControlledDataTable controller={dataGridController} rows={filteredRows.output} />
      </PrintLayout>
    </ReportProvider>
  );
};

FuelTaxSummaryReport.propTypes = {
  fetchFuelTaxes: PropTypes.func.isRequired,
  fuelTaxes: PropTypes.array,
  taxRates: PropTypes.array,
  reportViewId: PropTypes.string.isRequired,
  openFuelTaxDetailReportRoute: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};
