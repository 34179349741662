import { connect } from 'react-redux';

import { camera } from '../../../../../data/administration/devices';

import {
  selectSystemTenant,
  selectSystemUser,
  selectSystemOus,
} from '../../../../../data/system/systemSelectors';

import { CameraSummary } from './summary';

export const CameraSummaryContainer = connect(
  (state) => ({
    systemTenant: selectSystemTenant(state),
    systemUser: selectSystemUser(state),
    cameraDevices: camera.selectors.selectCameraDevices(state),
    organizations: selectSystemOus(state),
  }),
  {
    fetchCameraDevice: camera.actions.fetchCameraDevice,
    fetchCameraDevices: camera.actions.fetchCameraDevices,
    deleteCameraDevice: camera.actions.deleteCameraDevice,
    fetchSystemCameraDevices: camera.actions.fetchSystemCameraDevices,
    fetchSystemCameraDevice: camera.actions.fetchSystemCameraDevice,
  }
)(CameraSummary);
