const DEFAULT_LOCATION = { lat: 53.5333, lng: -113.4667 };
const DEFAULT_ZOOM = 5;
const DEFAULT_MAP_ID = 'FLEET-TRACK-MAP';
const DEFAULT_UNIT_SYSTEM = 'metric';

const CATEGORIES = {
  DRIVER: 'driver',
  DRIVERS: 'drivers',
  VEHICLE: 'vehicle',
  VEHICLES: 'vehicles',
  TRAILER: 'trailer',
  TRAILERS: 'trailers',
};

const SIDEBAR_TITLE = 'Drivers and Vehicles';

export {
  CATEGORIES,
  DEFAULT_LOCATION,
  DEFAULT_MAP_ID,
  DEFAULT_UNIT_SYSTEM,
  DEFAULT_ZOOM,
  SIDEBAR_TITLE,
};
