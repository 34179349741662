import React from 'react';
import _, { intersection } from 'lodash';

import { humanizeDate, deHumanizeDate } from './dates';
import { config } from './config';

const mapStyle = {
  padding: '1em',
  height: '80vh',
  width: 227,
  backgroundColor: 'rgba(255, 255, 255, .85)',
  boxShadow: '0 0 .5em #999',
};

const markerLabelStyle = {
  position: 'absolute',
  boxShadow: '0 0 6px #999',
  backgroundColor: 'rgba(255, 255, 255, .8)',
  left: 35,
  top: 5,
  padding: 2,
  whiteSpace: 'nowrap',
};

const gridStyle = { display: 'flex', flexDirection: 'row', gap: 8, marginBottom: 10 };

const secondaryColor = '#FFF';

function getMinutes(dateString) {
  const now = new Date();
  const ms = now.getTime() - new Date(dateString).getTime();
  return Math.floor(ms / 60000);
}

function getMarkerColorByMinutes(minutes, isLoggedIn) {
  if (minutes >= 0 && minutes < 30) {
    return '#0DAB0D';
  }

  if (minutes >= 30 && minutes < 60) {
    return '#AB830D';
  }

  if (minutes >= 60 && minutes < 1440 && isLoggedIn) {
    return '#AB0D0D';
  }

  return '#777777';
}

function setColorByCategoryAndDate({ date, driver, vehicle }) {
  const minutes = getMinutes(date);
  const isLoggedIn = driver && vehicle;

  return getMarkerColorByMinutes(minutes, isLoggedIn);
}

function getMarker(marker, selectedMarkerKey) {
  if (!marker || !marker.vehicle) return null;

  const isSelected = marker.key === selectedMarkerKey;
  const color = setColorByCategoryAndDate(marker);

  const width = config.INCREASE_SELECTED_MARKER_SIZE && isSelected ? 45 : 35;
  const height = config.INCREASE_SELECTED_MARKER_SIZE && isSelected ? 45 : 35;

  return (
    <svg
      className={!config.INCREASE_SELECTED_MARKER_SIZE && isSelected ? 'is-selected' : undefined}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      preserveAspectRatio="xMidYMid meet"
      style={{
        transform: `rotate(${marker.vehicle.heading || 0}deg)`,
        borderRadius: '50%',
      }}
      shapeRendering="geometricPrecision"
    >
      <rect
        x="10%"
        y="10%"
        width="70%"
        height="70%"
        fill={marker.vehicle.loaded ? color : secondaryColor}
        rx="50%" // rounded to cover only the 1st path
      />
      <path
        fill={marker.vehicle.loaded ? secondaryColor : color}
        d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z"
      />
      <path
        fill={marker.vehicle.loaded ? secondaryColor : color}
        d="m7.293 11.293 1.414 1.414L11 10.414V16h2v-5.586l2.293 2.293 1.414-1.414L12 6.586l-4.707 4.707z"
      />
    </svg>
  );
}

function getMarkerInfo({ category, ...rest }) {
  const { vehicle, trailers } = rest;

  if (category === 'trailer') {
    return (
      vehicle?.connectedTrailersNames?.join(', ') ||
      trailers.map(({ vehicleId }) => vehicleId)?.join(', ')
    );
  }

  return vehicle?.vehicleId;
}

function getActiveStatus(dateString, category, hasDriverAssigned) {
  const minutes = getMinutes(dateString);

  if (category !== 'driver') {
    return hasDriverAssigned ? minutes < 1440 : minutes < 60;
  }

  return minutes < 1440;
}

const createMapDataset = ({ driverActivity, vehicleLocations }, filteredOus) => {
  const filteredData = {
    driverActivity: driverActivity.filter(
      ({ deleted, enabled, ous }) =>
        deleted !== true && enabled !== false && intersection(ous, filteredOus).length > 0
    ),
    vehicleLocations: vehicleLocations.filter(
      ({ deleted, enabled, trailerClass, ous }) =>
        deleted !== true &&
        enabled !== false &&
        trailerClass === false &&
        intersection(ous, filteredOus).length > 0
    ),
    trailerLocations: vehicleLocations.filter(
      ({ deleted, enabled, trailerClass, ous }) =>
        deleted !== true &&
        enabled !== false &&
        trailerClass === true &&
        intersection(ous, filteredOus).length > 0
    ),
  };

  const drivers = filteredData.driverActivity.map((driver) => {
    const vehicleFound = filteredData.vehicleLocations.find(
      (item) => item.driver === driver.driverKey
    );

    const assignedDate = new Date(
      new Date(vehicleFound?.date || driver.date) > new Date(driver.date)
        ? vehicleFound?.date
        : driver.date
    )
      .toISOString()
      .replace('Z', '+0000');

    return {
      category: 'driver',
      key: driver.driverKey, // driver key here is the UID for google map
      driver: {
        ...driver,
      },
      ...(vehicleFound && {
        vehicle: {
          ...vehicleFound,
          location: {
            lat: vehicleFound?.location?.latitude,
            lng: vehicleFound?.location?.longitude,
          },
          connectedTrailersNames: vehicleFound?.connectedTrailers.map(
            (key) =>
              filteredData.trailerLocations.find((trailer) => trailer.vehicle === key)?.vehicleId
          ),
        },
      }),
      date: assignedDate,
      isActive: getActiveStatus(assignedDate, 'driver'),
    };
  });

  const vehicles = filteredData.vehicleLocations.map((vehicle) => {
    const driverFound = filteredData.driverActivity.find(
      (item) => item.driverKey === vehicle.driver
    );

    const assignedDate = new Date(
      new Date(driverFound?.date || vehicle.date) > new Date(vehicle.date)
        ? driverFound?.date
        : vehicle.date
    )
      .toISOString()
      .replace('Z', '+0000');

    return {
      key: vehicle.vehicle, // vehicle key here is the UID for google map
      ...(driverFound && { driver: { ...driverFound } }),
      vehicle: {
        ...vehicle,
        location: { lat: vehicle?.location?.latitude, lng: vehicle?.location?.longitude },
        connectedTrailersNames: vehicle.connectedTrailers.map(
          (key) =>
            filteredData.trailerLocations.find((trailer) => trailer.vehicle === key)?.vehicleId
        ),
      },
      category: 'vehicle',
      date: assignedDate,
      isActive: getActiveStatus(assignedDate, 'driver', driverFound),
    };
  });

  const connectedTrailers = filteredData.vehicleLocations
    .filter((item) => item.connectedTrailers.length > 0)
    .map((vehicle) => {
      const trailersFound = vehicle.connectedTrailers
        .map((key) => filteredData.trailerLocations.find((trailer) => trailer.vehicle === key))
        .filter(Boolean);

      const driverFound = filteredData.driverActivity.find(
        (item) => item.driverKey === vehicle.driver
      );

      const assignedDate = new Date(
        new Date(driverFound?.date || vehicle.date) > new Date(vehicle.date)
          ? driverFound?.date
          : vehicle.date
      )
        .toISOString()
        .replace('Z', '+0000');

      return {
        key: vehicle.connectedTrailers.join('-'), // vehicle connectedTrailers here is the UID for google map for CONNECTED trailers
        vehicle: {
          ...vehicle,
          location: { lat: vehicle?.location?.latitude, lng: vehicle?.location?.longitude },
          connectedTrailersNames: vehicle.connectedTrailers.map(
            (key) =>
              filteredData.trailerLocations.find((trailer) => trailer.vehicle === key)?.vehicleId
          ),
        },
        ...(trailersFound && { trailers: trailersFound }),
        ...(driverFound && { driver: { ...driverFound } }),
        category: 'trailer',
        date: assignedDate,
        isActive: getActiveStatus(assignedDate, 'driver', driverFound),
      };
    });

  const alreadyMappedTrailers = [
    ...new Set(connectedTrailers.flatMap((item) => item.key.split('-'))),
  ];

  const disconnectedTrailers = filteredData.trailerLocations
    .filter((trailer) => !alreadyMappedTrailers.includes(trailer.vehicle))
    .map((trailer) => {
      const driverFound = filteredData.driverActivity.find(
        (item) => item.driverKey === trailer.driver
      );

      const vehicleFound = filteredData.vehicleLocations.find(
        (item) => item.vehicle === trailer.connectedTo
      );

      const assignedDate = new Date(
        new Date(driverFound?.date || trailer.date) > new Date(trailer.date)
          ? driverFound?.date
          : trailer.date
      )
        .toISOString()
        .replace('Z', '+0000');

      return {
        key: trailer.vehicle, // trailer key here is the UID for google map
        ...(driverFound && { driver: { ...driverFound } }),
        ...(vehicleFound && { vehicle: vehicleFound }),
        trailer: {
          ...trailer,
          location: { lat: trailer?.location?.latitude, lng: trailer?.location?.longitude },
        },
        category: 'trailer',
        date: assignedDate,
        isActive: getActiveStatus(assignedDate, 'driver', driverFound),
      };
    });

  const uniqDrivers = _.chain(drivers).sortBy('date').reverse().uniqBy('key').value();
  const uniqVehicles = _.chain(vehicles).sortBy('date').reverse().uniqBy('key').value();
  const uniqConnectedTrailers = _.chain(connectedTrailers)
    .sortBy('date')
    .reverse()
    .uniqBy('key')
    .value();
  const uniqDisconnectedTrailers = _.chain(disconnectedTrailers)
    .sortBy('date')
    .reverse()
    .uniqBy('key')
    .value();

  return [
    ...uniqDrivers,
    ...uniqVehicles,
    ...uniqConnectedTrailers,
    ...uniqDisconnectedTrailers,
  ].filter(Boolean);
};

const formatters = {
  getDriverName: ({ driver }) => driver?.driverName || 'No driver',
  getDutyStatus: ({ driver }) => driver?.dutyStatusDescription || 'N/A',
  getVehicleName: ({ vehicle }) =>
    vehicle?.vehicleId ? (
      <>
        <b>{vehicle?.vehicleId}</b>
        <span>{vehicle?.loaded ? '(Loaded)' : '(Unloaded)'}</span>
      </>
    ) : (
      'No vehicle'
    ),
  getTrailerNames: ({ vehicle }) => vehicle?.connectedTrailersNames.join(', ') || 'No trailers',

  getSpeedValue: ({ vehicle }, unitSystem) => {
    if (!vehicle?.speed) return `0 ${unitSystem?.toLowerCase() === 'imperial' ? 'mph' : 'km/h'}`;

    const speedInMps = vehicle.speed;
    const speedValue =
      unitSystem === 'imperial'
        ? (speedInMps * 3.6) / 1.609 // Convert m/s to mph
        : speedInMps * 3.6; // Convert m/s to km/h

    return `${speedValue.toFixed(0)} ${unitSystem?.toLowerCase() === 'imperial' ? 'mph' : 'km/h'}`;
  },

  getCameraInfo: ({ vehicle }) => (vehicle?.camera?.provider ? 'Navistream' : 'No camera'),

  getHumanizedDate: ({ date }) => humanizeDate(date),
  getNonHumanizedDate: ({ date }) => deHumanizeDate(date),
};

export {
  createMapDataset,
  formatters,
  getMarker,
  getMarkerInfo,
  gridStyle,
  mapStyle,
  markerLabelStyle,
  setColorByCategoryAndDate,
};
