import { connect } from 'react-redux';

import { speedViolations } from '../../../../data/reports';
import { SpeedViolationLocationReport } from './SpeedViolationLocationReport';

export const SpeedViolationLocationReportContainer = connect(
  (state, { speedViolationKey }) => ({
    speedViolation: speedViolations.selectors.selectSpeedViolationsByKey(state, speedViolationKey),
  }),
  {}
)(SpeedViolationLocationReport);
