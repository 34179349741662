import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { isEmpty } from 'lodash';
import { RateLimit } from 'async-sema';

import { selectSystemOus } from '../../../../data/system';

import { useReport } from '../useReport';
import {
  getDateSequences,
  daysBetweenDates,
  getRangeStepByMode,
  setEndDateAfterStartDate,
  toMoment,
} from '../../../../helpers/moment';

const limit = RateLimit(1);

const moment = extendMoment(Moment);

const FetchData = ({
  fetchAction,
  startDate,
  endDate,
  ouKey,
  ous,
  timeZone,
  args = {},
  dateRangeFor,
  noDateRange,
}) => {
  const { registerFetch, validateControl } = useReport();

  useEffect(() => {
    if (noDateRange && !validateControl('startDate', 'endDate')) return registerFetch();

    // this will set invalid endDates for the same as startDate
    if (!noDateRange) {
      if (endDate === 'Invalid date') {
        // eslint-disable-next-line no-param-reassign
        endDate = startDate;
      }
    }

    const fetchData = async () => {
      await limit(); // This will throttle BE endpoint calls
      registerFetch(
        fetchAction({
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),
          ...(ouKey && { ouKey }),
          ...(ous && { ous }),
          ...(timeZone && { timeZone }),
          ...(!isEmpty(args) && { ...args }),
          ...(dateRangeFor && { dateRangeFor }),
        })
      );
    };
    fetchData();
    return undefined;
  }, [startDate, endDate]);

  return null;
};

FetchData.propTypes = {
  fetchAction: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  ouKey: PropTypes.string,
  ous: PropTypes.array,
  timeZone: PropTypes.string,
  args: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dateRangeFor: PropTypes.string,
  noDateRange: PropTypes.bool,
};

export const FetchReportData = connect(
  (state) => ({
    ous: selectSystemOus(state),
  }),
  {}
)(
  ({
    fetchAction,
    isOptimized,
    isMonthly,
    isQuarterlyOrMonthly,
    limits,
    ous,
    args,
    noDateRange,
  }) => {
    const { controls, shouldFetch } = useReport();

    if (!shouldFetch) return null;

    const { startDate, endDate, ouMode, ouKeys, ousSelected, dateRangeFor } = controls;

    let endDateFetch;

    if (toMoment() < toMoment(endDate)) {
      endDateFetch = toMoment().format('YYYY-MM-DD');
    } else {
      endDateFetch = toMoment(endDate).format('YYYY-MM-DD');
    }

    if (isEmpty(endDateFetch)) {
      endDateFetch = toMoment(startDate).endOf('day').format('YYYY-MM-DD');
    }

    // this means that users are trying to query invalid dates, so we will not fetch
    if (!noDateRange && toMoment(startDate) > toMoment(endDateFetch)) {
      return null;
    }

    if (isQuarterlyOrMonthly) {
      return (
        <FetchData startDate={startDate} endDate={endDate} fetchAction={fetchAction} args={args} />
      );
    }

    if (isOptimized) {
      // We will break dates into intervals, if ouMode equals 'all' so we will have a lesser limit
      // If we are querying custom ous or just one, the limit will be greater
      // Limits will be retrieved from an endpoint and send to this component as props
      // With these limits we will then break the range between startDate and endDate, in pairs

      // If we receive noDateRange param, it means we will only do ONE request and not creating
      // any array of multiple fetches sliced by dates;

      const momentStart = moment(startDate);
      const momentEnd = moment(endDateFetch);

      const differenceByDays = daysBetweenDates(startDate, endDateFetch);

      const step = getRangeStepByMode(ouMode, limits, differenceByDays);

      const range = moment.range(momentStart, momentEnd);
      const startDates = Array.from(range.by('days', { step }));

      const fetches = noDateRange
        ? [{ start: undefined, end: undefined }]
        : startDates.map((row) => ({
            start: row.toISOString(),
            end: setEndDateAfterStartDate(row.add(step, 'days'), endDateFetch),
          }));

      if (ouMode === 'all') {
        return (
          <>
            {fetches.map(({ start, end }, index) => (
              <FetchData
                key={index}
                fetchAction={fetchAction}
                startDate={start}
                endDate={end}
                args={args}
                dateRangeFor={dateRangeFor}
                noDateRange={noDateRange}
              />
            ))}
          </>
        );
      }

      if (ouMode === 'custom') {
        return (
          <>
            {ousSelected.map((ouKey) =>
              fetches.map(({ start, end }, index) => (
                <FetchData
                  key={ouKey + index}
                  fetchAction={fetchAction}
                  startDate={start}
                  endDate={end}
                  ouKey={ouKey}
                  ous={ous}
                  timeZone={ous?.find((ou) => ou?.key === ouKey)?.timeZone}
                  dateRangeFor={dateRangeFor}
                  args={args}
                  noDateRange={noDateRange}
                />
              ))
            )}
          </>
        );
      }

      // This is a intermediate state where ouMode is equal to 'default' and still have all ouKeys selected
      // we will not fetch any unnecessary data here, when the next state update happens and we have only one ouKey
      // then we will call the endpoint with only one ouKey
      if (ouMode === 'default' && ouKeys.length > 1) {
        return null;
      }

      const currentOuKey = ouKeys[0];

      return (
        <>
          {fetches.map(({ start, end }, index) => (
            <FetchData
              key={currentOuKey + index}
              fetchAction={fetchAction}
              startDate={start}
              endDate={end}
              ouKey={currentOuKey}
              ous={ous}
              timeZone={ous?.find((ou) => ou?.key === currentOuKey)?.timeZone}
              dateRangeFor={dateRangeFor}
              args={args}
              noDateRange={noDateRange}
            />
          ))}
        </>
      );
    }

    if (isMonthly) {
      // We will break startDate and endDate in year-month sequences in order to call specific endpoints that accept only one
      // date parameter instead of start and end.

      if (ouMode === 'all') {
        return (
          <>
            {getDateSequences(startDate, endDateFetch).map((currentDate) => (
              <FetchData
                fetchAction={fetchAction}
                startDate={currentDate}
                endDate={currentDate}
                args={args}
                dateRangeFor={dateRangeFor}
                noDateRange={noDateRange}
              />
            ))}
          </>
        );
      }

      if (ouMode === 'custom') {
        return (
          <>
            {ousSelected.map((ouKey) =>
              getDateSequences(startDate, endDateFetch).map((currentDate) => (
                <FetchData
                  key={ouKey + currentDate}
                  fetchAction={fetchAction}
                  startDate={currentDate}
                  endDate={currentDate}
                  ouKey={ouKey}
                  timeZone={ous?.find((ou) => ou?.key === ouKey)?.timeZone}
                  ous={ous}
                  dateRangeFor={dateRangeFor}
                  args={args}
                  noDateRange={noDateRange}
                />
              ))
            )}
          </>
        );
      }

      // This is a intermediate state where ouMode is equal to 'default' and still have all ouKeys selected
      // we will not fetch any unnecessary data here, when the next state update happens and we have only one ouKey
      // then we will call the endpoint with only one ouKey
      if (ouMode === 'default' && ouKeys.length > 1) {
        return null;
      }

      const currentOuKey = ouKeys[0];

      return (
        <>
          {getDateSequences(startDate, endDateFetch).map((currentDate) => (
            <FetchData
              key={currentOuKey + currentDate}
              fetchAction={fetchAction}
              startDate={currentDate}
              endDate={currentDate}
              ouKey={currentOuKey}
              timeZone={ous?.find((ou) => ou?.key === currentOuKey)?.timeZone}
              ous={ous}
              dateRangeFor={dateRangeFor}
              args={args}
              noDateRange={noDateRange}
            />
          ))}
        </>
      );
    }

    // Finally, if the component is not optimized or monthly, it means that we need to iterate all the ous
    // and make the api calls using each OU by start an end dates intervals, this is tends to be a heavy operation.
    // this will be deprecated in the future, for now this is just a fallback mechanism
    return (
      <>
        {ouKeys.map((ouKey) => (
          <FetchData
            key={ouKey}
            fetchAction={fetchAction}
            startDate={startDate}
            endDate={endDateFetch}
            ouKey={ouKey}
            ous={ous}
            timeZone={ous?.find((ou) => ou?.key === ouKey)?.timeZone}
            dateRangeFor={dateRangeFor}
            args={args}
            noDateRange={noDateRange}
          />
        ))}
      </>
    );
  }
);

FetchReportData.propTypes = {
  fetchAction: PropTypes.func.isRequired,
  isOptimized: PropTypes.bool,
  limits: PropTypes.object,
};
