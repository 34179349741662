import { connect } from 'react-redux';

import { ShiftsSummaryReport } from './ShiftsSummaryReport';
import { shifts } from '../../../../data/reports';
import { selectSystemUser, setPrintMode, selectSearchLimits } from '../../../../data/system';

export const ShiftsSummaryReportContainer = connect(
  (state) => ({
    shifts: shifts.selectors.selectShifts(state),
    userKey: selectSystemUser(state).key,
    limits: selectSearchLimits(state, 'shifts'),
  }),
  { fetchShifts: shifts.actions.fetchShifts, setPrintMode }
)(ShiftsSummaryReport);
