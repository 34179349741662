import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ManageReports } from './ManageReports';
import { reportViews } from '../../../data/reports';
import { selectSystemActiveOu } from '../../../data/system';

export const ManageReportsContainer = connect(
  (state, props) => ({
    activeOu: selectSystemActiveOu(state),
    reportViews: reportViews.selectors.selectVisibleReportViews(state, props),
  }),
  {
    fetchReportViewsByReportType: reportViews.actions.fetchReportViewsByReportType,
    deleteReportView: reportViews.actions.deleteReportView,
    updateReportView: reportViews.actions.updateReportView,
  }
)(ManageReports);

ManageReportsContainer.propTypes = {
  reportType: PropTypes.string.isRequired,
};
