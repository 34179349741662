import { columnDef } from '../../../../commons/ControlledDataGrid';

export const columnDefs = [
  columnDef.textColumn({
    field: 'gatewayDevice.type',
    headerName: 'Device Type',
    width: 120,
  }),
  columnDef.textColumn({
    field: 'uptime',
    headerName: 'Uptime',
    valueGetter: ({ data }) =>
      data?.uptime > 100 ? '100' : `${Math.floor(data?.uptime).toString()}%`,
    width: 90,
  }),
  columnDef.textColumn({
    field: 'status',
    headerName: 'Working',
    cellRenderer: 'Working',
  }),
  columnDef.textColumn({
    field: 'vehicle.name',
    headerName: 'Vehicle ID',
  }),
  columnDef.textColumn({
    field: 'ou.name',
    headerName: 'Managed By',
  }),
  columnDef.dateColumn({
    field: 'date',
    headerName: 'Date',
  }),
  columnDef.textColumn({
    field: 'gatewayDevice.firmwareVersion',
    headerName: 'Firmware Version',
    enableRowGroup: false,
  }),
  columnDef.textColumn({
    field: 'gatewayDevice.serialNumber',
    headerName: 'Serial Number',
  }),
];
