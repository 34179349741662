import { DateTime } from './date';

const startGeneralPurposeTransfer = (payload, setLoading) => {
  console.log('startGeneralPurposeTransfer', payload); // Todo: understand the BE functionality before send to BE
  setLoading(false);
};

const startDataTransfer = (payload, setLoading) => {
  console.log('startDataTransfer', payload); // Todo: understand the BE functionality before send to BE
  setLoading(false);
};

export const handleNonEldSubmit = (data, setLoading) => {
  const { currentSystemUser, content, toEmails, orientation, records } = data;

  const canadianUrls = records.includes('Canada')
    ? content.map(
        (log) => `/#/canadianEldLogs/${log.driver.key}-${log.year}${log.month}${log.day}/default`
      )
    : [];

  const usUrls = records.includes('US')
    ? content.map(
        (log) => `/#/usEldLogs/${log.driver.key}-${log.year}${log.month}${log.day}/default`
      )
    : [];

  const urls = canadianUrls.concat(usUrls);
  const payload = {
    title: 'General Purpose Data Transfer',
    orientation,
    urls,
    toEmails,
    userKey: currentSystemUser?.key,
  };

  setLoading(true);
  startGeneralPurposeTransfer(payload, setLoading);
};

export const handleEldSubmit = (data, setLoading) => {
  const { content, isSingleRow, comment, agency, email, mechanism } = data;

  let processedLogs = content;

  if (isSingleRow) {
    const { logDate, timeZone } = content[0];
    const { startDate, endDate } = data;
    if (startDate !== logDate || endDate !== logDate) {
      processedLogs = [
        {
          ...content[0],
          startedAt: DateTime.fromISO(startDate).setZone(timeZone).startOf('day').toISO(),
          endedAt: DateTime.fromISO(endDate).setZone(timeZone).endOf('day').toISO(),
        },
      ];
    }
  }
  setLoading(true);
  startDataTransfer({
    logs: processedLogs,
    comment,
    jurisdiction: agency === 'FMCSA' ? 'Us' : 'Cad',
    ...(email && { email }),
    mechanism: agency === 'FMCSA' ? mechanism : 'EMAIL',
  });
};
