import { findIndex, castArray, flatten, reject, isObject, find, cloneDeep, uniqBy } from 'lodash';

function mergeObjects(target, source) {
  const clone = cloneDeep(target);
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (Array.isArray(source[key])) {
          clone[key] = uniqBy([...(clone[key] || []), ...source[key]], (item) =>
            isObject(item) ? JSON.stringify(item) : item
          );
        } else {
          clone[key] = mergeObjects(clone[key] || {}, source[key]);
        }
      } else {
        clone[key] = source[key];
      }
    }
  }
  return clone;
}

const mergeEntities = (originalArray, newItemOrArray, key, mergeFn) => {
  const oldItems = [...originalArray];
  const processItems = castArray(newItemOrArray);
  const addItems = [];
  processItems.forEach((newItem) => {
    if (!isObject(newItem) || !newItem[key])
      throw new Error(
        `Entity to process is not an object or is missing key property. New item: ${JSON.stringify(
          newItem
        )}`
      );
    const index = findIndex(originalArray, { [key]: newItem[key] });
    if (index !== -1) {
      oldItems[index] = mergeFn(oldItems[index], newItem);
    } else {
      addItems.push(newItem);
    }
  });

  return oldItems.concat(addItems);
};

const deepMergeFn = (originalItem, newItem) => mergeObjects({ ...originalItem }, newItem);
const shallowMergeFn = (originalItem, newItem) => mergeObjects(originalItem, newItem);

export const deepMergeEntities = (originalArray, newItemOrArray, key = 'id') =>
  mergeEntities(originalArray, newItemOrArray, key, deepMergeFn);

export const shallowMergeEntities = (originalArray, newItemOrArray, key = 'id') =>
  mergeEntities(originalArray, newItemOrArray, key, shallowMergeFn);

export const removeEntity = (originalArray = [], removeItem, key = 'id') => {
  if (!isObject(removeItem) || !removeItem[key])
    throw new Error('Entity to remove not an object or is missing key property.');
  const updatedItems = [...originalArray];
  return reject(updatedItems, { [key]: removeItem[key] });
};

export const actionTypeRequest = (actionType, matchType) => {
  const matchTypes = castArray(matchType).map((baseMatchType) => `${baseMatchType}.request`);
  return matchTypes.includes(actionType);
};

export const actionTypeSuccess = (actionType, matchType) => {
  const matchTypes = castArray(matchType).map((baseMatchType) => `${baseMatchType}.success`);
  return matchTypes.includes(actionType);
};

export const actionTypeProgressOrSuccess = (actionType, matchType) => {
  const matchTypes = flatten(
    castArray(matchType).map((baseMatchType) => [
      `${baseMatchType}.progress`,
      `${baseMatchType}.success`,
    ])
  );
  return matchTypes.includes(actionType);
};

export const actionTypeFailure = (actionType, matchType) => {
  const matchTypes = castArray(matchType).map((baseMatchType) => `${baseMatchType}.failure`);
  return matchTypes.includes(actionType);
};

export const eagerlyMergeEntity = (originalArray, newItem, key = 'id') => {
  const foundItem = find(originalArray, { [key]: newItem[key] });
  const interimItem = {
    ...newItem,
    __updatingFrom: foundItem,
  };
  return mergeEntities(
    originalArray,
    interimItem,
    key,
    (originalItem, replacementItem) => replacementItem
  );
};

export const revertEagerlyMergedEntity = (originalArray, revertingItem, key = 'id') => {
  const foundItem = find(originalArray, { [key]: revertingItem[key] });
  if (!foundItem || !foundItem.__updatingFrom) return originalArray; // item not found as expected, do nothing
  return mergeEntities(
    originalArray,
    foundItem.__updatingFrom,
    key,
    (originalItem, replacementItem) => replacementItem
  );
};
