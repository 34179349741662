import produce from 'immer';

import { actionTypeProgressOrSuccess, deepMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchVehiclesLatestInspections: 'reports/inspections/fetchVehiclesLatestInspections',
  fetchInspectorsLatestInspections: 'reports/inspections/fetchInspectorsLatestInspections',
  fetchInspections: 'reports/inspections/fetchInspections',
  fetchMyInspections: 'reports/inspections/fetchMyInspections',
  fetchInspection: 'reports/inspections/fetchInspection',
  updateInspection: 'reports/inspections/updateInspection',
  updateInspectionDefectAndNote: 'reports/inspections/updateInspectionDefectAndNote',
};

const initialState = {
  data: [],
};

export const inspectionsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchInspections,
        ACTION_TYPE.fetchMyInspections,
        ACTION_TYPE.fetchInspection,
        ACTION_TYPE.updateInspection,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchVehiclesLatestInspections,
        ACTION_TYPE.fetchInspectorsLatestInspections,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload);
    }

    if (actionTypeProgressOrSuccess(type, [ACTION_TYPE.updateInspectionDefectAndNote])) {
      const { data } = state;
      const newData = data.filter(({ id }) => id !== payload.id);
      newData.push(payload);
      draft.data = newData;
    }
  });
