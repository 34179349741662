import moment from 'moment-timezone';

import { isNil } from 'lodash';
import { omitPrivateProperties } from '../../apiActionSupport';

export const driverObject = ({ driver }) => {
  if (!driver) return { name: '', firstName: '', lastName: '' };
  return {
    firstName: driver.name.split(' ')[0],
    lastName: driver.name.split(' ')[1],
    ...driver,
  };
};

const yearMonthDayToDate = (year, month, day) =>
  `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

export const dateProperties = (logDate, timeZone) => {
  const startOfDayMoment = moment.tz(logDate, timeZone).startOf('day');
  const endOfDayMoment = moment.tz(logDate, timeZone).endOf('day');
  const isPartial = moment().isBefore(endOfDayMoment);

  return {
    logDate,
    timeZone,
    startedAt: startOfDayMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
    endedAt: isPartial ? undefined : endOfDayMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
  };
};

export const transformReviewDetails = ({
  driver: driverKey,
  reviewer: reviewerKey,
  year,
  month,
  day,
  startTime, // drop
  ...rest
}) => ({
  // Need to add key matching driver log record
  key: `${yearMonthDayToDate(year, month, day)}-${driverKey}`,
  reviewDetails: {
    driverKey,
    reviewerKey,
    ...omitPrivateProperties(rest),
  },
});

export const transformDriverLogs = (logs, { ouKey, hasActivity }) => {
  const transformedLogs = [];
  const filteredLogs = isNil(hasActivity)
    ? logs
    : logs.filter((log) => log.hasActivity === hasActivity);

  for (let i = 0; i < filteredLogs.length; i += 1) {
    const { logDate, year, month, day, timeZone, ous, coDrivers, ...rest } = filteredLogs[i];
    const date = logDate || yearMonthDayToDate(year, month, day);

    transformedLogs.push({
      ...rest,
      ...dateProperties(date, timeZone),
      ouKey: ouKey || (ous && ous[0] && ous[0].key.toString()) || null,
      driver: driverObject(logs[i]),
      coDrivers: (coDrivers && coDrivers.map(driverObject)) || [],
    });
  }

  return transformedLogs;
};
