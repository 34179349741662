/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Clickable,
  Icon,
  ToggleInput,
  BasicDialog,
  GoogleMap,
  ExpansionPanel,
} from 'stti-react-common';
import { filter, includes, isNil } from 'lodash';
import { Feature } from '../../../../commons/Feature';
import { customPropTypes } from '../../../../../helpers/customPropTypes';
import {
  ControlledDataGrid,
  adminGridOptions as gridOptions,
} from '../../../../commons/ControlledDataGrid';

import { makeColumnDefs } from './columnDefs';
import { useDebounce } from '../../../../../helpers/hooks';
import { AdminSearchPanel, AdminExportDataMenu } from '../../../../commons/AdminCommon';
import { MapIcon, AggregatesCard } from '../../../../commons/ReportsCommon';
import { symbols, EventInfo } from '../../../../commons/MapsCommon';

import { durationBetweenIntervals, toMoment } from '../../../../../helpers/moment';
import { ADMIN_ROLE_KEY, SUPER_ADMIN_ROLE_KEY } from '../../../../../data/system/constants';

import { getMembership } from '../utils';

import { useFormats } from '../../../../commons/Formats';

import './summary.scss';

const { Marker } = GoogleMap;

const RenderNoVideoProvider = (route) => (
  <Feature.Wrapper className="CameraSummary">
    <Feature.Header onClose={route && route?.close} title="Cameras" service="cameraDevices" />
    <Feature.Body>
      <div className="presentation">
        <div className="cameraImage" />
        <div className="infoContainer">
          <h4>You don’t have any Cameras associated to your account.</h4>
          <p>
            Click{' '}
            <a href="http://stti.com/navistream-camera/" target="_blank" rel="noreferrer">
              HERE
            </a>{' '}
            to learn more about our Navistream Camera.
          </p>
          <p>
            Reach out to our Sales Team (<a href="mailto:sales@stti.ca">sales@stti.ca</a> or
            844.571.8200 option #1) for more information and pricing.
          </p>
        </div>
      </div>
    </Feature.Body>
  </Feature.Wrapper>
);

const RenderVideoProvider = ({
  cameraDevices,
  deleteCameraDevice,
  fetchCameraDevice,
  fetchCameraDevices,
  organizations,
  openCameraDetail,
  openVehicleDetail,
  route,
  systemUser,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState({ id: null, label: null, isOpen: false });
  const [expanded, setExpanded] = useState(false);
  const [item, setItem] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [showActive, setShowActive] = useState(false);
  const [showAssigned, setShowAssigned] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500, '');

  const { formatUnit } = useFormats();

  useEffect(() => {
    fetchCameraDevices({ ouKey: undefined });
  }, []);

  const topOu = useMemo(
    () =>
      organizations.find((org) => isNil(org?.parentKey) && org?.scope?.toLowerCase() === 'tenant'),
    [organizations]
  );

  const isSuperAdminFromTopOu = useMemo(
    () =>
      !!systemUser?.memberships?.find(
        ({ ou, role }) => role.includes(SUPER_ADMIN_ROLE_KEY) && ou === topOu?.key?.toString()
      ),

    [systemUser, topOu]
  );

  const isAdminFromTopOu = useMemo(
    () =>
      !!systemUser?.memberships?.find(
        ({ ou, role }) => role.includes(ADMIN_ROLE_KEY) && ou === topOu?.key?.toString()
      ),
    [systemUser, topOu]
  );

  const rows = useMemo(() => {
    const memberships = systemUser?.memberships?.map(({ ou }) => ou);

    return filter(
      cameraDevices,
      (row) =>
        (showActive ? row.lastSeenOnline !== null : true) &&
        (showAssigned ? row.vehicleKey !== null : true) &&
        getMembership(memberships, row.ouKey, isSuperAdminFromTopOu, isAdminFromTopOu)
    );
  }, [cameraDevices, showActive, showAssigned, systemUser]);

  const disableDelete = useMemo(() => !systemUser?.username?.includes('streamline@'), [systemUser]);

  const { useDataGridComponents, useDataGridController, asDataGridHeaderComponent, createState } =
    ControlledDataGrid;

  const dataGridComponents = useDataGridComponents({
    /* eslint-disable react/prop-types */
    Actions: ({ data }) => (
      <>
        <Button
          onClick={() => {
            openCameraDetail({ cameraId: data.id, mode: 'edit' });
          }}
          icon="edit"
        />
        <Button
          onClick={() => {
            setIsRefreshing(true);
            setCurrentRow(data);
            fetchCameraDevice(data.id).then(() => setIsRefreshing(false));
          }}
          icon="refresh"
        />
        <Button
          onClick={() => {
            setOpenDeleteModal({ id: data.id, label: data.assetTag, isOpen: true });
          }}
          icon="delete"
          disabled={disableDelete}
        />
      </>
    ),
    ActionsRefresh: () => (
      <>
        <Button disabled icon="edit" />
        <Button disabled icon="pending" />
      </>
    ),
    AssetHeader: () => <Icon icon="launch" />,
    AssetTag: ({ data, value }) => (
      <Clickable onClick={() => openCameraDetail({ cameraId: data.id, mode: 'view' })}>
        <Icon icon="launch" />
      </Clickable>
    ),
    LocationMapHeader: asDataGridHeaderComponent(() => <MapIcon />),
    LocationMap: ({ data }) => (
      <Clickable className="MapIcon" disabled={!data?.location} onClick={() => setItem(data)}>
        <MapIcon style={{ color: data?.location ? '#000000' : '#808080' }} />
      </Clickable>
    ),
    Status: ({ value }) => (
      <>
        <span className={`status ${value}`} />
        <span>{value}</span>
      </>
    ),
    Vehicle: ({ data, value }) =>
      !value ? (
        'Not Assigned'
      ) : (
        <Clickable
          onClick={() =>
            openVehicleDetail(
              { cameraId: data.id, assetId: data.vehicleKey },
              { ignoreUrlByMenuCategory: 'administration', from: 'cameras' }
            )
          }
        >
          {value}
        </Clickable>
      ),
  });

  const dataGridController = useDataGridController({
    gridOptions,
    columnDefs: makeColumnDefs(isRefreshing),
  });

  useEffect(() => {
    dataGridController.setState(createState({ columnDefs: makeColumnDefs(isRefreshing) }));
    dataGridController.methods.autoSizeColumns();

    if (isRefreshing && currentRow) {
      dataGridController.setState({ selectedRowIds: [currentRow.id] });
    }
  }, [isRefreshing, currentRow]);

  return (
    <Feature.Wrapper className="CameraSummary">
      <Feature.Header onClose={route && route?.close} title="Cameras" service="cameraDevices">
        <AdminExportDataMenu dataGridController={dataGridController} filename="Cameras" />
      </Feature.Header>

      <Feature.Body>
        <div className="CameraSummary__Aggregates">
          <ExpansionPanel
            title="Aggregates"
            onChange={() => setExpanded(!expanded)}
            value={expanded}
            summary={
              expanded ? null : (
                <div>
                  {rows.length} registered cameras,{' '}
                  {rows.filter(({ lastSeenOnline }) => lastSeenOnline !== null)?.length} active
                  cameras, {rows.filter(({ status }) => status === 'online')?.length} currently
                  online,{' '}
                  {
                    rows.filter(
                      ({ lastSeenOnline }) =>
                        durationBetweenIntervals(lastSeenOnline, toMoment().format(), 'days') <= 30
                    )?.length
                  }{' '}
                  online last 30 days
                </div>
              )
            }
          >
            <AggregatesCard
              title="Overview"
              name="overview"
              fields={[
                {
                  header: 'Registered cameras',
                  value: cameraDevices.length,
                },
                {
                  header: 'Active Cameras',
                  value: cameraDevices.filter(({ lastSeenOnline }) => lastSeenOnline !== null)
                    ?.length,
                },
                {
                  header: 'Currently Online',
                  value: cameraDevices.filter(({ status }) => status === 'online')?.length,
                },
                {
                  header: 'Online Last 30 days',
                  value: cameraDevices.filter(
                    ({ lastSeenOnline }) =>
                      durationBetweenIntervals(lastSeenOnline, toMoment().format(), 'days') <= 30
                  )?.length,
                },
              ]}
            />
          </ExpansionPanel>
        </div>

        <AdminSearchPanel
          searchPlaceHolder="Search By"
          searchText={searchText}
          onSearchTextChanged={setSearchText}
          renderPanelActions={
            <>
              <ToggleInput
                label="Show Only Active"
                value={showActive}
                onChange={() => {
                  setShowActive(!showActive);
                }}
              />
              <ToggleInput
                label="Show Only Assigned"
                value={showAssigned}
                onChange={() => {
                  setShowAssigned(!showAssigned);
                }}
              />
            </>
          }
        />
        <ControlledDataGrid
          theme="balham"
          controller={dataGridController}
          components={dataGridComponents}
          rows={rows}
          searchText={debouncedSearchText}
          rowIdProperty="id"
          enableCellTextSelection
          getRowStyle={(params) => {
            if (!params.data) return true;
            if (includes(params.context.selectedRowIds, params.data.id)) {
              return { background: '#ffff77' };
            }
            return true;
          }}
        />
        {!!item && (
          <BasicDialog
            className="CameraSummary__Modal"
            title={`${item.assetTag} : Last Known Location`}
            isOpen={!!item}
            onClose={() => setItem(null)}
            maxWidth="lg"
            buttons={[
              {
                label: 'Close',
                onClick: () => setItem(null),
              },
            ]}
          >
            <GoogleMap focus={item?.location} focusZoom={12}>
              <Marker position={item?.location} icon={symbols.videoIcon}>
                <EventInfo
                  event={{
                    eventType: `Last Known Location`,
                    eventAt: item?.lastSeenOnline,
                    timeZone: item?.ouTimeZone,
                  }}
                  trip={{ ...item, startedAt: item?.lastSeenOnline || '' }}
                  extraInfo={
                    <ul>
                      <li>Vehicle Assignment: {item?.vehicleName}</li>
                      <li>
                        Altitude:{' '}
                        {item?.location?.altitude
                          ? Number(item?.location?.altitude).toFixed(2)
                          : '-'}
                        m
                      </li>
                      <li>Speed: {item?.speed ? formatUnit('speed', item?.speed) : '-'}</li>
                    </ul>
                  }
                />
              </Marker>
            </GoogleMap>
          </BasicDialog>
        )}

        <BasicDialog
          className="CameraSummary__Delete_Modal"
          title={`Are you sure you want to delete ${openDeleteModal?.label} ?`}
          isOpen={openDeleteModal?.isOpen}
          onClose={() => setOpenDeleteModal({ id: null, label: null, isOpen: false })}
          maxWidth="lg"
          buttons={[
            {
              label: 'Cancel',
              onClick: () => setOpenDeleteModal({ id: null, label: null, isOpen: false }),
            },
            {
              label: 'Delete',
              onClick: () =>
                deleteCameraDevice(openDeleteModal?.id).then(
                  setOpenDeleteModal({ id: null, label: null, isOpen: false })
                ),
            },
          ]}
        />
      </Feature.Body>
    </Feature.Wrapper>
  );
};

export const CameraSummary = (props) => {
  const hasVideoProvider =
    props?.systemTenant?.enabledVideoProviders &&
    props?.systemTenant?.enabledVideoProviders?.length > 0;

  return !hasVideoProvider ? RenderNoVideoProvider(props.route) : RenderVideoProvider({ ...props });
};

CameraSummary.propTypes = {
  cameraDevices: PropTypes.array,
  deleteCameraDevice: PropTypes.func,
  fetchCameraDevice: PropTypes.func,
  fetchCameraDevices: PropTypes.func,
  openCameraDetail: PropTypes.func,
  openVehicleDetail: PropTypes.func,
  organizations: PropTypes.array,
  route: PropTypes.object,
  systemTenant: customPropTypes.tenant.isRequired,
  systemUser: PropTypes.object.isRequired,
};

RenderVideoProvider.propTypes = {
  cameraDevices: PropTypes.array,
  deleteCameraDevice: PropTypes.func,
  fetchCameraDevice: PropTypes.func,
  fetchCameraDevices: PropTypes.func,
  openCameraDetail: PropTypes.func,
  openVehicleDetail: PropTypes.func,
  organizations: PropTypes.array,
  route: PropTypes.object,
  systemUser: PropTypes.object.isRequired,
};
