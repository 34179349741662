import { columnDef } from '../../../commons/ControlledDataGrid';

export const columnDefs = [
  {
    headerName: 'Date',
    children: [
      columnDef.dateColumn({
        field: 'dateEntered',
        headerName: 'Entered',
        width: 100,
      }),
      columnDef.dateColumn({
        field: 'dateExited',
        headerName: 'Exited',
        width: 100,
      }),
    ],
  },
  {
    headerName: 'Details',
    children: [
      columnDef.textColumn({
        field: 'geozoneName',
        headerName: 'Geozone Name',
        width: 130,
        hide: true,
      }),
      columnDef.textColumn({
        field: 'geozoneTypeName',
        headerName: 'Geozone Type',
        hide: true,
      }),
      columnDef.textColumn({
        field: 'vehicleId',
        headerName: 'Vehicle',
        width: 120,
      }),
      columnDef.textColumn({
        field: 'driverName',
        headerName: 'Driver',
        cellRenderer: 'OpenDriverShift',
        width: 130,
      }),
      columnDef.distanceColumn({
        field: 'distanceDriven',
        headerName: 'Distance',
        width: 100,
        precision: 1,
      }),
      columnDef.fuelColumn({
        field: 'fuelConsumed',
        headerName: 'Fuel',
        width: 80,
      }),
      columnDef.numberColumn({
        field: 'speedViolationCount',
        headerName: 'Speed Violations',
        width: 80,
      }),
      columnDef.speedColumn({
        field: 'averageSpeed',
        headerName: 'Average Speed',
        width: 80,
      }),
      columnDef.speedColumn({
        field: 'maximumSpeed',
        headerName: 'Maximum Speed',
        width: 80,
      }),
      columnDef.speedColumn({
        field: 'speedLimit',
        headerName: 'Speed Limit',
        width: 80,
        hide: true,
      }),
      columnDef.durationColumn({
        field: 'gracePeriod',
        headerName: 'Grace Period',
        width: 80,
        hide: true,
      }),
      columnDef.booleanColumn({
        field: 'loaded',
        headerName: 'Exit Status',
        trueLabelText: 'Loaded',
        falseLabelText: 'Unloaded',
        falseRank: 0,
        trueRank: 1,
        width: 80,
        hide: true,
      }),
    ],
  },
  {
    headerName: 'Duration',
    children: [
      columnDef.durationColumn({
        field: 'driveDuration',
        headerName: 'Driving',
        width: 100,
      }),
      columnDef.durationColumn({
        field: 'stopDuration',
        headerName: 'Stopped',
        width: 100,
      }),
      columnDef.durationColumn({
        field: 'idleDuration',
        headerName: 'Idling',
        width: 100,
      }),
      columnDef.durationColumn({
        field: 'duration',
        headerName: 'Total',
        width: 100,
      }),
    ],
  },
];
