import produce from 'immer';
import { sortBy } from 'lodash';

import { actionTypeProgressOrSuccess, deepMergeEntities } from '../../apiReducerSupport';

export const ACTION_TYPE = {
  fetchDriverLogs: 'reports/driverLogs/fetchDriverLogs',
  fetchDriverLog: 'reports/driverLogs/fetchDriverLog',
  submitCreateLogDutyStatusEvent: 'reports/driverLogs/submitCreateLogDutyStatusEvent',
  submitUpdateLogDutyStatusEvent: 'reports/driverLogs/submitUpdateLogDutyStatusEvent',
  submitReassignLogDutyStatusEvent: 'reports/driverLogs/submitReassignLogDutyStatusEvent',
  startDataTransfer: 'reports/driverLogs/startDataTransfer',
  startGeneralPurposeTransfer: 'reports/driverLogs/startGeneralPurposeTransfer',
  fetchDriverLogsReviewDetails: 'reports/driverLogs/fetchDriverLogsReviewDetails',
  fetchDriverLogReviewDetailsByKey: 'reports/driverLogs/fetchDriverLogReviewDetailsByKey',
  reviewViolation: 'reports/driverLogs/reviewViolation',
  fetchMyDriverLogs: 'reports/driverLogs/fetchMyDriverLogs',
  signDriverLog: 'reports/driverLogs/signDriverLog',
  addDriverLogComment: 'reports/driverLogs/addDriverLogComment',
  deleteDriverLogComment: 'reports/driverLogs/deleteDriverLogComment',
};

const initialState = {
  data: [],
};

export const driverLogsReducer = (state = initialState, { type, request, payload }) =>
  produce(state, (draft) => {
    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchDriverLogs,
        ACTION_TYPE.fetchDriverLog,
        ACTION_TYPE.fetchMyDriverLogs,
        ACTION_TYPE.signDriverLog,
      ])
    ) {
      draft.data = sortBy(deepMergeEntities(state.data, payload, 'key', true), [
        'logDate',
        'driver.name',
      ]);
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.submitCreateLogDutyStatusEvent,
        ACTION_TYPE.submitReassignLogDutyStatusEvent,
        ACTION_TYPE.submitUpdateLogDutyStatusEvent,
      ])
    ) {
      const appendExistingStatusChanges = ({ key, adminStatusChanges }) => ({
        adminStatusChanges: state.data
          .find((log) => log.key === key)
          .adminStatusChanges.concat(adminStatusChanges),
        key,
      });
      draft.data = deepMergeEntities(state.data, appendExistingStatusChanges(payload), 'key');
    }

    if (
      actionTypeProgressOrSuccess(type, [
        ACTION_TYPE.fetchDriverLogReviewDetailsByKey,
        ACTION_TYPE.fetchDriverLogsReviewDetails,
        ACTION_TYPE.reviewViolation,
      ])
    ) {
      draft.data = deepMergeEntities(state.data, payload, 'key');
    }
  });
