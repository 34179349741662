import { connect } from 'react-redux';

import { AppHeader } from './AppHeader';
import {
  selectMenu,
  selectSystemTenant,
  selectSystemUser,
  selectCurrentOus,
  setMenu,
  setActiveOu,
} from '../../../data/system';
import { messenger } from '../../../data/messenger';

export const AppHeaderContainer = connect(
  (state) => ({
    user: selectSystemUser(state),
    tenant: selectSystemTenant(state),
    ous: selectCurrentOus(state),
    menu: selectMenu(state),
    unreadCount: messenger.selectors.selectUnreadCount(state),
  }),
  {
    setMenu,
    setActiveOu,
  }
)(AppHeader);
