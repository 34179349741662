import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, Button, BasicDialog } from 'stti-react-common';
import { sortBy } from 'lodash';

import { customPropTypes } from '../../../helpers/customPropTypes';
import { textColorByHexLuminance } from '../../../helpers/styleHelpers';
import { Messenger } from '../Messenger';
import { AppNotifications } from '../AppNotifications';
import { FEATURES } from '../../../features';

import './AppHeader.scss';

const renderTabs = (tabs, brandPrimary, onClickFn, { category: selectedCategory }) =>
  tabs.map(({ key, name, icon }) => (
    <Button
      key={key}
      onClick={() => {
        onClickFn({ category: key, option: '' });
        window.location.href = `#/${key}`;
      }}
      label={name}
      icon={icon}
      style={{
        background: selectedCategory === key ? textColorByHexLuminance(brandPrimary) : brandPrimary,
        color: selectedCategory === key ? brandPrimary : textColorByHexLuminance(brandPrimary),
      }}
    />
  ));

export const AppHeader = ({
  user,
  tabs,
  tenant,
  ous,
  activeOu,
  menu,
  setMenu,
  setActiveOu,
  unreadCount,
}) => {
  const { logo, name: tenantName, brandPrimary } = tenant;

  const [dialogOpen, setDialogOpen] = useState(false);

  const sso = window.location.href.includes('code=');

  return (
    <div className="AppHeader" style={{ backgroundColor: sso ? '#a14b28' : brandPrimary }}>
      <div className="AppLogo">
        {logo ? (
          <img className="TenantLogo" src={logo} alt={tenantName} />
        ) : (
          <div
            className="TenantName"
            style={{ color: sso ? 'white' : textColorByHexLuminance(brandPrimary) }}
          >
            {sso ? 'Navistream' : tenantName}
          </div>
        )}
      </div>
      <div className="AppTabs">{renderTabs(tabs, brandPrimary, setMenu, menu)}</div>
      <div />
      <div className="OuList">
        {menu?.category !== 'fleetTracking' && !sso && (
          <SelectInput
            className={textColorByHexLuminance(brandPrimary)}
            options={sortBy(
              ous?.map(({ key, name }) => ({ value: key, label: name })),
              'label'
            )}
            value={activeOu?.key}
            label=" "
            onChange={(ev) => {
              const activeSelectedOu = ous.find((ou) => ou.key === ev);
              setActiveOu(activeSelectedOu);
            }}
          />
        )}
      </div>
      {menu?.category !== 'fleetTracking' && !sso && (
        <div className="AppChat">
          <Button
            onClick={() => setDialogOpen(true)}
            icon="chat_bubble_outline"
            style={{
              left: 44,
              background: brandPrimary,
              color: textColorByHexLuminance(brandPrimary),
            }}
          />
          {unreadCount > 0 && <div className="AppChat__UnreadCount">{unreadCount}</div>}
        </div>
      )}
      {menu?.category === 'fleetTracking' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {FEATURES.admin.hideNotifications ? <div style={{ flex: 1 }} /> : <AppNotifications />}
          <Button
            onClick={() => {
              window.localStorage.removeItem('oauth');
              window.localStorage.removeItem('menu');
              window.location.replace('/#/login');
              window.location.reload();
            }}
            icon="logout"
            style={{
              background: brandPrimary,
              color: textColorByHexLuminance(brandPrimary),
            }}
          />
        </div>
      )}
      <BasicDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullHeight
        fullWidth
        maxWidth="lg"
        className="Messenger__dialog"
      >
        <Messenger user={user} />
      </BasicDialog>
    </div>
  );
};

AppHeader.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  user: customPropTypes.user,
  tenant: customPropTypes.tenant,
  ous: customPropTypes.organizations,
  activeOu: customPropTypes.organization,
  menu: PropTypes.shape({
    category: PropTypes.string,
    option: PropTypes.string,
  }),
  setMenu: PropTypes.func.isRequired,
  setActiveOu: PropTypes.func,
  unreadCount: PropTypes.number,
};
