import { connect } from 'react-redux';

import { HOSReportSummary } from './Summary';

import {
  selectApi,
  selectAccessToken,
  selectCurrentOus,
  selectSystemUserRole,
  selectSystemUser,
  selectSystemActiveOu,
} from '../../../../../../data/system';

import { report } from './report.config';

export const HOSReportSummaryContainer = connect(
  (state) => ({
    api: selectApi(state),
    currentSystemOu: selectSystemActiveOu(state),
    currentSystemOus: selectCurrentOus(state),
    currentSystemUser: selectSystemUser(state),
    currentUserSystemRoles: selectSystemUserRole(state),
    limits: { name: 'driverlogs', forAllOus: 14, forOu: 59 },
    report: { ...report },
    token: selectAccessToken(state),
  }),
  {}
)(HOSReportSummary);
